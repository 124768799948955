import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useLocation } from 'react-router-dom';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import { delayResolve } from 'utils/delay';
import { joinName } from 'utils/join-name';
import BaseLayout from 'layouts/components/BaseLayout';
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import CreateFields from './components/CreateFields';
import CustomerDetails from './components/CustomerDetails';
import OrderDetails from './components/OrderDetails';

function DeliveryOrderDetails() {
  const [loading, setLoading] = useState(null);
  const [info, setInfo] = useState(null);
  const [orders, setOrders] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);

  const { id } = useParams();

  const { authentication, pageControl } = useStores();
  const { pathname } = useLocation();
  const [refetchToggle, setRefetchToggle] = useState(false);

  useEffect(() => {
    let routes = pathname.split('/').slice(2);
    routes[routes.length - 1] = joinName(info) || 'Delivery Order Info';
    pageControl.overwriteBreadcrumbsRoutes(pathname, routes);
  }, [pathname, info]);

  const fetchDeliveryOrderInfo = async () => {
    setLoading('Fetching delivery order info...');
    try {
      const { deliveryOrderInfo, orders: { models: orders }, statuses, shippingMethods } = await delayResolve(
        async () => ({
          deliveryOrderInfo: await ModuspaceService.GetDeliveryOrder(
            authentication.jwtToken,
            id,
          ),
          orders: await ModuspaceService.GetAllOrders(authentication.jwtToken),
          statuses: await ModuspaceService.GetDeliveryOrderStatuses(authentication.jwtToken),
          shippingMethods: await ModuspaceService.GetDeliveryOrderShippingMethods(authentication.jwtToken),
        }),
        100,
      );
      setInfo(deliveryOrderInfo);
      setOrders(orders?.map((order) => ({ label: `#${order.referenceOrderId}`, ...order })));
      setStatuses(statuses?.map(({ name }) => ({ id: name, label: name })));
      setShippingMethods(shippingMethods?.map(({ name }) => ({ id: name, label: name })));
      setRefetchToggle(false);
    } catch (err) {
      console.error('Error at fetchDeliveryOrderInfo:', err);
    }
    setLoading(null);
  };

  useEffect(() => {
    if (info === null && authentication.jwtToken || refetchToggle) {
      fetchDeliveryOrderInfo();
    }
  }, [info, authentication.jwtToken, refetchToggle]);

  const updateDeliveryOrder = async (deliveryOrderInfo) => {
    setLoading('Submitting');
    try {
      const updatedInfo = await ModuspaceService.UpdateDeliveryOrder(
        authentication.jwtToken,
        id,
        deliveryOrderInfo,
      );
      setInfo(updatedInfo);
      toast.success('Delivery order updated successfully');
    } catch (err) {
      console.error('Error at updateDeliveryOrder:', err);
    }
    setLoading(null);
  }

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CreateFields 
              info={info}
			        orders={orders}
              statuses={statuses}
              shippingMethods={shippingMethods}
              submitHandler={updateDeliveryOrder}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomerDetails customer={info?.customer} />
            <OrderDetails order={info?.salesOrder} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(DeliveryOrderDetails);