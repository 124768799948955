import { rolePermissions } from 'utils/constants';
export const getFormSchema = () => [
  {
    type: 'string',
    name: 'name',
    label: 'Role Name',
    placeholder: 'Write a role name',
    required: true,
    gridSpacing: 12
  },
  {
    type: 'string',
    multiline: true,
    name: 'description',
    label: 'Role Description',
    placeholder: 'Write a role description',
    required: true,
    gridSpacing: 12
  },
  {
    type: 'dropdown',
    name: 'status',
    options: ['Active', 'Locked', 'Inactive'],
    label: 'Status',
    placeholder: 'Select a status',
    required: true,
    gridSpacing: 12
  },
  // {
  //   type: "permission",
  //   name: "permissions",
  //   label: "Permissions",
  //   placeholder: "Select permissions",
  //   required: true,
  //   gridSpacing: 12,
  // },
  {
    type: 'multiple-dropdown',
    name: 'permissions',
    label: 'Permissions',
    placeholder: 'Select permission(s)',
    required: true,
    gridSpacing: 12,
    options: rolePermissions,
    readonly: true
  }
];
