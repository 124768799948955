/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { toast } from 'react-toastify';
import { useConfirmModal } from 'components/MDConfirm';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function ChangePassword({
  onSubmit = async () => void 0,
  onChange = () => void 0,
  onSubmitEmailGeneratedPassword = () => void 0,
  createMode = false,
  onSubmitRef = null
}) {
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    prompt: promptEmailGeneratedPassword,
    MDConfirm: MDConfirmEmailGeneratedPassword
  } = useConfirmModal({
    title: 'Generate New Password?',
    content:
      'This user will receive an email with a new generated password. Proceed?',
    onConfirm: onSubmitEmailGeneratedPassword
  });

  const validateFormData = () => {
    const cleanedPassword = password ?? '';
    setPassword(cleanedPassword);
    const isCleanedPasswordValid = ChangePassword.areRequiredFieldsValid(
      cleanedPassword,
      confirmPassword
    );

    if (!isCleanedPasswordValid) {
      toast.error('Check your password inputs!');
      return {
        error: true
      };
    }
    return {
      error: false,
      password: cleanedPassword,
      confirmPassword: confirmPassword
    };
  };

  if (onSubmitRef && createMode) {
    onSubmitRef.current = {
      onSubmitListener: validateFormData
    };
  }

  const handleSubmit = () => {
    const { error, password } = validateFormData();
    if (error) {
      return;
    }
    onSubmit(password).then(() => {
      setPassword(null);
      setConfirmPassword('');
    });
  };

  React.useEffect(() => {
    onChange(password, confirmPassword);
    // eslint-disable-next-line
  }, [password, confirmPassword]);

  const passwordRequirements = [
    'Password must be at least 7 characters long, and must contain at least one letter and number'
    // "Min 6 characters",
    // "One number (2 are recommended)",
  ];

  const isPasswordInvalid = () => ChangePassword.isPasswordInvalid(password);

  const isConfirmPasswordInvalid = () =>
    ChangePassword.isConfirmPasswordInvalid(password, confirmPassword);

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}>
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDConfirmEmailGeneratedPassword />
      <MDBox p={3}>
        <MDTypography variant="h5">
          {createMode ? 'Password' : 'Change Password'}
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              inputProps={{
                type: showPassword ? 'text' : 'password',
                autoComplete: ''
              }}
              value={password ?? ''}
              onChange={({ target: { value } }) => setPassword(value)}
              required={createMode}
              error={isPasswordInvalid()}
              helperText={
                isPasswordInvalid() ? (
                  <MDTypography variant="caption" color="error">
                    Password must satisfy requirements.
                  </MDTypography>
                ) : (
                  ' '
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Re-enter New Password"
              inputProps={{
                type: showPassword ? 'text' : 'password',
                autoComplete: ''
              }}
              value={confirmPassword ?? ''}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
              required={createMode}
              error={isConfirmPasswordInvalid()}
              helperText={
                isConfirmPasswordInvalid() ? (
                  <MDTypography variant="caption" color="error">
                    Passwords do not match.
                  </MDTypography>
                ) : (
                  ' '
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        <MDBox
          pr={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h6">Password requirements</MDTypography>
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row', marginLeft: 'auto' }}>
            {!createMode && (
              <MDButton
                variant="gradient"
                color="dark"
                size="large"
                onClick={promptEmailGeneratedPassword}>
                email new generated password
              </MDButton>
            )}
            {!createMode && (
              <MDButton
                variant="gradient"
                color="dark"
                size="large"
                style={{ marginLeft: '.5rem' }}
                onClick={handleSubmit}>
                update password
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {string} password
 * @returns
 */
ChangePassword.isPasswordInvalid = (password) => {
  if (password === null) return false;
  if (password.length < 7) return true;
  if (password.replace(/(?=.*?[0-9])(?=.*?[A-Za-z]).{6,}/g, '').length > 0)
    return true;
  return false;
};

/**
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
ChangePassword.isConfirmPasswordInvalid = (password, confirmPassword) => {
  if (confirmPassword === null) return false;
  if (password === null) return false;
  return confirmPassword !== password;
};

/**
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
ChangePassword.areRequiredFieldsValid = (password, confirmPassword) => {
  if (ChangePassword.isPasswordInvalid(password)) return false;
  if (ChangePassword.isConfirmPasswordInvalid(password, confirmPassword))
    return false;
  return true;
};

export default ChangePassword;
