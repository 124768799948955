import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

function SignOut() {
  const { authentication } = useStores();
  const navigate = useNavigate();
  React.useEffect(() => {
    authentication?.clear();
    // navigate(`/signin?warning=${encodeURIComponent('You have been signed out.')}`);
    navigate(`/signin`);
  }, []);
  return null;
}

export default observer(SignOut);
