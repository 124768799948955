import React, { useEffect } from 'react';
import { useForm } from 'components/MDForm';
import { Modal, Grid, Card, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { addressType } from 'utils/option';
import UserAddressSchema from './FormSchema/UserAddressSchema';

const TabModal = ({
  open,
  onClose,
  onCreate = () => 0,
  onUpdate = () => 0,
  currentInfo,
  countries = [],
  createMode,
  getStatesByCountryId = () => 0
}) => {
  countries =
    countries?.map((item) => ({ label: item.name, id: String(item.id) })) ?? [];
  const {
    components: formComponents,
    formData,
    validateForm,
    updateSchema,
    setFormData
  } = useForm(UserAddressSchema(countries, [], addressType));

  const countryId = formData?.country?.id ?? null;

  useEffect(async () => {
    if (!createMode && currentInfo) {
      const states = await getStatesByCountryId(currentInfo?.countryId);

      setFormData({
        ...currentInfo,
        country: countries.find(
          (item) => item.id === String(currentInfo.countryId)
        ),
        state:
          states?.find((item) => item.id === currentInfo.state) ??
          currentInfo.state,
        addressType: addressType.find(
          (item) => item.label === currentInfo.addressType
        )
      });
    }
  }, []);

  useEffect(async () => {
    if (countryId) {
      const states = await getStatesByCountryId(countryId);
      updateSchema(UserAddressSchema(countries, states, addressType, formData));
    }
  }, [countryId]);

  const handleCreate = () => {
    const { formData, error } = validateForm();

    if (!error) {
      const { country, state, addressType } = formData;
      let payload = {
        ...formData,
        countryId: country.id,
        state: typeof state === 'object' ? state.id : state,
        addressType: addressType.id
      };

      onCreate(payload);
    }
  };

  const handleUpdate = () => {
    const { formData, error } = validateForm();

    if (!error) {
      const { country, state, addressType } = formData;
      let payload = {
        ...formData,
        countryId: country.id,
        state: typeof state === 'object' ? state.id : state,
        addressType: addressType.id
      };

      onUpdate(currentInfo.id, payload);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ mx: 5 }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      {createMode && 'Add Customer Address'}
                      {!createMode && 'Edit Customer Address'}
                    </MDTypography>
                  </Grid>
                  <Grid item style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Complete the form below to save this customer address.
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
              <MDBox
                py={2}
                px={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}>
                {createMode ? (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleCreate}
                    sx={{ mx: 2 }}
                    size="large">
                    create
                  </MDButton>
                ) : (
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleUpdate}
                    sx={{ mx: 2 }}
                    size="large">
                    update
                  </MDButton>
                )}
                <MDButton
                  variant="gradient"
                  color="secondary"
                  size="large"
                  style={{ float: 'right' }}
                  onClick={onClose}>
                  Cancel
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Modal>
  );
};

export default TabModal;
