const DEFAULT_TIMEOUT = 500;

/** Do nothing for `time` ms */
export function delay(time) {
  return new Promise((res) => setTimeout(res, time ?? DEFAULT_TIMEOUT));
}

/**
 * Delay resolving of a Promise task for at least `time` ms
 *
 * @template T return value
 * @param {() => PromiseLike<T>} task
 * @returns {Promise<T>}
 */
export function delayResolve(task, time) {
  return Promise.all([delay(time), task()]).then((e) => e[1]);
}
