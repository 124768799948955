/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import FormField from 'components/MDFormField';
import { useConfirmModal } from 'components/MDConfirm';

import CommentListItem from 'pages/ideaboard/posts/components/CommentInfo/commentListItem';

function Comments({
  postComments = [],
  onFlag = () => void 0,
  onDelete = () => void 0
}) {
  const [currentCommentsInfo, setCurrentCommentsInfo] = React.useState([]);

  React.useEffect(() => {
    const info = postComments ?? [];
    setCurrentCommentsInfo(info);
  }, [postComments]);

  const { prompt: promptFlag, MDConfirm: MDConfirmFlag } = useConfirmModal({
    title: 'Confirm Flag Post Comment?',
    content:
      'Once you flag this comment, it will be hidden from post comments. Please be certain.',
    confirmColor: 'warning',
    confirmText: 'Flag',
    onConfirm: () => {
      onFlag();
    }
  });

  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: 'Confirm Delete Comment?',
    content:
      'Once you delete this comment, it will be remove from database. Please be certain.',
    confirmColor: 'error',
    confirmText: 'delete',
    onConfirm: () => {
      onDelete();
    }
  });

  return (
    <Card id="comments">
      <MDConfirmFlag />
      <MDConfirmDelete />
      <MDBox p={3} pb={1} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h6">Comments</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={3} sx={{ overflow: 'auto' }}>
        {currentCommentsInfo?.map((e) => {
          // if (e.status !== "Pending")
          return (
            <CommentListItem
              postComment={e}
              promptFlag={promptFlag}
              promptDelete={promptDelete}
              onFlag={onFlag}
              onDelete={onDelete}
            />
          );
        })}
      </MDBox>
    </Card>
  );
}

export default Comments;
