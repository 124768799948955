/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';

function DeleteTicket({ onClose, onReopen, status, deleted }) {
  const [showClose, setShowClose] = React.useState(true);

  React.useEffect(() => {
    if (status === 'Closed') {
      setShowClose(false);
    } else {
      setShowClose(true);
    }
  }, [status]);

  const { prompt: promptClose, MDConfirm: MDConfirmClose } = useConfirmModal({
    title: 'Confirm Close Ticket?',
    content:
      'Closing a ticket is a safer alternative than deleting it. You may recover the ticket later.',
    confirmColor: 'error',
    confirmText: 'close',
    onConfirm: onClose
  });

  return (
    <Card id="close-ticket">
      {!deleted && (
        <>
          <MDConfirmClose />
        </>
      )}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Close Ticket</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            You can choose close this ticket. A ticket can be reopened once
            closed.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {/* Close/reopen */}
          {showClose && (
            <MDButton
              variant="gradient"
              color="dark"
              size="large"
              onClick={promptClose}
              style={{ marginLeft: '.5rem' }}>
              close
            </MDButton>
          )}
          {!showClose && (
            <MDButton
              variant="gradient"
              color="success"
              size="large"
              onClick={onReopen}
              style={{ marginLeft: '.5rem' }}>
              reopen
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DeleteTicket;
