/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDChip from 'components/MDChip';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import InputAdornment from '@mui/material/InputAdornment';

// Settings page components
import FormField from 'layouts/components/FormField';

// Data
import selectData from 'pages/users/admins/components/BasicInfo/data/selectData';
import { isFalsyExceptNumber } from 'utils/falsy';
import { displayDate } from 'utils/date';
import { toast } from 'react-toastify';

var validator = require('email-validator');

function BasicInfo({
  userInfo,
  onChangeSubmit = () => void 0,
  onChange = () => void 0,
  createMode = false,
  countries,
  roles,
  onSubmitRef
}) {
  const [currentUserInfo, setCurrentUserInfo] = React.useState({});
  const [currentRoles, setCurrentRoles] = React.useState([]);
  const [confirmEmail, setConfirmEmail] = React.useState('');

  React.useEffect(() => {
    const info = userInfo ?? {};
    setCurrentUserInfo(info);
  }, [userInfo]);

  React.useEffect(() => {
    // console.log(currentUserInfo);
    onChange(currentUserInfo);
    // eslint-disable-next-line
  }, [currentUserInfo]);

  React.useEffect(() => {
    const r = roles ?? [];
    setCurrentRoles([
      ...r.filter((e) => e.status !== 'Inactive'),
      ...r.filter((e) => e.status === 'Inactive')
    ]);
    if (createMode) {
      //if it is create mode, set default role customer
      setCurrentUserInfo({
        ...currentUserInfo,
        applicationRoles: r.filter((e) => e.type === 'Admin')
      });
    }
  }, [roles]);

  const getUserRoles = () => {
    const roles = currentUserInfo.applicationRoles || [];
    return roles;
  };

  const onChangeRoles = (selectedRoles) => {
    setCurrentUserInfo({
      ...currentUserInfo,
      applicationRoles: selectedRoles
    });
  };

  const validateFormData = () => {
    const newUserInfo = BasicInfo.fillEmptyRequiredFields(currentUserInfo);
    const isNewUserInfoValid = BasicInfo.areRequireFieldsValid(newUserInfo);
    const isRolesInvalid = BasicInfo.isRolesInvalid(currentUserInfo);

    setCurrentUserInfo(newUserInfo);
    if (!isNewUserInfoValid || isRolesInvalid) {
      toast.error('Fill in the required basic info first!');
      return {
        error: true
      };
    }
    return {
      error: false,
      userInfo: newUserInfo
    };
  };

  if (onSubmitRef && createMode) {
    onSubmitRef.current = {
      onSubmitListener: validateFormData
    };
  }

  const handleSubmit = () => {
    const { error, userInfo } = validateFormData();
    if (!error) onChangeSubmit(userInfo);
  };

  const nonEmptyProps = (field) => ({
    error: BasicInfo.isFieldInvalid(field),
    helperText: BasicInfo.isFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : null,
    required: true
  });

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <MDBox pt={3} px={3}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize">
          Basic Informations
        </MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              placeholder="e.g. John"
              value={currentUserInfo.firstName ?? ''}
              onChange={({ target: { value: firstName } }) =>
                setCurrentUserInfo({
                  ...currentUserInfo,
                  firstName
                })
              }
              {...nonEmptyProps(currentUserInfo.firstName)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Last Name"
              placeholder="e.g. Doe"
              value={currentUserInfo.lastName ?? ''}
              onChange={({ target: { value: lastName } }) =>
                setCurrentUserInfo({
                  ...currentUserInfo,
                  lastName
                })
              }
              {...nonEmptyProps(currentUserInfo.lastName)}
            />
          </Grid>
          {/* for create user */}
          {createMode && (
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Email"
                    placeholder="example@email.com"
                    inputProps={{ type: 'email' }}
                    value={currentUserInfo.email ?? ''}
                    onChange={({ target: { value: email } }) =>
                      setCurrentUserInfo({
                        ...currentUserInfo,
                        email
                      })
                    }
                    error={
                      (typeof currentUserInfo.email === 'string' &&
                        !validator.validate(currentUserInfo.email)) ||
                      (confirmEmail.length > 0 &&
                        confirmEmail !== currentUserInfo.email)
                    }
                    helperText={
                      typeof currentUserInfo.email === 'string' &&
                      !validator.validate(currentUserInfo.email) ? (
                        <MDTypography variant="caption" color="error">
                          Invalid email.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Confirmation Email"
                    placeholder="example@email.com"
                    inputProps={{ type: 'email' }}
                    value={confirmEmail}
                    onChange={({ target: { value: email } }) =>
                      setConfirmEmail(email)
                    }
                    error={
                      confirmEmail.length > 0 &&
                      confirmEmail !== currentUserInfo.email
                    }
                    helperText={
                      confirmEmail.length > 0 &&
                      confirmEmail !== currentUserInfo.email ? (
                        <MDTypography variant="caption" color="error">
                          Your email does not match.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* for update user */}
          {!createMode && (
            <Grid item xs={12} sm={12}>
              <FormField
                label="Email"
                placeholder="example@email.com"
                inputProps={{ type: 'email' }}
                value={currentUserInfo.email ?? ''}
                onChange={({ target: { value: email } }) =>
                  setCurrentUserInfo({
                    ...currentUserInfo,
                    email
                  })
                }
                error={
                  typeof currentUserInfo.email === 'string' &&
                  !validator.validate(currentUserInfo.email)
                }
                helperText={
                  typeof currentUserInfo.email === 'string' &&
                  !validator.validate(currentUserInfo.email) ? (
                    <MDTypography variant="caption" color="error">
                      Invalid email.
                    </MDTypography>
                  ) : null
                }
              />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            {/* <Autocomplete
              multiple
              value={getUserRoles()}
              options={currentRoles}
              getOptionLabel={(option) => (option.status === "Inactive" ? `${option.name} (Inactive)` : option.name)}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Role"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Choose a role"
                    error={isRolesInvalid()}
                    helperText={
                      isRolesInvalid() ? (
                        <MDTypography variant="caption" color="error">
                          You must pick at least one role.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                );
              }}
              onChange={(_, selectedRoles) => onChangeRoles(selectedRoles)}
              disabled
            /> */}
            <MDBox py={1}>
              <MDInput
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Role"
                InputProps={{
                  startAdornment: getUserRoles().map((item) => (
                    <InputAdornment position="start">
                      <MDChip size="small" text={item.name} varient="filled" />
                    </InputAdornment>
                  )),
                  readOnly: true
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        {/* activities */}
        {!createMode && (
          <>
            <MDBox pt={2}>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize">
                Activities
              </MDTypography>
            </MDBox>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12} sx={{ display: 'flex' }}>
                <Card sx={{ boxShadow: 'none' }}>
                  <MDBox pt={1} pb={2} lineHeight={1.25}>
                    {/* <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="capitalize">
                      Activities
                    </MDTypography> */}
                    <MDBox alignItems="center" mb={0.5}>
                      <MDBox mt={0.5}>
                        <MDTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize">
                          Created Since:{' '}
                          <b>
                            {currentUserInfo?.createdAt
                              ? displayDate(
                                  new Date(currentUserInfo?.createdAt)
                                )
                              : '-'}
                          </b>
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={0.5}>
                        <MDTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize">
                          Last Updated:{' '}
                          <b>
                            {currentUserInfo?.updatedAt
                              ? displayDate(
                                  new Date(currentUserInfo?.updatedAt)
                                )
                              : '-'}
                          </b>
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={0.5}>
                        <MDTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          textTransform="capitalize">
                          Last Login:{' '}
                          <b>
                            {currentUserInfo?.lastLoginAt
                              ? displayDate(
                                  new Date(currentUserInfo?.lastLoginAt)
                                )
                              : '-'}
                          </b>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </>
        )}

        {!createMode && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap">
            <MDBox mt={3} ml="auto">
              <MDButton
                variant="gradient"
                color="dark"
                size="large"
                onClick={handleSubmit}>
                update profile
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {{
 * userName?: "",
 * firstName?: "",
 * lastName?: "",
 * email?: "",
 * phone?: "",
 * countryId?: number | null,
 * applicationRoles?: [],
 * }} userInfo
 */
BasicInfo.fillEmptyRequiredFields = (userInfo) => {
  return {
    // userName: "",
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    applicationRoles: [],
    ...userInfo,
    countryId: userInfo.countryId === undefined ? null : userInfo.countryId,
    dob: userInfo.dob === undefined ? null : userInfo.dob
  };
};

/**
 * @param {{userName?: string}} currentUserInfo
 */
BasicInfo.isUserNameInvalid = (currentUserInfo) =>
  typeof currentUserInfo.userName === 'string' &&
  !!(
    currentUserInfo.userName.length === 0 ||
    currentUserInfo.userName.replace(/[a-z0-9_-]/gi, '').length
  );

/**
 * @param {{phone?: string}} currentUserInfo
 */
BasicInfo.isPhoneNumberInvalid = (currentUserInfo) =>
  typeof currentUserInfo.phone === 'string' &&
  !!(
    currentUserInfo.phone.length === 0 ||
    currentUserInfo.phone.replace(/[0-9_+ -]/gi, '').length ||
    currentUserInfo.phone.replace(/[^0-9]/gi, '').length < 8
  );

/**
 * @param {{countryId: number | null}} currentUserInfo
 */
BasicInfo.isCountryInvalid = (currentUserInfo) =>
  currentUserInfo.countryId === null;

/**
 *
 * @param {string | undefined} field
 * @returns
 */
BasicInfo.isFieldInvalid = (field) =>
  typeof field === 'string' && field.length === 0;

/**
 *
 * @param {{ applicationRoles?: any[] }} currentUserInfo
 * @returns
 */
BasicInfo.isRolesInvalid = (currentUserInfo) =>
  currentUserInfo.applicationRoles instanceof Array &&
  currentUserInfo.applicationRoles.length === 0;

/**
 * Get clean value from `BasicInfo.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  userName: "",
 *  firstName: "",
 *  lastName: "",
 *  email: "",
 *  phone: "",
 *  countryId: number | null,
 *  applicationRoles: [],
 * }} userInfo
 */
BasicInfo.areRequireFieldsValid = (userInfo) => {
  // if (BasicInfo.isUserNameInvalid(userInfo)) return false;
  // if (BasicInfo.isPhoneNumberInvalid(userInfo)) return false;
  // if (BasicInfo.isCountryInvalid(userInfo)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.firstName)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.lastName)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.email)) return false;
  if (BasicInfo.isRolesInvalid(userInfo.applicationRoles)) return false;
  return true;
};

export default BasicInfo;
