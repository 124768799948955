// All functions return false for field === null.

import { validate } from 'email-validator';

export function isFieldInvalidEmail(field) {
  return typeof field === 'string' && !validate(field);
}

export function isFieldInvalidString(field) {
  return typeof field === 'string' && field.length === 0;
}

export function isFieldInvalidNumber(field) {
  if (field === null) return false;
  if (typeof field === 'string' && field.length === 0) field = undefined;
  return isNaN(Number(field));
}

export const isFieldInvalidPhoneNumber = (field) => {
  const phoneRegex =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  if (field === null) return false;

  return (
    field.replace(/[^0-9]/gi, '').length < 7 ||
    field.replace(/[0-9_+ -.]/gi, '').length > 0 ||
    !phoneRegex.test(field)
  );
};
