import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { Checkbox } from '@mui/material';
import { delayResolve } from "utils/delay";
import { Link } from "react-router-dom";
import { joinPath } from "utils/join-url";
import { joinName } from "utils/join-name";
import { displayDate } from "utils/date";
import { importOrderValidator } from "./util/validator";
import { getOrderUploadToken, importOrders } from "./util/import";
import ordersTemplate from "./data/ordersTemplate.json";

function OrderManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [applicationClients, setApplicationClients] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: "",
    type: "", // asc or desc
  });
  // For bulk actions
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    setSelectedRows([]);
    try {
      if (!tablePagination?.pageSize || sortBy?.type === "") {
        return;
      }
      const {
        list: { list, pagination },
        statuses,
        applicationClients,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListAllOrders(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            sortBy,
          ),
          statuses: await ModuspaceService.GetOrderBigCommerceStatuses(
            authentication.jwtToken,
          ),
          applicationClients:
            await ModuspaceService.GetContentApplicationClients(
              authentication.jwtToken,
            ),
        }),
        100,
      );
      // If customer sso is not present, then add billing_address data to customer
      list?.forEach((order) => {
        if (!order.customerId) {
          order.customer = {
            firstName: order.billing_address?.first_name,
            lastName: order.billing_address?.last_name,
            email: order.billing_address?.email,
          };
        }
      });
      setData(list);
      setStatuses(statuses?.map((status) => ({ label: status, id: status })));
      setApplicationClients(
        applicationClients?.map((client) => ({
          label: client.label,
          id: client.name,
        })),
      );
      setTotalCount(pagination.total);
    } catch (err) {
      console.error("OrderManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListAllOrders(
        authentication.jwtToken,
        Number.MAX_SAFE_INTEGER,
        1,
        appliedFilter ? dataFilters : [],
      );
      return list;
    } catch (err) {
      console.error("OrderManagement:", err);
    }
  }

  const selectAllRows = () => {
    data.forEach((row) => (row.__checked = allRowsSelected));
    setData([...data]);
    setAllRowsSelected(!allRowsSelected);
    setSelectedRows(allRowsSelected ? [] : data.map((row) => row.id));
  }

  const bulkActionHandler = async (action) => {
    // Map selected rows to order objects containing only id and status
    const status = 
      action === 'STATUS_AWAITING_PAYMENT'
        ? 'Awaiting Payment'
        : action === 'STATUS_AWAITING_FULFILLMENT'
        ? 'Awaiting Fulfillment'
        : action === 'STATUS_AWAITING_SHIPMENT'
        ? 'Awaiting Shipment'
        : action === 'STATUS_PARTIALLY_SHIPPED'
        ? 'Partially Shipped'
        : action === 'STATUS_CANCELLED'
        ? 'Cancelled'
        : action === 'STATUS_REFUNDED'
        ? 'Refunded'
        : action === 'STATUS_COMPLETED'
        ? 'Completed'
        : null;
    setRefreshing(true);
    try {
      if (action.startsWith('STATUS_')) {
        // Confirm the action
        if(window.confirm(`Are you sure you want to change the status of ${selectedRows.length} orders to ${status}?`)) {
          const updatedRows = await ModuspaceService.BulkUpdateSalesOrders(
            authentication.jwtToken,
            {
              ids: selectedRows,
              columns: { 
                orderStatus: status,
              },
            }
          );
          if (updatedRows.length > 0) {
            init();
          }
        }
      }
    } catch (err) {
      console.error("OrderManagement:", err);
    }
    setSelectedRows([]);
    setRefreshing(false);
  }

  // The alias property is used to get the value from the nested object. Useful for sorting in the backend.
  const tableInfo = [
    {
      label: <Checkbox style={{ padding: 0 }} onClick={selectAllRows} />,
      id: 'checkBox',
      width: '2%',
      disableSortBy: true
    },
    { 
      label: "Order ID",
      id: "referenceOrderId", 
      width: "3%",
      tableRowMapper: (value, row) => {
        return (
          <Link
            className="custom-link"
            to={joinPath("/dashboard/orders/", row.id)}
          >
            {value}
          </Link>
        );
      }
    },
    {
      label: "Order date",
      id: "orderDate",
      tableRowMapper: (value) => `${displayDate(new Date(value))}`,
    },
    { label: "Status", id: "orderStatus" },
    {
      label: "Store",
      id: "applicationClient",
      alias: "applicationClient.name",
      width: "3%",
      tableRowMapper: (value) => {
        // If ssotype is bigcommerce and name starts with moduspace, then get the part after `moduspace-`
        if (
          value?.ssoType === "bigcommerce" &&
          value?.name?.startsWith("moduspace")
        ) {
          return value?.name?.split("-")[1]?.toUpperCase();
        }
        return value?.name?.toUpperCase();
      },
    },
    {
      label: "Currency",
      id: "orderCurrency",
      alias: "orderCurrency.shortName",
      tableRowMapper: (value) => `${value.shortName}`,
    },
    {
      label: "Amount",
      id: "orderTotalIncTax",
      tableRowMapper: (value, row) => `${row.orderCurrency?.symbol}${value}`,
    },
    {
      label: "Payment",
      id: "totalAmountPaid",
      tableRowMapper: (value) => {
        return value.toFixed(2);
      },
    },
    {
      label: "Customer",
      id: "customer",
      alias: "customer.firstName",
      tableRowMapper: (value, row) => {
        return row.customerId ? (
          <Link
            className="custom-link"
            to={joinPath("/dashboard/customers/", row.customerId)}
            target="_blank">
            {joinName(value)}
          </Link>
        ) : (
          <span
            style={{
              color: "#EF5350",
              fontWeight: "bold",
            }}>
            {joinName(value)}
          </span>
        );
      },
    },
  ];

  const sortInfo = [
    { name: "orderTotalIncTax", rowMapper: (value) => Number(value) },
    { name: "customer", rowMapper: (value) => joinName(value) },
  ];

  const importInfo = [
    {
      title: "Import Orders",
      fileName: "orders-upload",
      validator: (data) => importOrderValidator(data),
      getSession: async () => getOrderUploadToken(authentication.jwtToken),
      importData: async (data) => importOrders(authentication.jwtToken, data),
      importDataExample: {
        title: "Order import file template",
        data: ordersTemplate,
      },
      importColumn: [
        { label: "Order ID", name: "Order ID" },
        { label: "Order Status", name: "Order Status" },
        { label: "Order Date", name: "Order Date" },
        { label: "Order Time", name: "Order Time" },
        { label: "Subtotal (inc tax)", name: "Subtotal (inc tax)" },
        { label: "Subtotal (ex tax)", name: "Subtotal (ex tax)" },
        { label: "Tax Total", name: "Tax Total" },
        { label: "Shipping Cost (inc tax)", name: "Shipping Cost (inc tax)" },
        { label: "Shipping Cost (ex tax)", name: "Shipping Cost (ex tax)" },
        { label: "Handling Cost (inc tax)", name: "Handling Cost (inc tax)" },
        { label: "Handling Cost (ex tax)", name: "Handling Cost (ex tax)" },
        { label: "Coupon Details", name: "Coupon Details" },
        { label: "Order Total (inc tax)", name: "Order Total (inc tax)" },
        { label: "Order Total (ex tax)", name: "Order Total (ex tax)" },
        { label: "Refund Amount", name: "Refund Amount" },
        { label: "Customer ID", name: "Customer ID" },
        { label: "Customer Name", name: "Customer Name" },
        { label: "Customer Email", name: "Customer Email" },
        { label: "Customer Phone", name: "Customer Phone" },
        { label: "Ship Method", name: "Ship Method" },
        { label: "Payment Method", name: "Payment Method" },
        { label: "Store Credit Redeemed", name: "Store Credit Redeemed" },
        {
          label: "Gift Certificate Amount Redeemed",
          name: "Gift Certificate Amount Redeemed",
        },
        { label: "Gift Certificate Code", name: "Gift Certificate Code" },
        {
          label: "Gift Certificate Expiration Date",
          name: "Gift Certificate Expiration Date",
        },
        { label: "Total Quantity", name: "Total Quantity" },
        { label: "Total Shipped", name: "Total Shipped" },
        { label: "Date Shipped", name: "Date Shipped" },
        { label: "Order Currency Code", name: "Order Currency Code" },
        { label: "Exchange Rate", name: "Exchange Rate" },
        { label: "Order Notes", name: "Order Notes" },
        { label: "Customer Message", name: "Customer Message" },
        { label: "Billing Name", name: "Billing Name" },
        { label: "Billing First Name", name: "Billing First Name" },
        { label: "Billing Last Name", name: "Billing Last Name" },
        { label: "Billing Company", name: "Billing Company" },
        { label: "Billing Street 1", name: "Billing Street 1" },
        { label: "Billing Street 2", name: "Billing Street 2" },
        { label: "Billing Suburb", name: "Billing Suburb" },
        { label: "Billing State", name: "Billing State" },
        {
          label: "Billing State Abbreviation",
          name: "Billing State Abbreviation",
        },
        { label: "Billing Zip", name: "Billing Zip" },
        { label: "Billing Country", name: "Billing Country" },
        {
          label: "Billing Suburb + State + Zip",
          name: "Billing Suburb + State + Zip",
        },
        { label: "Billing Phone", name: "Billing Phone" },
        { label: "Billing Email", name: "Billing Email" },
        {
          label: "Merchant-defined Order Status",
          name: "Merchant-defined Order Status",
        },
        { label: "Shipping Name", name: "Shipping Name" },
        { label: "Shipping First Name", name: "Shipping First Name" },
        { label: "Shipping Last Name", name: "Shipping Last Name" },
        { label: "Shipping Company", name: "Shipping Company" },
        { label: "Shipping Street 1", name: "Shipping Street 1" },
        { label: "Shipping Street 2", name: "Shipping Street 2" },
        { label: "Shipping Suburb", name: "Shipping Suburb" },
        { label: "Shipping State", name: "Shipping State" },
        { label: "Shipping Zip", name: "Shipping Zip" },
        { label: "Shipping Country", name: "Shipping Country" },
        { label: "Shipping Phone", name: "Shipping Phone" },
        { label: "Shipping Email", name: "Shipping Email" },
        { label: "Customer Group Name", name: "Customer Group Name" },
        { label: "Product Details", name: "Product Details" },
        {
          label: "# Unique Products in Order",
          name: "# Unique Products in Order",
        },
        { label: "Combined Product Weight", name: "Combined Product Weight" },
        { label: "Order Source", name: "Order Source" },
        { label: "Channel ID", name: "Channel ID" },
        { label: "Channel Name", name: "Channel Name" },
        { label: "Transaction ID", name: "Transaction ID" },
      ],
    },
  ];

  const filterInfo = [
    {
      name: "multiField",
      label: "Keywords",
      type: "string",
      placeholder: "Order ID, Customer name, email, phone number",
      searchColumn:
        "referenceOrderId,customer.first_name,customer.last_name,customer.email,customer.phone,billing_address",
    },
    {
      name: "orderDate",
      label: "Order date",
      type: "dateRange",
      searchColumn: "orderDate",
    },
    {
      name: "status",
      optionName: "status",
      label: "Status",
      type: "multiSelect",
      searchColumn: "orderStatus",
      options: statuses,
    },
    {
      name: "store",
      optionName: "store",
      label: "Store",
      type: "multiSelect",
      searchColumn: "applicationClient.name,applicationClient.label",
      options: applicationClients,
    },
  ];

  const exportOrderInfo = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Order ID",
      name: "referenceOrderId",
    },
    {
      label: "Order Status",
      name: "orderStatus",
    },
    {
      label: "Order Date",
      name: "orderDate",
    },
    {
      label: "Subtotal (inc tax)",
      name: "subtotalIncTax",
    },
    {
      label: "Subtotal (ex tax)",
      name: "subtotalExTax",
    },
    {
      label: "Tax Total",
      name: "taxTotal",
    },
    {
      label: "Shipping Cost (inc tax)",
      name: "shippingCostIncTax",
    },
    {
      label: "Shipping Cost (ex tax)",
      name: "shippingCostExTax",
    },
    {
      label: "Handling Cost (inc tax)",
      name: "handlingCostIncTax",
    },
    {
      label: "Handling Cost (ex tax)",
      name: "handlingCostExTax",
    },
    {
      label: "Refunded Amount",
      name: "refundedAmount",
    },
    {
      label: "Shipping Method",
      name: "shippingMethod",
    },
    {
      label: "Payment Method",
      name: "paymentMethod",
    },
    {
      label: "Store Credit Redeemed",
      name: "storeCreditRedeemed",
    },
    {
      label: "Gift Certificate Amount Redeemed",
      name: "giftCertificateAmountRedeemed",
    },
    {
      label: "Total Quantity",
      name: "totalQuantity",
    },
    {
      label: "Total Shipped",
      name: "totalShipped",
    },
    {
      label: "Date Shipped",
      name: "dateShipped",
    },
    {
      label: "Order Currency Code",
      name: "orderCurrency.shortName",
    },
    {
      label: "Exchange Rate",
      name: "exchangeRate",
    },
    {
      label: "Order Notes",
      name: "orderNotes",
    },
    {
      label: "Customer Message",
      name: "customerMessage",
    },
    {
      label: "Unique Products",
      name: "uniqueProducts",
    },
    {
      label: "Product Details",
      name: "productDetails",
    },
    {
      label: "Combined Product Weight",
      name: "combinedProductWeight",
    },
    {
      label: "Billing First Name",
      name: "billing_address.first_name",
    },
    {
      label: "Billing Last Name",
      name: "billing_address.last_name",
    },
    {
      label: "Billing Company",
      name: "billing_address.company",
    },
    {
      label: "Billing Street 1",
      name: "billing_address.street_1",
    },
    {
      label: "Billing Street 2",
      name: "billing_address.street_2",
    },
    {
      label: "Billing City",
      name: "billing_address.city",
    },
    {
      label: "Billing State",
      name: "billing_address.state",
    },
    {
      label: "Billing Zip",
      name: "billing_address.zip",
    },
    {
      label: "Billing Country",
      name: "billing_address.country",
    },
    {
      label: "Billing Phone",
      name: "billing_address.phone",
    },
    {
      label: "Billing Email",
      name: "billing_address.email",
    },
    {
      label: "Order Source",
      name: "orderSource",
    },
    {
      label: "Channel ID",
      name: "channelId",
    },
    {
      label: "Channel Name",
      name: "applicationClient.label",
    },
    {
      label: "Transaction ID",
      name: "transactionId",
    },
  ];

  const exportProductListingInfo = [
    {
      label: "SKU",
      name: "sku",
    },
    {
      label: "Product Name",
      name: "name",
    },
    {
      label: "Order ID",
      name: "base.referenceOrderId",
    },
    {
      label: "Qty",
      name: "quantity",
    },
    {
      label: "Price",
      name: "base_price",
    },
    {
      label: "Display Colour",
      name: "product_options",
      rowMapper: (obj, rows) => {
        let jsonFields = obj;
        if (typeof jsonFields === "string") jsonFields = JSON.parse(jsonFields);

        return (
          jsonFields.find(
            (x) => x.display_name?.toUpperCase() === "DISPLAY COLOUR",
          )?.display_value ?? "-"
        );
      },
    },
    {
      label: "Dimensions",
      name: "product_options",
      rowMapper: (obj, rows) => {
        let jsonFields = obj;
        if (typeof jsonFields === "string") jsonFields = JSON.parse(jsonFields);

        return (
          jsonFields.find((x) => x.display_name?.toUpperCase() === "DIMENSIONS")
            ?.display_value ?? "-"
        );
      },
    },
    {
      label: "Lights",
      name: "product_options",
      rowMapper: (obj, rows) => {
        let jsonFields = obj;
        if (typeof jsonFields === "string") jsonFields = JSON.parse(jsonFields);

        return (
          jsonFields.find((x) => x.display_name?.toUpperCase() === "LIGHTS")
            ?.display_value ?? "-"
        );
      },
    },
  ];

  return (
    <>
      <MDGeneralListingV3
        // for filtering
        filterInfo={filterInfo}
        dataFilters={dataFilters}
        setDataFilters={setDataFilters}
        // for sorting
        sortInfo={sortInfo}
        sortBy={sortBy}
        setSortBy={setSortBy}
        // for table rendering
        _data={data}
        tableInfo={tableInfo}
        disabledSortColumns={["next-steps"]}
        dateColumn={["registeredAt", "createdAt"]}
        tablePagination={tablePagination}
        setTablePagination={setTablePagination}
        totalCount={totalCount} // all available data count
        // for action
        rowIDAccessor="id"
        allowCreate={false}
        createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
          editButton
        }
        bulkActions={{
          selectedData: selectedRows,
          setSelectedData: setSelectedRows,
          options: [
            {
              id: 'STATUS_AWAITING_PAYMENT',
              label: 'Set status to Awaiting Payment',
            },
            {
              id: 'STATUS_AWAITING_FULFILLMENT',
              label: 'Set status to Awaiting Fulfillment',
            },
            {
              id: 'STATUS_AWAITING_SHIPMENT',
              label: 'Set status to Awaiting Shipment',
            },
            {
              id: 'STATUS_PARTIALLY_SHIPPED',
              label: 'Set status to Partially Shipped',
            },
            {
              id: 'STATUS_CANCELLED',
              label: 'Set status to Cancelled',
            },
            {
              id: 'STATUS_REFUNDED',
              label: 'Set status to Refunded',
            },
            {
              id: 'STATUS_COMPLETED',
              label: 'Set status to Completed',
            },
          ],
          bulkActionHandler: bulkActionHandler,
        }}
        // for exports
        exportInfo={[
          {
            buttonName: "Order",
            exportFileNamePrefix: "orders",
            fields: exportOrderInfo,
          },
          {
            buttonName: "Product",
            exportFileNamePrefix: "orders_product_listing",
            exportTargetKey: "products",
            fields: exportProductListingInfo,
          },
        ]}
        isTreeExport={true}
        fetchAllExportData={fetchAllExportData}
        // for common usage
        title="Orders"
        currentActiveParent="orders"
        currentActiveTab="orders"
        init={init}
        isRefreshing={isRefreshing}
        importInfo={importInfo}
      />
    </>
  );
}

export default observer(OrderManagement);
