import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Card, Grid, List, ListItem, ListItemText } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';
import { joinName } from 'utils/join-name';
import { displayDate } from 'utils/date';
import { useConfirmModal } from 'components/MDConfirm';

function WarrantyClaimDetails() {
  const [loading, setLoading] = React.useState('');
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const { pageControl, authentication } = useStores();

  const { claimForm } = getFormSchema();

  const {
    components: claimFormComponent,
    validateForm: validateClaimForm,
    setFormData: setClaimFormData
  } = useForm(claimForm);

  // >>> Button Control: Claim Started >>>

  /// <<< Button Control: Claim Started <<<
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedWarranty, setSelectedWarranty] = React.useState(null);

  React.useEffect(() => {
    if (authentication.jwtToken && selectedWarranty === null) fetchInfo();
  }, [selectedWarranty, authentication.jwtToken]);

  const fetchInfo = async () => {
    setLoading('Fetching metadata...');
    try {
      const claim = await delayResolve(() =>
        ModuspaceService.GetWarrantyClaimById(authentication.jwtToken, id)
      );
      setSelectedWarranty(claim.warranty);
      const newFormData = {
        amount: claim.amount,
        void_immediately: null
      };
      setClaimFormData(newFormData);
    } catch (err) {
      console.error('Error at fetchInfo:', { err });
    }
    setLoading('');
  };

  const handleSubmit = async () => {
    const { formData, error } = validateClaimForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    const newFormData = {
      amount: formData.amount,
      warrantyDd: selectedWarranty.id,
      voidImmediately: formData.void_immediately === 'Void Immediately'
    };
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateWarrantyClaimById(
          authentication.jwtToken,
          id,
          newFormData
        )
      );
      if (response) {
        setUpdated(true);
        setSelectedWarranty(response.warranty);
        const updatedFormData = {
          amount: response.amount,
          void_immediately: null
        };
        setClaimFormData(updatedFormData);
        toast.success('Warranty claim has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to update warranty claim.');
    }
    setLoading('');
  };

  const handleDelete = async () => {
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteWarrantyClaimById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success('Warranty claim has been deleted.');
      }
    } catch (err) {
      console.error('Error at submitting:', { err });
      toast.error('Fail to delete warranty claim.');
    }
    setLoading(null);
  };

  const {
    prompt: promptDeleteWarrantyClaim,
    MDConfirm: MDConfirmDeleteWarrantyClaimModal
  } = useConfirmModal({
    title: 'Delete ',
    content:
      'This claim record will still be accessible, but voiding a warranty claim will exclude the claim transaction from the total claim amount.',
    confirmText: 'Delete',
    confirmColor: 'error',
    onConfirm: handleDelete
  });

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/warranties/claims');
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteWarrantyClaimModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Claim Warranty
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Successful claim that was registered for this warranty
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {claimFormComponent}
                </Grid>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography
                      variant="subtitle"
                      style={{ fontWeight: 'bold' }}>
                      Selected warranty info:
                    </MDTypography>
                  </Grid>
                  <Grid item key="warranty-plan-info" xs={12}>
                    <List className="warranty-info-list">
                      <ListItem>
                        <ListItemText
                          primary="Customer Name"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : joinName(selectedWarranty.user)
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Device"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : `${selectedWarranty.devices[0].deviceModel.name} (Serial No: ${selectedWarranty.devices[0].serialNo})`
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Warranty Plan"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : `${selectedWarranty.warrantyPlan.name} (${
                                  selectedWarranty.warrantyPlan.active
                                    ? 'Active'
                                    : 'Inactive'
                                })`
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Warranty Plan Eligible Claim"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : `${selectedWarranty.warrantyPlan.amount} SGD`
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Claimed Amount (Including Current)"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : `${selectedWarranty.warrantyClaims.reduce(
                                  (a, b) => a + b.amount,
                                  0
                                )} SGD`
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Warranty Period"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : `${displayDate(
                                  new Date(selectedWarranty.startDate)
                                )} until ${displayDate(
                                  new Date(selectedWarranty.endDate)
                                )}`
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Warranty Voided"
                          secondary={
                            !selectedWarranty
                              ? 'Loading...'
                              : selectedWarranty.voided
                              ? 'Yes'
                              : 'No'
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                py={3}
                px={3}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleSubmit}
                  size="large"
                  style={{ marginLeft: 'auto' }}>
                  update claim
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">
                      Void Warranty Claim
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Voiding a warranty claim will exclude the claim transaction
                    from the claimed history.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={promptDeleteWarrantyClaim}
                    size="large">
                    void claim
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(WarrantyClaimDetails);
