import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';
import { joinName } from 'utils/join-name';
import { displayDate } from 'utils/date';

function WarrantyPlanCreate() {
  const [loading, setLoading] = React.useState('');
  const [claim, setClaim] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const { pageControl, authentication } = useStores();

  const { searchForm, claimForm } = getFormSchema();

  const {
    components: searchWarrantyComponent,
    formData: searchData,
    validateForm: validateSearchForm
  } = useForm(searchForm);
  const { components: claimFormComponent, validateForm: validateClaimForm } =
    useForm(claimForm);

  // >>> Button Control: Search Warranty >>>
  const [searchResult, setSearchResult] = React.useState(null);
  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, [searchResult]);
  const searchWarranty = async () => {
    const { formData, error } = validateSearchForm();
    if (error) {
      toast.error('Please input the correct value in the search boxes.');
      return;
    }
    if (
      (formData.user_email === null || formData.user_email.length === 0) &&
      (formData.device_serial_no === null ||
        formData.device_serial_no.length === 0)
    ) {
      toast.error('Please fill in at least one input box.');
      return;
    }
    setLoading('Searching...');
    try {
      const newSearchResult = await delayResolve(() =>
        ModuspaceService.ListWarrantyItemPaginate(authentication.jwtToken, {
          pageSize: 5000,
          currentPage: 1,
          user_filtered: formData.user_email
            ? [{ id: 'email', value: formData.user_email }]
            : [],
          device_filtered: formData.device_serial_no
            ? [{ id: 'serialNo', value: formData.device_serial_no }]
            : []
        })
      );
      setSearchResult(
        newSearchResult.list.map((warranty) => {
          const {
            id,
            user,
            deviceWarranties,
            warrantyPlan,
            startDate,
            endDate,
            active,
            voided,
            warrantyClaims
          } = warranty;

          return (
            <>
              <Grid item xs={10} key={`title-${id}`}>
                <MDBox pl={1} pb={1}>
                  <MDTypography variant="body2">
                    {deviceWarranties[0].device.deviceModel.name} -
                    {warrantyPlan.name}
                  </MDTypography>
                </MDBox>
                <MDBox pl={1} display="flex" flexDirection="column">
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Email:{' '}
                    {
                      <span
                        style={{
                          fontWeight:
                            searchData.user_email?.length > 0 &&
                            user.email.includes(searchData.user_email)
                              ? 'bold'
                              : 'normal'
                        }}>
                        {user.email}
                      </span>
                    }
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Model: {deviceWarranties[0].device.deviceModel.name}
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Device:{' '}
                    {
                      <span
                        style={{
                          fontWeight:
                            searchData.serialNo?.length > 0 &&
                            deviceWarranties[0].device.serialNo.includes(
                              searchData.serialNo
                            )
                              ? 'bold'
                              : 'normal'
                        }}>
                        {deviceWarranties[0].device.serialNo}
                      </span>
                    }
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Warranty Period: {displayDate(new Date(startDate))} -{' '}
                    {displayDate(new Date(endDate))}
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Active: {active ? 'Yes' : 'No'}
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Voided: {voided ? 'Yes' : 'No'}
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Total claimed:{' '}
                    {warrantyClaims.reduce((a, b) => a + b.amount, 0)} SGD
                  </MDTypography>
                  <MDTypography
                    variant="overline"
                    style={{ lineHeight: '1rem' }}>
                    Suggested eligible claim:{' '}
                    {warrantyPlan.amount -
                      warrantyClaims.reduce((a, b) => a + b.amount, 0)}{' '}
                    SGD
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={2} key={`result-${id}`}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  onClick={() => setSelectedWarranty(warranty)}>
                  claim
                </MDButton>
              </Grid>
              <Grid item xs={12} key={`divider-${id}`}>
                <Divider style={{ padding: 0 }} />
              </Grid>
            </>
          );
        })
      );
    } catch (err) {
      console.error('Error at searchWarranty:', { err, formData });
    }
    setLoading('');
  };
  // <<< Button Control: Search Warranty <<<

  // >>> Button Control: Claim Started >>>
  const [selectedWarranty, setSelectedWarranty] = React.useState(null);

  /// <<< Button Control: Claim Started <<<
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToId = (id) => {
    let routes = location.pathname.split('/');
    routes[routes.length - 1] = id;
    navigate(routes.join('/'));
  };

  const handleSubmit = async () => {
    const { formData, error } = validateClaimForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    const newFormData = {
      amount: formData.amount,
      warrantyId: selectedWarranty.id
      // voidImmediately: formData.void_immediately === "Void Immediately",
    };
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.CreateNewWarrantyClaim(
          authentication.jwtToken,
          newFormData
        )
      );
      if (response) {
        setIsCreated(true);
        setClaim(response);
        toast.success('Warranty Claim has been created successfully.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to create warranty claim.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (isCreated) navigateToId(claim.id);
    // eslint-disable-next-line
  }, [isCreated]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Claim Warranty
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  {selectedWarranty
                    ? `Create warranty claim for ${joinName(
                        selectedWarranty.user
                      )}`
                    : 'Search for warranty to be claimed first'}
                </MDTypography>
              </MDBox>
              {!selectedWarranty && (
                <>
                  <MDBox py={3} px={3}>
                    <Grid container spacing={3}>
                      {searchWarrantyComponent}
                    </Grid>
                  </MDBox>
                  {searchResult && (
                    <MDBox py={3} px={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MDTypography variant="subtitle" fontWeight="medium">
                            Search result
                          </MDTypography>
                        </Grid>
                        {searchResult}
                      </Grid>
                    </MDBox>
                  )}
                  <MDBox
                    py={3}
                    px={3}
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => setSearchResult(null)}
                      size="large"
                      style={{ marginLeft: 'auto' }}>
                      clear
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={searchWarranty}
                      size="large"
                      style={{ marginLeft: '.8rem' }}>
                      search warranty
                    </MDButton>
                  </MDBox>
                </>
              )}

              {selectedWarranty && (
                <>
                  <MDBox py={3} px={3}>
                    <Grid container spacing={3}>
                      {claimFormComponent}
                    </Grid>
                  </MDBox>
                  <MDBox py={3} px={3}>
                    <Grid container>
                      <Grid item>
                        <MDTypography
                          variant="subtitle"
                          style={{ fontWeight: 'bold' }}>
                          Selected warranty info:
                        </MDTypography>
                      </Grid>
                      <Grid item key="warranty-plan-info" xs={12}>
                        <List className="warranty-info-list">
                          <ListItem>
                            <ListItemText
                              primary="Customer Name"
                              secondary={joinName(selectedWarranty.user)}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Device"
                              secondary={`${selectedWarranty.deviceWarranties[0].device.deviceModel.model} (Serial No: ${selectedWarranty.deviceWarranties[0].device.serialNo})`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Warranty Plan"
                              secondary={`${
                                selectedWarranty.warrantyPlan.name
                              } (${
                                selectedWarranty.warrantyPlan.active
                                  ? 'Active'
                                  : 'Inactive'
                              })`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Warranty Plan Eligible Claim"
                              secondary={`${selectedWarranty.warrantyPlan.amount} SGD`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Total Previous Claimed Amount"
                              secondary={`${selectedWarranty.warrantyClaims.reduce(
                                (a, b) => a + b.amount,
                                0
                              )} SGD`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Warranty Period"
                              secondary={`${displayDate(
                                new Date(selectedWarranty.startDate)
                              )} until ${displayDate(
                                new Date(selectedWarranty.endDate)
                              )}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Warranty Voided"
                              secondary={selectedWarranty.voided ? 'Yes' : 'No'}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox
                    py={3}
                    px={3}
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setSelectedWarranty(null);
                        setSearchResult(null);
                      }}
                      size="large"
                      style={{ marginLeft: 'auto' }}>
                      undo select warranty
                    </MDButton>
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">
                      Create Warranty Claim
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Submit your warranty claim after completing the forms.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleSubmit}
                    size="large">
                    submit claim
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(WarrantyPlanCreate);
