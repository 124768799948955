/* eslint-disable prefer-destructuring */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function item(theme, ownerState) {
  const { palette, borders, functions, transitions } = theme;
  const {
    active,
    color,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    useInitial
  } = ownerState;
  const { pxToRem } = functions;

  const { transparent, white, grey } = palette;
  const { borderRadius } = borders;
  const { rgba } = functions;

  const spacings = useInitial
    ? {
        pl: 3,
        mt: 0.375,
        mb: 0.3
      }
    : {
        padding: `${pxToRem(8)} ${pxToRem(16)}`
        // margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
      };

  return {
    ...spacings,
    width: '100%',
    borderRadius: borderRadius.md,
    cursor: 'pointer',
    backgroundColor: () => {
      let backgroundValue = transparent.main;

      if (
        (active === 'isParent' && !transparentSidenav && !whiteSidenav) ||
        (active === 'isParent' && transparentSidenav && darkMode)
      ) {
        backgroundValue = rgba(white.main, 0.2);
      } else if (active === 'isParent' && transparentSidenav) {
        backgroundValue = grey[300];
      } else if (active === 'isParent' && whiteSidenav) {
        backgroundValue = grey[200];
      } else if (active) {
        backgroundValue = palette[color].main;
      }

      return backgroundValue;
    },
    transition: transitions.create('background-color', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.sharp
    }),

    '&:hover, &:focus': {
      backgroundColor:
        !active &&
        rgba(
          (transparentSidenav && !darkMode) || whiteSidenav
            ? grey[400]
            : white.main,
          0.2
        )
    }
  };
}

function itemIconBox(theme, ownerState) {
  const { palette, transitions, borders, functions } = theme;
  const { transparentSidenav, whiteSidenav, darkMode } = ownerState;

  const { white, dark } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color:
      (transparentSidenav && !darkMode) || whiteSidenav
        ? dark.main
        : white.main,
    borderRadius: borderRadius.md,
    display: 'grid',
    placeItems: 'center',
    transition: transitions.create('margin', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard
    }),

    '& svg, svg g': {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main
    }
  };
}

function itemContent(theme, ownerState) {
  const { palette, typography, functions } = theme;
  const {
    name,
    active,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    useInitial
  } = ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: useInitial ? `${pxToRem(12)} ${pxToRem(16)}` : 'none',
    marginLeft: useInitial ? pxToRem(18) : '0',
    userSelect: 'none',
    position: 'relative',

    '&::before': useInitial
      ? {
          content: `"${name[0]}"`,
          color:
            ((transparentSidenav && !darkMode) || whiteSidenav) &&
            (active === 'isParent' || !active)
              ? dark.main
              : white.main,
          fontWeight: fontWeightRegular,
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: pxToRem(-15),
          opacity: 1,
          borderRadius: '50%',
          fontSize: size.sm
        }
      : {}
  };
}

function itemText(theme, ownerState) {
  const { palette, typography, transitions, functions } = theme;
  const { miniSidenav, active, transparentSidenav, whiteSidenav, darkMode } =
    ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    span: {
      marginLeft: pxToRem(10),
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) &&
        (active === 'isParent' || !active)
          ? dark.main
          : white.main,
      fontWeight: active ? fontWeightRegular : fontWeightLight,
      fontSize: size.sm,
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(['opacity', 'color'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard
      })
    }
  };
}

const itemIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state
});

function itemArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    noCollapse,
    transparentSidenav,
    whiteSidenav,
    miniSidenav,
    open,
    active,
    darkMode
  } = ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? 'rotate(0)' : 'rotate(-180deg)',
    color: () => {
      let colorValue;

      if (transparentSidenav && darkMode) {
        colorValue = open || active ? white.main : rgba(white.main, 0.25);
      } else if (transparentSidenav || whiteSidenav) {
        colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
      } else {
        colorValue = open || active ? white.main : rgba(white.main, 0.5);
      }

      return colorValue;
    },
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter
    }),

    [breakpoints.up('xl')]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? 'none !important'
          : 'block !important'
    }
  };
}

export { item, itemIconBox, itemText, itemIcon, itemContent, itemArrow };
