import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import { joinName } from 'utils/join-name';
import BasicInfo from './details/components/BasicInfo';
import DeleteTicket from './details/components/DeleteTicket';
import TicketMessages from './details/components/TicketMessages';

function TicketDetails() {
  const [loading, setLoading] = React.useState(null);
  const [deleted, setDeleted] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  const [priorities, setPriorities] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [issues, setIssues] = React.useState(null);
  const [statuses, setStatuses] = React.useState(null);
  const [products, setProducts] = React.useState(null);
  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    let routes = pathname.split('/').slice(2);
    routes[routes.length - 1] = joinName(info) || 'Ticket Info';
    pageControl.overwriteBreadcrumbsRoutes(pathname, routes);
    // // eslint-disable-next-line
  }, [pathname, info]);

  const fetchTicketInfo = async () => {
    setLoading('Fetching ticket info...');
    try {
      const { info, priorities, issues, statuses, users, products } =
        await delayResolve(
          async () => ({
            info: await ModuspaceService.GetTicketById(
              authentication.jwtToken,
              id
            ),
            priorities: await ModuspaceService.GetTicketPriorities(
              authentication.jwtToken
            ),
            issues: await ModuspaceService.GetTicketIssues(
              authentication.jwtToken
            ),
            statuses: await ModuspaceService.GetTicketStatus(
              authentication.jwtToken
            ),
            users: await ModuspaceService.GetAllUsers(authentication.jwtToken),
            products: await ModuspaceService.GetTicketProducts(
              authentication.jwtToken
            )
          }),
          100
        );
      setInfo(info);
      setPriorities(priorities);
      setIssues(issues);
      setStatuses(statuses);
      setUsers(users);
      setProducts(products);
    } catch (err) {
      console.error('Error at fetchTicketInfo:', err);
    }
    setLoading(null);
  };

  const updateTicketInfo = async (updatedInfo) => {
    setLoading('Submitting');
    try {
      const info = await delayResolve(() =>
        ModuspaceService.UpdateTicketById(
          authentication.jwtToken,
          id,
          updatedInfo
        )
      );
      setInfo(info);
    } catch (err) {
      console.error('Error at updateTicketInfo:', err);
    }
    setLoading(null);
  };

  const deleteTicket = async () => {
    setLoading('Submitting');
    try {
      await delayResolve(() =>
        ModuspaceService.DeleteTicketById(authentication.jwtToken, id)
      );
      setLoading(null);
      setDeleted(true);
    } catch (err) {
      console.error('Error at deleteTicket:', err);
    }
  };

  const resolveTicket = async () => {
    setLoading('Submitting');
    try {
      await delayResolve(() =>
        ModuspaceService.UpdateTicketById(authentication.jwtToken, id, {
          status: 'Resolved'
        })
      );
    } catch (err) {
      console.error('Error at resolveTicket:', err);
    }
    setLoading(null);
    fetchTicketInfo();
  };

  const closeTicket = async () => {
    setLoading('Submitting');
    try {
      await delayResolve(() =>
        ModuspaceService.UpdateTicketById(authentication.jwtToken, id, {
          status: 'Closed'
        })
      );
    } catch (err) {
      console.error('Error at closeTicket:', err);
    }
    setLoading(null);
    fetchTicketInfo();
  };

  const reopenTicket = async () => {
    setLoading('Submitting');
    try {
      await delayResolve(() =>
        ModuspaceService.UpdateTicketById(authentication.jwtToken, id, {
          status: 'Open'
        })
      );
    } catch (err) {
      console.error('Error at reopenTicket:', err);
    }
    setLoading(null);
    fetchTicketInfo();
  };

  React.useEffect(() => {
    if (info === null && authentication.jwtToken) fetchTicketInfo();
    // eslint-disable-next-line
  }, [info, authentication.jwtToken]);

  React.useEffect(() => {
    if (deleted) navigate('/dashboard/support-center/tickets/');
  }, [deleted]);

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              info={info}
              priorities={priorities}
              issues={issues}
              statuses={statuses}
              users={users}
              products={products}
              onChangeSubmit={updateTicketInfo}
            />
          </Grid>
          <Grid item xs={12}>
            <TicketMessages
              ticketId={id}
              requester={info?.requestedBy}
              fetchTicketInfo={fetchTicketInfo}
            />
          </Grid>
          <Grid item xs={12}>
            <DeleteTicket
              onDelete={deleteTicket}
              onResolve={resolveTicket}
              onClose={closeTicket}
              onReopen={reopenTicket}
              status={info?.status}
              deleted={deleted}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(TicketDetails);
