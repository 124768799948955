import { Grid, Tooltip } from "@mui/material";
import React from "react";
import {
  Marker,
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import GeneralTable from "controls/Tables/GeneralTable";
import { displayCountryFlagImg } from "utils/data-display";

const geoUrl =
  "https://raw.githubusercontent.com/lotusms/world-map-data/main/world.json";

const MapMarker = ({ markerObj }) => {
  let minMaxDiff = {
    numberOfAuthenticScan: 1,
    numberOfCounterfeitScan: 1,
    numberOfCounterfeitReport: 1,
  };

  if (markerObj) {
    [
      "numberOfAuthenticScan",
      "numberOfCounterfeitScan",
      "numberOfCounterfeitReport",
    ].map((key) => {
      const minCount = Math.min(...markerObj.map((x) => x[key]));
      const maxCount = Math.max(...markerObj.map((x) => x[key]));
      minMaxDiff[key] = maxCount - minCount;
    });
  }

  return (
    <>
      {markerObj?.map(
        ({
          countryCode,
          country,
          numberOfAuthenticScan,
          numberOfCounterfeitScan,
          numberOfCounterfeitReport,
          coordinates,
          markerOffset = -15,
        }) => (
          <Tooltip
            title={
              <>
                <p>Country: {country}</p>
                <p>Authentic Scan: {numberOfAuthenticScan}</p>
                <p>Counterfeit Scan: {numberOfCounterfeitScan}</p>
                <p>Counterfeit Report: {numberOfCounterfeitReport}</p>
              </>
            }>
            <Marker key={country} coordinates={coordinates}>
              <circle
                r={
                  (numberOfAuthenticScan * 25) /
                  minMaxDiff.numberOfAuthenticScan
                }
                fill="#f00"
                stroke="#fff"
                opacity={0.3}
                strokeWidth={2}
              />
              <circle
                r={
                  (numberOfCounterfeitScan * 25) /
                  minMaxDiff.numberOfCounterfeitScan
                }
                fill="#0f0"
                stroke="#fff"
                opacity={0.3}
                strokeWidth={2}
              />
              <circle
                r={
                  (numberOfCounterfeitReport * 25) /
                  minMaxDiff.numberOfCounterfeitReport
                }
                fill="#00f"
                stroke="#fff"
                opacity={0.3}
                strokeWidth={2}
              />
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{
                  fontFamily: "system-ui",
                  fill: "#5D5A6D",
                  fontSize: 12,
                }}>
                {countryCode}
              </text>
            </Marker>
          </Tooltip>
        ),
      )}
    </>
  );
};

const Map = ({ mapData }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <GeneralTable
          headColumn={[
            {
              label: "Country",
              name: "country",
              align: "right",
              render: (value, row) => {
                return (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}>
                    <Grid item xs={3}>
                      <img
                        src={displayCountryFlagImg(row.countryCode)}
                        width={15}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {value}
                    </Grid>
                  </Grid>
                );
              },
            },
            {
              label: "No. Authentic Scan",
              name: "numberOfAuthenticScan",
              align: "right",
            },
            {
              label: "No. Counterfeit Scan",
              name: "numberOfCounterfeitScan",
              align: "right",
            },
            {
              label: "No. Counterfeit Report",
              name: "numberOfCounterfeitReport",
              align: "right",
            },
          ]}
          bodyColumn={mapData}
          disablePagination={true}
        />
      </Grid>
      <Grid item xs={6}>
        <ComposableMap
          projectionConfig={{
            scale: 150, // default zoom size
            rotation: [-11, 0, 0],
          }}
          width={800}
          height={400}
          style={{
            background: "lightblue",
            width: "100%",
            maxHeight: "25vh", // outer projection height
          }}>
          <ZoomableGroup>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#dee1e6"
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                  />
                ))
              }
            </Geographies>
            {mapData && <MapMarker markerObj={mapData} />}
          </ZoomableGroup>
        </ComposableMap>
      </Grid>
    </Grid>
  );
};

export default Map;
