/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { useStores } from 'stores';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ModuspaceService from 'services/moduspace.service';
import TicketMessage from './TicketMessage';
import TicketMessageForm from './TicketMessageForm';

import TimelineList from 'components/MDTimelineList';

function TicketMessages({ ticketId, requester, fetchTicketInfo }) {
  const [loading, setLoading] = React.useState(null);
  const [messages, setMessages] = React.useState(null);
  const { authentication } = useStores();

  const fetchTicketMessages = async () => {
    setLoading('Fetching ticket messages...');
    try {
      const messages = await ModuspaceService.GetTicketMessages(
        authentication.jwtToken,
        ticketId
      );
      setMessages(messages);
    } catch (err) {
      console.error('Error at fetchTicketMessages:', err);
    }
    setLoading(null);
  };

  // Scroll to the end of message area
  const messageAreaRef = React.useRef(null);
  const scrollToLastMessage = () => {
    messageAreaRef?.current?.scroll({
      top: messageAreaRef.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  React.useEffect(() => {
    if (messages === null && ticketId && authentication.jwtToken) {
      fetchTicketMessages();
    } else if (messages) {
      scrollToLastMessage();
    }
  }, [messages, ticketId, authentication.jwtToken]);

  return (
    <Card id="ticket-messages">
      <TimelineList title="Ticket messages">
        {messages?.map((message, index) => (
          <TicketMessage
            key={message.id}
            message={message}
            lastItem={index === messages.length - 1}
          />
        ))}
      </TimelineList>
      <TicketMessageForm
        ticketId={ticketId}
        requester={requester}
        fetchTicketInfo={fetchTicketInfo}
        fetchTicketMessages={fetchTicketMessages}
      />
    </Card>
  );
}

export default TicketMessages;
