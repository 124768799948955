import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MDAutocomplete from "components/MDAutocomplete";
import MDButton from "components/MDButton";
import CustomerSearchModal from "components/MDCustomersSearch/CustomerSearchModal";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";
import { joinPath } from "utils/join-url";

function WarrantyItemsManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [deviceModel, setDeviceModel] = useState([]);
  const [warrantyPlan, setWarrantyPlan] = useState([]);
  const [customerSearchModalVisible, setCustomerSearchModalVisible] =
    useState(false);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize) {
        return;
      }

      let device_filtered = [];
      // ugly code pattern
      let deviceModelIdFiltered = dataFilters.find(
        (item) => item.id === "deviceModelId",
      );
      if (deviceModelIdFiltered) {
        device_filtered.push({
          id: "deviceModelId",
          value: deviceModelIdFiltered.value,
        });
      }

      let deviceSerialNoFiltered = dataFilters.find(
        (item) => item.id === "serialNo",
      );
      if (deviceSerialNoFiltered) {
        device_filtered.push({
          id: "serialNo",
          value: deviceSerialNoFiltered.value,
        });
      }

      const {
        list: { list, pagination },
        deviceModel,
        warrantyPlan,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListWarrantyItemsPagination({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            device_filtered,
            ordering: sortBy
          }),
          deviceModel: await ModuspaceService.ListDevicesModels(
            authentication.jwtToken,
          ),
          warrantyPlan: await ModuspaceService.ListWarrantyPlans(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      // Add new property to each object
      list.forEach((item) => (item.__checked = false));
      list.forEach((item) => (item.user.id = item?.userId));
      list.forEach((item) => (item.device = item?.deviceWarranties[0]?.device));
      list.forEach(
        (item) => (item.serialNo = item?.deviceWarranties[0]?.device?.serialNo),
      );
      list.forEach(
        (item) =>
          (item.deviceModelId =
            item?.deviceWarranties[0]?.device?.deviceModel?.id),
      );
      list.forEach(
        (item) => (item.warrantyPlanName = item?.warrantyPlan?.name),
      );
      list.forEach((item) => (item.customerFullName = joinName(item.user)));
      list.forEach((item) => (item.customerEmail = item?.user?.email));
      list.forEach((item) => (item.customerContactNumber = item?.user?.phone));
      setData(list);
      setTotalCount(pagination.total);

      setDeviceModel(
        deviceModel?.list?.map((item) => ({
          label: item.name,
          id: String(item.id),
        })),
      );
      setWarrantyPlan(
        warrantyPlan?.list?.map((item) => ({
          label: item.name,
          id: String(item.id),
        })),
      );
    } catch (err) {
      console.error("WarrantyItemsManagement init:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      let device_filtered = [];
      // ugly code pattern
      let deviceModelIdFiltered = dataFilters.find(
        (item) => item.id === "deviceModelId",
      );
      if (deviceModelIdFiltered) {
        device_filtered.push({
          id: "deviceModelId",
          value: deviceModelIdFiltered.value,
        });
      }

      let deviceSerialNoFiltered = dataFilters.find(
        (item) => item.id === "serialNo",
      );
      if (deviceSerialNoFiltered) {
        device_filtered.push({
          id: "serialNo",
          value: deviceSerialNoFiltered.value,
        });
      }

      const { list } = await ModuspaceService.ListWarrantyItemsPagination({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: dataFilters,
        device_filtered,
      });
      // Add new property to each object
      list.forEach((item) => (item.__checked = false));
      list.forEach((item) => (item.user.id = item?.userId));
      list.forEach((item) => (item.device = item?.deviceWarranties[0]?.device));
      list.forEach(
        (item) => (item.serialNo = item?.deviceWarranties[0]?.device?.serialNo),
      );
      list.forEach(
        (item) =>
          (item.deviceModelId =
            item?.deviceWarranties[0]?.device?.deviceModel?.id),
      );
      list.forEach(
        (item) => (item.warrantyPlanName = item?.warrantyPlan?.name),
      );
      list.forEach((item) => (item.customerFullName = joinName(item.user)));
      list.forEach((item) => (item.customerEmail = item?.user?.email));
      list.forEach((item) => (item.customerContactNumber = item?.user?.phone));
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      name: "serialNo",
      label: "Serial No",
      type: "string",
      placeholder: "Serial No",
      searchColumn: "serialNo",
    },
    {
      type: "multiSelect",
      label: "Customer",
      name: "userId",
      value: String(user.map((u) => u.id)),
      options: user || [],
      searchColumn: "userId",
      render: () => (
        <>
          {customerSearchModalVisible && (
            <CustomerSearchModal
              open={customerSearchModalVisible}
              onClose={() =>
                setCustomerSearchModalVisible(!customerSearchModalVisible)
              }
              onSearch={(selectedUsers) => {
                setUser(selectedUsers);
                setDataFilters([
                  ...dataFilters,
                  {
                    id: "userId",
                    value: selectedUsers.map((x) => x.id),
                  },
                ]);
              }}
            />
          )}
          <MDAutocomplete
            label="Customer"
            name="userId"
            value={user || []}
            options={user || []}
            multiple
            readOnly
          />
          <MDButton
            variant="outlined"
            size="small"
            iconOnly
            style={{ fontSize: 25, color: "#D2D6DA", border: "none" }}
            onClick={() => setUser([])}>
            <CloseOutlined />
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            iconOnly
            onClick={() => setCustomerSearchModalVisible(true)}>
            <SearchOutlined />
          </MDButton>
        </>
      ),
    },
    {
      name: "deviceModelId",
      label: "Product Model",
      type: "multiSelect",
      optionName: "deviceModelId",
      options: deviceModel,
      searchColumn: "deviceModelId",
    },
    {
      name: "warrantyPlanId",
      label: "Warranty Plan",
      type: "multiSelect",
      optionName: "warrantyPlanId",
      options: warrantyPlan,
      searchColumn: "warrantyPlanId",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "dateRange",
      searchColumn: "startDate",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "dateRange",
      searchColumn: "endDate",
    },
  ];

  const tableInfo = [
    {
      label: "Device",
      id: "device",
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath("/dashboard/products/serial-numbers/", value.serialNo)}
          target="_blank">
          {value.serialNo}
        </Link>
      ),
      className: "custom-link",
    },
    {
      label: "Customer Name",
      id: "customerFullName",
    },
    {
      label: "Customer Email",
      id: "customerEmail",
    },
    {
      label: "Contact Number",
      id: "customerContactNumber",
    },
    {
      label: "Plan",
      id: "warrantyPlanName",
    },
    {
      label: "Start Date",
      id: "startDate",
    },
    {
      label: "End Date",
      id: "endDate",
    },
    {
      label: "Updated Date",
      id: "updatedAt",
    },
  ];

  const sortInfo = [
    {
      name: "user",
      rowMapper: (value) => joinName(value),
    },
  ];

  const exportInfo = [
    {
      label: "Serial No",
      name: "serialNo",
    },
    {
      label: "Customer Name",
      name: "customerFullName",
    },
    {
      label: "Customer Email",
      name: "customerEmail",
    },
    {
      label: "Customer Contact Number",
      name: "customerContactNumber",
    },
    {
      label: "Warranty Plan Name",
      name: "warrantyPlanName",
    },
    {
      label: "Start Date",
      name: "startDate",
    },
    {
      label: "End Date",
      name: "endDate",
    },
    {
      label: "Created Date",
      name: "createdAt",
    },
    {
      label: "Updated Date",
      name: "updatedAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      sortInfo={sortInfo}
      dateColumn={["startDate", "endDate", "createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportFileNamePrefix="Warranty_Items"
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      onFilterReset={() => setUser([])}
      // for common usage
      title="Manage Warranty Registration List"
      currentActiveParent="warranties"
      currentActiveTab="items"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(WarrantyItemsManagement);
