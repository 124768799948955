import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from 'pages/profile/components/BasicInfo';
import ChangePassword from 'pages/profile/components/ChangePassword';
import { joinName } from 'utils/join-name';

function UserDetails() {
  const [loading, setLoading] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);
  const emailRef = React.useRef('');
  const [countries, setCountries] = React.useState(null);
  const [fandoms, setFandoms] = React.useState(null);
  const [scales, setScales] = React.useState(null);
  const [brands, setBrands] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const { authentication, pageControl } = useStores();
  const location = useLocation();

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = joinName(userInfo) || 'Profile';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, userInfo]);

  React.useEffect(() => {
    pageControl.setCurrentActiveTab('profile', 'profile');
  }, [pageControl]);

  const fetchUserInfo = async () => {
    setLoading('Fetching profie info...');
    try {
      const { userInfo, countries, fandoms, scales, brands, roles } =
        await delayResolve(async () => ({
          userInfo: await ModuspaceService.GetProfileById(
            authentication.jwtToken,
            authentication.id
          ),
          countries: await ModuspaceService.GetCountries(
            authentication.jwtToken
          ),
          fandoms: await ModuspaceService.GetFandoms(authentication.jwtToken),
          scales: await ModuspaceService.GetScales(authentication.jwtToken),
          brands: await ModuspaceService.GetBrands(authentication.jwtToken),
          roles: await ModuspaceService.GetUserRolesIncludingInactive(
            authentication.jwtToken
          )
        }));
      setUserInfo(userInfo);
      setCountries(countries);
      setFandoms(fandoms);
      setScales(scales);
      setBrands(brands);
      setRoles(roles);
      emailRef.current = userInfo.email;
    } catch (err) {
      console.error('Error at profile fetchUserInfo:', err);
    }
    setLoading(null);
  };

  const updateUserInfo = async (updatedUserInfo) => {
    setLoading('Submitting');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateProfileById(
          authentication.jwtToken,
          authentication.id,
          updatedUserInfo
        )
      );
      if (response) {
        setUserInfo(response);
        toast.success('Your account info has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at profile updateUserInfo:', err);
      toast.error('Fail to updated your account info.');
    }
    setLoading(null);
  };

  const updatePassword = async (password) => {
    setLoading('Submitting');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateProfilePasswordByUserId(
          authentication.jwtToken,
          authentication.id,
          { password }
        )
      );
      if (response) {
        toast.success('Your account password has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at Profile UpdateUserPasswordByUserId:', err);
      toast.error('Fail to update your account password.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (userInfo === null && authentication.jwtToken) fetchUserInfo();
    // eslint-disable-next-line
  }, [userInfo, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              userInfo={userInfo}
              onChangeSubmit={updateUserInfo}
              countries={countries}
              fandoms={fandoms}
              scales={scales}
              brands={brands}
              roles={roles}
            />
          </Grid>
          <Grid item xs={12}>
            <ChangePassword onSubmit={updatePassword} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(UserDetails);
