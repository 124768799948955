/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function TimelineItem({ color, icon, title, dateTime, lastItem, children }) {
  const timelineItemStyles = (theme, lastItem) => {
    const { borders } = theme;
    const { borderWidth, borderColor } = borders;

    return {
      '&:after': {
        content: !lastItem && "''",
        position: 'absolute',
        top: '2rem',
        left: '17px',
        height: '100%',
        opacity: 1,
        borderRight: `${borderWidth[2]} solid ${borderColor}`
      }
    };
  };

  return (
    <MDBox
      position="relative"
      mb={3}
      sx={(theme) => timelineItemStyles(theme, lastItem)}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="4px"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}>
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt="0.7" lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color="dark">
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color="text">
            {dateTime}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {children}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: 'info',
  lastItem: false
};

export default TimelineItem;
