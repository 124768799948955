import React, { useState, useEffect } from 'react';
import {
  Modal,
  Grid,
  Card,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Close } from '@mui/icons-material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import GeneralTable from 'controls/Tables/GeneralTable';

import Papa from 'papaparse';

const ImportConfirmationModal = ({
  open,
  onClose,
  importType = 'importSerialNumber',
  importInfo,
  importFile,
  onSubmit
}) => {
  const { title, validator: importValidator, importColumn } = importInfo || {};

  const [jsonData, setJsonData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
  })
  const [importConfig, setImportConfig] = useState([]);

  const validatedExcelFile =
    !jsonData?.map((item) => item.valid).includes(false) &&
    jsonData?.length > 0;

  useEffect(() => {
    const defaultConfig = [
      { label: 'Overwrite Existing Records', key: 'allowUpsert', value: false }
    ];

    setImportConfig(defaultConfig);
  }, [open]);

  useEffect(() => {
    if (importFile) {
      Papa.parse(importFile, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const { data } = result || {};
          const checkedData = importValidator(data);
          // Truncate values to 30 characters max
          const truncatedData = checkedData.map((item, index) => {
            Object.entries(item).forEach(([key, value]) => {
              if (typeof value === 'string' && value.length > 30) {
                item[key] = value.substring(0, 30) + '...';
              }
            });
            return item;
          });
          setJsonData(truncatedData);
          setTablePagination({
            ...tablePagination,
            total: truncatedData.length,
            currentPage: 1,
          })
        }
      });
    }
  }, [importFile]);

  const onConfigChange = (event) => {
    const { id } = event.target;
    const newImportConfig = importConfig.map((item) => {
      if (item.key === id) {
        item.value = !item.value;
      }
      return item;
    });
    setImportConfig(newImportConfig);
  };

  const handleSubmit = () => {
    let submitConfig = {};
    for (var i = 0; i < importConfig.length; i++) {
      const { key, value } = importConfig[i];
      submitConfig = { ...submitConfig, [key]: value };
    }

    onSubmit(submitConfig);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ mx: 5 }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <MDBox style={{ maxWidth: '90%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid
                  container
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      {title ?? 'Import Data'}
                      {!validatedExcelFile && (
                        <MDTypography
                          variant="caption"
                          fontWeight="light"
                          color="error">
                          {' '}
                          (invalid)
                        </MDTypography>
                      )}
                    </MDTypography>
                  </Grid>
                  <Grid item style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Please confirm your data is accurate before update.
                </MDTypography>
              </MDBox>
              <MDBox py={2} px={2}>
                <GeneralTable
                  headColumn={importColumn ?? []}
                  bodyColumn={jsonData || []}
                  height={400}
                  pagination={tablePagination}
                  onTableChange={(newPagination) =>
                    setTablePagination(newPagination)
                  }
                />
              </MDBox>
              {validatedExcelFile && (
                <>
                  <MDBox px={3} pb={2}>
                    {importConfig.map((item) => (
                      <FormGroup key={item.key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={item.key}
                              onChange={onConfigChange}
                              checked={item.value}
                            />
                          }
                          label={item.label}
                        />
                      </FormGroup>
                    ))}
                  </MDBox>
                  <MDBox pb={2} px={3}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      size="large"
                      onClick={handleSubmit}>
                      upload
                    </MDButton>
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Modal>
  );
};

export default ImportConfirmationModal;
