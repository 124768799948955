export const ImportMessage = ({ job }) => {
  console.log(job);
  if (
    (job.status === 'Completed' || job.status === 'Uncompleted') &&
    job.failed > 0
  )
    return (
      <div>
        <h5>
          Import Job is uncompleted. {job.failed} / {job.total} records failed
          to import.
        </h5>
        <span style={{ display: 'block', fontSize: 15, lineHeight: '20px' }}>
          Check the failed reason by download the processed csv file.
        </span>
      </div>
    );
  else if (job.status === 'Completed' && job.failed === 0)
    return (
      <div>
        <h5>
          Import Job is completed. {job.succeed} / {job.total} records
          successfully imported.
        </h5>
      </div>
    );

  return (
    <div>
      <h5>Import Job is processing in background service.</h5>
      <span style={{ display: 'block', fontSize: 15, lineHeight: '20px' }}>
        Check back later on the import result by refresh the list.
      </span>
    </div>
  );
};
