export const getFormSchema = () => [
  {
    type: 'string',
    name: 'name',
    label: 'Model',
    placeholder: 'Write product model, e.g. LightHub',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'sku',
    label: 'SKU',
    placeholder: 'Write product SKU',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'upc',
    label: 'UPC',
    placeholder: 'Write product UPC',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'ean',
    label: 'EAN',
    placeholder: 'Write product EAN',
    required: true,
    gridSpacing: 6
  }
];
