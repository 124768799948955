import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import { joinName } from 'utils/join-name';
import CreateFields from './details/components/BasicInfo/CreateFields';
import DeleteTicket from './details/components/DeleteTicket';
import TicketMessages from './details/components/TicketMessages';
import { toast } from 'react-toastify';

function TicketCreate() {
  const [loading, setLoading] = React.useState(null);
  const [info, setInfo] = React.useState(null);
  const [priorities, setPriorities] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [customers, setCustomers] = React.useState(null);
  const [issues, setIssues] = React.useState(null);
  const [statuses, setStatuses] = React.useState(null);
  const [products, setProducts] = React.useState(null);
  const { authentication } = useStores();
  const navigate = useNavigate();

  const fetchFieldOptions = async () => {
    setLoading('Fetching field options...');
    try {
      const { priorities, issues, statuses, users, customers, products } =
        await delayResolve(
          async () => ({
            priorities: await ModuspaceService.GetTicketPriorities(
              authentication.jwtToken
            ),
            issues: await ModuspaceService.GetTicketIssues(
              authentication.jwtToken
            ),
            statuses: await ModuspaceService.GetTicketStatus(
              authentication.jwtToken
            ),
            users: await ModuspaceService.GetAllUsers(authentication.jwtToken),
            customers: await ModuspaceService.GetAllCustomers(
              authentication.jwtToken
            ),
            products: await ModuspaceService.GetTicketProducts(
              authentication.jwtToken
            )
          }),
          100
        );
      setPriorities(priorities);
      setIssues(issues);
      setStatuses(statuses);
      setUsers(users);
      setCustomers(customers);
      setProducts(products);
    } catch (err) {
      console.error('Error at fetchFieldOptions:', err);
    }
    setLoading(null);
  };

  const createTicket = async (ticketInfo) => {
    setLoading('Submitting');
    try {
      const info = await delayResolve(() =>
        ModuspaceService.CreateNewTicket(authentication.jwtToken, ticketInfo)
      );
      setInfo(info);
    } catch (err) {
      toast.error(err);
      console.error('Error at createTicket:', err);
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (info?.id) navigate(`/dashboard/support-center/tickets/${info.id}`);
  }, [info]);

  React.useEffect(() => {
    if (info === null && authentication.jwtToken) fetchFieldOptions();
    // eslint-disable-next-line
  }, [info, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateFields
              info={info}
              priorities={priorities}
              issues={issues}
              statuses={statuses}
              users={users}
              customers={customers}
              products={products}
              onChangeSubmit={createTicket}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(TicketCreate);
