import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import { useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
// import { useForm } from "components/MDForm";
// import { getFormSchema } from "./pairedDeviceFormSchema";
// import { joinName } from "utils/join-name";
import CustomerInfo from 'pages/products/paired-devices/components/CustomerInfo';
import DeviceModelInfo from 'pages/products/paired-devices/components/DeviceModelInfo';
import DeviceMasterInfo from 'pages/products/paired-devices/components/DeviceMasterInfo';
import PairedDeviceInfo from 'pages/products/paired-devices/components/PairedDeviceInfo';

function PairedDeviceDetail() {
  const { pageControl, authentication } = useStores();

  const [loading, setLoading] = React.useState('');
  const [currentPairInfo, setCurrentPairInfo] = React.useState(null);
  const [currentGroups, setCurrentGroups] = React.useState([]);

  const { id } = useParams();

  const fetchInfo = async () => {
    setLoading('Fetching Paired Device Info');
    try {
      const { pairInfo, pairGroups } = await delayResolve(async () => ({
        pairInfo: await ModuspaceService.GetPairedDeviceById(
          authentication.jwtToken,
          id
        ),
        pairGroups: await ModuspaceService.GetPairedDeviceGroupById(
          authentication.jwtToken,
          id
        )
      }));

      setCurrentPairInfo(pairInfo);
      setCurrentGroups(pairGroups);
    } catch (err) {
      console.error('Error at fetchInfo:', { err });
    }
    setLoading(null);
  };

  React.useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    console.log('currentGroups: ', currentGroups);
  }, [currentGroups]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {currentPairInfo && (
              <PairedDeviceInfo
                userDeviceInfo={currentPairInfo}
                userDeviceGroupInfo={currentGroups}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {currentPairInfo?.device && (
              <DeviceMasterInfo deviceMasterInfo={currentPairInfo?.device} />
            )}
          </Grid>

          <Grid item xs={12}>
            {currentPairInfo?.device?.deviceModel && (
              <DeviceModelInfo
                deviceModelInfo={currentPairInfo?.device?.deviceModel}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {currentPairInfo?.user && (
              <CustomerInfo customerInfo={currentPairInfo?.user} />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(PairedDeviceDetail);
