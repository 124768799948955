import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useForm } from 'components/MDForm';
import MDButton from 'components/MDButton';
import MDLoading from 'components/MDLoading';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import { toast } from 'react-toastify';

function GenerateSerialNumbersModal({
  show,
  setShow,
  products,
  init,
}) {
  const [loading, setLoading] = useState(false);
  const { authentication } = useStores();

  const { components: formComponents, validateForm, clearForm } = useForm(
    formSchema(products)
  );

  const onConfirm = async (e) => {
    e.preventDefault();
    const { formData, error } = validateForm();
    // Change product from { label, id } to id. `id` is the product name.
    formData.product = formData.product.id;
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Generating serial numbers...');
    try {
      const response = await ModuspaceService.GenerateSerialNumbers(
        authentication.jwtToken,
        formData
      );
      const jobId = response?.id;
      if (jobId) {
        await getJob(jobId);
      } else {
        toast.error('Failed to generate serial numbers.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Failed to generate serial numbers.');
    } finally {
      clearForm();
    }
  };

  // If job status is pending or processing, get job status every 2.5 seconds recursively.
  const getJob = async (jobId) => {
    const job = await ModuspaceService.GetJobById(authentication.jwtToken, jobId);
    if (job.status === 'Pending' || job.status === 'Processing') {
      setTimeout(() => getJob(jobId), 2500);
    }
    if (job.status === 'Completed') {
      toast.success('Serial numbers generated successfully.');
      // Fetch serial numbers again
      init();
      setShow(false);
      setLoading(false);
    } else if (job.status === 'Failed') {
      toast.error('Failed to generate serial numbers.');
      setShow(false);
      setLoading(false);
    }
  }


  return (
    <Dialog open={show}>
      <MDLoading show={!!loading} text={loading} />
      <DialogTitle>Generate Moducase Serial Numbers</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the product name, SKU and number of serial numbers to
          generate.
        </DialogContentText>
        {formComponents}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => setShow(false)}>Cancel</MDButton>
        <MDButton color="success" onClick={onConfirm} autoFocus>
          Add
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

const formSchema = (products = []) => [
  {
    type: 'dropdown',
    name: 'product',
    label: 'Product Name',
    required: true,
    options: products,
    placeholder: 'Select product name',
    getOptionLabel: (option) => option?.label ?? '',
    isOptionEqual: (option, check) =>
      option?.id === check?.id || option?.label === check?.label,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'sku',
    label: 'SKU',
    placeholder: 'Enter SKU',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'num',
    label: 'Number of S/N',
    placeholder: 'Enter number of serial numbers to generate',
    required: true,
    gridSpacing: 6
  }
];

export default GenerateSerialNumbersModal;
