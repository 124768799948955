import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import { useEffect, useState } from "react";
import { useStores } from "stores";
import Header from "./Header";
import OrderInfo from "./OrderInfo";
import Payment from "./Payment";
import PaymentLogs from "./PaymentLogs";
import { delayResolve } from "utils/delay";
import ModuspaceService from "services/moduspace.service";
import { useParams } from 'react-router-dom';

function OrderPaymentInfo({ data }) {
  const [tabValue, setTabValue] = useState(0);
  const { authentication } = useStores();
  const [loading, setLoading] = useState(null);
  const { id: customerId } = useParams();

  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (authentication.jwtToken) fetchInfo();
  }, [authentication.jwtToken]);

  const fetchInfo = async () => {
    setLoading("Fetching info...");
    try {
      const {
        ordersResponse,
        paymentsResponse,
        paymentLogsResponse
      } = await delayResolve(async () => ({
        ordersResponse: await ModuspaceService.ListAllOrders(
          authentication.jwtToken,
          5000,
          1,
          [{
            id: "customerId",
            value: customerId
          }]
        ),
        paymentsResponse: await ModuspaceService.ListAllPayments(
          authentication.jwtToken,
          5000,
          1,
          [{
            id: "customerId",
            value: customerId
          }]
        ),
        paymentLogsResponse: await ModuspaceService.ListAllPaymentLogs(authentication.jwtToken,
          5000,
          1,
          [{
            id: "payment__customerId",
            value: customerId
          }]
        )
      }));

      if (paymentsResponse.payments) {
        setPayments(paymentsResponse.payments);
      }

      if (ordersResponse.list) {
        setOrders(ordersResponse.list)
      }

      if (paymentLogsResponse.list) {
        setLogs(paymentLogsResponse.list?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
      }

    } catch (err) {
      console.error("Error at fetchInfo:", err);
    }
    setLoading(null);

  }

  return (
    <Card id="order-payment-info" sx={{ overflow: 'visible' }}>
      <MDBox p={3}>
        <Header
          tabValue={tabValue}
          onChange={(_, newValue) => setTabValue(newValue)}>
          {tabValue === 0 ? (
            <OrderInfo data={orders} />
          ) : tabValue === 1 ? (
            <Payment data={payments} />
          ) : tabValue === 2 ? (
            <PaymentLogs data={logs} />
          ) : null}
        </Header>
      </MDBox>
    </Card>
  );
}

export default OrderPaymentInfo;
