import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogContent, Stack } from '@mui/material';
import MDBox from './MDBox';

export default function MDLoading({ show = false, text }) {
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <MDBox>
          <LoadingButton
            loading
            variant="standard"
            style={{
              margin: '0px -15px'
            }}></LoadingButton>
          {text ?? 'Please Wait'}
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}
