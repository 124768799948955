import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';
import { delayResolve } from 'utils/delay';
import BaseLayout from 'components/MDBaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { useConfirmModal } from 'components/MDConfirm';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';

function RoleDetails() {
  const [loading, setLoading] = React.useState('');
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const { pageControl, authentication } = useStores();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { prompt: promptDeleteRole, MDConfirm: MDConfirmDeleteRoleModal } =
    useConfirmModal({
      title: 'Delete Role?',
      content:
        'Once you delete this role, there is no going back. Please be certain.',
      confirmText: 'Delete',
      confirmColor: 'error'
    });

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = 'Create New Role';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location]);

  const {
    components: formComponents,
    validateForm,
    formData,
    setFormData
  } = useForm(getFormSchema());

  const fetchRoleInfo = async () => {
    setLoading('Fetching info...');
    try {
      const roleInfo = await delayResolve(() =>
        ModuspaceService.GetRoleById(authentication.jwtToken, id)
      );
      setFormData(roleInfo);
    } catch (err) {
      console.error('Error at fetching roles:', err);
    }
    setLoading('');
  };

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = formData?.name ?? 'Role Info';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, formData]);

  React.useEffect(() => {
    if (authentication.jwtToken) fetchRoleInfo();
    // eslint-disable-next-line
  }, [authentication.jwtToken]);

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateRoleById(authentication.jwtToken, id, formData)
      );
      if (response) {
        setUpdated(true);
        toast.success('Role has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at submitUpdateRole:', { err });
      toast.error('Fail to update role.');
    }
    setLoading('');
  };

  const submitDeleteRole = async () => {
    setLoading('Deleting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteRoleById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success('Role has been deleted successfully.');
      }
    } catch (err) {
      console.error('Error at submitDeleteRole:', { err });
      toast.error('Fail to delete role.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/settings/roles');
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteRoleModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap">
                  <MDBox mt={3} ml="auto">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      onClick={handleSubmit}>
                      update role
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Delete Role</MDTypography>
                  </MDBox>
                  {id <= 5 && (
                    <MDTypography variant="button" color="text">
                      System preset role cannot be deleted.
                    </MDTypography>
                  )}
                  {id > 5 && (
                    <MDTypography variant="button" color="text">
                      Once you delete this role, there is no going back.
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  sx={id <= 5 && { display: 'none' }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() =>
                      promptDeleteRole({ onConfirm: submitDeleteRole })
                    }
                    size="large">
                    delete role
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

RoleDetails.isFieldInvalid = (field) =>
  typeof field === 'string' && field.length === 0;

export default observer(RoleDetails);
