const qs = require('qs');

/**
 * converts object into Node.js Express compatible query parameter
 * @param {*} object
 * @returns
 */
export function objectToQueryParams(object = {}) {
  return qs.stringify(object, {
    indices: false
  });
}
