import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDLoading from "components/MDLoading";
import BaseLayout from "layouts/components/BaseLayout";

import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";

import CreateFields from "./details/components/BasicInfo/CreateFields";

function PaymentCreate() {
  const [loading, setLoading] = useState(null);
  const [info, setInfo] = useState(null);
  const [orders, setOrders] = useState(null);
  const [paymentModes, setPaymentModes] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    let routes = pathname.split("/").slice(2);
    routes[routes.length - 1] = joinName(info) || "Payment Info";
    pageControl.overwriteBreadcrumbsRoutes(pathname, routes);
    // eslint-disable-next-line
  }, [pathname, info]);

  const fetchPaymentInfo = async () => {
    setLoading("Fetching payment info...");
    try {
      const {
        info,
        orders: { models },
        modes,
        currencies,
        statuses,
      } = await delayResolve(
        async () => ({
          info: await ModuspaceService.GetPaymentById(
            authentication.jwtToken,
            id,
          ),
          orders: await ModuspaceService.GetAllOrders(authentication.jwtToken),
          modes: await ModuspaceService.GetAllPaymentModes(
            authentication.jwtToken,
          ),
          currencies: await ModuspaceService.GetAllCurrencies(
            authentication.jwtToken,
          ),
          statuses: await ModuspaceService.GetAllPaymentStatuses(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      setInfo(info);
      // Format data in the required format for the Autocomplete component
      setOrders(
        models?.map(
          ({
            id,
            referenceOrderId,
            customerId,
            billing_address,
            orderCurrency,
          }) => ({
            id,
            label: `#${referenceOrderId}`,
            customerId,
            referenceOrderId,
            billing_address,
            orderCurrency,
          }),
        ),
      );
      setPaymentModes(modes?.map(({ name }) => ({ id: name, label: name })));
      // Only allow sgd, aud, usd, eur
      setCurrencies(
        currencies
          .filter(({ shortName }) =>
            ["SGD", "AUD", "USD", "EUR"].includes(shortName),
          )
          ?.map(({ id, name, shortName }) => ({
            id,
            label: `${name} (${shortName})`,
          })),
      );
      // Hide `void` status
      setStatuses(
        statuses
          ?.filter(({ name }) => name !== "Void")
          ?.map(({ name }) => ({ id: name, label: name })),
      );
    } catch (err) {
      console.error("Error at fetchPaymentInfo:", err);
    }
    setLoading(null);
  };

  const updatePayment = async (paymentInfo) => {
    setLoading("Submitting");
    try {
      // Remove salesOrders object from the paymentInfo as it increases the payload size and is not needed
      delete paymentInfo.salesOrders;
      // Send request
      const info = await delayResolve(() =>
        ModuspaceService.UpdatePaymentById(
          authentication.jwtToken,
          id,
          paymentInfo,
        ),
      );
      setInfo(info);
      toast.success("Payment updated successfully");
    } catch (err) {
      console.error("Error at updatePayment:", err);
    }
    setLoading(null);
  };

  useEffect(() => {
    if (info?.id) {
      navigate(`/dashboard/payments/${info.id}`);
    }
  }, [info]);

  useEffect(() => {
    if (info === null && authentication.jwtToken) {
      fetchPaymentInfo();
    }
    // eslint-disable-next-line
  }, [info, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateFields
              info={info}
              orders={orders}
              setOrders={setOrders}
              paymentModes={paymentModes}
              currencies={currencies}
              paymentStatuses={statuses}
              onChangeSubmit={updatePayment}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(PaymentCreate);
