export const getFormSchema = ({
  // for user lazy loading and debounce func
  onUserTextSearch = () => {},
  searchUserLoading = false,
  searchUserTypingLoading = false,
  triggerFetchMoreUser = () => {},
  hasMoreUser = () => {},

  withUserOptions = [],
  withDeviceOptions = [],
  withPlanOptions = [],
} = {}) => [
  {
    type: "dropdown-lazy",
    name: "user",
    label: "User",
    placeholder: "Select a user",
    options: withUserOptions,
    triggerFetchMore: triggerFetchMoreUser,
    hasMore: hasMoreUser,
    onTextSearch: onUserTextSearch,
    loading: searchUserLoading || searchUserTypingLoading,
    isOptionEqual: (option, check) => option.id === check.id,
    getOptionLabel: (option) => (option ? option.label : ""),
    required: true,
    gridSpacing: 6,
    actions: ["CREATE", "UPDATE"]
  },
  {
    type: "dropdown",
    name: "device",
    label: "Device",
    placeholder: "Select a device",
    options: withDeviceOptions,
    isOptionEqual: (option, check) => option.id === check.id,
    getOptionLabel: (option) => (option ? option.label : ""),
    required: true,
    gridSpacing: 6,
    actions: ["CREATE"]
  },
  {
    type: "dropdown",
    name: "warranty",
    label: "Warranty",
    placeholder: "Select a warranty",
    options: withPlanOptions,
    isOptionEqual: (option, check) => option.id === check.id,
    getOptionLabel: (option) => (option ? option.name : ""),
    required: true,
    gridSpacing: 6,
    actions: ["CREATE", "UPDATE"]
  },
  {
    type: "date",
    name: "startDate",
    label: "Start Date",
    placeholder: "Pick your date",
    required: true,
    gridSpacing: 6,
    actions: ["CREATE", "UPDATE"]
  },
  /* {
      type: "date",
      name: "endDate",
      label: "End Date",
      placeholder: "Pick your date",
      required: true,
      gridSpacing: 6,
    }, */
  {
    type: "dropdown",
    name: "voided",
    label: "Voided",
    placeholder: "Set void status of the warranty, default: Non-voided",
    options: ["Non-voided", "Voided"],
    gridSpacing: 6,
    actions: ["CREATE", "UPDATE"]
  },
  {
    type: "dropdown",
    name: "active",
    label: "Active",
    placeholder: "Set warranty status, default: Active",
    options: ["Active", "Inactive"],
    gridSpacing: 6,
    actions: ["CREATE", "UPDATE"]
  },
];
