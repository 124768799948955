import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/components/FormField";
import moment from "moment";
import { Link } from "react-router-dom";
import { joinPath } from "utils/join-url";

export default function Customer({ info }) {
  const customer = info.customer;

  return (
    <Card id="customer">
      <MDBox p={3}>
        <MDTypography variant="h5">
          Customer Details:{" "}
          <Link
            className="custom-link"
            to={joinPath("/dashboard/customers/", customer.id)}
            target="_blank">
            {customer.id}
          </Link>
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="First Name" value={customer.firstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Last Name" value={customer.lastName} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField label="Email" value={customer.email} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField label="Contact Number" value={customer.phone} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField label="Country" value={customer.country?.name} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label="Date Of Birth"
              value={moment(customer.dob).format("YYYY-MM-DD")}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
