import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import MDBadge from 'components/MDBadge';
import { delayResolve } from 'utils/delay';
import { Link } from 'react-router-dom';
import { joinPath } from 'utils/join-url';
import { joinName } from 'utils/join-name';
import { displayDate } from 'utils/date';

function PaymentManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize) return;
      const { 
        list: { payments, pagination },
        statuses,
      } = await delayResolve(
        async() => ({
          list: await ModuspaceService.ListAllPayments(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            sortBy
          ),
          statuses: await ModuspaceService.GetAllPaymentStatuses(authentication.jwtToken),
        }),
        100
      );
      setData(payments);
      setStatuses(statuses?.map(({ name }) => ({ id: name, label: name })));
      setTotalCount(pagination.total);
    } catch (err) {
      console.error('PaymentManagement:', err);
    }
    setRefreshing(false);
  }

  const getUniqueOrders = (orders) => {
    const uniqueOrders = [];
    orders?.forEach(({ id, referenceOrderId }) => {
      if (!uniqueOrders.find((order) => order.id === id)) {
        uniqueOrders.push({ id, referenceOrderId });
      }
    });
    return uniqueOrders;
  };

  const tableInfo = [
    { label: 'Transaction ID', id: 'transactionRef', tableRowMapper: (value, row) => (
      <Link
        className="custom-link"
        to={joinPath('/dashboard/payments', row.id)}
      >
        {value.replace('PYMT-', '')}
      </Link>
    )},
    { label: 'Order', id: 'salesOrders', tableRowMapper: (orders) => (
      <>
        {getUniqueOrders(orders).map(({ id, referenceOrderId }, index) => (
          <Link
            key={`${id}-${referenceOrderId}-${index}`}
            className="custom-link"
            to={joinPath('/dashboard/orders', id)}
            target="_blank"
          >
            {referenceOrderId}
          </Link>
        )).reduce((prev, curr) => [prev, ', ', curr])}
      </>
    ) },
    { label: 'Amount', id: 'amount', tableRowMapper: (value) => `$${value}` },
    { label: 'Status', id: 'status', tableRowMapper: (value) => (
      <MDBadge
        size="xs"
        badgeContent={value}
        color={value === 'Paid' ? 'success' : value === 'Pending' || value === 'Draft' ? 'warning' : value === 'Processing' ? 'info' : 'error'}
        container={true}
      />
    ) },
    { label: 'Customer', id: 'customer', tableRowMapper: (value, row) => {
      return value && row.customerId ? (
        <Link
          className="custom-link"
          to={joinPath('/dashboard/customers', row.customerId)}
          target="_blank"
        >
          {joinName(value)}
        </Link>
      ) : (
        <span style={{
          color: '#EF5350',
          fontWeight: 'bold',
        }}>
          {`${row.salesOrders?.[0]?.billing_address?.first_name} ${row.salesOrders?.[0]?.billing_address?.last_name}`}
        </span>
      )} 
    },
    { label: 'Payment Date', id: 'paymentDate' },
    { label: 'Created Date', id: 'createdAt' },
  ];

  async function fetchAllPayments (appliedFilter = false) {
    try {
      const { payments } = await ModuspaceService.ListAllPayments(
        authentication.jwtToken,
        Number.MAX_SAFE_INTEGER,
        1,
        appliedFilter ? dataFilters : []
      );
      return payments;
    } catch (err) {
      console.error('PaymentManagement:', err);
    }
  } 

  const exportInfo = [
    {
      label: 'ID',
      name: 'id',
    },
    {
      label: 'Transaction ID',
      name: 'transactionRef',
    },
    {
      label: 'Payment Date',
      name: 'paymentDate',
    },
    {
      label: 'Status',
      name: 'status',
    },
    {
      label: 'Payment Mode',
      name: 'mode',
    },
    {
      label: 'Currency',
      name: 'currency.shortName',
    },
    {
      label: 'Settlement Amount',
      name: 'settlementAmount',
    },
    {
      label: 'Exchange Rate',
      name: 'exchangeRate',
    },
    {
      label: 'Total Amount',
      name: 'amount',
    },
    {
      label: 'Fee',
      name: 'fee',
    },
    {
      label: 'Tax',
      name: 'tax',
    },
    {
      label: 'Sales Order IDs',
      name: 'salesOrderPayments',
      rowMapper: (value, row) => value?.map(({ salesOrderId, amount, description }) => {
        const referenceOrderId = row?.salesOrders?.find(({ id }) => id === salesOrderId)?.referenceOrderId;
        return `Order #${referenceOrderId} - ${row.currency?.shortName} ${amount} ${description ? `- ${description}` : ''}`;
      }).join(' | '),
    },
    {
      label: 'Customer First Name',
      name: 'customer.firstName',
    },
    {
      label: 'Customer Last Name',
      name: 'customer.lastName',
    },
    {
      label: 'Customer Email',
      name: 'customer.email',
    },
    {
      label: 'Remarks',
      name: 'remarks',
    },
    {
      label: 'Notes',
      name: 'notes',
    },
    {
      label: 'Date Created',
      name: 'createdAt',
    },
  ];

  const filterInfo = [
    {
      name: 'multiField',
      label: 'Keywords',
      type: 'string',
      placeholder: 'Transaction ID, Customer Name, Customer Email',
      searchColumn: 'id,transactionRef,customer.first_name,customer.last_name,customer.email',
    },
    {
      name: 'paymentDate',
      label: 'Payment Date',
      type: 'dateRange',
      searchColumn: 'paymentDate',
    },
    {
      name: 'status',
      optionName: 'status',
      label: 'Status',
      type: 'multiSelect',
      searchColumn: 'status',
      options: statuses || [],
    },
    {
      name: 'createdAt',
      label: 'Date Created',
      type: 'dateRange',
      searchColumn: 'createdAt',
    },
  ];

  return (
    <>
      <MDGeneralListingV3
        // Filters
        filterInfo={filterInfo}
        dataFilters={dataFilters}
        setDataFilters={setDataFilters}
        // for sorting
        sortBy={sortBy}
        setSortBy={setSortBy}
        // Table rendering
        _data={data}
        tableInfo={tableInfo}
        dateColumn={['paymentDate', 'createdAt']}
        tablePagination={tablePagination}
        setTablePagination={setTablePagination}
        totalCount={totalCount}
        init={init}
        isRefreshing={isRefreshing}
        rowIdAccessor="id"
        // Actions
        createActionButtons={(rowId, rowname, editButton, deleteButton, row) => editButton}
        allowCreate={true}
        // For export
        exportInfo={exportInfo}
        exportFileNamePrefix="payments"
        fetchAllExportData={fetchAllPayments}
        // Common
        title="Payments"
        currentActiveParent="payments"
        currentActiveTab="payments"
      />
    </>
  )
}

export default observer(PaymentManagement);