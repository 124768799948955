import { TableCell, TableSortLabel, TableRow } from '@mui/material';
import MDTypography from 'components/MDTypography';

const TableHeadCustom = ({
  columns = [],
  disabledSortColumns = [],
  sort = {},
  onSortChange = () => 0
}) => {
  const disabledSortColumn = ['action', 'checkBox', ...disabledSortColumns];
  const { column: sortColumn, type: sortType } = sort;

  return (
    <thead>
      <TableRow>
        {columns.map((column) => {
          const isSorted = (sortColumn === column.id || sortColumn === column.alias) && sortType !== '';
          const nextSortType = isSorted
            ? sortType === 'asc'
              ? 'desc'
              : ''
            : 'asc';

          return (
            <TableCell
              key={column.id}
              align={column.align}
              sortDirection={isSorted && sortType ? sortType : false}
              sx={{ width: column.width, minWidth: column.width || 150 }}>
              {!disabledSortColumn.includes(column.id) && !disabledSortColumn.includes(column.alias) ? (
                <TableSortLabel
                  active={isSorted}
                  direction={sortType !== '' ? sortType : 'asc'}
                  onClick={() => onSortChange(column.alias ? column.alias : column.id, nextSortType)}
                  sx={{ textTransform: 'capitalize' }}>
                  <MDTypography variant="button" fontWeight="medium">
                    {column.label}
                  </MDTypography>
                </TableSortLabel>
              ) : (
                <MDTypography variant="button" fontWeight="medium">
                  {column.label}
                </MDTypography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </thead>
  );
};

export default TableHeadCustom;
