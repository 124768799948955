import joinUrl from "./join-url";

export const hostName =
  process.env.REACT_APP_MODUSPACE_URL ?? "https://api.moduspace.sg";
export const client =
  process.env.REACT_APP_MODUSPACE_CLIENT ?? "moduspace-dashboard";

export const rolePermissions = [
  "superuser",
  "dashboard",
  "customers",
  "products",
  "warranty",
  "ideaboard",
  "content-management",
  "support-center",
  "settings",
];
export const rolePermissionEnums = {
  superuser: "superuser",
  dashboard: "dashboard",
  customers: "customers",
  products: "products",
  warranty: "warranty",
  ideaboard: "ideaboard",
  contentManagement: "content-management",
  supportCenter: "support-center",
  settings: "settings",
  orders: "orders",
  catalog: "catalog",
  payments: "payments",
  deliveryOrders: "delivery-orders",
};
export const ModuspaceEndpoints = {
  //Login/ Logout
  ANALYTICS_DATA: joinUrl(hostName, "api/v1/dashboard/analytic"),
  DASHBOARD_LOGIN: joinUrl(hostName, "api/v1/dashboard/login"),
  DASHBOARD_LOGOUT: joinUrl(hostName, "api/v1/dashboard/logout"),

  // Token Chcek
  VERIFY_TOKEN: joinUrl(hostName, "api/v1/verify-token"),
  REFRESH_TOKEN: joinUrl(hostName, "api/v1/refresh-token"),

  // roles
  ROLE_STATUS_LIST_ALL: joinUrl(hostName, "api/v1/references/role-statuses"), // role status
  ROLE_PERMISSION_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/user-roles-permission",
  ), // role permissions
  ROLES_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/roles"),
  ROLES_LIST_PAGINATE: joinUrl(hostName, "api/v1/dashboard/roles/list"),
  ROLES_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/roles", id);
  },

  // users & customers
  /** @@deprecated */
  USERS_EMAIL_RESET_PASSWORD_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/users/forgot-password", id);
  },
  // // users & customers
  USERS_EMAIL_GENERATE_NEW_PASSWORD_BY_ID_FOR_ADMIN(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/users/generate-new-password",
      id,
    );
  },
  USERS_EMAIL_GENERATE_NEW_PASSWORD_BY_ID_FOR_CUSTOMER(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/customers/generate-new-password",
      id,
    );
  },

  // customers
  CUSTOMER_UPLOAD_TOKEN: joinUrl(
    hostName,
    "api/v1/dashboard/customers/upload-token",
  ),
  CUSTOMERS_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/customers"),
  CUSTOMERS_LIST_PAGINATE: joinUrl(hostName, "api/v1/dashboard/customers/list"),
  CUSTOMERS_IMPORT: joinUrl(hostName, "api/v1/dashboard/customers/import"),
  CUSTOMERS_VERIFY_EMAIL: joinUrl(hostName, "api/v1/sso/verify/email"),
  CUSTOMERS_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/customers", id);
  },
  CUSTOMERS_STATUS_UPDATE_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/customers", id, "status");
  },
  CUSTOMER_ADDRESSES_IMPORT: joinUrl(
    hostName,
    "api/v1/dashboard/customers/addresses/import",
  ),
  CUSTOMER_ADDRESSES_GET_BY_USER_ID(userId) {
    return joinUrl(hostName, "api/v1/dashboard/customers", userId, "addresses");
  },
  CUSTOMER_ADDRESSES_DELETE_BY_ID(userId, id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/customers",
      userId,
      "addresses",
      id,
    );
  },
  CUSTOMER_ADDRESSES_UPDATE_BY_ID(userId, id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/customers",
      userId,
      "addresses",
      id,
    );
  },
  CUSTOMER_ADDRESSES_ADD_BY_USER_ID(userId) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/customers",
      userId,
      "addresses/new",
    );
  },

  // users
  USERS_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/users"),
  USERS_LIST_PAGINATE: joinUrl(hostName, "api/v1/dashboard/users/list"),
  USERS_IMPORT: joinUrl(hostName, "api/v1/dashboard/users/import"),
  USERS_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/users", id);
  },
  USERS_STATUS_UPDATE_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/users", id, "status");
  },
  USERS_VERIFY_EMAIL: joinUrl(hostName, "api/v1/dashboard/users/verify/email"),

  // Profile
  PROFILE_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/profile", id);
  },

  // apis
  API_KEY: joinUrl(hostName, "api/v1/dashboard/application-keys"),
  API_KEY_PAGINATE: joinUrl(hostName, "api/v1/dashboard/application-keys/list"),
  API_KEY_BY_ID(key) {
    return joinUrl(hostName, "api/v1/dashboard/application-keys", key);
  },

  // references tables
  COUNTRIES_LIST_ALL: joinUrl(hostName, "api/v1/references/countries"), // countries
  STATES_LIST_BY_COUNTRY_ID(countryId) {
    return joinUrl(
      hostName,
      "api/v1/references/countries",
      countryId,
      "states",
    );
  }, // states
  FANDOMS_LIST_ALL: joinUrl(hostName, "api/v1/references/fandoms"), // fandoms
  SCALES_LIST_ALL: joinUrl(hostName, "api/v1/references/scales"), // scales
  BRANDS_LIST_ALL: joinUrl(hostName, "api/v1/references/brands"), // brands
  TICKET_PRODUCTS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/ticket-products",
  ), // ticket products

  // device model
  DEVICE_MODEL: joinUrl(hostName, "api/v1/dashboard/devices/models"),
  DEVICE_MODEL_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/devices/models/list",
  ),
  DEVICE_MODEL_OPTIONS: joinUrl(
    hostName,
    "api/v1/dashboard/devices/models/type",
  ),
  DEVICE_MODEL_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/devices/models", id);
  },

  // device items
  DEVICE_UPLOAD_TOKEN: joinUrl(
    hostName,
    "api/v1/dashboard/device/upload-token",
  ),
  DEVICE: joinUrl(hostName, "api/v1/dashboard/devices"),
  DEVICE_SERIAL: joinUrl(hostName, "api/v1/dashboard/devices/serial"),
  DEVICE_SERIAL_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/devices/serial/list",
  ),
  DEVICE_SERIAL_IMPORT: joinUrl(
    hostName,
    "api/v1/dashboard/devices/serial/import",
  ),
  DEVICE_BY_SERIAL(serialNo) {
    return joinUrl(hostName, "api/v1/dashboard/devices/serial", serialNo);
  },
  DEVICE_ITEM: joinUrl(hostName, "api/v1/dashboard/devices/items"),
  DEVICE_ITEM_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/devices/items", id);
  },
  PAIRED_DEVICE_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/devices/paired/list",
  ),
  PAIRED_DEVICE_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/devices/paired", id);
  },
  PAIRED_DEVICE_GROUP_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/devices/paired", id, "group");
  },
  PAIRED_DEVICE: joinUrl(hostName, "api/v1/dashboard/devices/paired"),

  // product serial numbers
  PRODUCT_SERIAL_NUMBERS_GENERATE: joinUrl(
    hostName,
    "api/v1/dashboard/products/moducase/serial-numbers/generate",
  ),
  PRODUCT_SERIAL_NUMBERS_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/products/moducase/serial-numbers/list",
  ),
  PRODUCT_SERIAL_NUMBERS_BULK_ACTION: joinUrl(
    hostName,
    "api/v1/dashboard/products/moducase/serial-numbers/bulk",
  ),
  PRODUCT_SERIAL_NUMBER_DELETE: joinUrl(
    hostName,
    "api/v1/dashboard/products/moducase/serial-numbers",
  ),
  PRODUCT_SERIAL_NUMBER_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/products/moducase/serial-number-statuses",
  ),
  PRODUCT_SERIAL_NUMBER_PRODUCTS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/products/moducase/serial-number-products",
  ),

  // anticounterfeit module
  PRODUCT_SERIAL_COUNTERFEIT_REPORT_PAGINATE: joinUrl(
    hostName,
    "api/v1/products/moducase/anti-counterfeit/report/list",
  ),

  ANTI_COUNTERFEIT_ANALYTIC: joinUrl(
    hostName,
    "api/v1/products/moducase/anti-counterfeit/analytic",
  ),

  // warranty plan
  WARRANTY_PLAN_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/warranty/plans/list",
  ),
  WARRANTY_PLAN_OPTIONS: joinUrl(
    hostName,
    "api/v1/dashboard/warranty/plans/type",
  ),
  WARRANTY_PLAN_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/warranty/plans", id);
  },

  // warranty item
  WARRANTY_ITEM_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/warranty/items/list",
  ),
  WARRANTY_ITEM_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/warranty/items", id);
  },

  // warranty claim
  WARRANTY_CLAIM_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/warranty/claims/list",
  ),
  WARRANTY_CLAIM_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/warranty/claims", id);
  },

  // ideaboard
  // - posts
  IDEABOARD_POST_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/post-statuses",
  ),
  IDEABOARD_POST_REVIEW_TYPE_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/post-review-types",
  ),
  IDEABOARD_POST_REVIEW_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/post-review-statuses",
  ),
  IDEABOARD_POST_TAGS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/post-tags",
  ),
  IDEABOARD_POST_PRODUCT_SERIES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/product-series",
  ),
  IDEABOARD_POST_COMMENT_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/comment-statuses",
  ),
  IDEABOARD_POSTS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts",
  ),
  IDEABOARD_POSTS_LIST_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts/list",
  ),
  IDEABOARD_POSTS_LIST_PENDING_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts/list/pending",
  ),
  IDEABOARD_POSTS_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ideaboard/posts", id);
  },
  IDEABOARD_POSTS_COMMENTS_BY_POST_ID(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/ideaboard/posts",
      id,
      "comments",
    );
  },
  IDEABOARD_POSTS_REVIEWS_BY_POST_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ideaboard/posts", id, "reviews");
  },
  IDEABOARD_POSTS_IMPRESSIONS_BY_POST_ID(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/ideaboard/posts",
      id,
      "impression",
    );
  },
  IDEABOARD_POSTS_FLAG_BY_POST_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ideaboard/posts", id, "flag");
  },
  IDEABOARD_POSTS_REVIEWS_BY_POST_REVIEW_ID(id, postReviewId) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/ideaboard/posts",
      id,
      "reviews",
      postReviewId,
    );
  },
  // - comments
  IDEABOARD_POSTS_COMMENTS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts/comments",
  ),
  IDEABOARD_POSTS_COMMENTS_LIST_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts/comments/list",
  ),
  IDEABOARD_POSTS_COMMENTS_LIST_PENDING_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/ideaboard/posts/comments/list/pending",
  ),
  IDEABOARD_POSTS_COMMENTS_BY_COMMNET_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ideaboard/comments", id);
  },
  IDEABOARD_POSTS_COMMENTS_REVIEWS_BY_POST_COMMENT_ID(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/ideaboard/comments",
      id,
      "reviews",
    );
  },
  IDEABOARD_POSTS_COMMENTS_FLAG_BY_POST_COMMENTS_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ideaboard/comments", id, "flag");
  },
  IDEABOARD_POSTS_COMMENTS_REVIEWS_BY_POST_COMMENT_REVIEW_ID(
    id,
    commentReviewId,
  ) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/ideaboard/comments",
      id,
      "reviews",
      commentReviewId,
    );
  },

  // content management
  CONTENT_MEDIA_UPLOAD_TOKEN: joinUrl(
    hostName,
    "api/v1/dashboard/contents/media/upload-token",
  ),
  CONTENT_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/contents"),
  CONTENT_LIST_PAGINATE: joinUrl(hostName, "api/v1/dashboard/contents/list"),
  CONTENT_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/content", id);
  },
  CONTENT_TYPES_LIST_ALL: joinUrl(hostName, "api/v1/references/content-types"), // content types
  CONTENT_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/content-statuses",
  ), // content status
  CONTENT_APPLICATION_CLIENT_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/application-clients",
  ), // content application clients

  // support ticket
  TICKET_MEDIA_UPLOAD_TOKEN: joinUrl(
    hostName,
    "api/v1/dashboard/ticket/message/media/upload-token",
  ),
  TICKET_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/tickets"),
  TICKET_LIST_PAGINATE: joinUrl(hostName, "api/v1/dashboard/tickets/list"),
  TICKET_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/ticket", id);
  },
  TICKET_GET_BY_CUSTOMER_ID(customerId) {
    return joinUrl(hostName, `api/v1/dashboard/customer/${customerId}/tickets`);
  },
  TICKET_PRIORITIES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/ticket-priorities",
  ), // ticket priorities
  TICKET_STATUS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/ticket-statuses",
  ), // ticket status
  TICKET_ISSUES_LIST_ALL: joinUrl(hostName, "api/v1/references/ticket-issues"), // ticket issues

  // support ticket messages
  TICKET_MESSAGE(ticketId) {
    return joinUrl(hostName, `api/v1/dashboard/ticket/${ticketId}/message/new`);
  },
  TICKET_MESSAGES_GET_BY_TICKET_ID(ticketId) {
    return joinUrl(hostName, `api/v1/dashboard/ticket/${ticketId}/messages`);
  },

  // Orders
  ORDER_UPLOAD_TOKEN: joinUrl(hostName, "api/v1/dashboard/orders/upload-token"),
  ORDERS_PAGINATE: joinUrl(hostName, "api/v1/dashboard/orders/list"),
  ORDERS_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/orders"),
  ORDER_BIGCOMMERCE_STATUSES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/orders/bigcommerce-order-statuses",
  ),
  BIGCOMMERCE_CATALOG_PRODUCTS(client) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/catalog/ext",
      client,
      "products",
    );
  },

  BIGCOMMERCE_CATALOG_PRODUCT_OPTIONS_BY_PRODUCT_ID(client, productId) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/catalog/ext",
      client,
      "products",
      productId,
      "options",
    );
  },

  BIGCOMMERCE_ORDER_PRODUCTS(client, orderId) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/orders/ext",
      client,
      orderId,
      "products",
    );
  },

  ORDERS_IMPORT: joinUrl(hostName, "api/v1/dashboard/orders/import"),
  ORDER_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/order", id);
  },
  ORDERS_BULK_UPDATE: joinUrl(hostName, "api/v1/dashboard/orders/bulk-update"),

  // Catalog Products
  CATALOG_PRODUCTS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/catalog/categories",
  ),
  CATALOG_PRODUCTS_LIST_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/catalog/products/list",
  ),

  // Catalog Categories
  CATALOG_CATEGORIES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/catalog/categories",
  ),
  CATALOG_CATEGORIES_LIST_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/catalog/categories/list",
  ),

  // Payments
  PAYMENTS_PAGINATE: joinUrl(hostName, "api/v1/dashboard/payments/list"),
  PAYMENTS_LIST_ALL: joinUrl(hostName, "api/v1/dashboard/payments"),
  PAYMENT_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/payment", id);
  },
  PAYMENT_SEND_EMAIL_REMINDER(id) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/payment",
      id,
      "send-email-reminder",
    );
  },
  PAYMENT_MODES_LIST_ALL: joinUrl(hostName, "api/v1/references/payment-modes"),
  PAYMENT_TYPES_LIST_ALL: joinUrl(hostName, "api/v1/references/payment-types"),
  PAYMENT_STATUSES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/payment-statuses",
  ),
  PAYMENT_LOGS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/payment-logs/list",
  ),
  PAYMENT_LOGS_GET_BY_PAYMENT_ID(paymentId) {
    return joinUrl(hostName, "api/v1/dashboard/payment", paymentId, "logs");
  },
  PAYMENT_INTENTS_GET_BY_PAYMENT_ID(paymentId) {
    return joinUrl(hostName, "api/v1/dashboard/payment", paymentId, "intents");
  },

  // Currencies
  CURRENCIES_LIST_ALL: joinUrl(hostName, "api/v1/references/currencies"),

  // Job
  JOB_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/jobs", id);
  },

  // Delivery orders
  DELIVERY_ORDERS_PAGINATE: joinUrl(
    hostName,
    "api/v1/dashboard/delivery-orders/list",
  ),
  DELIVERY_ORDERS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/dashboard/delivery-orders",
  ),
  DELIVERY_ORDER_GET_BY_ID(id) {
    return joinUrl(hostName, "api/v1/dashboard/delivery-order", id);
  },
  DELIVERY_ORDERS_BY_SALES_ORDER_ID(salesOrderId) {
    return joinUrl(
      hostName,
      "api/v1/dashboard/delivery-orders-by-sales-order",
      salesOrderId,
    );
  },
  DELIVERY_ORDER_STATUSES_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/delivery-order-statuses",
  ),
  DELIVERY_ORDER_SHIPPING_METHODS_LIST_ALL: joinUrl(
    hostName,
    "api/v1/references/delivery-order-shipping-methods",
  ),
};

export const presetPaymentDescriptionOptions = [
  "Deposit Payment",
  "Balance Payment",
  "Shipping Fee",
];
