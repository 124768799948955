export const getFormSchema = () => [
  {
    type: 'string',
    name: 'name',
    label: 'Service Name',
    placeholder: 'Write a Service Name',
    required: true,
    gridSpacing: 12,
    readonly: true
  },
  {
    type: 'string',
    name: 'clearText',
    label: 'Key',
    placeholder: 'Write a clearText',
    required: true,
    gridSpacing: 12,
    readonly: true
  },
  {
    type: 'string',
    name: 'hashed',
    label: 'Hash',
    placeholder: 'Write a hashed',
    required: true,
    gridSpacing: 12,
    readonly: true
  }
  // {
  //   type: "permission",
  //   name: "scope",
  //   label: "Scope",
  //   placeholder: "Write a Service Scope",
  //   required: true,
  //   gridSpacing: 12,
  // },
];
