import AWS from 'aws-sdk';

export const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    console.log(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export function dataURItoBlob(dataURI) {
  // http://stackoverflow.com/a/12300351/4578017
  var byteString = atob(dataURI.split(',')[1]);

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const uploadS3file = ({
  session = {},
  file = {},
  fileName = null,
  onError = () => {},
  onProgress = () => {},
  onSuccess = () => {}
}) => {
  return new Promise(async (resolve, reject) => {
    const {
      s3Bucket,
      s3Folder,
      s3Region,
      accessKeyId,
      secretAccessKey,
      sessionToken,
      folderId,
      sessionId
    } = session;
    AWS.config.region = s3Region;
    AWS.config.credentials = new AWS.Credentials(
      accessKeyId,
      secretAccessKey,
      sessionToken
    );
    const dataURL = file?.dataURL || (await fileToDataUri(file));
    const params = {
      ACL: 'public-read',
      Body: dataURItoBlob(dataURL),
      Bucket: s3Bucket,
      Key: fileName
        ? s3Folder + folderId + fileName
        : s3Folder +
          folderId +
          sessionId +
          '-' +
          `${file?.upload?.uuid}-${file?.upload?.filename}`,
      Metadata: {
        'Content-Type': file.type
      }
    };

    // console.log(file.dataURL, fileName, file.type, data);
    // console.log("params: ", params, JSON.stringify(params));

    const request = new AWS.S3.ManagedUpload({
      partSize: 5 * 1024 * 1024,
      queueSize: 5,
      params: params
    });

    request.on('httpUploadProgress', ({ loaded, total }) => {
      onProgress({ percent: Math.round((loaded / total) * 100) }, fileName);
    });

    request.send((err, data) => {
      if (err) {
        onError(err);
        reject(err);
      } else {
        onSuccess({ ...data }, fileName);
        resolve({ ...data, fileName });
      }
    });
  });
};

export const downloadS3file = ({ session = {}, file = {} }) => {
  const s3 = new AWS.S3();
  const {
    s3Bucket,
    s3Folder,
    s3Region,
    accessKeyId,
    secretAccessKey,
    sessionToken,
    folderId,
    sessionId
  } = session;

  AWS.config.region = s3Region;
  AWS.config.credentials = new AWS.Credentials(
    accessKeyId,
    secretAccessKey,
    sessionToken
  );

  const params = {
    Key: file,
    Bucket: s3Bucket
  };

  s3.getObject(params, (err, data) => {
    if (err) {
      console.log(err, err.stack);
    } else {
      const fileName = file.split('/')[file.split('/').length - 1];
      downloadInputFile(fileName, data);
    }
  });
};

export const downloadInputFile = (fileName = '', data = {}) => {
  let blob = new Blob([data.Body], { type: data.ContentType });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
