import { useState, useEffect } from 'react';
import { useStores } from 'stores';
import { Link } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import GeneralTable from 'controls/Tables/GeneralTable';
import MDTypography from 'components/MDTypography';
import { displayDate } from 'utils/date';
import { joinPath } from 'utils/join-url';

const DeliveryOrders = ({ salesOrderId, customerId }) => {
  const [deliveryOrders, setDeliveryOrders] = useState([]);

  const { authentication } = useStores();

  const getDeliveryOrdersBySalesOrderId = async () => {
    try {
      const data = await ModuspaceService.GetDeliveryOrdersBySalesOrderId(authentication.jwtToken, salesOrderId);
      setDeliveryOrders(data);
    } catch (error) {
      console.error('GetDeliveryOrdersBySalesOrderId:', error);
    }
  };

  useEffect(() => {
    if (salesOrderId) {
      getDeliveryOrdersBySalesOrderId();
    }
  }, [salesOrderId]);

  const tableInfo = [
    {
      label: 'DO ID',
      name: 'id',
      render: (id) => (
        <Link
          className="custom-link"
          to={joinPath('/dashboard/delivery-orders/', id)}
          target="_blank">
          {id}
        </Link>
      ),
    },
    {
      label: 'Shipment Date',
      name: 'shipmentDate',
      render: (date) => `${date ? displayDate(date) : '-'}`,
    },
    {
      label: 'Status',
      name: 'status',
    },
    {
      label: 'Shipper',
      name: 'shipperName',
    },
    {
      label: 'Tracking Code',
      name: 'trackingCode',
    },
    {
      label: 'Amount',
      name: 'amount',
    }
  ];

  const createNewDeliveryOrder = () => {
    // Open a new tab to create a new delivery order. Send order ID and customer ID as the query param
    const url = joinPath(
      '/dashboard/delivery-orders/new',
      `?salesOrderId=${salesOrderId}&customerId=${customerId}`
    );
    window.open(url, '_blank');
  };

  return (
    <MDBox px={2}>
      <MDTypography variant="h6" fontWeight="bold" mb={2} mx={1} >Delivery orders</MDTypography>
      <GeneralTable
        headColumn={tableInfo}
        bodyColumn={deliveryOrders || []}
        disablePagination={true}
        mx={-3}
      />
      {/* Button to create new delivery order */}
      <MDButton
        type="button"
        variant="contained"
        color="success"
        onClick={createNewDeliveryOrder}
        sx={{
          display: 'inline-block',
          mx: 1,
          my: 3,
        }}
      >
        Create new delivery order
      </MDButton>
    </MDBox>
  )
}

export default DeliveryOrders