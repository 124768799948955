/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the SoftwarcurrentReviewsInfo.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

function ReviewInfo({ commentReview }) {
  const [currentReviewsInfo, setCurrentReviewsInfo] = React.useState([]);

  React.useEffect(() => {
    const info = commentReview ?? {};
    setCurrentReviewsInfo(info);
  }, [commentReview]);

  return (
    <MDBox p={1}>
      {currentReviewsInfo && (
        <MDBox alignItems="center" mb={0.5}>
          <MDBox mt={0.5}>
            <MDBox mt={0.5} display="flex">
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                sx={{ marginBottom: '5px' }}>
                Type: <b>{currentReviewsInfo.type} </b> on{' '}
                <b>
                  {currentReviewsInfo?.createdAt &&
                    displayDate(new Date(currentReviewsInfo?.createdAt))}
                </b>
              </MDTypography>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                sx={{ marginBottom: '5px' }}>
                {currentReviewsInfo?.requester && (
                  <>
                    &nbsp;by <b>{joinName(currentReviewsInfo?.requester)}</b>
                  </>
                )}
              </MDTypography>
            </MDBox>
            <MDTypography
              component="p"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
              sx={{ marginBottom: '5px' }}>
              Reason: <b>{currentReviewsInfo.reason}</b>
            </MDTypography>
            <Divider />
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default ReviewInfo;
