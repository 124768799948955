export function getBadgeColorByStatus(status) {
  status = status.toLowerCase();

  switch (status) {
    case 'active':
    case 'approved':
    case 'published':
      return 'success';

    case 'rejected':
    case 'unpublished':
      return 'error';

    case 'pending':
    case 'draft':
      return 'light';

    case 'inactive':
      return 'dark';

    default:
      return 'primary';
  }
}
