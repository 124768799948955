/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Dropzone components
import Dropzone from 'dropzone';

// Dropzone styles
import 'dropzone/dist/dropzone.css';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Custom styles for the MDDropzone
import MDDropzoneRoot from 'components/MDDropzone/MDDropzoneRoot';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';

function MDDropzone({ options, upload = () => void 0 }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dropzoneRef = useRef();

  //default function to override default form action upload. keep file in local. as completed upload state.
  const defaultUploadFn = (files) => {
    for (var j = 0; j < files.length; j++) {
      var file = files[j];
      Dropzone.instances.forEach((instance) => {
        // console.log("emit complete");
        instance.emit('complete', file);
      });
    }
  };

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();
    Dropzone.prototype.uploadFiles = upload ?? defaultUploadFn;

    return () => removeDropzone();
  }, [options]);

  return (
    <MDDropzoneRoot
      component="form"
      action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
      ownerState={{ darkMode }}>
      <MDBox className="fallback" bgColor="transparent">
        <MDBox component="input" name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}

// Typechecking props for the MDDropzone
MDDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired
};

export default MDDropzone;
