/* This is only used in SerialNumberManagement.js > MDGeneralListingV3 */
import { useMemo, useState } from "react";
import { TableContainer, Table, Toolbar, Grid, Button } from "@mui/material";
import FormField from "layouts/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDActivityIndicator from "components/MDActivityIndicator";
import TableHeadCustom from "./TableHeadCustom";
import TableBodyCustom from "./TableBodyCustom";

const DataTableV3 = ({
  table = [],
  sort = {},
  disabledSortColumns = [],
  onSortChange = () => 0,
  loading = false,
  showBulkActions,
  bulkActions = [],
  bulkActionHandler = () => 0,
}) => {
  const columns = useMemo(() => table.columns, [table]);
  const rows = useMemo(() => table.rows, [table]);
  const [bulkAction, setBulkAction] = useState(null);

  const onSubmitBulkAction = (e) => {
    e.preventDefault();
    bulkActionHandler(bulkAction);
  };

  return (
    <MDBox sx={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          background: "lightgray",
          opacity: loading ? 0.3 : 0,
          position: "absolute",
          pointerEvents: loading ? "all" : "none",
          zIndex: 1,
          transition: "opacity 500ms ease-out",
        }}
      >
        {
          <MDActivityIndicator
            style={{
              margin: "auto",
              scale: "0.3",
            }}
          />
        }
      </div>
      <TableContainer sx={{ boxShadow: "none" }}>
        {showBulkActions && (
          <Toolbar>
            <MDBox component="form">
              <Grid container>
                <Grid
                  item
                  display="flex"
                  alignItems="flex-end"
                  xs={12}
                  sm={12}
                  style={{ width: "420px" }}
                >
                  <Autocomplete
                    options={bulkActions}
                    getOptionLabel={(option) => option.label ?? option}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    disableClearable
                    renderInput={(params) => {
                      return (
                        <FormField
                          {...params}
                          label="Bulk Actions"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Action"
                          width="100%"
                        />
                      );
                    }}
                    onChange={(_, selected) => {
                      setBulkAction(selected.id);
                    }}
                    style={{ flex: 1 }}
                  />
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="success"
                    size="small"
                    onClick={onSubmitBulkAction}
                    style={{ marginLeft: 8 }}
                  >
                    Apply
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Toolbar>
        )}
        <Table>
          <TableHeadCustom
            columns={columns}
            sort={sort}
            disabledSortColumns={disabledSortColumns}
            onSortChange={onSortChange}
          />
          <TableBodyCustom columns={columns} rows={rows} />
        </Table>
      </TableContainer>
    </MDBox>
  );
};

export default DataTableV3;
