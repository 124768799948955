import MDBox from "components/MDBox";
import GeneralTable from "controls/Tables/GeneralTable";
import { displayDate } from 'utils/date';

const OrderInfo = ({ data }) => {
    const tableInfo = [
        {
            label: "Transaction ID",
            name: "transactionRef",
        },
        {
            label: "Payment Mode",
            name: "mode",
        },
        {
            label: "Payment Gateway Ref",
            name: "paymentGatewayRef",
        },
        {
            label: "Order ID",
            name: "referenceOrderId",
            render: (object, row) => {
                return row?.salesOrders?.[0].referenceOrderId;
            },
        },
        {
            label: "Status",
            name: "status",
        },
        {
            label: "Amount",
            name: "amount",
            render: (object, row) => {
                return `${row?.currency?.symbol}${object}`;
            },
        },
        {
            label: 'Payment Date',
            name: 'paymentDate',
            render: (object) => (
                `${displayDate(new Date(object))}`
            )
        },
    ];

    return (
        <MDBox mt={3}>
            <GeneralTable
                headColumn={tableInfo}
                bodyColumn={data || []}
                disablePagination={true}
            />
        </MDBox>
    );
};

export default OrderInfo;
