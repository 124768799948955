import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

export default ({ loading, filterComponents, onSearch }) => (
  <>
    <MDBox py={2} px={2}>
      <Grid container spacing={3} px={1} py={3}>
        {filterComponents?.map(({ key, render }, index) => render && render())}
      </Grid>
    </MDBox>
    <MDBox pb={2} px={3}>
      <Grid container justifyContent="center" spacing={3} px={1} py={3}>
        <Grid item xs={2}>
          <MDButton
            loading={loading}
            variant="outlined"
            color="success"
            size="large"
            onClick={onSearch}>
            Search
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  </>
);
