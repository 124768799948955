/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { toast } from 'react-toastify';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';
import { Grid } from '@mui/material';
import FormField from 'components/MDFormField';

// import { useStores } from "stores";
// import { observer } from "mobx-react";

function UpdateComment({
  remarks = '',
  setRemarks = () => void 0,
  isInitial = true,
  setIsInitial = () => void 0,
  initialCommentInfo = {},
  onUpdate = () => void 0,
  onDelete = () => void 0,
  onFlag = () => void 0,
  areUpdateRequireFieldsValid = () => void 0,
  areFlagRequireFieldsValid = () => void 0,
  areDeleteRequireFieldsValid = () => void 0
}) {
  // const [remarks, setRemarks] = React.useState("");

  // const { authentication } = useStores();

  const { prompt: promptUpdate, MDConfirm: MDConfirmUpdate } = useConfirmModal({
    title: 'Confirm Update Post?',
    content: 'Update will be immediate effect. Please be certain.',
    confirmText: 'update'
  });

  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: 'Confirm Delete Post?',
    content: "Deleting a post won't show it to the public.",
    confirmColor: 'error',
    confirmText: 'delete'
  });

  const { prompt: promptFlag, MDConfirm: MDConfirmFlag } = useConfirmModal({
    title: 'Confirm Flag Post?',
    content:
      "Flag post won't show it to the public. but still visiable on user profile's idearboard",
    confirmColor: 'warning',
    confirmText: 'flag'
  });

  const getFlagPostReviewConfirmModelProps = () => {
    if (areFlagRequireFieldsValid()) {
      promptFlag({
        title: 'Confirm Flag Post?',
        content:
          `You will flag this post with remarks: ${remarks}.` +
          ' Once you flag this post, it will be hidden from public. Please be certain.',
        onConfirm: async () => await onFlag()
      });
    } else {
      setIsInitial(false);
      toast.error('Fill in the required info first!');
    }
  };

  const getUpdatePostReviewConfirmModelProps = () => {
    if (areUpdateRequireFieldsValid()) {
      promptUpdate({
        title: 'Confirm Update Post?',
        content:
          `You will update this post with remarks: ${remarks}.` +
          ' Once you update this post, it will be update content to public immediately. Please be certain.',
        onConfirm: async () => await onUpdate()
      });
    } else {
      setIsInitial(false);
      toast.error('Fill in the required info first!');
    }
  };

  const getDeletePostReviewConfirmModelProps = () => {
    promptDelete({
      title: 'Confirm Delete Post?',
      content:
        `You will be deleting this post with remarks: ${remarks}.` +
        ' Deleteing a post will remove it from public and user profile ideaboard.',
      onConfirm: async () => await onDelete()
    });
    // if(areDeleteRequireFieldsValid()) {
    //   promptDelete({
    //     title: "Confirm Delete Post?",
    //     content:
    //       `You will be deleting this post with remarks: ${remarks}.` +
    //       " Deleteing a post will remove it from public and user profile ideaboard.",
    //     onConfirm: async () => await onDelete(remarks),
    //   });
    // } else {
    //   toast.error("Fill in the required info first!")
    // }
  };

  const nonEmptyProps = (field = '') => ({
    error: !isInitial ? UpdateComment.isTextInputFieldInvalid(field) : '',
    helperText:
      UpdateComment.isTextInputFieldInvalid(field) && isInitial ? (
        <MDTypography variant="caption" color="error">
          This field is required.
        </MDTypography>
      ) : (
        ' '
      ),
    required: !isInitial
  });

  return (
    <Card id="delete-post">
      <MDConfirmUpdate />
      <MDConfirmDelete />
      <MDConfirmFlag />
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h6">Action</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            You can choose to flag, update or delete this comment. Once you
            delete this comment, there is no going back.
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Remarks"
              fullWidth
              multiline
              maxRows={20}
              placeholder="Write remarks about your reason (Optional)"
              value={remarks}
              onChange={({ target: { value } }) => {
                setIsInitial(false);
                setRemarks(value);
              }}
              {...nonEmptyProps(remarks ?? '')}
            />
          </Grid>
        </Grid>
        <MDBox
          mt={3}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end">
          {!initialCommentInfo?.flaggedAt && (
            <MDButton
              variant="gradient"
              color="warning"
              size="large"
              onClick={() => getFlagPostReviewConfirmModelProps()}
              style={{ marginLeft: '.5rem', marginBottom: '.5rem' }}>
              flag
            </MDButton>
          )}

          <MDButton
            variant="gradient"
            color="dark"
            size="large"
            onClick={() => getUpdatePostReviewConfirmModelProps()}
            style={{ marginLeft: '.5rem', marginBottom: '.5rem' }}>
            update
          </MDButton>

          {/* {authentication?.permissions?.length > 0 && authentication?.permissions?.includes("superuser") && ( */}
          <MDButton
            variant="gradient"
            color="error"
            size="large"
            onClick={() => getDeletePostReviewConfirmModelProps()}
            style={{ marginLeft: '.5rem', marginBottom: '.5rem' }}>
            delete
          </MDButton>
          {/* )} */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

UpdateComment.fillEmptyRequiredFields = (commentInfo) => {
  return {
    remarks: '',
    ...commentInfo
  };
};

UpdateComment.isTextInputFieldInvalid = (field = '') =>
  typeof field === 'string' && field.length === 0;

UpdateComment.areRequireFieldsValid = (commentInfo) => {
  if (UpdateComment.isTextInputFieldInvalid(comment.remarks)) return false;
  return true;
};

// export default observer(DeletePost);
export default UpdateComment;
