import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import { joinName } from 'utils/join-name';
import { checkValidField } from 'utils/validation';

import AdditionalInfo from 'pages/ideaboard/posts-reviews-flag/components/AdditionalInfo';
import PostInfo from 'pages/ideaboard/posts-reviews-flag/components/PostInfo';
import CommentInfo from 'pages/ideaboard/posts-reviews-flag/components/CommentInfo';
import ReviewInfo from 'pages/ideaboard/posts-reviews-flag/components/ReviewInfo';
import UpdatePost from 'pages/ideaboard/posts-reviews-flag/components/UpdatePost';
import ReviewApproval from 'pages/ideaboard/posts-reviews-flag/components/ReviewApproval';
import Images from 'pages/ideaboard/posts-reviews-flag/components/Images';

function IdeaboardReviewFlagPostDetails() {
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(null);
  const [postInfo, setPostInfo] = React.useState(null);
  const [remarks, setRemarks] = React.useState('');
  const [isInitial, setIsInitial] = React.useState(true);
  const emailRef = React.useRef('');
  const [tags, setTags] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [relatedProducts, setRelatedProducts] = React.useState([]);
  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const [comments, setComments] = React.useState([]);
  const [pastReviews, setPastReviews] = React.useState([]);
  const [pendingReviews, setPendingReviews] = React.useState([]);
  const [impression, setImpression] = React.useState({});

  React.useEffect(() => {
    // console.log(postInfo);
  }, [postInfo]);

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = joinName(postInfo) || 'Post Info';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, postInfo]);

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/ideaboard/review-flag/posts');
  }, [updated, deleted]);

  const fetchPostInfo = async () => {
    setLoading('Fetching post info...');
    try {
      const {
        postInfo,
        comments,
        reviews,
        impression,
        statuses,
        tags,
        relatedProducts
      } = await delayResolve(async () => ({
        postInfo: await ModuspaceService.GetIdeaboardPostById(
          authentication.jwtToken,
          id
        ),
        comments: await ModuspaceService.GetIdeaboardPostCommentsByPostId(
          authentication.jwtToken,
          id
        ),
        reviews: await ModuspaceService.GetIdeaboardPostReviewsByPostId(
          authentication.jwtToken,
          id
        ),
        impression: await ModuspaceService.GetIdeaboardPostImpressionByPostId(
          authentication.jwtToken,
          id
        ),
        statuses: await ModuspaceService.GetIdeaboardPostStatus(
          authentication.jwtToken
        ),
        tags: await ModuspaceService.GetIdeaboardPostTags(
          authentication.jwtToken
        ),
        relatedProducts: await ModuspaceService.GetIdeaboardPostProductSeries(
          authentication.jwtToken
        )
      }));

      //sanity check on related_series
      setPostInfo(postInfo);
      setComments(comments);
      setPastReviews(reviews.filter((r) => r.status !== 'Pending'));
      setPendingReviews(reviews.filter((r) => r.status === 'Pending'));
      setImpression(impression);
      setStatuses(statuses.filter((s) => s !== 'Draft'));
      setTags(tags);
      setRelatedProducts(relatedProducts);
      emailRef.current = postInfo.email;
    } catch (err) {
      console.error('Error at fetchPostInfo:', err);
    } finally {
      setLoading(null);
    }
  };

  const updatePost = async () => {
    setLoading('Submitting');
    try {
      //populate payload - update postInfo
      const payload = {
        ...postInfo,
        remarks
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setIsInitial(true);
        setRemarks('');
        fetchPostInfo();
        toast.success('Post has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at reactivatePost:', err);
      toast.error('Fail to update post.');
    } finally {
      setLoading(null);
    }
  };

  const areUpdateRequireFieldsValid = () => {
    if (checkValidField(postInfo.title)) return false;
    if (checkValidField(postInfo.caption)) return false;
    if (checkValidField(postInfo.tags)) return false;
    if (checkValidField(postInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const flagPost = async () => {
    setLoading('Submitting');
    try {
      //populate payload - update postInfo
      const payload = {
        ...postInfo,
        remarks
      };
      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostFlagById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setIsInitial(true);
        setRemarks('');
        fetchPostInfo();
        toast.success('Post has been flagged successfully.');
      }
    } catch (err) {
      console.error('Error at flagPost:', err);
      toast.error('Fail to flag post.');
    } finally {
      setLoading(null);
    }
  };

  const areFlagRequireFieldsValid = () => {
    if (checkValidField(postInfo.title)) return false;
    if (checkValidField(postInfo.caption)) return false;
    if (checkValidField(postInfo.tags)) return false;
    if (checkValidField(postInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const deletePost = async () => {
    setLoading('Submitting');
    try {
      const response = await delayResolve(
        async () =>
          await ModuspaceService.DeleteIdeaboardPostById(
            authentication.jwtToken,
            id
          )
      );
      if (response) {
        setDeleted(true);
        toast.success('Post has been deleted successfully.');
      }
    } catch (err) {
      console.error('Error at deletePost:', err);
      toast.error('Fail to delete post.');
    } finally {
      setLoading(null);
    }
  };

  const areDeleteRequireFieldsValid = () => {
    if (checkValidField(postInfo.title)) return false;
    if (checkValidField(postInfo.caption)) return false;
    if (checkValidField(postInfo.tags)) return false;
    if (checkValidField(postInfo.status)) return false;
    // if(checkValidField(postInfo.remarks)) return false
    return true;
  };

  const approvePostReview = async (reviews) => {
    setLoading('Submitting');
    try {
      //populate payload - approve
      const postReviews = reviews.map((review) => {
        return {
          postReviewId: review.id,
          snapshot: review.snapshot,
          remarks: remarks ?? ''
        };
      });

      const payload = {
        postReviews: postReviews,
        type: reviews[0].type,
        related_series: postInfo.related_series,
        status: 'Approved'
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostReviewsById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setUpdated(true);
        fetchPostInfo();
        toast.success('Post review has been approved successfully.');
      }
    } catch (err) {
      console.error('Error at approvePostReview:', err);
      toast.error('Fail to approve post review.');
    } finally {
      setLoading(null);
    }
  };

  const areApproveRequireFieldsValid = () => {
    if (checkValidField(postInfo.title)) return false;
    if (checkValidField(postInfo.type)) return false;
    if (checkValidField(postInfo.status)) return false;
    if (checkValidField(postInfo.link)) return false;
    if (checkValidField(postInfo.content)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const rejectPostReview = async (reviews) => {
    setLoading('Submitting');
    try {
      //populate payload - reject
      const postReviews = reviews.map((review) => {
        return {
          postReviewId: review.id,
          snapshot: review.snapshot,
          remarks: remarks ?? ''
        };
      });

      const payload = {
        postReviews: postReviews,
        type: reviews[0].type,
        related_series: postInfo.related_series,
        status: 'Rejected'
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostReviewsById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        fetchPostInfo();
        toast.success('Post review has been rejected successfully.');
      }
    } catch (err) {
      console.error('Error at rejectPostReview:', err);
      toast.error('Fail to reject post review.');
    } finally {
      setLoading(null);
    }
  };

  const areRejectRequireFieldsValid = () => {
    if (checkValidField(postInfo.title)) return false;
    if (checkValidField(postInfo.type)) return false;
    if (checkValidField(postInfo.status)) return false;
    if (checkValidField(postInfo.link)) return false;
    if (checkValidField(postInfo.content)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const flagPostComment = async (comment = {}) => {
    setLoading('Submitting');
    try {
      const { id: commentId } = comment;
      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostCommentFlagById(
            authentication.jwtToken,
            id,
            commentId
          )
      );
      if (response) {
        setUpdated(true);
        fetchPostInfo();
        toast.success('Post review has been flagged successfully.');
      }
    } catch (err) {
      console.error('Error at flagPostComment:', err);
      toast.error('Fail to flag post review.');
    } finally {
      setLoading(null);
    }
  };

  const deletePostComment = async (comment = {}) => {
    setLoading('Submitting');
    try {
      const { id: commentId } = comment;
      const response = await delayResolve(
        async () =>
          await ModuspaceService.DeleteIdeaboardPostCommentById(
            authentication.jwtToken,
            id,
            commentId
          )
      );
      if (response) {
        setUpdated(true);
        fetchPostInfo();
        toast.success('Post comment has been deleted successfully.');
      }
    } catch (err) {
      console.error('Error at deletePostComment:', err);
      toast.error('Fail to delete post comment.');
    } finally {
      setLoading(null);
    }
  };

  React.useEffect(() => {
    if (postInfo === null && authentication.jwtToken) fetchPostInfo();
    // eslint-disable-next-line
  }, [postInfo, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={1}>
          <Grid container spacing={2} item xs={12} mb={1}>
            <Grid item xs={12} lg={6} xl={6}>
              <Grid item xs={12}>
                <PostInfo
                  setPostInfo={setPostInfo}
                  postInfo={postInfo}
                  statuses={statuses}
                  tagsOptions={tags}
                  relatedProducts={relatedProducts}
                />
              </Grid>
              {pendingReviews.length > 0 && (
                <Grid item xs={12} my={1}>
                  <ReviewApproval
                    remarks={remarks}
                    setRemarks={setRemarks}
                    isInitial={isInitial}
                    setIsInitial={setIsInitial}
                    pendingReviews={pendingReviews}
                    onApprove={approvePostReview}
                    onReject={rejectPostReview}
                    areApproveRequireFieldsValid={areApproveRequireFieldsValid}
                    areRejectRequireFieldsValid={areRejectRequireFieldsValid}
                  />
                </Grid>
              )}
              {pendingReviews.length === 0 && (
                <Grid item xs={12} my={1}>
                  <UpdatePost
                    remarks={remarks}
                    setRemarks={setRemarks}
                    isInitial={isInitial}
                    setIsInitial={setIsInitial}
                    postInfo={postInfo}
                    onUpdate={updatePost}
                    onDelete={deletePost}
                    onFlag={flagPost}
                    areFlagRequireFieldsValid={areFlagRequireFieldsValid}
                    areUpdateRequireFieldsValid={areUpdateRequireFieldsValid}
                    areDeleteRequireFieldsValid={areDeleteRequireFieldsValid}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Images media={postInfo?.media} />
                </Grid>
                <Grid item xs={12}>
                  <AdditionalInfo
                    postInfo={postInfo}
                    customerInfo={postInfo?.user}
                    impressionInfo={impression}
                    tags={tags}
                    relatedProducts={relatedProducts}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {pastReviews?.length > 0 && (
            <Grid item xs={12}>
              <ReviewInfo
                postReviews={pastReviews}
                tags={tags}
                relatedProducts={relatedProducts}
              />
            </Grid>
          )}

          {comments.length > 0 && (
            <Grid item xs={12} my={1}>
              <CommentInfo
                postComments={comments}
                onDelete={deletePostComment}
                onFlag={flagPostComment}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(IdeaboardReviewFlagPostDetails);
