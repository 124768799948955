import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

const localeStore = makeAutoObservable({
  timeZone: 'Asia/Singapore',
  language: null
});

makePersistable(localeStore, {
  name: 'LocaleStore',
  properties: ['timeZone', 'language'],
  storage: window.localStorage
});

export default localeStore;
