import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "stores";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import MDBadge from "components/MDBadge";
import { joinName } from "utils/join-name";
import { getBadgeColorByStatus } from "utils/element";
import { status } from "utils/option";
import { delayResolve } from "utils/delay";

import {
  importCustomerValidator,
  importCustomerAddressValidator,
} from "./util/validator";
import {
  getCustomerUploadToken,
  importCustomer,
  importCustomerAddress,
} from "./util/import";

import customersTemplate from "./data/customersTemplate.json";
import customerAddressesTemplate from "./data/customerAddressesTemplate.json";

function UsersManagement() {
  const { authentication, pageControl } = useStores();

  const [data, setData] = useState([]);
  const [country, setCountry] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [dataFilters, setDataFilters] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState({
    id: "",
    type: "", // asc or desc
  });
  
  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize) {
        return;
      }
      const {
        list: { list, pagination },
        country,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListCustomers(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            sortBy,
          ),
          country: await ModuspaceService.GetCountries(authentication.jwtToken),
        }),
        100
      );

      const customer = list.map(
        ({
          id,
          firstName,
          lastName,
          company,
          dob,
          email,
          notes,
          phone,
          status,
          countryId,
          country,
          lastLoginAt,
          profile,
          createdAt,
          updatedAt,
        }) => {
          const name = joinName({ firstName, lastName });
          const countryName = country?.name ?? "";
          const profileFandom = profile?.fandoms ?? "";
          const profileCollection = profile?.scales ?? "";
          const profileFavBrand = profile?.brands ?? "";
          const marketingNotification = profile?.activity?.marketing
            ? "Yes"
            : "No";

          return {
            id,
            name,
            firstName,
            lastName,
            company,
            dob,
            profileFandom,
            profileCollection,
            profileFavBrand,
            marketingNotification,
            email,
            notes,
            phone,
            status,
            countryId,
            countryName,
            lastLoginAt,
            createdAt,
            updatedAt,
          };
        }
      );
      setTotalCount(pagination.total);

      setData(customer);
      setCountry(
        country?.map((item) => ({ label: item.name, id: String(item.id) }))
      );
    } catch (err) {
      console.error("UsersManagement:", err);
    }
    setRefreshing(false);
  };

  async function fetchAllExportData(appliedFilter = false) {
    const maximumPageSize = Math.max(...toJS(pageControl?.pageSizeOptions)) ?? 10;
    try {
      let accurateList = [];
      for (let i = 0; i <= (totalCount / maximumPageSize); i++) {
        const { list } = await ModuspaceService.ListCustomers(
          authentication.jwtToken,
          maximumPageSize,
          i + 1,
          appliedFilter ? dataFilters : []
        );
        const updatedList = list.map(
          ({
            id,
            firstName,
            lastName,
            company,
            dob,
            email,
            notes,
            phone,
            status,
            countryId,
            country,
            lastLoginAt,
            profile,
            createdAt,
            updatedAt,
          }) => {
            const name = joinName({ firstName, lastName });
            const countryName = country?.name ?? "";
            const profileFandom = profile?.fandoms ?? "";
            const profileCollection = profile?.scales ?? "";
            const profileFavBrand = profile?.brands ?? "";
            const marketingNotification = profile?.activity?.marketing
              ? "Yes"
              : "No";

            return {
              id,
              name,
              firstName,
              lastName,
              company,
              dob,
              profileFandom,
              profileCollection,
              profileFavBrand,
              marketingNotification,
              email,
              notes,
              phone,
              status,
              countryId,
              countryName,
              lastLoginAt,
              createdAt,
              updatedAt,
            };
          }
        );
        accurateList = [...accurateList, ...updatedList];
      }
      return accurateList;
    } catch (err) {
      console.error('UsersManagement fetchAllExportData:', { err });
    }
  }

  const filterInfo = [
    {
      name: "multiField",
      label: "Keywords",
      type: "string",
      placeholder: "First Name, Last Name, Email, Notes, Contact No",
      searchColumn: "firstName,lastName,email,notes,phone",
    },
    {
      name: "createdAt",
      label: "Registration Date",
      type: "dateRange",
      searchColumn: "createdAt",
    },
    {
      name: "countryId",
      label: "Country",
      type: "multiSelect",
      optionName: "countryId",
      options: country,
      searchColumn: "countryId",
    },
    {
      name: "status",
      label: "Status",
      type: "multiSelect",
      optionName: "status",
      options: status,
      searchColumn: "status",
    },
  ];

  const tableInfo = [
    {
      label: "Name",
      id: "name"
    },
    {
      label: "Email",
      id: "email"
    },
    {
      label: "Contact No",
      id: "phone"
    },
    {
      label: "Status",
      id: "status",
      tableRowMapper: (value) => (
        <MDBadge
          size="xs"
          badgeContent={value}
          color={getBadgeColorByStatus(value)}
          container
        />
      ),
    },
    {
      label: "Country",
      id: "countryName"
    },
    {
      label: "Last Login",
      id: "lastLoginAt"
    },
    {
      label: "Registration Date",
      id: "createdAt"
    },
  ];

  const importInfo = [
    {
      title: "Import Customers",
      fileName: "customer-import",
      validator: (data) => importCustomerValidator(data),
      getSession: async () => getCustomerUploadToken(authentication.jwtToken),
      importData: async (data) => importCustomer(authentication.jwtToken, data),
      importDataExample: {
        title: "Customers Template",
        data: customersTemplate,
      },
      importColumn: [
        { label: "Email Address", name: "email" },
        { label: "Site(Label)", name: "site" },
        { label: "Reference Id", name: "referenceId" },
        { label: "First Name", name: "firstName" },
        { label: "Last Name", name: "lastName" },
        { label: "Company Name", name: "company" },
        { label: "Password", name: "password" },
        { label: "Phone Number", name: "phone" },
        { label: "Date Of Birth", name: "dob" },
        { label: "Country Name", name: "country" },
        { label: "Brand Name", name: "brands" },
        { label: "Fandom", name: "fandoms" },
        { label: "Scale", name: "scales" },
        { label: "Status", name: "status" },
        { label: "Marketing Notification", name: "marketing" },
        { label: "Schema Check", name: "reason" },
      ],
    },
    {
      title: "Import Customer’s Addresses",
      fileName: "customer-address-import",
      validator: (data) => importCustomerAddressValidator(data),
      getSession: async () => getCustomerUploadToken(authentication.jwtToken),
      importData: async (data) =>
        importCustomerAddress(authentication.jwtToken, data),
      importDataExample: {
        title: "Customer’s Addresses Template",
        data: customerAddressesTemplate,
      },
      importColumn: [
        { label: "Email Address", name: "email" },
        { label: "Site(Label)", name: "site" },
        { label: "Reference Id", name: "referenceId" },
        { label: "First Name", name: "firstName" },
        { label: "Last Name", name: "lastName" },
        { label: "Company Name", name: "company" },
        { label: "Phone Number", name: "phone" },
        { label: "Address Line 1", name: "addressLine1" },
        { label: "Address Line 2", name: "addressLine2" },
        { label: "City", name: "city" },
        { label: "Country Name", name: "country" },
        { label: "State", name: "state" },
        { label: "Postal Code", name: "postalCode" },
        { label: "Address Type", name: "addressType" },
        { label: "Schema Check", name: "reason" },
      ],
    },
  ];

  const exportInfo = [
    {
      label: "First Name",
      name: "firstName",
    },
    {
      label: "Last Name",
      name: "lastName",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Company",
      name: "company",
    },
    {
      label: "Contact Number",
      name: "phone",
    },
    {
      label: "Country",
      name: "countryName",
    },
    {
      label: "Date of Birth",
      name: "dob",
    },
    {
      label: "Profile Fandom",
      name: "profileFandom",
    },
    {
      label: "Profile Collection",
      name: "profileCollection",
    },
    {
      label: "Profile FavBrand",
      name: "profileFavBrand",
    },
    {
      label: "Marketing Notification",
      name: "marketingNotification",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Last Login",
      name: "lastLoginAt",
    },
    {
      label: "Registration Date",
      name: "createdAt",
    },
    {
      label: "Last Updated Date",
      name: "updatedAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for table rendering
      _data={data}
      sortBy={sortBy}
      setSortBy={setSortBy}
      tableInfo={tableInfo}
      dateColumn={["dob", "lastLoginAt", "createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count      
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      // for exports
      // MODU-198/223 change export filename
      exportFileNamePrefix="Customer_Export"
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Customers"
      currentActiveTab="customer-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(UsersManagement);
