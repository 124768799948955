import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { useEffect, useState } from "react";

const GeneralTable = ({
  selectable = false,
  onSelect,
  selectedItems,
  headColumn = [],
  bodyColumn = [],
  height = 875,
  pagination = {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  onTableChange = () => {},
  disablePagination = false,
}) => {
  const [isSelectAll, setIsSelectAll] = useState(false);

  const tableCellStyles = {
    fontSize: "12px",
    color: "dark",
    px: 2.5,
    minWidth: 80,
  };

  const renderBodyColumn = bodyColumn.map(({ valid = true, ...rest }) => ({
    valid,
    ...rest,
  }));

  return (
    <Grid item xs={12} sx={{ m: 1, maxHeight: 975 }}>
      <Paper>
        <TableContainer sx={{ maxHeight: height }}>
          <Table size="small" stickyHeader>
            <TableHead sx={{ p: 0, display: "table-header-group" }}>
              <TableRow>
                {selectable && (
                  <TableCell
                    sx={{ ...tableCellStyles }}
                    component="th"
                    align="left"
                    onClick={(e) => {
                      setIsSelectAll(true);
                    }}
                  >
                    {/* <Checkbox
                      checked={false}
                      onClick={(e) => {
                        onSelect(e.target.checked, renderBodyColumn);
                      }}
                    /> */}
                  </TableCell>
                )}
                {headColumn.map((item, index) => (
                  <TableCell
                    key={`th-cell-${index}`}
                    sx={{ ...tableCellStyles }}
                    component="th"
                    align={
                      item.align ?? index === headColumn.length - 1
                        ? "right"
                        : "left"
                    }
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderBodyColumn.map((row, index) => (
                <TableRow
                  key={`tb-row-${index}`}
                  style={{ background: !row?.valid ? "red" : "transparent" }}
                >
                  {selectable && (
                    <TableCell
                      sx={{ ...tableCellStyles }}
                      component="td"
                      align="left"
                    >
                      <Checkbox
                        checked={
                          selectedItems.filter((x) => x.id === "" + row.id)
                            .length > 0
                        }
                        onClick={(e) => {
                          onSelect(e.target.checked, row);
                        }}
                      />
                    </TableCell>
                  )}
                  {headColumn.map(({ name, align, render }, idx) => (
                    <TableCell
                      key={`tb-cell-${idx}`}
                      sx={{ ...tableCellStyles }}
                      component="td"
                      align={
                        align ?? idx === headColumn.length - 1
                          ? "right"
                          : "left"
                      }
                    >
                      {render ? render(row[name], row) : row[name]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            component="div"
            rowsPerPage={pagination.pageSize}
            count={pagination.total}
            page={pagination.currentPage - 1}
            onPageChange={(e, val) =>
              onTableChange({ ...pagination, currentPage: val + 1 })
            }
            onRowsPerPageChange={(event) => {
              onTableChange({
                ...pagination,
                currentPage: 1,
                pageSize: parseInt(event.target.value, 10),
              });
            }}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default GeneralTable;
