import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid, Card } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
// import { joinName } from "utils/join-name";
import BasicInfo from 'pages/content/components/BasicInfo';
import DeleteNews from 'pages/content/components/DeleteNews';
import { fileToDataUri, uploadS3file } from 'utils/s3Uploader';
import { dateTimeToLocal, dateTimeToZulu } from '../../utils/date';

function ContentDetails() {
  const [loading, setLoading] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [types, setTypes] = React.useState(null);
  const [statuses, setStatuses] = React.useState(null);
  const [clients, setClients] = React.useState(null);
  const [newsInfo, setNewsInfo] = React.useState(null);
  const contentInfoRef = React.useRef(null);

  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  // const { pathname } = useLocation();

  //   React.useEffect(() => {
  //     // let routes = pathname.split("/").slice(2)
  //     // routes[routes.length - 1] = joinName(newsInfo) || "News Info"
  //     // pageControl.overwriteBreadcrumbsRoutes(pathname, routes)
  //     // eslint-disable-next-line
  //   }, [pathname, newsInfo]);

  const fetchNewsInfo = async () => {
    setLoading('Fetching content info...');
    try {
      const { newsInfo, types, statuses, clients } = await delayResolve(
        async () => ({
          newsInfo: await ModuspaceService.GetContentById(
            authentication.jwtToken,
            id
          ),
          types: await ModuspaceService.GetContentTypes(
            authentication.jwtToken
          ),
          statuses: await ModuspaceService.GetContentStatus(
            authentication.jwtToken
          ),
          clients: await ModuspaceService.GetContentApplicationClients(
            authentication.jwtToken
          )
        })
      );

      const updatedNewsInfo = {
        ...newsInfo,
        displayAt: dateTimeToLocal(newsInfo.displayAt)
      };

      setNewsInfo(updatedNewsInfo);
      setTypes(types);
      setStatuses(statuses);
      setClients(clients);
    } catch (err) {
      console.error('Error at fetchNewsInfo:', err);
    } finally {
      setLoading(null);
    }
  };

  const updateContentInfo = async () => {
    let error = false;
    let info;
    if (contentInfoRef.current.onSubmitListener) {
      const { error: validationError, info: validatedContentInfo } =
        contentInfoRef.current.onSubmitListener();
      error = validationError;
      info = validatedContentInfo;
    } else {
      // flow should not trigger if ref is successfully passed
      error = true;
    }

    if (error) {
      console.log(error);
      return;
    }
    setLoading('Submitting');
    //upload all files to s3 and populate media prop
    try {
      const session = await ModuspaceService.GetContentMediaUploadToken(
        authentication.jwtToken
      );
      const newMedia = await Promise.all(
        info.files.map(async (file) => {
          const {
            type
            // upload: { filename, uuid },
          } = file;
          // const dataUri = await fileToDataUri(file)
          const result = await uploadS3file({
            session,
            file: file,
            // data: dataUri,
            onError: (err) => {
              console.log(err);
            },
            onProgress: (progress) => {
              console.log(progress);
            },
            onSuccess: (result) => {
              // console.log(result);
            }
          });

          const { Location, Key, Bucket } = result;
          return { type, link: Location };
        })
      );

      delete info.files;

      let payload = {
        ...info,
        displayAt: dateTimeToZulu(info.displayAt)
      };

      if (newMedia?.length > 0) {
        payload = {
          ...info,
          media: newMedia
        };
      }

      try {
        const response = await delayResolve(() =>
          ModuspaceService.UpdateContentById(
            authentication.jwtToken,
            id,
            payload
          )
        );
        if (response) {
          setUpdated(true);
          setNewsInfo(response);
          toast.success(
            'Your promotion/feature/article is updated successfully.'
          );
        }
      } catch (err) {
        console.error('Error at updateContentInfo:', err);
        toast.error('Fail to update promotion/feature/article.');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(null);
    }
  };

  const deleteContent = async () => {
    setLoading('Deleting');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteContentById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success(
          'Your promotion/feature/article has been updated successfully.'
        );
      }
    } catch (err) {
      console.error('Error at deleteContent:', err);
      toast.error('Fail to delete promotion/feature/article.');
    } finally {
      setLoading(null);
    }
  };

  React.useEffect(() => {
    if (newsInfo === null && authentication.jwtToken) fetchNewsInfo();
    // eslint-disable-next-line
  }, [newsInfo, authentication.jwtToken]);

  React.useEffect(() => {
    if (deleted) navigate('/dashboard/content');
  }, [deleted]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              contentInfo={newsInfo}
              types={types}
              statuses={statuses}
              clients={clients}
              onSubmitRef={contentInfoRef}
              onChangeSubmit={updateContentInfo}
            />
          </Grid>
          <Grid item xs={12}>
            <DeleteNews onConfirm={deleteContent} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(ContentDetails);
