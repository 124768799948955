/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React controls
import SalesTable from 'controls/Tables/SalesTable';
import QuestionMark from 'assets/images/question-mark.png';
import { displayCountryFlagImg } from '../../../utils/data-display';

function SalesByCountry({ _data }) {
  const headRows = [
    { title: '', content: '' },
    {
      title: 'Total Registered User',
      content:
        _data.reduce((acc, curr) => acc + curr.registered_user_quantity, 0) ?? 0
    },
    {
      title: 'Total Registered Warranty',
      content:
        _data.reduce(
          (acc, curr) => acc + curr.registered_warranty_quantity,
          0
        ) ?? 0
    },
    {
      title: 'Total Ideaboard Posts',
      content:
        _data.reduce((acc, curr) => acc + curr.ideaboard_post_quantity, 0) ?? 0
    },
    {
      title: 'Total Support Tickets',
      content:
        _data.reduce((acc, curr) => acc + curr.support_ticket_quantity, 0) ?? 0
    }
  ];

  const bodyRows = _data
    .filter(
      (item) =>
        item.registered_user_quantity !== 0 ||
        item.registered_device_quantity !== 0 ||
        item.ideaboard_post_quantity !== 0 ||
        item.support_ticket_quantity !== 0
    )
    .map((item) => {
      const imgUrl = displayCountryFlagImg(item.short_name);
      const countryName = item.name || 'N/A';

      return {
        Country: [imgUrl, countryName],
        'Registered User': item.registered_user_quantity,
        'Registered Warranty': item.registered_warranty_quantity,
        'Ideaboard Posts': item.ideaboard_post_quantity,
        'Support Tickets': item.support_ticket_quantity
      };
    });

  return (
    <Card sx={{ width: '100%' }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}>
          <Icon fontSize="medium" color="inherit">
            language
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          Users by Country
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <SalesTable
              headRows={headRows}
              bodyRows={bodyRows}
              shadow={false}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SalesByCountry;
