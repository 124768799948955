import React from 'react';
import { toast } from 'react-toastify';
import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';
import { delayResolve } from 'utils/delay';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';

function RoleCreate() {
  const [loading, setLoading] = React.useState('');
  const [role, setRole] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const { pageControl, authentication } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToRoleId = (id) => {
    const locationPaths = location.pathname.split('/');
    locationPaths[locationPaths.length - 1] = id;
    navigate(locationPaths.join('/'));
  };

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = 'Create New Role';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location]);

  const { components: formComponents, validateForm } = useForm(getFormSchema());

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.CreateNewRole(authentication.jwtToken, formData)
      );
      if (response) {
        setRole(response);
        setIsCreated(true);
        toast.success('Your role has been created successfully.');
      }
    } catch (err) {
      console.error('Error at submitCreateRole:', { err });
      toast.error('Fail to create role.');
    } finally {
      setLoading(null);
    }
  };

  React.useEffect(() => {
    if (isCreated) navigateToRoleId(role.id);
    // eslint-disable-next-line
  }, [isCreated]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Create Role</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Once the role is created, it will be available in user
                    details page.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleSubmit}
                    size="large">
                    create role
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

RoleCreate.isFieldInvalid = (field) =>
  typeof field === 'string' && field.length === 0;

export default observer(RoleCreate);
