import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';

// Material Dashboard 2 PRO React kits
import Sidenav from 'controls/Sidenav';

// Images
import brandWhite from 'assets/images/moduspace-logo.png';
import brandDark from 'assets/images/moduspace-logo-dark.png';

import { createSidebarRoutes } from 'sidebar.route';
import { useStores } from './stores';
import { observer } from 'mobx-react';

import { ModuspaceEndpoints } from './utils/constants';

import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';
import { toast } from 'react-toastify';

function Layout() {
  const [isLoading, setIsLoading] = useState(true);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const navigate = useNavigate();

  const { authentication } = useStores();
  const { pathname } = useLocation();
  // const refresh = useRefreshToken();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const resetSessionAndRedirect = (showWarning = false) => {
    try {
      authentication?.clear();
      showWarning ?? toast.error('Session Expired');
    } finally {
      if (showWarning) {
        navigate(
          `/signin?warning=${encodeURIComponent('Your session has expired.')}`
        );
        navigate(0);
        return false;
      } else {
        navigate(`/signin`);
        navigate(0);
        return false;
      }
    }
  };

  async function checkJwt(abortController, path, ts) {
    // if (pathname === '/signout' || pathname === '/signin') return;
    console.log(
      'verify JWT. length:',
      authentication?.jwtToken?.length,
      path,
      ts
    );
    const token = authentication?.jwtToken;
    if (authentication?.jwtToken?.length) {
      try {
        const response = await fetch(ModuspaceEndpoints.VERIFY_TOKEN, {
          headers: {
            authorization: `Bearer ${token}`
          },
          signal: abortController.signal
        });

        if (response.ok) {
          return { result: true, path, ts };
        }

        if (!response.ok) {
          console.log('JwtCheck Failed: ', path, ts);
          const result = resetSessionAndRedirect(true);
          return { result, path, ts };
        }
      } catch (err) {
        console.log('Error Fetch: ', err, path, ts);
        const result = resetSessionAndRedirect(true);
        return { result, path, ts };
      }
    } else {
      console.log('JwtCheck Failed: ', path, ts);
      const result = resetSessionAndRedirect(true);
      return { result, path, ts };
    }
  }

  useEffect(() => {
    const timestamp = Date.now();
    console.log('who call? layout useEffect [pathname]', pathname, timestamp);

    setIsLoading(true);

    //hack reset scroll to top when path change.
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    return () => {
      const abortController = new AbortController();
      //setIsLoading(true);
      console.log('layout useEffect [pathname] return', pathname, timestamp);

      checkJwt(abortController, pathname, timestamp).then(
        ({ result, path, ts }) => {
          console.log(
            'layout useEffect [pathname] checkJwt promise resolved: ',
            result,
            path,
            ts
          );
          setIsLoading(false);
          abortController.abort();
        }
      );
    };
  }, [pathname]);

  // //second useEffect on pathName,
  // useEffect(() => {

  // }, [pathname]);

  //for first App load
  useEffect(() => {
    const timestamp = Date.now();
    console.log('who call? layout useEffect[]', pathname, timestamp);
    const abortController = new AbortController();
    //setIsLoading(true);
    console.log('layout useEffect []', pathname, timestamp);

    checkJwt(abortController, pathname, timestamp).then(
      ({ result, path, ts }) => {
        console.log(
          'layout useEffect [] checkJwt promise resolved: ',
          result,
          path,
          ts
        );
        setIsLoading(false);
        abortController.abort();
      }
    );

    return () => {
      abortController.abort();
    };
  }, []);

  // if (authentication?.jwtToken?.length <= 0) {
  //   return <Navigate to="/signin" />;
  // }

  return (
    <React.Fragment>
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Moduspace"
            routes={createSidebarRoutes({
              name: authentication.name,
              permissions: authentication.permissions
            })}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {isLoading && !authentication?.jwtToken ? <></> : <Outlet />}
    </React.Fragment>
  );
}

export default observer(Layout);
