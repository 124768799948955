import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Redirect({ to = '/' }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(to);
  }, [navigate, to]);
  return null;
}
