import { Link } from 'react-router-dom';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormField from 'layouts/components/FormField';

const CustomerDetails = ({ customer }) => {
  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="h5" gutterBottom>
            Customer Details
          </MDTypography>
          <Link to={`/dashboard/customers/${customer?.id}`} className="custom-link" target="_blank">
            <MDTypography variant="h6" color="info">View customer</MDTypography></Link>
        </MDBox>
        <Grid container spacing={3} my={1}>
          {/* Customer first name */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer First Name"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter customer first name"
              value={customer?.firstName || ''}
              InputProps={{ disabled: true }}
            />
          </Grid>
          {/* Customer last name */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer Last Name"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter customer last name"
              value={customer?.lastName || ''}
              InputProps={{ disabled: true }}
            />
          </Grid>
          {/* Customer email */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer Email"
              InputLabelProps={{ shrink: true }}
              value={customer?.email || '-'}
              InputProps={{ disabled: true }}
            />
          </Grid>
          {/* Customer phone */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer Phone"
              InputLabelProps={{ shrink: true }}
              value={customer?.phone || '-'}
              InputProps={{ disabled: true }}
            />
          </Grid>
          {/* Customer company */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer Company"
              InputLabelProps={{ shrink: true }}
              value={customer?.company || '-'}
              InputProps={{ disabled: true }}
            />
          </Grid>
          {/* Customer country */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Customer Country"
              InputLabelProps={{ shrink: true }}
              value={customer?.country || '-'}
              InputProps={{ disabled: true }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );   
};

export default CustomerDetails;