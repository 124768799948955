/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from 'layouts/components/FormField';

// Data
// import { getFormSchema } from "./formSchema";
// import { useForm } from "components/MDForm";
import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

function CommentInfo({
  commentInfo = {},
  setCommentInfo = () => void 0,
  onChangeSubmit = () => void 0,
  statuses = []
}) {
  // const [currentInfo, setCurrentInfo] = React.useState({ title: "Best Display ever", caption: "The Jedi Return", relatedSeries: ["df"], status: "pending" });
  // const [currentTags, setCurrentTags] = React.useState([]);

  // React.useEffect(() => {
  //   console.log("commentInfo: ", commentInfo);
  // }, [commentInfo]);

  const isStatusInvalid = () =>
    CommentInfo.isTextInputFieldInvalid(commentInfo);

  const nonEmptyProps = (field = '') => ({
    error: CommentInfo.isTextInputFieldInvalid(field),
    helperText: CommentInfo.isTextInputFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : (
      ' '
    ),
    required: true
  });

  return (
    <Card id="basic-info">
      <MDBox p={3}>
        <MDTypography variant="h6">Ideaboard Comment Information</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {commentInfo && (
            <>
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  multiline
                  maxRows={20}
                  label="Text"
                  placeholder="Some Text"
                  value={commentInfo.text ?? ''}
                  onChange={({ target: { value: text } }) =>
                    setCommentInfo({
                      ...commentInfo,
                      text
                    })
                  }
                  InputProps={{
                    readOnly: true
                  }}
                  {...nonEmptyProps(commentInfo.text ?? '')}
                />
              </Grid>

              {/* show editable only if post is published */}
              <Grid item xs={12}>
                <Autocomplete
                  value={commentInfo.status ?? null}
                  options={statuses ?? []}
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Status"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Status"
                        error={isStatusInvalid()}
                        helperText={
                          isStatusInvalid() ? (
                            <MDTypography variant="caption" color="error">
                              You must pick at least one status.
                            </MDTypography>
                          ) : (
                            ' '
                          )
                        }
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    );
                  }}
                  isOptionEqualToValue={(a, b) => a === b}
                  onChange={(_, selectedStatus) => {
                    // console.log(currentInfo, type)
                    setCommentInfo({
                      ...commentInfo,
                      status: selectedStatus
                    });
                  }}
                  disableClearable
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center">
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Posted:&nbsp;{' '}
                      <b>
                        {commentInfo?.createdAt
                          ? displayDate(new Date(commentInfo?.createdAt))
                          : '-'}
                      </b>{' '}
                      {commentInfo?.creator && (
                        <>
                          by <b>{joinName(commentInfo?.creator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Edited:&nbsp;{' '}
                      <b>
                        {commentInfo?.updatedAt
                          ? displayDate(new Date(commentInfo?.updatedAt))
                          : '-'}
                      </b>{' '}
                      {commentInfo?.updator && (
                        <>
                          by <b>{joinName(commentInfo?.updator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Flagged:&nbsp;{' '}
                      <b>
                        {commentInfo?.flaggedAt
                          ? displayDate(new Date(commentInfo?.flaggedAt))
                          : '-'}{' '}
                      </b>{' '}
                      {commentInfo?.flagger && (
                        <>
                          by <b>{joinName(commentInfo?.flagger)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox mt={3} ml="auto">
            <MDButton variant="gradient" color="dark" size="large" onClick={handleSubmit}>
              update profile
            </MDButton>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {{
 * title: "",
 * type: "",
 * status: "",
 * sites: string[],
 * link: "",
 * media: IMedia[] | null,
 * content: "" | null
 * }} userInfo
 */
CommentInfo.fillEmptyRequiredFields = (contentInfo) => {
  return {
    title: '',
    type: '',
    status: '',
    sites: [],
    link: '',
    media: [],
    content: '',
    ...contentInfo
  };
};

/**
 *
 * @param {string | undefined} field
 * @returns
 */
CommentInfo.isTextInputFieldInvalid = (field = '') =>
  typeof field === 'string' && field.length === 0;
// /**
//  *
//  * @param {{ sites?: string[] }} currentContentInfo
//  * @returns
//  */
// commentInfo.isMediaInvalid = (currentContentInfo) => currentContentInfo.media instanceof Array && currentContentInfo.media.length === 0;

/**
 * Get clean value from `commentInfo.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  text: "",
 * }} info
 */
CommentInfo.areRequireFieldsValid = (contentInfo) => {
  if (CommentInfo.isTextInputFieldInvalid(contentInfo.text)) return false;
  if (CommentInfo.isTextInputFieldInvalid(contentInfo.status)) return false;
  return true;
};

export default CommentInfo;
