import { Close } from "@mui/icons-material";
import { Divider, Icon, IconButton, Modal, Skeleton } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDAutocomplete from "components/MDAutocomplete";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDLoading from "components/MDLoading";
import MDTypography from "components/MDTypography";
import GeneralTable from "controls/Tables/GeneralTable";
import FormField from "layouts/components/FormField";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";

const AddCustomProductModal = ({
  applicationClientName,
  referenceOrderId,
  setRefetchToggle,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [inputProduct, setInputProduct] = useState({
    quantity: 1,
  });

  const onSubmit = async () => {
    if (
      [
        { label: "Name", value: "name" },
        { label: "Price (Inc. Tax)", value: "price_inc_tax" },
        { label: "Price (Ex. Tax)", value: "price_ex_tax" },
        { label: "Qty", value: "quantity" },
      ].filter(({ value }) => !inputProduct[value]).length > 0
    ) {
      toast.error("Please enter all required fields");
      return;
    } else {
      const invalidInputs = [
        { label: "Price (Inc. Tax)", value: "price_inc_tax" },
        { label: "Price (Ex. Tax)", value: "price_ex_tax" },
        { label: "Qty", value: "quantity" },
      ]
        .filter(({ value }) => inputProduct[value] < 1)
        .map((i) => i.label)
        .join(", ");

      if (invalidInputs.length > 0) {
        toast.error(`${invalidInputs} cannot be less than or equal to 0`);
        return;
      }
    }

    toast.info(`Adding new order product in store: ${applicationClientName}`);
    setIsRefreshing(true);
    try {
      await ModuspaceService.UpsertBigcommerceSalesOrderProducts({
        client: applicationClientName,
        orderId: referenceOrderId,
        payload: {
          products: [inputProduct],
        },
      });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setRefetchToggle((prev) => !prev);
      toast.success("Done");
    } catch (err) {
      toast.error(err.message);
    }

    setIsRefreshing(false);
    setIsVisible(false);
    // reset
    setInputProduct({ quantity: 1 });
  };

  return (
    <div>
      <Link
        className="custom-link"
        style={{ fontSize: 12 }}
        onClick={() => setIsVisible(!isVisible)}>
        Custom Product
      </Link>
      <Modal
        open={isVisible}
        onClose={() => setIsVisible(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <MDBox
          style={{
            height: "95%",
            width: "80%",
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Card>
            <MDBox pt={3} px={3}>
              <MDLoading show={isRefreshing} text="Processing" />
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Grid item>
                  <MDTypography variant="h5" fontWeight="medium">
                    Add Custom Product
                  </MDTypography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setIsVisible(!isVisible)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox py={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="Name"
                    placeholder="Enter Name"
                    InputLabelProps={{ shrink: true }}
                    value={inputProduct.name}
                    onChange={(e) =>
                      setInputProduct({ ...inputProduct, name: e.target.value })
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="SKU"
                    placeholder="Enter SKU"
                    InputLabelProps={{ shrink: true }}
                    value={inputProduct.sku}
                    onChange={(e) =>
                      setInputProduct({ ...inputProduct, sku: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="Price (Inc. Tax)"
                    placeholder="Enter Price (Inc. Tax)"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    value={inputProduct.price_inc_tax}
                    onChange={(e) =>
                      setInputProduct({
                        ...inputProduct,
                        price_inc_tax: Number(e.target.value),
                      })
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="Price (Ex. Tax)"
                    placeholder="Enter Price (Ex. Tax)"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    value={inputProduct.price_ex_tax}
                    onChange={(e) =>
                      setInputProduct({
                        ...inputProduct,
                        price_ex_tax: Number(e.target.value),
                      })
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="Qty (>= 1)"
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    value={inputProduct.quantity}
                    onChange={(e) =>
                      setInputProduct({
                        ...inputProduct,
                        quantity: Number(e.target.value),
                      })
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="success"
                    style={{
                      width: "100%",
                    }}
                    onClick={onSubmit}>
                    Add
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </Modal>
    </div>
  );
};

const ProductVariantEditForm = ({
  row,
  isRefreshing = false,
  applicationClientName,
  productId,
  refreshController,
  setRefreshController,
  setOrderProducts,
  resetProductOptions,
}) => {
  const [productOptionSelections, setProductOptionSelections] = useState([]);

  useEffect(() => {
    if (resetProductOptions && productId === resetProductOptions)
      setProductOptionSelections([]);
  }, [resetProductOptions]);

  useEffect(() => {
    (async () => {
      setRefreshController({
        ...refreshController,
        fetchProductOptions: true,
      });
      let productOptions = [];

      try {
        productOptions =
          await ModuspaceService.ListBigcommerceCatalogProductOptionsByProductId(
            {
              client: applicationClientName,
              productId,
            },
          );
      } catch (err) {
        console.error(err.message);
      }
      setRefreshController({
        ...refreshController,
        fetchProductOptions: false,
      });
      if (productOptions) setProductOptionSelections(productOptions);
    })();
  }, [productId]);

  return (
    <>
      {resetProductOptions &&
        productId === resetProductOptions &&
        refreshController.fetchProductOptions && <Skeleton animation="wave" />}
      <Grid container>
        {productOptionSelections?.map((opt) =>
          isRefreshing ? (
            <Grid item md={12} key={opt.referenceId}>
              <Skeleton animation="wave" />
            </Grid>
          ) : (
            <Grid item md={12} key={opt.referenceId}>
              <MDAutocomplete
                label={opt.displayName}
                name="product-option"
                value={opt.optionValues?.find(
                  (ov) =>
                    ov.label ===
                    row?.product_options?.find(
                      (x) => x.display_name === opt.displayName,
                    )?.display_value,
                )}
                options={opt.optionValues.map((r) => {
                  return {
                    label: r.label,
                    id: r.referenceId,
                  };
                })}
                onChange={(e, val) => {
                  setOrderProducts((prev) =>
                    prev.map((i) => {
                      if (i.id === row.id) {
                        let productOptions = [];
                        if (!i.productOptions) {
                          productOptions = [
                            {
                              id: opt.referenceId,
                              value: val?.id,
                            },
                          ];
                        } else {
                          let existingProductOptionsIndex =
                            i.productOptions.findIndex(
                              (x) => x.id === opt.referenceId,
                            );
                          if (existingProductOptionsIndex !== -1) {
                            i.productOptions[existingProductOptionsIndex] =
                              val?.id;
                            productOptions = i.productOptions;
                          } else {
                            productOptions = [
                              ...i.productOptions,
                              {
                                id: opt.referenceId,
                                value: val?.id,
                              },
                            ];
                          }
                        }
                        return {
                          ...i,
                          productOptions,
                        };
                      }
                      return i;
                    }),
                  );
                }}
                width={100}
              />
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
};

const AddOrderProductForm = ({
  productSelections,
  applicationClientName,
  referenceOrderId,
  setRefetchToggle,
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [productOptionSelections, setProductOptionSelections] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [selectedQty, setSelectedQty] = useState(1);

  useEffect(() => {
    if (selectedProductId)
      (async () => {
        setIsRefreshing(true);
        let productOptions = [];

        try {
          productOptions =
            await ModuspaceService.ListBigcommerceCatalogProductOptionsByProductId(
              {
                client: applicationClientName,
                productId: selectedProductId,
              },
            );
        } catch (err) {
          console.error(err.message);
        }
        setIsRefreshing(false);
        if (productOptions) setProductOptionSelections(productOptions);
      })();
  }, [selectedProductId]);

  return (
    <MDBox p={3}>
      <MDBox component="form" onSubmit={() => {}}>
        {isRefreshing && <MDLoading show={isRefreshing} text="Processing" />}
        <Grid container spacing={3} pt={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDTypography variant="h5">Add New Product</MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {!isRefreshing && (
                  <AddCustomProductModal
                    applicationClientName={applicationClientName}
                    referenceOrderId={referenceOrderId}
                    setRefetchToggle={setRefetchToggle}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <MDAutocomplete
                  label="Product"
                  name="product"
                  value={selectedProductId}
                  options={productSelections}
                  onChange={async (e, val) => {
                    setSelectedProductId(val?.id);
                    setSelectedProductOptions([]);
                  }}
                  freeSolo
                  // getOptionLabel={option => option.title || option}
                  width={100}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormField
                  label="Qty"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={selectedQty}
                  onChange={(e) => {
                    setSelectedQty(Number(e.target.value));
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <MDButton
                  disabled={isRefreshing}
                  color="secondary"
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onClick={async () => {
                    if (
                      !selectedProductId ||
                      !selectedProductOptions ||
                      !selectedQty
                    ) {
                      toast.error("Please select valid input");
                      return;
                    } else if (
                      selectedProductOptions.filter((x) => x.id && x.value)
                        .length !== productOptionSelections.length
                    ) {
                      toast.error("Please select all product options");
                      return;
                    }
                    toast.info(
                      `Adding new order product in store: ${applicationClientName}`,
                    );
                    setIsRefreshing(true);
                    try {
                      await ModuspaceService.UpsertBigcommerceSalesOrderProducts(
                        {
                          client: applicationClientName,
                          orderId: referenceOrderId,
                          payload: {
                            products: [
                              {
                                product_id: selectedProductId,
                                product_options: selectedProductOptions,
                                quantity: selectedQty,
                              },
                            ],
                          },
                        },
                      );
                      await new Promise((resolve) => setTimeout(resolve, 5000));
                      setRefetchToggle((prev) => !prev);
                      toast.success("Done");
                    } catch (err) {
                      toast.error(err.message);
                    }

                    setIsRefreshing(false);
                  }}>
                  <Icon>add</Icon>
                </MDButton>
              </Grid>
            </Grid>
            <Divider />
            {isRefreshing && <Skeleton animation="wave" />}
            <Grid container>
              {productOptionSelections?.map((opt) => (
                <Grid item md={12} key={opt.referenceId}>
                  {isRefreshing ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <MDAutocomplete
                      required
                      label={opt.displayName}
                      name="product-option"
                      options={opt.optionValues.map((r) => {
                        return {
                          label: r.label,
                          id: r.referenceId,
                        };
                      })}
                      onChange={(e, val) => {
                        setSelectedProductOptions((prev) => {
                          if (!prev)
                            return [
                              {
                                id: opt.referenceId,
                                value: val?.id,
                              },
                            ];

                          return [
                            ...prev.filter((i) => i.id !== opt.referenceId),
                            {
                              id: opt.referenceId,
                              value: val?.id,
                            },
                          ];
                        });
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default function Product({ info, setRefetchToggle }) {
  const sortedOrderProduct = info.products.sort(
    (a, b) => b.quantity - a.quantity,
  );
  const applicationClientName = info.applicationClient?.name;
  const currencySymbol = info.orderCurrency?.symbol;
  const originalOrderProductsRef = useRef(sortedOrderProduct);
  const [orderProducts, setOrderProducts] = useState([]);
  const [toggleUpdateProduct, setToggleUpdateProduct] = useState(false);
  const [updatedID, setUpdatedID] = useState();

  const [showAddProduct, setShowAddProduct] = useState(false);
  const [productSelections, setProductSelections] = useState([]);
  const [resetProductOptions, setResetProductOptions] = useState();

  const [refreshController, setRefreshController] = useState({
    actions: false,
    fetchProducts: false,
    fetchProductOptions: false,
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setOrderProducts(sortedOrderProduct);
    setToggleUpdateProduct(!toggleUpdateProduct);
  }, [info]);

  async function init() {
    setRefreshController({
      ...refreshController,
      fetchProducts: true,
    });
    try {
      const { list } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListBigcommerceCatalogProducts({
            client: applicationClientName,
          }),
        }),
        100,
      );
      setOrderProducts(sortedOrderProduct);

      setToggleUpdateProduct(!toggleUpdateProduct);
      setProductSelections(
        list
          .filter((x) => x.referenceId)
          .map((item) => ({
            label: item.name,
            id: item.referenceId,
          }))
          .sort((a, b) =>
            a.label.localeCompare(b.label, undefined, { numeric: true }),
          ),
      );
    } catch (err) {
      console.error("OrderProductInformation:", err);
    }
    setRefreshController({
      ...refreshController,
      fetchProducts: false,
    });
  }

  useEffect(() => {
    if (productSelections?.length > 0) {
      setOrderProducts((prev) =>
        prev.map((i) => {
          if (productSelections?.find((i2) => i2.id === i.product_id)) return i;
          return {
            ...i,
            // product === 0 mean custom product
            isDiscontinued: i.product_id !== 0,
          };
        }),
      );
    }
  }, [toggleUpdateProduct, productSelections]);

  let tableSchema = [
    {
      label: "Product Info",
      name: "product-info",
      width: 100,
      render: (object, row) => {
        const name = row.name;
        const sku = row.sku;

        return false && row.isEditRow && !row.isDiscontinued ? (
          <>
            {refreshController.fetchProducts || updatedID === row?.id ? (
              <Skeleton animation="wave" />
            ) : (
              <MDAutocomplete
                name="product"
                value={
                  productSelections.find((x) => x.id === row?.productId) || null
                }
                options={productSelections}
                onChange={async (e, val) => {
                  if (!val || !productSelections) return;
                  setResetProductOptions(val.id);
                  setOrderProducts((prev) =>
                    prev.map((i) => {
                      if (i.id === row.id)
                        return {
                          ...i,
                          productId: val.id,
                        };

                      // remove existing productOptions
                      delete i.productOptions;
                      return i;
                    }),
                  );
                  setToggleUpdateProduct(!toggleUpdateProduct);
                }}
                width={100}
              />
            )}
            <Divider />

            {row?.productId && (
              <ProductVariantEditForm
                row={row}
                isRefreshing={updatedID === row?.id}
                productId={row.productId}
                applicationClientName={applicationClientName}
                refreshController={refreshController}
                setRefreshController={setRefreshController}
                updatedID={updatedID}
                resetProductOptions={resetProductOptions}
                setOrderProducts={setOrderProducts}
              />
            )}
          </>
        ) : (
          <div style={{ opacity: row.isDiscontinued ? 0.5 : 1 }}>
            <Grid container>
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={5}>
                    <b
                      style={{
                        textDecoration:
                          row.quantity <= 0 ? "line-through" : null,
                      }}>
                      {name}
                    </b>
                  </Grid>
                  <Grid item md={7}>
                    <div
                      style={{
                        textDecoration:
                          row.quantity <= 0 ? "line-through" : null,
                      }}>
                      {sku}{" "}
                      {row.isDiscontinued ? (
                        <MDBadge
                          size="xs"
                          badgeContent="Discontinued"
                          color="error"
                          container
                        />
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {row.product_options?.map((opt) => (
                <Grid item md={12} key={opt.display_name}>
                  <Grid container>
                    <Grid item md={5}>
                      <b>{opt.display_name}</b>:
                    </Grid>
                    <Grid item md={7}>
                      {opt.display_value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        );
      },
    },
    {
      label: "Rate",
      name: "base_price",
      render: (object, row) => {
        return updatedID === row?.id ? (
          <Skeleton animation="wave" />
        ) : (
          <>
            {currencySymbol}
            {Number(object ?? 0).toFixed(2)}
          </>
        );
      },
    },
    {
      label: "Qty",
      name: "quantity",
      render: (object, row) =>
        updatedID === row?.id ? (
          <Skeleton animation="wave" />
        ) : row.isNewRow || row.isEditRow ? (
          <FormField
            InputLabelProps={{ shrink: true }}
            type="number"
            value={object}
            onChange={(e) => {
              setOrderProducts((prev) =>
                prev.map((i) => {
                  if (i.id === row.id)
                    return {
                      ...i,
                      quantity: Number(e.target.value),
                    };
                  return i;
                }),
              );
            }}
          />
        ) : (
          object
        ),
    },
    {
      label: "Action",
      name: "action",
      render: (obj, row) => {
        return row.isEditRow ? (
          <>
            <MDButton
              onClick={async () => {
                toast.info(
                  `Updating order product in store: ${applicationClientName}`,
                );

                setUpdatedID(row?.id);
                setRefreshController({
                  ...refreshController,
                  actions: true,
                });
                try {
                  await ModuspaceService.UpsertBigcommerceSalesOrderProducts({
                    client: applicationClientName,
                    orderId: info.referenceOrderId,
                    payload: {
                      products: [
                        {
                          id: row.id,
                          product_id: row.productId,
                          product_options: row.productOptions,
                          quantity: row.quantity,
                        },
                      ],
                    },
                  });
                } catch (err) {}

                await new Promise((resolve) => setTimeout(resolve, 5000));

                setOrderProducts((prev) =>
                  prev.map((i) => {
                    if (i.id === row.id)
                      return {
                        ...row,
                        isEditRow: false,
                      };
                    return i;
                  }),
                );
                setRefetchToggle((prev) => !prev);
                setRefreshController({
                  ...refreshController,
                  actions: false,
                });

                setUpdatedID(null);

                toast.success("Done");
              }}
              size="small"
              color="white"
              disabled={refreshController.actions}
              style={{ minWidth: "unset", color: "#2e2" }}>
              Save
            </MDButton>
            <MDButton
              onClick={async () => {
                setOrderProducts((prev) =>
                  prev.map((i) => {
                    if (
                      i.id === row.id &&
                      originalOrderProductsRef &&
                      originalOrderProductsRef.current
                    ) {
                      const originalData =
                        originalOrderProductsRef.current.find(
                          (x) => x.id === row.id,
                        );

                      return originalData;
                    }
                    return i;
                  }),
                );

                setToggleUpdateProduct(!toggleUpdateProduct);
              }}
              size="small"
              color="white"
              disabled={refreshController.actions}
              style={{ minWidth: "unset", color: "red" }}>
              Cancel
            </MDButton>
          </>
        ) : (
          !row.isDiscontinued && (
            <MDButton
              onClick={() => {
                setOrderProducts((prev) =>
                  prev.map((i) => {
                    if (i.id === row.id)
                      return {
                        ...i,
                        isEditRow: true,
                        productId: productSelections?.find(
                          (x) => x.label === row?.name,
                        )?.id,
                      };
                    return i;
                  }),
                );
              }}
              size="small"
              color="secondary"
              disabled={refreshController.actions}
              style={{ minWidth: "unset" }}>
              <Icon>edit</Icon>
            </MDButton>
          )
        );
      },
    },
  ];

  return (
    <Card id="product">
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <MDTypography variant="h5">Product Information</MDTypography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDButton
              color="secondary"
              style={{ minWidth: "unset" }}
              onClick={() => setShowAddProduct(!showAddProduct)}>
              Add Product
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      {showAddProduct && (
        <AddOrderProductForm
          productSelections={productSelections}
          applicationClientName={applicationClientName}
          referenceOrderId={info.referenceOrderId}
          setRefetchToggle={setRefetchToggle}
        />
      )}
      <GeneralTable
        headColumn={tableSchema}
        bodyColumn={orderProducts || []}
        disablePagination={true}
        height={600}
      />
    </Card>
  );
}
