export const getGraphComparisonPercentage = (graphData) => {
  const value1 = graphData ? graphData[graphData.length - 2].quantity : 0; // last month
  const value2 = graphData ? graphData[graphData.length - 1].quantity : 0; // this month

  if (value1 === value2) {
    return '0%';
  } else if (value1 === 0 && value2 > 0) {
    return `+${value2 * 100}%`;
  } else if (value2 === 0 && value1 > 0) {
    return `-${value1 * 100}%`;
  } else {
    const symbol = value2 > value1 ? '+' : '-';
    const percentage =
      value2 > value1 ? (value2 / value1) * 100 : (value1 / value2) * 100;
    const displayPercentage = Number.isInteger(percentage)
      ? percentage
      : percentage.toFixed(2);

    return `${symbol}${displayPercentage}%`;
  }
};

export const getLatestMonthQuantity = (graphData) => {
  return graphData ? graphData[graphData.length - 1].quantity : 0;
};
