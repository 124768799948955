/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { toast } from 'react-toastify';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';
import { Grid } from '@mui/material';
import FormField from 'components/MDFormField';
import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

function Approval({
  remarks = '',
  setRemarks = () => void 0,
  isInitial = true,
  setIsInitial = () => void 0,
  pendingReview = {},
  onApprove = () => void 0,
  onReject = () => void 0,
  areApproveRequireFieldsValid = () => void 0,
  areRejectRequireFieldsValid = () => void 0
}) {
  // const [remarks, setRemarks] = React.useState("");
  // const remarks = commentInfo.remarks || null;
  const [review, setReview] = React.useState({});

  React.useEffect(() => {
    const info = pendingReview ?? {};
    setReview(info);
  }, [pendingReview]);

  const { prompt: promptApprove, MDConfirm: MDConfirmApprove } =
    useConfirmModal({
      title: 'Confirm Approve Post?',
      content:
        'Once you flag this comment, it will be remove from public. Please be certain.',
      confirmColor: 'warning',
      confirmText: 'flag'
    });

  const { prompt: promptReject, MDConfirm: MDConfirmReject } = useConfirmModal({
    title: 'Confirm Reject Post?',
    content: 'Rejecting this request will keep the comment on post',
    confirmColor: 'light',
    confirmText: 'reject'
  });

  const getApprovePostReviewConfirmModelProps = () => {
    if (areApproveRequireFieldsValid()) {
      promptApprove({
        title: 'Confirm Flag Comment?',
        content:
          `You will be flag this comment with remarks: ${remarks}.` +
          'Once you flag this comment, it will be remove from public. Please be certain.\n',
        confirmColor: 'warning',
        confirmText: 'flag',
        onConfirm: async () => await onApprove(review)
      });
    } else {
      setIsInitial(false);
      toast.error('Fill in the required info first!');
    }
  };

  const getRejectPostReviewConfirmModelProps = () => {
    if (areRejectRequireFieldsValid()) {
      promptReject({
        title: 'Confirm Reject Post?',
        content:
          `You will be rejecting this request with remarks: ${remarks}.` +
          'Rejecting this request will keep the comment on post\n',
        confirmColor: 'light',
        confirmText: 'reject',
        onConfirm: async () => await onReject(review)
      });
    } else {
      setIsInitial(false);
      toast.error('Fill in the required info first!');
    }
  };

  const nonEmptyProps = (field = '') => ({
    error: !isInitial ? Approval.isTextInputFieldInvalid(field) : '',
    helperText:
      Approval.isTextInputFieldInvalid(field) && !isInitial ? (
        <MDTypography variant="caption" color="error">
          This field is required.
        </MDTypography>
      ) : (
        ' '
      ),
    required: !isInitial
  });

  return (
    <Card id="review-post-approval">
      <MDConfirmApprove />
      <MDConfirmReject />
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h6">Review</MDTypography>
          </MDBox>
          <MDBox alignItems="center" mb={0.5}>
            <MDBox mt={0.5}>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
                sx={{ marginBottom: '5px' }}>
                Type: <b>{review?.type}</b>
              </MDTypography>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize">
                Reason: <b>{review?.reason}</b>
              </MDTypography>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize">
                Status: <b>{review?.status}</b>{' '}
                {review?.resolvedAt && (
                  <>
                    :&nbsp;<b>{displayDate(new Date(review?.resolvedAt))} </b>
                  </>
                )}
              </MDTypography>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize">
                {review?.resolver && (
                  <>
                    by <b>{joinName(review?.resolver)}</b>
                  </>
                )}
              </MDTypography>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                fontSize={'small'}
                sx={{ padding: '5px' }}>
                {review?.remarks}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="Remarks"
              fullWidth
              multiline
              maxRows={20}
              placeholder="Write review rejection/approval remarks"
              value={remarks}
              onChange={({ target: { value } }) => {
                setIsInitial(false);
                setRemarks(value);
              }}
              {...nonEmptyProps(remarks ?? '')}
            />
          </Grid>
        </Grid>
        <MDBox
          mt={3}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="light"
            size="large"
            onClick={() => getRejectPostReviewConfirmModelProps()}
            style={{ marginLeft: '.5rem', marginBottom: '.5rem' }}>
            Ignore This Flag
          </MDButton>
          <MDButton
            variant="gradient"
            color="warning"
            size="large"
            onClick={() => getApprovePostReviewConfirmModelProps()}
            style={{ marginLeft: '.5rem', marginBottom: '.5rem' }}>
            Set Comment as Inactive
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Approval.fillEmptyRequiredFields = (contentInfo) => {
  return {
    remarks: '',
    ...contentInfo
  };
};

Approval.isTextInputFieldInvalid = (field = '') =>
  typeof field === 'string' && field.length === 0;

Approval.areRequireFieldsValid = (contentInfo) => {
  if (Approval.isTextInputFieldInvalid(contentInfo.remarks)) return false;
  return true;
};

export default Approval;
