/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from 'react';

// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDDropzone from 'components/MDDropzone';

//mobx
import { observer } from 'mobx-react-lite';

// Dropzone components
import Dropzone from 'dropzone';

function CoverImages({ createMode, media, onChange = () => void 0 }) {
  const [editMode, setEditMode] = useState(true);
  const [files, setFiles] = useState([]);
  const [currentMedia, setCurrentMedia] = useState([]);

  useEffect(() => {
    if (!createMode) setEditMode(createMode);
  }, []);

  useEffect(() => {
    // console.log("CoverImages useEffect[currentMedia] media: ", media);
    setCurrentMedia(media);
  }, [media]);

  useEffect(() => {
    // console.log("media dropped: ", files);
    //callback
    onChange(files);
  }, [files]);

  const onEdit = (event) => {
    event.preventDefault();
    setEditMode(!editMode);
  };

  const onPreview = (event) => {
    event.preventDefault();
    setEditMode(!editMode);
  };

  const onClear = (event) => {
    event.preventDefault();
    setFiles([]);
    setCurrentMedia([]);
    Dropzone.instances.forEach((instance) => {
      instance.removeAllFiles(true);
    });
    setEditMode(true);
  };

  const accept = (file, done) => {
    // console.log("dropzone accepted file: ", file);
    setFiles((files) => [...files, file]);
    done();
  };

  let filesOrMedia =
    files.length > 0 ? files : currentMedia.length > 0 ? currentMedia : [];

  return (
    <MDBox>
      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <MDTypography
          component="label"
          variant="button"
          fontWeight="regular"
          color="text">
          Media - Cover image for app is optimised for image dimension of 800px
          (width) *
        </MDTypography>
      </MDBox>
      {/* if not edit mode, display none */}
      <MDBox
        sx={
          !editMode &&
          ((editMode && files.length > 0) || filesOrMedia.length > 0) && {
            display: 'none'
          }
        }>
        {useMemo(
          () => (
            <>
              <MDDropzone
                options={{
                  acceptedFiles: 'image/png,image/jpeg',
                  parallelUploads: 1,
                  uploadMultiple: false,
                  addRemoveLinks: true,
                  autoProcessQueue: false,
                  resizeQuality: 0.9,
                  accept: accept,
                  removedfile: function (file) {
                    file.previewElement.remove();
                    setFiles((files) =>
                      files.filter(
                        (item) => item.upload.uuid !== file.upload.uuid
                      )
                    );
                  }
                }}
              />
            </>
          ),
          []
        )}
      </MDBox>
      {/* if  edit mode, display none */}
      <MDBox sx={editMode && { display: 'none' }}>
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          overflow="auto"
          alignItems="center"
          position="relative"
          zIndex={1}>
          {createMode &&
            files.length > 0 &&
            files?.map((e, i) => {
              return (
                <MDBox
                  key={i}
                  m={1}
                  component="img"
                  src={e.dataURL}
                  alt="cover-Image"
                  borderRadius="lg"
                  shadow="sm"
                  minHeight={'250px'}
                  maxHeight={'250px'}
                  height={'250px'}
                  position="relative"
                  zIndex={10}
                  mb={2}
                />
              );
            })}

          {!createMode &&
            filesOrMedia.length > 0 &&
            filesOrMedia?.map((e, i) => {
              if (!e.dataURL && !e.link) return null;
              return (
                <MDBox
                  key={i}
                  m={1}
                  component="img"
                  src={e.dataURL ?? e.link}
                  alt="cover-Image"
                  borderRadius="lg"
                  shadow="sm"
                  minHeight={'250px'}
                  maxHeight={'250px'}
                  height={'250px'}
                  position="relative"
                  zIndex={10}
                  mb={2}
                />
              );
            })}
        </MDBox>
      </MDBox>

      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          zIndex={1}>
          {/* if not edit mode, and has file show edit toogle button */}
          {!editMode && files.length > 0 && (
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={onEdit}>
                Edit
              </MDButton>
            </MDBox>
          )}

          {/* if edit mode, and file more than 1, show preview toogle button */}
          {editMode && files.length > 0 && (
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={onPreview}>
                Preview
              </MDButton>
            </MDBox>
          )}

          {/* file more than 1, show clear button */}
          {((!createMode && files.length > 0) ||
            currentMedia.length > 0 ||
            (createMode && files.length > 0)) && (
            <MDBox mr={1}>
              <MDButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={onClear}>
                Clear
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default observer(CoverImages);
