import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import { checkValidField } from 'utils/validation';

import CustomerInfo from 'pages/ideaboard/comments-reviews/components/CustomerInfo';
import CommentInfo from 'pages/ideaboard/comments-reviews/components/CommentInfo';
import PostInfo from 'pages/ideaboard/comments-reviews/components/PostInfo';
import ReviewInfo from 'pages/ideaboard/comments-reviews/components/ReviewInfo';
import UpdateComment from 'pages/ideaboard/comments-reviews/components/UpdateComment';
import ReviewApproval from 'pages/ideaboard/comments-reviews/components/ReviewApproval';

function IdeaboardReviewCommentDetails() {
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(null);
  const [initialCommentInfo, setInitialCommentInfo] = React.useState(null);
  const [commentInfo, setCommentInfo] = React.useState(null);
  const [remarks, setRemarks] = React.useState('');
  const [isInitial, setIsInitial] = React.useState(true);
  const [pastReviews, setPastReviews] = React.useState([]);
  const [pendingReviews, setPendingReviews] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const { status, id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const emailRef = React.useRef('');

  // React.useEffect(() => {
  //   let routes = location.pathname.split("/").slice(2);
  //   routes[routes.length - 1] = joinName(commentInfo) || "Post Info";
  //   pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
  //   // eslint-disable-next-line
  // }, [location, commentInfo]);

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/ideaboard/review/comments');
  }, [updated, deleted]);

  const fetchPostCommentInfo = async () => {
    setLoading('Fetching post comment info...');
    try {
      const { commentInfo, reviews, statuses } = await delayResolve(
        async () => ({
          commentInfo: await ModuspaceService.GetIdeaboardPostCommentById(
            authentication.jwtToken,
            id
          ),
          reviews:
            await ModuspaceService.GetIdeaboardPostCommentReviewsByPostId(
              authentication.jwtToken,
              id
            ),
          statuses: await ModuspaceService.GetIdeaboardPostCommentStatus(
            authentication.jwtToken
          )
        })
      );
      setInitialCommentInfo(commentInfo);
      setCommentInfo(commentInfo);
      setPastReviews(reviews.filter((r) => r.status !== 'Pending'));
      setPendingReviews(reviews.filter((r) => r.status === 'Pending'));
      setStatuses(statuses);
      emailRef.current = commentInfo?.user?.email;
    } catch (err) {
      console.error('Error at fetchPostCommentInfo:', err);
    }
    setLoading(null);
  };

  const updateComment = async () => {
    setLoading('Submitting');
    try {
      //populate payload - update postInfo
      const payload = {
        ...commentInfo,
        remarks
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostCommentById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setUpdated(true);
        setRemarks('');
        setIsInitial(true);
        fetchPostCommentInfo();
        toast.success('Your comment has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at reactivatePost:', err);
      toast.error('Fail to update comment.');
    } finally {
      setLoading(null);
    }
  };

  const areUpdateRequireFieldsValid = () => {
    if (checkValidField(commentInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const flagComment = async () => {
    setLoading('Submitting');
    try {
      //populate payload - update postInfo
      const payload = {
        ...commentInfo,
        remarks
      };
      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostCommentFlagById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setUpdated(true);
        setRemarks('');
        setIsInitial(true);
        fetchPostCommentInfo();
        toast.success('Comment has been flagged successfully.');
      }
    } catch (err) {
      console.error('Error at flagPost:', err);
      toast.error('Fail to flag comment.');
    } finally {
      setLoading(null);
    }
  };

  const areFlagRequireFieldsValid = () => {
    if (checkValidField(commentInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const deleteComment = async () => {
    setLoading('Submitting');
    try {
      const response = await delayResolve(
        async () =>
          await ModuspaceService.DeleteIdeaboardPostCommentById(
            authentication.jwtToken,
            id
          )
      );
      if (response) {
        setDeleted(true);
        toast.success('Comment has been deleted successfully.');
      }
    } catch (err) {
      console.error('Error at deletePost:', err);
      toast.error('Fail to delete comment.');
    } finally {
      setLoading(null);
    }
  };

  const areDeleteRequireFieldsValid = () => {
    if (checkValidField(commentInfo.status)) return false;
    if (checkValidField(commentInfo.remarks)) return false;
    return true;
  };

  const approveCommentReview = async (reviews) => {
    setLoading('Submitting');
    try {
      //populate payload - approve
      const commentReviews = reviews.map((review) => {
        return {
          userCommentReviewId: review.id,
          snapshot: review.snapshot,
          remarks: remarks ?? ''
        };
      });

      const payload = {
        commentReviews: commentReviews,
        type: reviews[0].type,
        status: 'Approved'
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostCommentReviewsById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setUpdated(true);
        setRemarks('');
        setIsInitial(true);
        fetchPostCommentInfo();
        toast.success('Comment Review has been approved successfully.');
      }
    } catch (err) {
      console.error('Error at approveCommentReview:', err);
      toast.error('fail to approve comment review.');
    } finally {
      setLoading(null);
    }
  };

  const areApproveRequireFieldsValid = () => {
    if (checkValidField(commentInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  const rejectCommentReview = async (reviews) => {
    setLoading('Submitting');
    try {
      //populate payload - reject
      const commentReviews = reviews.map((review) => {
        return {
          userCommentReviewId: review.id,
          snapshot: review.snapshot,
          remarks: remarks ?? ''
        };
      });

      const payload = {
        commentReviews: commentReviews,
        type: reviews[0].type,
        status: 'Rejected'
      };

      const response = await delayResolve(
        async () =>
          await ModuspaceService.UpdateIdeaboardPostCommentReviewsById(
            authentication.jwtToken,
            id,
            payload
          )
      );
      if (response) {
        setUpdated(true);
        setRemarks('');
        setIsInitial(true);
        fetchPostCommentInfo();
        toast.success('Comment review has been rejected successfully.');
      }
    } catch (err) {
      console.error('Error at rejectCommentReview:', err);
      toast.error('Fail to reject comment review.');
    } finally {
      setLoading(null);
    }
  };

  const areRejectRequireFieldsValid = () => {
    if (checkValidField(commentInfo.status)) return false;
    if (checkValidField(remarks)) return false;
    return true;
  };

  React.useEffect(() => {
    if (commentInfo === null && authentication.jwtToken) fetchPostCommentInfo();
    // eslint-disable-next-line
  }, [commentInfo, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CommentInfo
              setCommentInfo={setCommentInfo}
              commentInfo={commentInfo}
              statuses={statuses}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerInfo customerInfo={commentInfo?.user} />
          </Grid>

          {pastReviews?.length > 0 && (
            <Grid item xs={12}>
              <ReviewInfo commentReviews={pastReviews} />
            </Grid>
          )}

          {pendingReviews?.length > 0 && (
            <Grid item xs={12} my={1}>
              <ReviewApproval
                remarks={remarks}
                setRemarks={setRemarks}
                isInitial={isInitial}
                setIsInitial={setIsInitial}
                pendingReviews={pendingReviews}
                onApprove={approveCommentReview}
                onReject={rejectCommentReview}
                areApproveRequireFieldsValid={areApproveRequireFieldsValid}
                areRejectRequireFieldsValid={areRejectRequireFieldsValid}
              />
            </Grid>
          )}

          {pendingReviews.length === 0 && (
            <Grid item xs={12} my={1}>
              <UpdateComment
                remarks={remarks}
                setRemarks={setRemarks}
                isInitial={isInitial}
                setIsInitial={setIsInitial}
                initialCommentInfo={initialCommentInfo}
                onUpdate={updateComment}
                onDelete={deleteComment}
                onFlag={flagComment}
                areFlagRequireFieldsValid={areFlagRequireFieldsValid}
                areUpdateRequireFieldsValid={areUpdateRequireFieldsValid}
                areDeleteRequireFieldsValid={areDeleteRequireFieldsValid}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PostInfo postInfo={commentInfo?.userPost} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(IdeaboardReviewCommentDetails);
