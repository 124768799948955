import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { delayResolve } from "utils/delay";
import ModuspaceService from "services/moduspace.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDLoading from "components/MDLoading";
import BaseLayout from "layouts/components/BaseLayout";
import { joinName } from "utils/join-name";
import BasicInfo from "./components/BasicInfo/index.js";
import { toast } from "react-toastify";

function OrderDetails() {
  const [loading, setLoading] = useState(null);
  const [info, setInfo] = useState(null);
  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const { pathname } = useLocation();
  const [refetchToggle, setRefetchToggle] = useState(false);

  useEffect(() => {
    let routes = pathname.split("/").slice(2);
    routes[routes.length - 1] = joinName(info) || "Order Info";
    pageControl.overwriteBreadcrumbsRoutes(pathname, routes);
    // // eslint-disable-next-line
  }, [pathname, info]);

  const fetchOrderInfo = async () => {
    setLoading("Fetching order info...");
    try {
      const { orderInfo } = await delayResolve(
        async () => ({
          orderInfo: await ModuspaceService.GetOrderById(
            authentication.jwtToken,
            id,
          ),
        }),
        100,
      );
      setInfo(orderInfo);
      setRefetchToggle(false);
    } catch (err) {
      console.error("Error at fetchOrderInfo: ", err);
    }
    setLoading(null);
  };

  useEffect(() => {
    if (info === null && authentication.jwtToken || refetchToggle) {
      fetchOrderInfo();
    }
  }, [info, authentication.jwtToken, refetchToggle]);

  const updateOrder = async (orderInfo) => {
    setLoading('Submitting');
    try {
      const updatedInfo = await ModuspaceService.UpdateSalesOrderById(authentication.jwtToken, id, {
        applicationClient: info.applicationClient,  
        referenceOrderId: info.referenceOrderId,
        ...orderInfo,
      });
      setInfo(updatedInfo);
      toast.success('Order updated successfully');
    } catch (err) {
      console.error('Error at updateOrder:', err);
    }
    setLoading(null);
  }

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {info && (
              <BasicInfo 
                info={info} 
                setRefetchToggle={setRefetchToggle}
                updateOrder={updateOrder}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(OrderDetails);
