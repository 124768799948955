import React from "react";

import { Card, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ApplicationClientList from "./components/ApplicationClientList";
import { displayDate } from "utils/date";

const index = ({ userInfo = {} }) => {
  const { applicationClientUsers } = userInfo || {};

  return (
    <Card id="additional-info" sx={{ overflow: "visible", mt: 2 }}>
      <MDBox pt={3} px={3}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize">
          Additional Information
        </MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid
          container
          spacing={{ xs: 1 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: applicationClientUsers?.length === 0 ? 360 : null,
          }}>
          {applicationClientUsers?.length > 0 ? (
            <ApplicationClientList
              applicationClientUsers={applicationClientUsers}
            />
          ) : (
            <MDTypography>No Data Found</MDTypography>
          )}
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Card sx={{ boxShadow: "none" }}>
              <MDBox alignItems="center" mb={0.5}>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Membership Since:{" "}
                    <b>
                      {userInfo?.createdAt
                        ? displayDate(new Date(userInfo?.createdAt))
                        : "-"}
                    </b>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Last Updated:{" "}
                    <b>
                      {userInfo?.updatedAt
                        ? displayDate(new Date(userInfo?.updatedAt))
                        : "-"}
                    </b>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Last Login:{" "}
                    <b>
                      {userInfo?.lastLoginAt
                        ? displayDate(new Date(userInfo?.lastLoginAt))
                        : "-"}
                    </b>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Deactivated Since:{" "}
                    <b>
                      {userInfo?.inactivedSince
                        ? displayDate(new Date(userInfo?.inactivedSince))
                        : "-"}
                    </b>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default index;
