import React, { useEffect } from 'react';
import { useStores } from 'stores';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MDDropzone from 'components/MDDropzone';
import MDButton from 'components/MDButton';
import Dropzone from 'dropzone';
import { uploadS3file } from 'utils/s3Uploader';
import ModuspaceService from 'services/moduspace.service';
import moment from 'moment';

// @mui icons
import Icon from '@mui/material/Icon';
import { AttachFile } from '@mui/icons-material';

const TicketMessageForm = ({
  ticketId,
  requester,
  fetchTicketInfo,
  fetchTicketMessages
}) => {
  const [response, setResponse] = React.useState(null);
  const [mediaDialogIsOpen, setMediaDialogIsOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [uploads, setUploads] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const { authentication } = useStores();

  const sendMessage = async (e = null) => {
    e?.preventDefault();
    try {
      const message = await ModuspaceService.CreateNewTicketMessage(
        authentication.jwtToken,
        { message: response ?? '', ticketId, media: uploads }
      );
      if (message) {
        e?.target.reset();
        fetchTicketMessages();
        fetchTicketInfo();
      }
    } catch (err) {
      console.error('Error at sendMessage:', err);
    }
  };

  const uploadToS3 = async (e) => {
    setIsUploading(true);
    // Get upload token
    const session = await ModuspaceService.GetTicketMediaUploadToken(
      authentication.jwtToken,
      ticketId
    );
    try {
      const uploads = await Promise.all(
        files.map(async (file, index) => {
          const { name, type } = file;
          const extension = name.split('.').pop();
          const fileName = `${ticketId}_${requester}_${moment().format(
            'DDMMYYYYHHmmss'
          )}_${index + 1}.${extension}`;
          const result = await uploadS3file({
            session,
            file,
            fileName
          });
          const { Location } = result;
          return { fileName, type, link: Location };
        })
      );
      setUploads(uploads);
      setIsUploading(false);
    } catch (err) {
      console.error(err);
    }
  };

  /* Close upload dialog and create message when all files have been uploaded */
  useEffect(() => {
    if (files.length === uploads.length && uploads.length > 0) {
      setMediaDialogIsOpen(false);
      sendMessage();
    }
  }, [uploads]);

  const acceptFiles = (file, done) => {
    setFiles((files) => [...files, file]);
    done();
  };

  const clearFilesOrCloseDialog = (e) => {
    e.preventDefault();
    if (files.length) {
      setFiles([]);
      Dropzone.instances.forEach((instance) => {
        instance.removeAllFiles(true);
      });
    } else {
      setMediaDialogIsOpen(false);
    }
  };

  return (
    <form onSubmit={sendMessage}>
      <Grid
        container
        spacing={3}
        style={{ padding: '20px' }}
        alignItems="center">
        <Grid item xs="auto" style={{ flexGrow: '1' }}>
          <TextField
            label="Type a response"
            fullWidth
            required
            multiline
            minRows={3}
            onChange={(e) => {
              setResponse(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs="auto" align="center">
          <Fab type="submit" color="primary" aria-label="add" size="medium">
            <Icon fontSize="small">send</Icon>
          </Fab>
        </Grid>
        <Grid item xs="auto" align="center">
          <Fab
            onClick={() => setMediaDialogIsOpen(true)}
            color="primary"
            aria-label="Upload image"
            component="label"
            size="medium">
            <AttachFile fontSize="small" />
          </Fab>
        </Grid>
        <Dialog
          open={mediaDialogIsOpen}
          onClose={() => setMediaDialogIsOpen(false)}
          fullWidth
          maxWidth="md">
          {React.useMemo(
            () => (
              <MDDropzone
                options={{
                  parallelUploads: 2,
                  uploadMultiple: true,
                  resizeQuality: 0.9,
                  addRemoveLinks: true,
                  accept: acceptFiles,
                  acceptedFiles: 'image/*',
                  removedfile: function (file) {
                    file.previewElement.remove();
                    setFiles((files) =>
                      files.filter(
                        (item) => item.upload.uuid !== file.upload.uuid
                      )
                    );
                  }
                }}
              />
            ),
            []
          )}

          <DialogActions>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto" style={{ flexGrow: '1' }}>
                <TextField
                  label="Add a message"
                  fullWidth
                  multiline
                  minRows={2}
                  onChange={(e) => {
                    setResponse(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs="auto" align="center">
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={uploadToS3}
                  disabled={!files.length}>
                  {isUploading ? 'Uploading...' : 'Upload'}
                </MDButton>
              </Grid>
              <Grid item xs="auto" align="center">
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={clearFilesOrCloseDialog}>
                  {files?.length ? 'Clear' : 'Close'}
                </MDButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </form>
  );
};

export default TicketMessageForm;
