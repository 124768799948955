import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { delayResolve } from 'utils/delay';
import { Link } from 'react-router-dom';
import { joinPath } from 'utils/join-url';
import { joinName } from 'utils/join-name';
import GenerateSerialNumbersModal from './GenerateSerialNumbersModal';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { downloadToBrowser } from 'utils/download-to-browser';
import { displayDate } from 'utils/date';

function SerialNumberManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [products, setProducts] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [showGenerateSerialNumbersModal, setShowGenerateSerialNumbersModal] =
    useState(false);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  
  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    setSelectedSerialNumbers([]);
    try {
      if (!tablePagination?.pageSize) {
        return;
      }
      const {
        list: { list, pagination },
        statuses,
        products
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListAllSerialNumbers(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            false,
            sortBy
          ),
          statuses: await ModuspaceService.GetSerialNumberStatus(
            authentication.jwtToken
          ),
          products: await ModuspaceService.GetSerialNumberProducts(
            authentication.jwtToken
          )
        }),
        100
      );
      // Add `__checked` property to each serial object
      list.forEach((serial) => (serial.__checked = false));
      setData(list);
      setTotalCount(pagination.total);
      setStatuses(statuses?.map((status) => ({ label: status, id: status })));
      setProducts(
        products?.map((product) => ({ label: product, id: product }))
      );
    } catch (err) {
      console.error('SerialNumberManagement:', err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListAllSerialNumbers(
        authentication.jwtToken,
        Number.MAX_SAFE_INTEGER,
        1,
        appliedFilter ? dataFilters : [],
        true, // isExport
      );
      return list;
    } catch (err) {
      console.error('SerialNumberManagement:', err);
    }
  }

  const selectAllRows = () => {
    data.forEach((row) => (row.__checked = allRowsSelected));
    setData([...data]);
    setAllRowsSelected(!allRowsSelected);
    setSelectedSerialNumbers(allRowsSelected ? [] : data.map((row) => row.id));
  };

  const filterInfo = [
    {
      name: 'multiField',
      label: 'Keywords',
      type: 'string',
      placeholder: 'Product name, Product code, SKU, Customer details',
      searchColumn: 'customerInfo,product,sku'
    },
    {
      name: 'createdAt',
      label: 'Created at',
      type: 'dateRange',
      searchColumn: 'createdAt'
    },
    {
      name: 'product',
      optionName: 'product',
      label: 'Product',
      type: 'multiSelect',
      searchColumn: 'product',
      options: products
    },
    {
      name: 'registeredAt',
      label: 'Registered at',
      type: 'dateRange',
      searchColumn: 'registeredAt'
    },
    {
      name: 'status',
      optionName: 'status',
      label: 'Status',
      type: 'multiSelect',
      searchColumn: 'status',
      options: statuses,
      defaultValue: 'Active,Registered,Void'
    }
  ];

  const tableInfo = [
    {
      label: <Checkbox style={{ padding: 0 }} onClick={selectAllRows} />,
      id: 'checkBox',
      width: '2%',
      disableSortBy: true
    },
    { label: 'Serial No', id: 'serial' },
    { label: 'Product', id: 'product' },
    {
      label: 'Status',
      id: 'status',
      tableRowMapper: (value) => (
        <span
          style={{
            fontWeight: 500,
            color:
              value === 'Registered'
                ? '#ff7fff'
                : value === 'New'
                ? '#5ca468'
                : ''
          }}>
          {value}
        </span>
      )
    },
    {
      label: 'Customer name',
      id: 'customer',
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath('/dashboard/customers/', value?.id)}
          target="_blank">
          {joinName(value)}
        </Link>
      ),
      className: 'custom-link'
    },
    { label: 'Registered at', id: 'registeredAt' },
    { label: 'Created at', id: 'createdAt' }
  ];

  const exportInfo = [
    { label: 'Serial No', name: 'serial' },
    { label: 'Product', name: 'product' },
    { label: 'SKU', name: 'sku' },
    { label: 'Status', name: 'status' },
    { label: 'Anti-Counterfeit Tag', name: 'antiCounterfeitTag' },
    { label: 'Customer Name', name: 'customerName' },
    { label: 'Customer Email', name: 'customerEmail' },
    { label: 'Date registered', name: 'registeredAt' },
    { label: 'Date created', name: 'createdAt' }
  ];

  const generateSerialNumbersInfo = {
    title: 'Generate Serial Numbers',
    onClick: () => {
      setShowGenerateSerialNumbersModal(true);
    }
  };

  const bulkActionHandler = async (action) => {
    // Map selected serials to serial objects containing id and statuses
    const status =
      action === 'status-active'
        ? 'Active'
        : action === 'status-void'
        ? 'Void'
        : null;
    setRefreshing(true);
    try {
      if (action === 'status-active' || action === 'status-void') {
        // Confirm the action
        const confirmaAction = window.confirm(
          `Are you sure you want to set the status of the ${
            selectedSerialNumbers.length
          } selected serial numbers to ${status.toLowerCase()}?`
        );
        if (confirmaAction) {
          const updatedSerials = await ModuspaceService.BulkUpdateSerialNumbers(
            authentication.jwtToken,
            {
              ids: selectedSerialNumbers,
              columns: { status }
            }
          );
          if (updatedSerials.length > 0) {
            init();
          }
        }
      } else if (action === 'export') {
        // Confirm the action
        const confirmAction = window.confirm('Export selected serial numbers?');
        if (confirmAction) {
          exportExcel();
        }
      } else {
        console.error('Invalid bulk action:', action);
      }
    } catch (err) {
      console.error('SerialNumberManagement:', err);
    }
    setSelectedSerialNumbers([]);
    setRefreshing(false);
  };

  return (
    <>
      <MDGeneralListingV3
        // for filtering
        filterInfo={filterInfo}
        dataFilters={dataFilters}
        setDataFilters={setDataFilters}
        // for sorting
        sortBy={sortBy}
        setSortBy={setSortBy}
        // for table rendering
        _data={data}
        tableInfo={tableInfo}
        dateColumn={['registeredAt', 'createdAt']}
        tablePagination={tablePagination}
        setTablePagination={setTablePagination}
        totalCount={totalCount} // all available data count
        // for action
        rowIDAccessor="id"
        allowCreate={false}
        createActionButtons={(rowId, rowName, editButton, deleteButton, row) => null
        }
        otherButtons={[generateSerialNumbersInfo]}
        bulkActions={{
          selectedData: selectedSerialNumbers,
          setSelectedData: setSelectedSerialNumbers,
          options: [
            { id: "status-active", label: "Set Status to Active" },
            {
              id: "status-void",
              label: "Set Status to Void",
            },
            { id: "export", label: "Export Selected" },
          ],
          bulkActionHandler,
        }}
        // for exports
        exportInfo={exportInfo}
        fetchAllExportData={fetchAllExportData}
        // for common usage
        title="Moducase Serial Number List"
        currentActiveParent="products"
        currentActiveTab="devices-serial-numbers"
        init={init}
        isRefreshing={isRefreshing}
      />
      {products.length > 0 && (
        <GenerateSerialNumbersModal
          show={showGenerateSerialNumbersModal}
          setShow={setShowGenerateSerialNumbersModal}
          products={products}
          init={init}
        />
      )}
    </>
  );
}

export default observer(SerialNumberManagement);
