import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { useConfirmModal } from 'components/MDConfirm';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';

function DeviceModelDetails() {
  const { pageControl, authentication } = useStores();

  const { prompt: promptDeleteModel, MDConfirm: MDConfirmDeleteModelModal } =
    useConfirmModal({
      title: 'Delete ',
      content:
        'Once deleted, the model cannot be retrieved and must be recreated.',
      confirmText: 'Delete',
      confirmColor: 'error'
    });

  const {
    components: formComponents,
    validateForm,
    formData,
    setFormData
  } = useForm(getFormSchema());

  const [loading, setLoading] = React.useState('');
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = formData?.model || '' || 'Model Info';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, formData?.model || '']);

  const fetchInfo = async () => {
    setLoading('Fetching Model Info');
    try {
      const {
        name = null,
        sku = null,
        upc = null,
        ean = null
      } = await delayResolve(() =>
        ModuspaceService.GetDeviceModelById(authentication.jwtToken, id)
      );
      setFormData({
        name,
        sku,
        upc,
        ean
      });
    } catch (err) {
      console.error('Error at fetchInfo:', { err });
    }
    setLoading(null);
  };

  React.useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateDeviceModelById(
          authentication.jwtToken,
          id,
          formData
        )
      );
      if (response) {
        setUpdated(true);
        toast.success('Model has been updated.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formContent: formData, err });
      toast.error('Fail to delete Model.');
    }
    setLoading(null);
  };

  const submitDeleteModel = async () => {
    setLoading('Deleting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteDeviceModelById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success('Model has been deleted.');
      }
    } catch (err) {
      console.error('Cannot delete model:', { err });
      toast.error('Fail to delete Model.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/products/models');
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteModelModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Create new Device Model
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Device model containing general product information
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap">
                  <MDBox mt={3} ml="auto">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      onClick={handleSubmit}>
                      Update model
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Delete Device</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Once deleted, the model cannot be retrieved and must be
                    recreated.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() =>
                      promptDeleteModel({ onConfirm: submitDeleteModel })
                    }
                    size="large">
                    delete model
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(DeviceModelDetails);
