/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from 'react';
import { toast } from 'react-toastify';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function ChangePassword({ onSubmit = async () => void 0 }) {
  const [isInitial, setInitial] = React.useState(true);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateFormData = () => {
    const cleanedPassword = password ?? '';
    setPassword(cleanedPassword);
    const isCleanedPasswordValid = ChangePassword.areRequiredFieldsValid(
      cleanedPassword,
      confirmPassword
    );

    if (!isCleanedPasswordValid) {
      toast.error('Check your password inputs!');
      return {
        error: true
      };
    }
    return {
      error: false,
      password: cleanedPassword,
      confirmPassword: confirmPassword
    };
  };

  const handleSubmit = () => {
    const { error, password } = validateFormData();
    if (error) {
      return;
    }
    onSubmit(password).then(() => {
      setPassword(null);
      setConfirmPassword('');
      setInitial(true);
    });
  };

  const passwordRequirements = [
    'Password must be at least 6 characters long, and must contain at least one letter and number'
    // "Min 6 characters",
    // "One number (2 are recommended)",
  ];

  const isPasswordInvalid = () => ChangePassword.isPasswordInvalid(password);

  const isConfirmPasswordInvalid = () =>
    ChangePassword.isConfirmPasswordInvalid(password, confirmPassword);

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}>
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              id="password"
              inputProps={{
                type: showPassword ? 'text' : 'password',
                autoComplete: ''
              }}
              value={password ?? ''}
              onChange={({ target: { value } }) => {
                setPassword(value);
                setInitial(false);
              }}
              error={isPasswordInvalid() && !isInitial}
              helperText={
                isPasswordInvalid() && !isInitial ? (
                  <MDTypography variant="caption" color="error">
                    Password must satisfy requirements.
                  </MDTypography>
                ) : (
                  ' '
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              id="confirmPassword"
              label="Confirm New Password"
              inputProps={{
                type: showPassword ? 'text' : 'password',
                autoComplete: ''
              }}
              value={confirmPassword ?? ''}
              onChange={({ target: { value } }) => {
                setConfirmPassword(value);
                setInitial(false);
              }}
              error={isConfirmPasswordInvalid() && !isInitial}
              helperText={
                isConfirmPasswordInvalid() && !isInitial ? (
                  <MDTypography variant="caption" color="error">
                    Passwords do not match.
                  </MDTypography>
                ) : (
                  ' '
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDButton
            variant="gradient"
            color="dark"
            size="large"
            onClick={handleSubmit}>
            update password
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {string} password
 * @returns
 */
ChangePassword.isPasswordInvalid = (password) => {
  if (password === null) return false;
  if (password.length < 7) return true;
  if (password.replace(/[^0-9]/gi, '').length < 1) return true;
  return false;
};

/**
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
ChangePassword.isConfirmPasswordInvalid = (password, confirmPassword) => {
  if (confirmPassword === null) return false;
  if (password === null) return false;
  return confirmPassword !== password;
};

/**
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
ChangePassword.areRequiredFieldsValid = (password, confirmPassword) => {
  if (ChangePassword.isPasswordInvalid(password)) return false;
  if (ChangePassword.isConfirmPasswordInvalid(password, confirmPassword))
    return false;
  return true;
};

export default ChangePassword;
