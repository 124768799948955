import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDLoading from "components/MDLoading";
import BaseLayout from "layouts/components/BaseLayout";
import { observer } from "mobx-react";
import BasicInfo from "pages/users/customers/components/BasicInfo";
import ChangePassword from "pages/users/customers/components/ChangePassword";
import DeleteAccount from "pages/users/customers/components/DeleteAccount";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";
import { dateTimeToBirthDate } from "../../../utils/date";
import AdditionalInfo from "./components/AdditionalInfo";
import ApplicationInfo from "./components/ApplicationInfo";
import OrderPaymentInfo from "./components/OrderPaymentInfo";

function UserDetails() {
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);
  const emailRef = React.useRef("");
  const clientRef = React.useRef("");
  const [countries, setCountries] = React.useState(null);
  const [fandoms, setFandoms] = React.useState(null);
  const [scales, setScales] = React.useState(null);
  const [brands, setBrands] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    let routes = location.pathname.split("/").slice(2);
    routes[routes.length - 1] = joinName(userInfo) || "User Info";
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, userInfo]);

  React.useEffect(() => {
    if (deleted) navigate("/dashboard/customers/");
  }, [deleted]);

  const fetchUserInfo = async () => {
    setLoading("Fetching user info...");
    try {
      const {
        userInfo,
        countries,
        fandoms,
        scales,
        brands,
        roles,
        // addresses
      } = await delayResolve(async () => ({
        userInfo: await ModuspaceService.GetCustomerByUserId(
          authentication.jwtToken,
          id,
        ),
        countries: await ModuspaceService.GetCountries(authentication.jwtToken),
        fandoms: await ModuspaceService.GetFandoms(authentication.jwtToken),
        scales: await ModuspaceService.GetScales(authentication.jwtToken),
        brands: await ModuspaceService.GetBrands(authentication.jwtToken),
        roles: await ModuspaceService.GetCustomerRolesIncludingInactive(
          authentication.jwtToken,
        ),
      }));

      if (userInfo?.dob) userInfo.dob = dateTimeToBirthDate(userInfo.dob); // convert into local timezone with 00:00:00.000 time

      setUserInfo(userInfo);
      setCountries(countries);
      setFandoms(fandoms);
      setScales(scales);
      setBrands(brands);
      setRoles(roles);
      emailRef.current = userInfo.email;
      clientRef.current =
        userInfo?.applicationClientUsers?.[0]?.applicationClient?.name;
    } catch (err) {
      console.error("Error at fetchUserInfo:", err);
    }
    setLoading(null);
  };

  const updateUserInfo = async (userInfo) => {
    setLoading("Submitting");
    try {
      const payload = {
        ...userInfo,
        dob: dateTimeToBirthDate(userInfo.dob, true), // convert into utc timezone with 00:00:00.000 time
      };

      const updatedUserInfo = await delayResolve(() =>
        ModuspaceService.UpdateCustomerByUserId(
          authentication.jwtToken,
          id,
          payload,
        ),
      );
      if (updatedUserInfo) {
        setUserInfo({ ...userInfo, ...updatedUserInfo });
        toast.success("Customer account info has been updated successfully.");
      }
    } catch (err) {
      console.error("Error at updateUserInfo:", err);
      toast.error("Fail to update user info.");
    }
    setLoading(null);
  };

  const updatePassword = async (password) => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateCustomerPasswordByUserId(
          authentication.jwtToken,
          id,
          { password },
        ),
      );
      if (response) {
        toast.success("Customer password has been updated successfully.");
      }
    } catch (err) {
      console.error("Error at updatePassword:", err);
      toast.error("Fail to update password.");
    }
    setLoading(null);
  };

  const emailGeneratedPassword = async () => {
    setLoading("Requesting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.EmailGenerateNewPasswordUserByIDForCustomer(
          authentication.jwtToken,
          id,
          {
            email: emailRef.current,
            client: clientRef.current,
          },
        ),
      );
      if (response) {
        toast.success(
          "Customer new generated password has been sent to your email.",
        );
      }
    } catch (err) {
      console.error("Error at emailGeneratedPassword:", { err });
      toast.error("Fail to generated password and sent to email.");
    }
    setLoading("");
  };

  const deleteAccount = async () => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteCustomerByUserId(authentication.jwtToken, id),
      );
      if (response) {
        setDeleted(true);
        toast.success("Customer account has been deleted successfully.");
      }
    } catch (err) {
      console.error("Error at deleteAccount:", err);
      toast.error("Fail to delete Customer.");
    }
    setLoading(null);
  };

  const deactivateAccount = async (userInfo) => {
    setLoading("Submitting");
    try {
      const updatedUserInfo = await delayResolve(() =>
        ModuspaceService.UpdateCustomerStatusByUserId(
          authentication.jwtToken,
          id,
          { status: "Inactive" },
        ),
      );
      if (updatedUserInfo) {
        setUserInfo({ ...userInfo, ...updatedUserInfo });
        toast.success("Customer account has been deactivate successfully.");
      }
    } catch (err) {
      console.error("Error at deactivateAccount:", err);
      toast.error("Fail to deactivate account.");
    }
    setLoading(null);
  };

  const activateAccount = async (userInfo) => {
    setLoading("Submitting");
    try {
      const updatedUserInfo = await delayResolve(() =>
        ModuspaceService.UpdateCustomerStatusByUserId(
          authentication.jwtToken,
          id,
          { status: "Active" },
        ),
      );
      if (updatedUserInfo) {
        setUserInfo({ ...userInfo, ...updatedUserInfo });
        toast.success("Customer account has been activate successfully.");
      }
    } catch (err) {
      console.error("Error at activateAccount:", err);
      toast.error("Fail to activate account.");
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (userInfo === null && authentication.jwtToken) fetchUserInfo();
    // eslint-disable-next-line
  }, [userInfo, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6}>
            <BasicInfo
              userInfo={userInfo}
              onChangeSubmit={updateUserInfo}
              countries={countries}
              fandoms={fandoms}
              scales={scales}
              brands={brands}
              roles={roles}
            />
            <ApplicationInfo userInfo={userInfo} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <OrderPaymentInfo />
            <AdditionalInfo
              authentication={authentication}
              userInfo={userInfo}
              countries={countries}
            />
          </Grid>
          <Grid item xs={12}>
            <ChangePassword
              onSubmit={updatePassword}
              onSubmitEmailGeneratedPassword={emailGeneratedPassword}
            />
          </Grid>
          <Grid item xs={12}>
            {userInfo?.status && (
              <DeleteAccount
                userInfo={userInfo}
                onDelete={deleteAccount}
                onDeactivate={deactivateAccount}
                onActivate={activateAccount}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(UserDetails);
