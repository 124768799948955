/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDEditor from 'components/MDEditor';
import MDInput from 'components/MDInput';
import MDAvatar from 'components/MDAvatar';

// Settings page components
import FormField from 'layouts/components/FormField';

// Data
import { toast } from 'react-toastify';

// Utils
import { joinName } from 'utils/join-name';
import { getInitials } from 'utils/get-initials';

import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';

function CreateFields({
  info,
  onChangeSubmit = () => void 0,
  onChange = () => void 0,
  createMode = false,
  priorities,
  issues,
  statuses,
  users,
  customers,
  products,
  onSubmitRef
}) {
  const [currentInfo, setCurrentInfo] = React.useState(info ?? {});

  const { authentication } = useStores();

  React.useEffect(() => {
    setCurrentInfo(info ?? {});
  }, [info]);

  React.useEffect(() => {
    onChange(currentInfo);
    // eslint-disable-next-line
  }, [currentInfo]);

  const onChangePriority = (selectedPriority) => {
    setCurrentInfo({
      ...currentInfo,
      priority: selectedPriority
    });
  };

  const onChangeIssue = (selectedIssue) => {
    setCurrentInfo({
      ...currentInfo,
      issue: selectedIssue
    });
  };

  const onChangeAssignee = (selectedAssignee) => {
    setCurrentInfo({
      ...currentInfo,
      handledBy: selectedAssignee.id
    });
  };

  const onChangeCustomer = (selectedCustomer) => {
    setCurrentInfo({
      ...currentInfo,
      requestedBy: selectedCustomer.id
    });
  };

  const onChangeRemarks = (value) => {
    setCurrentInfo({
      ...currentInfo,
      remarks: value
    });
  };

  const onChangeProduct = (selectedProduct) => {
    setCurrentInfo({
      ...currentInfo,
      product: selectedProduct
    });
  };

  const validateFormData = () => {
    const newInfo = CreateFields.fillEmptyRequiredFields(currentInfo);
    const isNewInfoValid = CreateFields.areRequireFieldsValid(newInfo);
    setCurrentInfo(newInfo);
    if (!isNewInfoValid) {
      toast.error('Fill in the required basic info first!');
      return {
        error: true
      };
    }
    return {
      error: false,
      info: newInfo
    };
  };

  if (onSubmitRef && createMode) {
    onSubmitRef.current = {
      onSubmitListener: validateFormData
    };
  }

  const handleSubmit = () => {
    const { error, info } = validateFormData();
    if (!error) onChangeSubmit(info);
  };

  const nonEmptyProps = (field) => ({
    error: CreateFields.isTextInputFieldInvalid(field),
    helperText: CreateFields.isTextInputFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : (
      ' '
    ),
    required: true
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingTop: '0' }}>
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Basic Info</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={
                    customers?.map((customer) => ({
                      id: customer.id,
                      label: `${joinName(customer)} - ${customer.email}`
                    })) ?? []
                  }
                  getOptionLabel={(option) => option.label ?? option}
                  isOptionEqualToValue={(a, b) => a.id === b.id}
                  disableClearable
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Customer"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Customer"
                      />
                    );
                  }}
                  onChange={(_, selected) => onChangeCustomer(selected)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={issues ?? []}
                  getOptionLabel={(option) => (option.length ? option : '')}
                  isOptionEqualToValue={(a, b) => a === b}
                  disableClearable
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Issue"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Issue"
                      />
                    );
                  }}
                  onChange={(_, selected) => onChangeIssue(selected)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={products ?? []}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => (option.length ? option : '')}
                  disableClearable
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Product"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Product"
                      />
                    );
                  }}
                  onChange={(_, selected) => onChangeProduct(selected)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={priorities ?? []}
                  isOptionEqualToValue={(a, b) => a === b}
                  getOptionLabel={(option) => (option.length ? option : '')}
                  disableClearable
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Priority"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Priority"
                      />
                    );
                  }}
                  onChange={(_, selected) => onChangePriority(selected)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MDTypography
                  variant="caption"
                  color="text"
                  sx={{ fontWeight: 400 }}>
                  Description
                </MDTypography>
                <MDInput
                  multiline
                  fullWidth
                  rows={3}
                  onChange={(e) => onChangeRemarks(e.target.value)}
                  style={{
                    display: 'block'
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={
                    users?.map((user) => ({
                      id: user.id,
                      label: joinName(user)
                    })) ?? []
                  }
                  isOptionEqualToValue={(a, b) => a.id === b.id}
                  getOptionLabel={(option) => option.label ?? option}
                  disableClearable
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        label="Assignee"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Assignee"
                      />
                    );
                  }}
                  sx={{ paddingTop: '24px' }}
                  onChange={(_, selected) => onChangeAssignee(selected)}
                />
              </Grid>
            </Grid>

            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap">
              <MDBox mt={3} ml="auto">
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="large"
                  onClick={handleSubmit}>
                  create ticket
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

/**
 *
 * @param {{
 * priority: "",
 * issue: "",
 * }} userInfo
 */
CreateFields.fillEmptyRequiredFields = (contentInfo) => {
  return {
    priority: '',
    issue: '',
    ...contentInfo
  };
};

/**
 *
 * @param {string | undefined} field
 * @returns
 */
CreateFields.isTextInputFieldInvalid = (field) => {
  return (
    typeof field === 'undefined' ||
    (typeof field === 'string' && field.length === 0)
  );
};

/**
 * Get clean value from `CreateFields.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  title: "",
 * }} info
 */
CreateFields.areRequireFieldsValid = (info) => {
  if (CreateFields.isTextInputFieldInvalid(info.remarks)) return false;
  if (CreateFields.isTextInputFieldInvalid(info.priority)) return false;
  if (CreateFields.isTextInputFieldInvalid(info.issue)) return false;
  return true;
};

export default CreateFields;
