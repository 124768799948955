import { useState, useEffect } from 'react';
import MDTypography from 'components/MDTypography';
import { toast } from 'react-toastify';
import { displayDate } from 'utils/date';
import GeneralTable from 'controls/Tables/GeneralTable';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';

function PaymentLogs({ paymentId }) {
  const [logs, setLogs] = useState([]);

  const { authentication } = useStores();

  useEffect(() => {
    if (paymentId) {
      getPaymentLogs(paymentId);
    }
  }, [paymentId]);

  const getPaymentLogs = async (paymentId) => {
    try {
      const paymentLogs = await ModuspaceService.GetPaymentLogsByPaymentId(authentication.jwtToken, paymentId);
      setLogs(paymentLogs);
    } catch (error) {
      console.error('Error at getPaymentLogs', error);
      toast.error(error);
    }
  };

  const tableInfo = [
    {
      label: 'Date',
      name: 'createdAt',
      render: (field) => displayDate(field, 'DD MMMM YYYY, hh:mm A'),
    },
    {
      label: 'Description',
      name: 'description',
      align: false,
    }
  ]

  return (
    <>
      <MDTypography variant="h6" fontWeight="bold" mb={2}>
        <GeneralTable 
          headColumn={tableInfo}
          bodyColumn={logs || []}
          disablePagination={true}
        />
      </MDTypography>
    </>
  );
};

export default PaymentLogs;