/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDChip from 'components/MDChip';
import MDTypography from 'components/MDTypography';

import { displayDate } from 'utils/date';
function BasicPairedDeviceInfo({ userDeviceInfo, userDeviceGroupInfo }) {
  const [currentUserDeviceInfo, setCurrentUserDeviceInfo] = React.useState({});
  const [currentUserDeviceGroupInfo, setCurrentUserDeviceGroupInfo] =
    React.useState([]);

  React.useEffect(() => {
    const info = userDeviceInfo ?? {};
    setCurrentUserDeviceInfo(info);
  }, [userDeviceInfo]);

  React.useEffect(() => {
    console.log('userDeviceGroupInfo: ', userDeviceGroupInfo.length);
    const info = userDeviceGroupInfo ?? [];
    setCurrentUserDeviceGroupInfo(info);
  }, [userDeviceGroupInfo]);

  return (
    <Card id="basic-paired-device-info" sx={{ overflow: 'visible' }}>
      {/* <MDBox p={3} pb={0}>
        <MDTypography variant="h6">Customer</MDTypography>
      </MDBox> */}
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={1} pt={1}>
          {/* basic information */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Paired Device information
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox key="name" display="flex" mt={0.5} mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Name: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox key="wifi-network" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      {/* Wifi Network (2.4G): &nbsp; */}
                      Wifi Network: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.wifi}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="wifi-ip" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Wifi IP Address: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.ip}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="current" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Current {currentUserDeviceInfo?.device?.deviceModel?.name}{' '}
                      firmware: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.firmware}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="power" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Power: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.power ? 'ON' : 'OFF'}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="favorite" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Favorite: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.fav ? 'Yes' : 'No'}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="theme-color" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Theme (color): &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceInfo?.theme?.colors &&
                        currentUserDeviceInfo?.theme?.colors?.map(
                          (color, key) => {
                            return (
                              <div key={key} style={{ marginLeft: '5px' }}>
                                <div
                                  style={{
                                    border: 'solid 0.5px rgba(0,0,0,0.1)',
                                    backgroundColor: `rgb(${color.red},${color.green},${color.blue})`
                                  }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>{' '}
                                <div>{`RGB (${color.red},${color.green},${color.blue})`}</div>
                              </div>
                            );
                          }
                        )}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="device-group" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      {currentUserDeviceGroupInfo?.length > 0
                        ? 'groups'
                        : 'group'}
                      : &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserDeviceGroupInfo &&
                        currentUserDeviceGroupInfo?.length > 0 &&
                        currentUserDeviceGroupInfo?.map((group, key) => {
                          return (
                            <MDChip
                              key={key}
                              size="small"
                              text={group?.name}
                              varient="filled"
                              sx={{ ml: 1 }}
                            />
                          );
                        })}

                      {currentUserDeviceGroupInfo &&
                        currentUserDeviceGroupInfo?.length === 0 &&
                        '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {/* activities */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Created Since:{' '}
                      <b>
                        {currentUserDeviceInfo?.createdAt
                          ? displayDate(currentUserDeviceInfo?.createdAt)
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Last Updated:{' '}
                      <b>
                        {currentUserDeviceInfo?.updatedAt
                          ? displayDate(currentUserDeviceInfo?.updatedAt)
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Last Paired:{' '}
                      <b>
                        {currentUserDeviceInfo?.lastPairedAt
                          ? displayDate(currentUserDeviceInfo?.lastPairedAt)
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicPairedDeviceInfo;
