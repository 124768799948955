import React from 'react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { getInitials } from 'utils/get-initials';
import { joinName } from 'utils/join-name';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const CustomerInfo = ({ id, customer }) => {
  const { authentication } = useStores();
  const [ticketStatusCount, setTicketStatusCount] = React.useState(null);

  const getTicketsByCustomer = async () => {
    const counts = await ModuspaceService.GetTicketsByCustomerId(
      authentication.jwtToken,
      id
    );
    setTicketStatusCount(counts);
  };

  React.useEffect(() => {
    if (id) getTicketsByCustomer();
  }, [id]);

  const tableCellStyles = {
    padding: '4px 0',
    fontSize: '12px',
    color: 'dark'
  };

  return (
    <Card id="customer-card" sx={{ height: '100%' }}>
      <MDBox p={3} sx={{ display: 'flex', alignItems: 'center' }}>
        <MDAvatar sx={{ bgcolor: 'red' }}>
          {customer ? getInitials(customer) : ''}
        </MDAvatar>
        <MDTypography ml={2} variant="h5">
          {customer ? joinName(customer) : ''}
        </MDTypography>
      </MDBox>
      <MDBox p={3} pt={0}>
        <MDBox
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <MDTypography variant="h6">Contact info</MDTypography>
          <MDTypography variant="caption" sx={{ marginLeft: '1ch' }}>
            <Link
              to={`/dashboard/customers/${id}`}
              target="_blank"
              style={{ color: '#006aff' }}>
              (Show details in new tab)
            </Link>
          </MDTypography>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDTypography
              variant="caption"
              color="text"
              sx={{ fontWeight: 400 }}>
              Email
            </MDTypography>
            <MDTypography
              variant="caption"
              color="dark"
              display="block"
              sx={{
                fontWeight: 400,
                fontSize: '0.875rem',
                letterSpacing: '0.00938em'
              }}>
              <a
                href={`mailto:${customer?.email}`}
                target="_blank"
                style={{ color: '#006aff' }}>
                {customer?.email ?? ''}
              </a>
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <MDTypography
              variant="caption"
              color="text"
              sx={{ fontWeight: 400 }}>
              Phone
            </MDTypography>
            <MDTypography
              variant="caption"
              color="dark"
              display="block"
              sx={{
                fontWeight: 400,
                fontSize: '0.875rem',
                letterSpacing: '0.00938em'
              }}>
              {customer?.phone ?? 'Not set'}
            </MDTypography>
          </Grid>

          <Grid item xs={12} display="none">
            <MDTypography
              variant="caption"
              color="text"
              sx={{ fontWeight: 400 }}>
              Company
            </MDTypography>
            <MDTypography
              variant="caption"
              color="dark"
              display="block"
              sx={{
                fontWeight: 400,
                fontSize: '0.875rem',
                letterSpacing: '0.00938em'
              }}>
              {customer?.company ?? 'Not set'}
            </MDTypography>
          </Grid>

          <Grid item xs={12} display="none">
            <MDTypography
              variant="caption"
              color="text"
              sx={{ fontWeight: 400 }}>
              Notes
            </MDTypography>
            <MDTypography
              variant="caption"
              color="dark"
              display="block"
              sx={{
                fontWeight: 400,
                fontSize: '0.875rem',
                letterSpacing: '0.00938em'
              }}>
              {customer?.notes ?? 'No notes yet'}
            </MDTypography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MDTypography variant="h6" color="dark" sx={{ fontWeight: 400 }}>
              Tickets by the customer
            </MDTypography>
            <Table size="small">
              <TableHead sx={{ p: 0, display: 'table-header-group' }}>
                <TableRow>
                  <TableCell sx={tableCellStyles} component="th">
                    Status
                  </TableCell>
                  <TableCell sx={tableCellStyles} component="th" align="right">
                    Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketStatusCount?.map((row) => (
                  <TableRow>
                    <TableCell sx={tableCellStyles} component="td">
                      {row.status}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyles}
                      component="td"
                      align="right">
                      {row.count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default CustomerInfo;
