/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDChip from 'components/MDChip';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Data
// import { getFormSchema } from "./formSchema";
// import { useForm } from "components/MDForm";
import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

import Images from 'pages/ideaboard/comments/components/PostInfo/Images';
function PostInfo({ postInfo = {} }) {
  // React.useEffect(() => {
  //   console.log("PostInfo: ", postInfo);
  // }, [postInfo]);

  return (
    <Card id="post-info">
      <MDBox p={3} pb={1}>
        <MDTypography variant="h6">Ideaboard Post Information</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox lineHeight={1.25}>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Title:&nbsp; <b>{postInfo?.title}</b>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Caption:&nbsp; <b>{postInfo?.caption}</b>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Tags:&nbsp;{' '}
                    {postInfo &&
                      postInfo?.tags?.map((tag, index) => {
                        return (
                          <MDChip
                            size="small"
                            text={tag}
                            varient="filled"
                            sx={{ marginLeft: '5px', marginBottom: '5px' }}
                            key={index}
                          />
                        );
                      })}
                  </MDTypography>
                </MDBox>
                <MDBox mt={0.5}>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize">
                    Status:&nbsp; <b>{postInfo?.status}</b>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Images media={postInfo?.media} />
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center">
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Posted:&nbsp;{' '}
                      <b>
                        {postInfo?.createdAt
                          ? displayDate(new Date(postInfo?.createdAt))
                          : '-'}
                      </b>{' '}
                      {postInfo?.creator && (
                        <>
                          by <b>{joinName(postInfo?.creator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Edited:&nbsp;{' '}
                      <b>
                        {postInfo?.updatedAt
                          ? displayDate(new Date(postInfo?.updatedAt))
                          : '-'}
                      </b>{' '}
                      {postInfo?.updator && (
                        <>
                          by <b>{joinName(postInfo?.updator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Published:&nbsp;{' '}
                      <b>
                        {postInfo?.publishedAt
                          ? displayDate(new Date(postInfo?.publishedAt))
                          : '-'}{' '}
                      </b>{' '}
                      {postInfo?.publisher && (
                        <>
                          by <b>{joinName(postInfo?.publisher)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Flagged:&nbsp;{' '}
                      <b>
                        {postInfo?.flaggedAt
                          ? displayDate(new Date(postInfo?.flaggedAt))
                          : '-'}{' '}
                      </b>{' '}
                      {postInfo?.flagger && (
                        <>
                          by <b>{joinName(postInfo?.flagger)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default PostInfo;
