import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { delayResolve } from 'utils/delay';

import { importDeviceValidator } from './util/validator';
import { getDeviceUploadToken, importDevice } from './util/import';

import devicesTemplate from './data/devicesTemplate.json';

function DeviceMasterListManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [deviceModel, setDeviceModel] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  
  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      
      const {
        list: { list, pagination },
        deviceModel
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListDeviceSerialsPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          deviceModel: await ModuspaceService.ListDevicesModels(
            authentication.jwtToken
          )
        }),
        100
      );
      // Add new property to each object
      list.forEach((deviceMaster) => (deviceMaster.deviceModelId = deviceMaster.deviceModel?.id));
      list.forEach((deviceMaster) => (deviceMaster.deviceModelName = deviceMaster.deviceModel?.name));
      setData(list);
      setTotalCount(pagination.total);
      setDeviceModel(
        deviceModel?.list.map((item) => ({ label: item.name, id: String(item.id) }))
      );
    } catch (err) {
      console.error('DeviceMasterListManagement:', err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListDeviceSerialsPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : []
      });
      // Add new property to each object
      list.forEach((deviceMaster) => (deviceMaster.deviceModelId = deviceMaster.deviceModel?.id));
      list.forEach((deviceMaster) => (deviceMaster.deviceModelName = deviceMaster.deviceModel?.name));
      return list;
    } catch (err) {
      console.error('DeviceMasterListManagement fetchAllExportData:', err);
    }
  };

  const filterInfo = [
    {
      name: 'device__serialNo,device__mac,device__lotNo,device__productionFirmware',
      label: 'Keywords',
      type: 'string',
      placeholder: 'Serial No, Mac, Lot No, Production Firmware',
      searchColumn: 'device__serialNo,device__mac,device__lotNo,device__productionFirmware'
    },
    {
      name: 'deviceModel__id',
      label: 'Product Series',
      type: 'multiSelect',
      optionName: 'deviceModelId',
      options: deviceModel,
      searchColumn: 'deviceModel__id'
    },
    {
      name: 'device__productionDate',
      label: 'Production Date',
      type: 'dateRange',
      searchColumn: 'device__productionDate'
    }
  ];

  const tableInfo = [
    {
      label: 'Serial No',
      id: 'serialNo'
    },
    {
      label: 'Model',
      id: 'deviceModelName',
      alias: 'deviceModel.name',
    },
    {
      label: 'Mac',
      id: 'mac'
    },
    {
      label: 'Lot No',
      id: 'lotNo'
    },
    {
      label: 'Production date',
      id: 'productionDate'
    },
    {
      label: 'Production firmware',
      id: 'productionFirmware'
    },
    {
      label: 'Created at',
      id: 'createdAt'
    },
    {
      label: 'Updated at',
      id: 'updatedAt'
    }
  ];

  const importInfo = [
    {
      title: 'Import Serial Numbers',
      fileName: 'device-upload',
      validator: (data) => importDeviceValidator(data),
      getSession: async () => getDeviceUploadToken(authentication.jwtToken),
      importData: async (data) => importDevice(authentication.jwtToken, data),
      importDataExample: {
        title: 'Serial Numbers Template',
        data: devicesTemplate
      },
      importColumn: [
        {
          label: 'Serial No',
          name: 'serialNo'
        },
        {
          label: 'Model',
          name: 'deviceModelName'
        },
        {
          label: 'Mac',
          name: 'mac'
        },
        {
          label: 'Lot No',
          name: 'lotNo'
        },
        {
          label: 'Production Date',
          name: 'productionDate'
        },
        {
          label: 'Production Firmware',
          name: 'productionFirmware'
        },
        {
          label: 'Reason',
          name: 'reason'
        }
      ]
    }
  ];

  const exportInfo = [
    {
      label: 'Serial No',
      name: 'serialNo'
    },
    {
      label: 'Model',
      name: 'deviceModelName'
    },
    {
      label: 'Mac',
      name: 'mac'
    },
    {
      label: 'Lot No',
      name: 'lotNo'
    },
    {
      label: 'Production date',
      name: 'productionDate'
    },
    {
      label: 'Production firmware',
      name: 'productionFirmware'
    },
    {
      label: 'Created at',
      name: 'createdAt'
    },
    {
      label: 'Updated at',
      name: 'updatedAt'
    }
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={['productionDate', 'createdAt', 'updatedAt']}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) => editButton}
      showBulkAction={false}
      // for imports
      importInfo={importInfo}
      // for exports
      exportInfo={exportInfo}
      exportFileNamePrefix="Device_Export"
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Device Master List"
      currentActiveParent="products"
      currentActiveTab="devices-master-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(DeviceMasterListManagement);
