/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the SoftwarpostComment.
*/
import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import FormField from 'components/MDFormField';
import { useConfirmModal } from 'components/MDConfirm';

import { displayDateTime } from 'utils/date';
import { joinName } from 'utils/join-name';
import TimeAgo from 'timeago-react';

function CommentListItem({
  postComment = {},
  promptFlag = () => void 0,
  promptDelete = () => void 0,
  onFlag = () => void 0,
  onDelete = () => void 0
}) {
  const actionButtonStyles = {
    '& .material-icons-round, & .MuiBadge-badge': {
      marginLeft: '10px',
      opacity: '0.5',
      //   transform: `translateX(0)`,
      transition: 'all 200ms cubic-bezier(0.34,1.61,0.7,1.3)'
    },

    '&:hover .material-icons-round, &:focus .material-icons-round, &:hover .MuiBadge-badge , &:focus .MuiBadge-badge':
      {
        opacity: '1',
        //   transform: `translateX(4px)`,
        cursor: 'pointer'
      }
  };

  return (
    <MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexWrap: 'wrap' }}>
        <MDBox
          display="flex"
          alignItems="flex-start"
          mr={2}
          sx={{ flexWrap: 'wrap' }}>
          <MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <MDTypography
                display="block"
                variant="body2"
                fontWeight="regular"
                color="text">
                {joinName(postComment.user)}
              </MDTypography>
              <MDTypography
                ml={2}
                variant="button"
                color="secondary"
                fontWeight="regular"
                fontSize={'small'}>
                {postComment.user?.country?.shortName}
              </MDTypography>
            </MDBox>
            <MDTypography
              display="block"
              variant="button"
              fontWeight="regular"
              color="text"
              fontSize={'small'}>
              {postComment.user?.email}
            </MDTypography>
          </MDBox>
          <MDBox ml={3}>
            <MDTypography
              display="block"
              variant="button"
              fontWeight="regular"
              color="text"
              fontSize={'small'}>
              comment at:{' '}
              <b>
                {postComment.createdAt
                  ? displayDateTime(new Date(postComment.createdAt))
                  : '-'}
              </b>{' '}
              - <TimeAgo datetime={postComment.createdAt} locale="en" />
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end">
          {postComment.flaggedBy && (
            <MDBadge
              variant="contained"
              size="xs"
              badgeContent="flagged"
              color="secondary"
              container
              sx={{ ...actionButtonStyles }}
            />
          )}
          <MDBox
            mt={1}
            display="flex"
            alignItems="center"
            sx={{ marginLeft: 'auto' }}>
            {!postComment.flaggedBy && (
              <MDBox
                onClick={() =>
                  promptFlag({
                    onConfirm: async () => await onFlag(postComment)
                  })
                }>
                <MDTypography
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                  sx={{ ...actionButtonStyles }}>
                  <Icon
                    color={'secondary'}
                    fontSize={'medium'}
                    sx={{ fontWeight: 'bold', verticalAlign: 'middle' }}>
                    flag_outlined
                  </Icon>
                </MDTypography>
              </MDBox>
            )}
            <MDBox
              onClick={() =>
                promptDelete({
                  onConfirm: async () => await onDelete(postComment)
                })
              }>
              <MDTypography
                variant="button"
                color="secondary"
                fontWeight="regular"
                sx={actionButtonStyles}>
                <Icon
                  color={'error'}
                  fontSize={'medium'}
                  sx={{ fontWeight: 'bold', verticalAlign: 'middle' }}>
                  delete
                </Icon>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mx={1} mt={1}>
        {/* <FormField
                //   label="Comment"
                fullWidth
                multiline
                maxRows={20}
                inputProps={{ readOnly: true }}
                value={postComment.text}
              /> */}
        <MDTypography
          component="p"
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ paddingTop: '5px' }}>
          {postComment.text}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default CommentListItem;
