import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Link } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MDAutocomplete from "components/MDAutocomplete";
import MDButton from "components/MDButton";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import CustomerSearchModal from "components/MDCustomersSearch/CustomerSearchModal";
import MDBadge from "components/MDBadge";
import { joinPath } from "utils/join-url";
import { joinName } from "utils/join-name";
import moment from "moment";
import { delayResolve } from "utils/delay";

function TicketManagement({ Navkey }) {
  const { authentication } = useStores();
  const [data, setData] = React.useState([]);
  const [user, setUser] = useState([]);
  const [statuses, setStatuses] = useState(null);
  const [products, setProducts] = useState(null);
  const [issues, setIssues] = useState(null);
  const [assignees, setAssignees] = useState(null);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  const [customerSearchModalVisible, setCustomerSearchModalVisible] =
    useState(false);
  
  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  const init = async () => {
    setIsRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      const {
        list: { list, pagination },
        statuses,
        products,
        issues,
        assignees,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListTicketsPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          statuses: await ModuspaceService.GetTicketStatus(
            authentication.jwtToken,
          ),
          products: await ModuspaceService.GetTicketProducts(
            authentication.jwtToken,
          ),
          issues: await ModuspaceService.GetTicketIssues(
            authentication.jwtToken,
          ),
          assignees: await ModuspaceService.GetAllUsers(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.handlerName = joinName(data.handler)));
      list.forEach((data) => (data.reporterName = joinName(data.requester)));

      list.forEach(
        (data) =>
          (data.duration =
            data?.status === "Closed"
              ? "-"
              : moment().diff(data.createdAt, "days")),
      );
      list.forEach((data) => (data.requester.id = data.requestedBy));
      list.forEach(
        (data) =>
          (data.requesterInfo = `${data.requester?.name ?? ""} ${
            data.requester?.email ?? ""
          } ${data.requester?.country ?? ""} ${data.requester?.phone ?? ""}`),
      );
      setData(list);
      setTotalCount(pagination.total);
      setStatuses(statuses?.map((status) => ({ label: status, id: status })));
      setProducts(
        products?.map((product) => ({ label: product, id: product })),
      );
      setIssues(issues?.map((issue) => ({ label: issue, id: issue })));
      setAssignees(
        assignees?.map((assignee) => ({
          label: joinName(assignee),
          id: String(assignee.id),
        })),
      );
      setIsRefreshing(false);
    } catch (err) {
      console.error("Error at init:", err);
    }
  };

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListTicketsPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
        ordering: sortBy,
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.handlerName = joinName(data.handler)));
      list.forEach((data) => (data.reporterName = joinName(data.requester)));
      list.forEach(
        (data) =>
          (data.duration =
            data?.status === "Closed"
              ? "-"
              : moment().diff(data.createdAt, "days")),
      );
      list.forEach((data) => (data.requester.id = data.requestedBy));
      list.forEach(
        (data) =>
          (data.requesterInfo = `${data.requester?.name ?? ""} ${
            data.requester?.email ?? ""
          } ${data.requester?.country ?? ""} ${data.requester?.phone ?? ""}`),
      );
      return list;
    } catch (err) {
      console.error("TicketManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      type: "multiSelect",
      label: "Customer",
      name: "userId",
      searchColumn: "userId",
      value: String(user.map((u) => u.id)),
      render: () => (
        <>
          {customerSearchModalVisible && (
            <CustomerSearchModal
              open={customerSearchModalVisible}
              onClose={() =>
                setCustomerSearchModalVisible(!customerSearchModalVisible)
              }
              onSearch={(selectedUsers) => {
                setUser(selectedUsers);
              }}
            />
          )}
          <MDAutocomplete
            label="Customer"
            name="userId"
            value={user || []}
            options={user || []}
            multiple
            readOnly
          />
          <MDButton
            variant="outlined"
            size="small"
            iconOnly
            style={{ fontSize: 25, color: "#D2D6DA", border: "none" }}
            onClick={() => setUser([])}>
            <CloseOutlined />
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            iconOnly
            onClick={() => setCustomerSearchModalVisible(true)}>
            <SearchOutlined />
          </MDButton>
        </>
      ),
    },
    {
      name: "createdAt",
      label: "Created At",
      type: "dateRange",
      searchColumn: "createdAt",
    },
    {
      name: "product",
      optionName: "product",
      label: "Product",
      type: "multiSelect",
      searchColumn: "product",
      options: products,
    },
    {
      name: "status",
      optionName: "status",
      label: "Status",
      type: "multiSelect",
      searchColumn: "status",
      options: statuses,
      // defaultValue: "Open,Processing,Pending Customer Reply",
    },
    {
      name: "issue",
      optionName: "issue",
      label: "Issue",
      type: "multiSelect",
      searchColumn: "issue",
      options: issues,
    },
    {
      name: "handledBy",
      optionName: "handledBy",
      label: "Assignee",
      type: "multiSelect",
      searchColumn: "handledBy",
      options: assignees,
    },
  ];

  const tableInfo = [
    {
      label: "#",
      id: "id",
      width: "5%",
    },
    {
      label: "Customer name",
      id: "requester",
      alias: "requester.firstName",
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath("/dashboard/customers/", value?.id)}
          target="_blank">
          {joinName(value)}
        </Link>
      ),
      className: "custom-link",
    },
    {
      label: "Product",
      id: "product",
    },
    {
      label: "Issue",
      id: "issue",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Date created",
      id: "createdAt",
    },
    {
      label: "Duration",
      id: "duration",
      tableRowMapper: (value) =>
        value !== "-" ? (
          <MDBadge
            size="xs"
            badgeContent={`${value} days`}
            color={value > 10 ? "error" : "success"}
          />
        ) : null,
    },
  ];

  const sortInfo = [
    {
      name: "requester",
      rowMapper: (value) => joinName(value),
    },
  ];

  const exportInfo = [
    {
      label: "Ticket ID",
      name: "id",
    },
    {
      label: "Customer Name",
      name: "reporterName",
    },
    {
      label: "Customer Email",
      name: "requester.email",
    },
    {
      label: "Product",
      name: "product",
    },
    {
      label: "Description",
      name: "description",
    },
    {
      label: "Notes",
      name: "remarks",
    },
    {
      label: "Issue",
      name: "issue",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Priority",
      name: "priority",
    },
    {
      label: "Assignee",
      name: "handlerName",
    },
    {
      label: "Date Created",
      name: "createdAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      sortInfo={sortInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      exportFileNamePrefix="Support_Ticket"
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Support Ticket Management"
      currentActiveParent="support-center"
      currentActiveTab={Navkey}
      init={init}
      onFilterReset={() => setUser([])}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(TicketManagement);
