import './MDActivityIndicator.css';
export default function MDActivityIndicator({ color = 'black', style = {} }) {
  return (
    <div
      className="lds-ring"
      style={{
        ...style
      }}>
      <div
        style={{
          borderColor: color + ' transparent transparent transparent'
        }}></div>
      <div
        style={{
          borderColor: color + ' transparent transparent transparent'
        }}></div>
      <div
        style={{
          borderColor: color + ' transparent transparent transparent'
        }}></div>
      <div
        style={{
          borderColor: color + ' transparent transparent transparent'
        }}></div>
    </div>
  );
}
