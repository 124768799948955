import QuestionMark from 'assets/images/question-mark.png';
import AN from 'assets/images/flag/AN.svg';
import BigCommerceLogo from 'assets/images/logos/big-commerce.svg';
import WordPressLogo from 'assets/images/logos/word-press.png';
import ModuspaceLogo from 'assets/images/logos/moduspace.png';

export const displayEnumData = (value) => {
  return value.replace(/([A-Z])/g, ' $1').trim();
};

export const displayCountryFlagImg = (countryCode) => {
  if (!countryCode) return QuestionMark;

  if (countryCode === 'AN') {
    return AN;
  }

  return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;
};

export const displayImageApplicationClient = (ssoType = null) => {
  switch (ssoType) {
    case 'bigcommerce':
      return BigCommerceLogo;
    case 'wordpress':
      return WordPressLogo;
    case 'native':
      return ModuspaceLogo;
    default:
      return ModuspaceLogo;
  }
};
