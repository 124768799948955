/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from 'react';
import Grid from '@mui/material/Grid';

import ModuspaceService from 'services/moduspace.service';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React controls
import DashboardLayout from 'controls/LayoutContainers/DashboardLayout';
import Footer from 'controls/Footer';
import ReportsLineChart from 'controls/Charts/LineCharts/ReportsLineChart';

// Anaytics components
import SalesByCountry from 'pages/dashboards/components';

import { observer } from 'mobx-react';
import { useStores } from 'stores';

import { getGraphComparisonPercentage } from 'utils/analytic';

import { getLatestMonthQuantity } from 'utils/analytic';
import { displayDate } from 'utils/date';

function Analytics() {
  const { authentication, pageControl } = useStores();
  const [analyticsData, setAnalyticsData] = React.useState({});

  const {
    countryData = [],
    memberRegistrationGraph = [],
    pairedDeviceGraph = [],
    supportTicketGraph = [],
    createdAt = 'N/A'
  } = analyticsData || {};

  React.useEffect(() => {
    pageControl.setCurrentActiveTab('dashboard', 'dashboard');
    init();
  }, []);

  const init = async () => {
    const data = await ModuspaceService.GetAnalyticsData(
      authentication.jwtToken
    );
    setAnalyticsData(data);
  };

  const memberChart = {
    datasets: {
      data: memberRegistrationGraph?.graphData?.map((item) => item.quantity)
    },
    labels: memberRegistrationGraph?.graphData?.map((item) => item.month)
  };

  const deviceChart = {
    datasets: {
      data: pairedDeviceGraph?.graphData?.map((item) => item.quantity)
    },
    labels: pairedDeviceGraph?.graphData?.map((item) => item.month)
  };

  const ticketChart = {
    datasets: {
      data: supportTicketGraph?.graphData?.map((item) => item.quantity)
    },
    labels: supportTicketGraph?.graphData?.map((item) => item.month)
  };

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title={`${
                    memberRegistrationGraph?.totalRegisteredMember || 0
                  } Members`}
                  description={
                    <>
                      <strong>
                        {getLatestMonthQuantity(
                          memberRegistrationGraph?.graphData
                        )}
                      </strong>{' '}
                      new registrations this month ,{' '}
                      <strong>
                        {getGraphComparisonPercentage(
                          memberRegistrationGraph?.graphData
                        )}
                      </strong>{' '}
                      from last month.
                    </>
                  }
                  date={
                    <strong>
                      Last updated on{' '}
                      {displayDate(createdAt, 'DD MMM YYYY, h.mma')}
                    </strong>
                  }
                  chart={memberChart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title={`${
                    pairedDeviceGraph?.totalPairedDevices || 0
                  } Paired Devices`}
                  description={
                    <>
                      <strong>
                        {getLatestMonthQuantity(pairedDeviceGraph?.graphData)}
                      </strong>{' '}
                      devices paired this month ,{' '}
                      <strong>
                        {getGraphComparisonPercentage(
                          pairedDeviceGraph?.graphData
                        )}
                      </strong>{' '}
                      from last month.
                    </>
                  }
                  date={
                    <strong>
                      Last updated on{' '}
                      {displayDate(createdAt, 'DD MMM YYYY, h.mma')}
                    </strong>
                  }
                  chart={deviceChart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title={`${
                    supportTicketGraph?.totalActiveTicket || 0
                  } Active Support Tickets`}
                  description={
                    <>
                      <strong>
                        {getLatestMonthQuantity(supportTicketGraph?.graphData)}
                      </strong>{' '}
                      new tickets this month ,{' '}
                      <strong>
                        {getGraphComparisonPercentage(
                          supportTicketGraph?.graphData
                        )}
                      </strong>{' '}
                      from last month.
                    </>
                  }
                  date={
                    <strong>
                      Last updated on{' '}
                      {displayDate(createdAt, 'DD MMM YYYY, h.mma')}
                    </strong>
                  }
                  chart={ticketChart}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container>
          <SalesByCountry _data={countryData} />
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default observer(Analytics);
