import React from "react";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { useStores } from "stores";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { delayResolve } from "utils/delay";
import ModuspaceService from "services/moduspace.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDLoading from "components/MDLoading";
import BaseLayout from "layouts/components/BaseLayout";
import BasicInfo from "pages/users/admins/components/BasicInfo";
import ChangePassword from "pages/users/admins/components/ChangePassword";
import DeleteAccount from "pages/users/admins/components/DeleteAccount";
import { joinName } from "utils/join-name";

function UserDetails() {
  const [loading, setLoading] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  const emailRef = React.useRef("");
  const [countries, setCountries] = React.useState(null);
  const [fandoms, setFandoms] = React.useState(null);
  const [scales, setScales] = React.useState(null);
  const [brands, setBrands] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const { id } = useParams();
  const { authentication, pageControl } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    let routes = location.pathname.split("/").slice(2);
    routes[routes.length - 1] = joinName(userInfo) || "User Info";
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, userInfo]);

  const fetchUserInfo = async () => {
    setLoading("Fetching user info...");
    try {
      const { userInfo, countries, fandoms, scales, brands, roles } =
        await delayResolve(async () => ({
          userInfo: await ModuspaceService.GetUserById(
            authentication.jwtToken,
            id,
          ),
          countries: await ModuspaceService.GetCountries(
            authentication.jwtToken,
          ),
          fandoms: await ModuspaceService.GetFandoms(authentication.jwtToken),
          scales: await ModuspaceService.GetScales(authentication.jwtToken),
          brands: await ModuspaceService.GetBrands(authentication.jwtToken),
          roles: await ModuspaceService.GetUserRolesIncludingInactive(
            authentication.jwtToken,
          ),
        }));
      setUserInfo(userInfo);
      setCountries(countries);
      setFandoms(fandoms);
      setScales(scales);
      setBrands(brands);
      setRoles(roles);
      emailRef.current = userInfo.email;
    } catch (err) {
      console.error("Error at fetchUserInfo:", err);
    }
    setLoading(null);
  };

  const updateUserInfo = async (updatedUserInfo) => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateUserById(
          authentication.jwtToken,
          id,
          updatedUserInfo,
        ),
      );
      if (response) {
        setUserInfo(response);
        toast.success("Administrator data has been updated successfully.");
      }
    } catch (err) {
      console.error("Error at updateUserInfo:", err);
      toast.error("Fail to update administrator data.");
    }
    setLoading(null);
  };

  const updatePassword = async (password) => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateUserPasswordByUserId(
          authentication.jwtToken,
          id,
          { password },
        ),
      );
      if (response) {
        toast.success("Administrator password has been updated.");
      }
    } catch (err) {
      console.error("Error at updateUserInfo:", err);
      toast.success("Fail to update administrator password.");
    }
    setLoading(null);
  };

  const emailGeneratedPassword = async () => {
    setLoading("Requesting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.EmailGenerateNewPasswordUserByIDForAdmin(
          authentication.jwtToken,
          id,
          {
            email: emailRef.current,
          },
        ),
      );
      if (response) {
        toast.success(
          "Your new generated password has been sent to your email.",
        );
      }
    } catch (err) {
      console.error("Error at requestResetPassword:", { err });
      toast.error("Fail to generate new password.");
    }
    setLoading("");
  };

  const deleteAccount = async () => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteUserById(authentication.jwtToken, id),
      );
      if (response) {
        setDeleted(true);
        toast.success("Administrator data has been deleted successfully.");
      }
    } catch (err) {
      console.error("Error at deleteAccount:", err);
      toast.error("Fail to delete administrator data");
    }
    setLoading(null);
  };

  const deactivateAccount = async () => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateUserStatusByUserId(authentication.jwtToken, id, {
          status: "Inactive",
        }),
      );
      if (response) {
        fetchUserInfo();
        toast.success("Administrator account has been inactivated.");
      }
    } catch (err) {
      console.error("Error at deactivateAccount:", err);
      toast.error("Fail to inactivated administrator account.");
    }
    setLoading(null);
  };

  const reactivateAccount = async () => {
    setLoading("Submitting");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateUserStatusByUserId(authentication.jwtToken, id, {
          status: "Active",
        }),
      );
      if (response) {
        fetchUserInfo();
        toast.success("Administrator account has been activated.");
      }
    } catch (err) {
      console.error("Error at reactivateAccount:", err);
      toast.error("Fail to activate administrator account.");
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (userInfo === null && authentication.jwtToken) fetchUserInfo();
    // eslint-disable-next-line
  }, [userInfo, authentication.jwtToken]);

  React.useEffect(() => {
    if (updated || deleted) navigate("/dashboard/settings/admins");
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <BasicInfo
              userInfo={userInfo}
              onChangeSubmit={updateUserInfo}
              countries={countries}
              fandoms={fandoms}
              scales={scales}
              brands={brands}
              roles={roles}
            />
          </Grid>
          <Grid item xs={6}>
            <ChangePassword
              onSubmit={updatePassword}
              onSubmitEmailGeneratedPassword={emailGeneratedPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <DeleteAccount
              onConfirm={deleteAccount}
              onDeactivate={deactivateAccount}
              onReactivate={reactivateAccount}
              showDeactivateOption={userInfo?.status !== "Inactive"}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(UserDetails);
