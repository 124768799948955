import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MDAutocomplete from "components/MDAutocomplete";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import CustomerSearchModal from "components/MDCustomersSearch/CustomerSearchModal";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { delayResolve } from "utils/delay";
import { getBadgeColorByStatus } from "utils/element";
import { joinName } from "utils/join-name";
import { joinPath } from "utils/join-url";

function IdeaboardReviewPostCommentManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [commentStatus, setCommentStatus] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  const [customerSearchModalVisible, setCustomerSearchModalVisible] =
    useState(false);

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }

      const {
        list: { list, pagination },
        commentStatus,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListPendingIdeaboardPostComments({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          commentStatus: await ModuspaceService.GetIdeaboardPostCommentStatus(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.commentText = data.text));
      list.forEach((data) => (data.email = data.user.email));
      list.forEach((data) => (data.userPostTitle = data.userPost?.title));
      list.forEach(
        (data) =>
          (data.numOfCommentReviews = data.userCommentReviews.length ?? 0),
      );
      list.forEach(
        (data) =>
          (data.reasonOfCommentReviews = data.userCommentReviews?.map(
            (item) => item.reason,
          )),
      );
      setData(list);
      setTotalCount(pagination.total);
      setCommentStatus(
        commentStatus?.map((item) => ({ label: item, id: item })),
      );
    } catch (err) {
      console.error("IdeaboardReviewPostCommentManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListPendingIdeaboardPostComments({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.commentText = data.text));
      list.forEach((data) => (data.email = data.user.email));
      list.forEach((data) => (data.userPostTitle = data.userPost?.title));
      list.forEach(
        (data) =>
          (data.numOfCommentReviews = data.userCommentReviews.length ?? 0),
      );
      list.forEach(
        (data) =>
          (data.reasonOfCommentReviews = data.userCommentReviews?.map(
            (item) => item.reason,
          )),
      );
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      type: "string",
      label: "Comment",
      name: "userComment__text",
      placeholder: "Comment Text",
      searchColumn: "userComment__text",
    },
    {
      type: "string",
      label: "Comment Review",
      name: "userCommentReview__reason",
      placeholder: "User Comment Review Reason",
      searchColumn: "userCommentReview__reason",
    },
    {
      type: "multiSelect",
      label: "Commentor",
      name: "userComment__userId",
      value: String(user.map((u) => u.id)),
      options: user || [],
      searchColumn: "userComment__userId",
      render: () => (
        <>
          {customerSearchModalVisible && (
            <CustomerSearchModal
              open={customerSearchModalVisible}
              onClose={() =>
                setCustomerSearchModalVisible(!customerSearchModalVisible)
              }
              onSearch={(selectedUsers) => {
                setUser(selectedUsers);
              }}
            />
          )}
          <MDAutocomplete
            label="Poster"
            name="userComment__userId"
            value={user || []}
            options={user || []}
            multiple
            readOnly
          />
          <MDButton
            variant="outlined"
            size="small"
            iconOnly
            style={{ fontSize: 25, color: "#D2D6DA", border: "none" }}
            onClick={() => setUser([])}>
            <CloseOutlined />
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            iconOnly
            onClick={() => setCustomerSearchModalVisible(true)}>
            <SearchOutlined />
          </MDButton>
        </>
      ),
    },
    {
      name: "userComment__status",
      label: "Comment Status",
      type: "multiSelect",
      optionName: "userComment__status",
      options: commentStatus,
      searchColumn: "userComment__status",
    },
    {
      name: "userComment__createdAt",
      label: "Commented At",
      type: "dateRange",
      searchColumn: "userComment__createdAt",
    },
    {
      name: "userCommentReview__createdAt",
      label: "Created At",
      type: "dateRange",
      searchColumn: "userCommentReview__createdAt",
    },
  ];

  const tableInfo = [
    {
      label: "Comment",
      id: "commentText",
      width: 500,
      tableRowMapper: (value) =>
        value.length >= 50 ? value.substring(0, 50) + "..." : value,
    },
    {
      label: "Customer",
      id: "user",
      alias: "user.firstName",
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath("/dashboard/customers/", value.id)}
          target="_blank">
          {joinName(value)}
        </Link>
      ),
      className: "custom-link",
    },
    { 
      label: "Post Title", 
      id: "userPostTitle",
      alias: "userPost.title",
    },
    { 
      label: "Number of Reviews", 
      id: "numOfCommentReviews", 
    },
    {
      label: "Comment Status",
      id: "status",
      tableRowMapper: (value) =>
        value ? (
          <MDBadge
            size="xs"
            badgeContent={value}
            color={getBadgeColorByStatus(value)}
            container
          />
        ) : null,
    },
    { label: "Created at", id: "createdAt" },
  ];

  const exportInfo = [];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      onFilterReset={() => setUser([])}
      // for common usage
      title="Ideaboard Review Post Comment Management"
      currentActiveParent="ideaboard"
      currentActiveTab="ideaboard-comment-review-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(IdeaboardReviewPostCommentManagement);
