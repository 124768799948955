import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import { delayResolve } from 'utils/delay';
import { Link } from 'react-router-dom';
import { joinPath } from 'utils/join-url';
import { joinName } from 'utils/join-name';
import { displayDate } from 'utils/date';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { Checkbox } from '@mui/material';
import MDBadge from 'components/MDBadge';

function DeliveryOrderManagement() {
	const { authentication } = useStores();

	const [data, setData] = useState([]);
	const [statuses, setStatuses] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
	const [isRefreshing, setRefreshing] = useState(false);
	const [tablePagination, setTablePagination] = useState({});
	const [totalCount, setTotalCount] = useState(0);
	const [dataFilters, setDataFilters] = useState([]);
	const [sortBy, setSortBy] = useState({
		id: '',
		type: '', // Asc or desc
	});
	// For bulk actions
	const [selectedRows, setSelectedRows] = useState([]);
	const [allRowsSelected, setAllRowsSelected] = useState(false);

	useEffect(() => {
		init();
	}, [tablePagination, sortBy]);

	async function init() {
		setRefreshing(true);
		setSelectedRows([]);
		try {
			if (!tablePagination?.pageSize || sortBy?.type === '') {
				return;
			}
      const {
        deliveryOrders: { deliveryOrders, pagination },
        statuses,
        shippingMethods,
      } = await delayResolve(
        async () => ({
          deliveryOrders: await ModuspaceService.ListDeliveryOrders(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            sortBy,
          ),
          statuses: await ModuspaceService.GetDeliveryOrderStatuses(
            authentication.jwtToken,
          ),
          shippingMethods: await ModuspaceService.GetDeliveryOrderShippingMethods(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      setData(deliveryOrders);
      setStatuses(statuses?.map(({ name }) => ({ label: name, id: name })));
      setShippingMethods(shippingMethods);
      setTotalCount(pagination.total);
		} catch (err) {
      console.error('DeliveryOrderManagement:', err);
    }
    setRefreshing(false);
	};

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const data = await ModuspaceService.GetAllDeliveryOrders(
        authentication.jwtToken,
        Number.MAX_SAFE_INTEGER,
        1,
        appliedFilter ? dataFilters : [],
      );
      return data;
    } catch (err) {
      console.error('DeliveryOrderManagement:', err);
    }
  };

  const selectAllRows = () => {
    data.forEach((row) => (row.__checked = allRowsSelected));
    setData([...data]);
    setAllRowsSelected(!allRowsSelected);
    setSelectedRows(allRowsSelected ? [] : data.map((row) => row.id));
  };

  const bulkActionHandler = async (action) => {
    return;
  }

  const tableInfo = [
    {
      label: <Checkbox style={{ padding: 0 }} onClick={selectAllRows} />,
      id: 'checkBox',
      width: '2%',
      disableSortBy: true,
    },
    {
      label: 'ID',
      id: 'id',
      width: '10%',
      tableRowMapper: (_, row) => (
        <Link
          className="custom-link"
          to={joinPath('/dashboard/delivery-orders', row.id)}
        >
          {row.id}
        </Link>
      ),
    },
    {
      label: 'Shipment Date',
      id: 'shipmentDate',
    },
    {
      label: 'Order ID',
      id: 'salesOrder',
      alias: 'salesOrder.referenceOrderId',
      tableRowMapper: (_, row) => (
        <Link
          className="custom-link"
          to={joinPath('/dashboard/orders', row.salesOrder.id)}
          target="_blank"
        >
          {row.salesOrder.referenceOrderId}
        </Link>
      ),
    },
    {
      label: 'Status',
      id: 'status',
      tableRowMapper: (value) => (
        <MDBadge
          size="xs"
          badgeContent={value}
          color={value === 'Delivered' ? 'success' : value === 'Pending' ? 'warning' : value === 'Confirmed' ? 'info' : 'error'}
          container={true}
        />
      )
    },
    {
      label: 'Customer',
      id: 'customer',
      alias: 'customer.firstName',
      tableRowMapper: (value, row) => {
        return row.customerId ? (
          <Link
            className="custom-link"
            to={joinPath('/dashboard/customers', row.customerId)}
            target="_blank"
          >
            {joinName(value)}
          </Link>
        ) : (
          <span
            style={{
              color: '#EF5350',
              fontWeight: 'bold',
          }}>
            {joinName(value)}
          </span>
        );
      },
    },
    {
      label: 'Shipper / Tracking Code',
      id: 'shipperName',
      // Display shipper name and tracking code
      tableRowMapper: (value, row) => {
        return (
          <>
            {value} <br /> {row.trackingCode}
          </>
        );
      },
    },
    {
      label: 'Amount',
      id: 'amount',
    },
  ];

  const sortInfo = [];

  const filterInfo = [
    {
      name: 'multiField',
      label: 'Keywords',
      type: 'string',
      placeholder: 'Shipper, Customer, DO ID, Notes, Reference, Order ID',
      searchColumn: "id,shipperName,shipperPhone,trackingCode,notes,remarks,reference,salesOrder.reference_order_id,customer.first_name,customer.last_name,customer.email,customer.phone",
    },
    {
      name: 'shipmentDate',
      label: 'Shipment Date',
      type: 'dateRange',
      searchColumn: 'shipmentDate',
    },
    {
      name: 'status',
      optionName: 'status',
      label: 'Status',
      type: 'multiSelect',
      searchColumn: 'status',
      options: statuses || [],
    },
    {
      name: 'deliveryDate',
      label: 'Delivery Date',
      type: 'dateRange',
      searchColumn: 'deliveryDate',
    },
  ];

  const exportInfo = [
    {
      label: 'DO ID',
      name: 'id',
    },
    {
      label: 'Order ID',
      name: 'salesOrder.referenceOrderId',
    },
    {
      label: 'Status',
      name: 'status',
    },
    {
      label: 'Shipping Method',
      name: 'shippingMethod',
    },
    {
      label: 'Amount',
      name: 'amount',
    },
    {
      label: 'Shipment Date',
      name: 'shipmentDate',
    },
    {
      label: 'Shipper Name',
      name: 'shipperName',
    },
    {
      label: 'Shipper Phone',
      name: 'shipperPhone',
    },
    {
      label: 'Tracking Code',
      name: 'trackingCode',
    },
    {
      label: 'DO Reference',
      name: 'reference',
    },
    {
      label: 'Delivery Date',
      name: 'deliveryDate',
    },
    {
      label: 'Customer Name',
      name: 'customer',
      rowMapper: (value, _) => joinName(value),
    },
    {
      label: 'Customer Email',
      name: 'customer.email',
    },
    {
      label: 'Customer Phone',
      name: 'customer.phone',
    },
    {
      label: 'Receiver Name',
      name: 'receiverName',
    },
    {
      label: 'Receiver Phone',
      name: 'receiverPhone',
    },
    {
      label: 'Remarks',
      name: 'remarks',
    },
    {
      label: 'Internal Notes',
      name: 'internalNotes',
    },
  ];

  return (
    <MDGeneralListingV3
      // For filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // For sorting
      sortInfo={sortInfo}
      sortBy={sortBy}
      setSortBy={setSortBy}
      // For table rendering
      _data={data}
      tableInfo={tableInfo}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount}
      // For export
      exportInfo={exportInfo}
      exportFileNamePrefix='delivery-orders'
      fetchAllExportData={fetchAllExportData}
      // For actions
      rowIDAccessor='id'
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) => null}
      bulkActions={{
        selectedData: selectedRows,
        setSelectedData: setSelectedRows,
      }}
      // For common usage
      title='Delivery Orders'
      dateColumn={['shipmentDate', 'deliveryDate']}
      currentActiveParent='delivery-orders'
      currentActiveTab='delivery-orders'
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(DeliveryOrderManagement);