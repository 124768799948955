import { Autocomplete, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import GeneralTable from "controls/Tables/GeneralTable";
import FormField from "layouts/components/FormField";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { displayDate } from "utils/date";
import { joinPath } from "utils/join-url";
import { presetPaymentDescriptionOptions } from "utils/constants";

// Calculate the total amount paid for an order
const getTotalPaid = (payments) => {
  return payments
    ?.filter((payment) => payment.status === "Paid")
    .reduce((acc, cur) => acc + Number(cur.amount), 0);
};

export default function Payments({ info }) {
  const payments = info.payments;
  const [newPayment, setNewPayment] = useState({
    amount: "",
    description: "",
  });

  const [balanceAmount, setBalanceAmount] = useState();

  useEffect(() => {
    const totalPaid = getTotalPaid(info.payments);

    const balanceAmount = (
      info.orderTotalIncTax - info.shippingCostIncTax ?? 0 - totalPaid
    ).toFixed(2);

    setBalanceAmount(balanceAmount);
  }, [info.payments]);

  const calculateButtons = [
    {
      name: "50% of Total",
      onClick: () => {
        setNewPayment({
          ...newPayment,
          amount: (balanceAmount * 0.5).toFixed(2),
        });
      },
    },
    {
      name: "95% of Total",
      onClick: () => {
        setNewPayment({
          ...newPayment,
          amount: (balanceAmount * 0.95).toFixed(2),
        });
      },
    },
    {
      name: "100% of Total",
      onClick: () => {
        setNewPayment({
          ...newPayment,
          amount: balanceAmount,
        });
      },
    },
    {
      name: "Shipping Total",
      onClick: () => {
        setNewPayment({
          ...newPayment,
          amount: info.shippingCostIncTax,
        });
      },
    },
  ];

  const tableInfo = [
    {
      label: "Transaction ID",
      name: "transactionRef",
      render: (object, row) => {
        return (
          <Link
            className="custom-link"
            to={joinPath("/dashboard/payments/", row.id)}
            target="_blank">
            {object}
          </Link>
        );
      },
    },
    {
      label: "Payment Mode",
      name: "mode",
      render: (object) => {
        return object ? object : "-";
      },
    },
    {
      label: "Payment Gateway Ref",
      name: "paymentGatewayRef",
      render: (object) => {
        return object ? object : "-";
      },
    },
    {
      label: "Order ID",
      name: "referenceOrderId",
      render: (object, row) => {
        return info.referenceOrderId;
      },
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Amount",
      name: "amount",
      render: (object, row) => {
        return `${row?.currency?.symbol}${object}`;
      },
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      render: (object) => `${object ? displayDate(new Date(object)) : "-"}`,
    },
  ];

  const createNewPayment = (e) => {
    e.preventDefault();
    // Show prompt if the new payment total is greater than the order total
    if (Number(newPayment.amount) > info.orderTotalIncTax) {
      if (
        !window.confirm(
          `The new payment amount is greater than the order total. Are you sure you want to request a payment of ${info.orderCurrency?.symbol}${newPayment.amount}?`,
        )
      ) {
        return;
      }
    }
    // Open a new tab to create a new payment. Send order ID, amount and description as query params
    const url = joinPath(
      "/dashboard/payments/new",
      `?order=${info.id}&amount=${newPayment.amount}&description=${newPayment.description}`,
    );
    window.open(url, "_blank");
  };

  return (
    <Card id="payments">
      <MDBox mt={3}>
        <GeneralTable
          headColumn={tableInfo}
          bodyColumn={payments || []}
          disablePagination={true}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5">Add a Payment Request</MDTypography>
        <MDBox component="form" onSubmit={createNewPayment}>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12} sm={2}>
              <FormField
                type="number"
                label="Amount"
                InputLabelProps={{ shrink: true }}
                placeholder="0.00"
                onChange={(e) => {
                  // Remove non-numeric characters and leading zeroes
                  let amount = e.target.value.replace(/\D|^0+/g, "");
                  // Pad amount with leading zeroes if less than 3 digits
                  if (amount.length < 3) {
                    amount = amount.padStart(3, "0");
                  }
                  // Insert decimal point
                  amount = amount.slice(0, -2) + "." + amount.slice(-2);

                  setNewPayment({
                    ...newPayment,
                    amount,
                  });
                }}
                value={newPayment.amount}
                required
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Autocomplete
                id="Description"
                freeSolo
                options={presetPaymentDescriptionOptions}
                sx={{
                  // border: "1px solid blue",
                  "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  },
                }}
                onChange={(e, value) =>
                  setNewPayment({
                    ...newPayment,
                    description: value,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Description"
                    placeholder="Add a description, e.g. Deposit, Shipping, etc."
                    value={newPayment.description}
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        description: e.target.value,
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDButton
                type="submit"
                variant="contained"
                color="success"
                style={{
                  width: "100%",
                }}>
                Request Payment
              </MDButton>
            </Grid>
            {calculateButtons.map((btn) => (
              <Grid item xs={12} sm={3}>
                <MDButton
                  variant="contained"
                  color="info"
                  style={{
                    width: "100%",
                  }}
                  onClick={btn.onClick}>
                  {btn.name}
                </MDButton>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
