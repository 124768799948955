import ModuspaceService from 'services/moduspace.service';

export async function getOrderUploadToken(token) {
  try {
    return await ModuspaceService.GetOrderUploadToken(token);
  } catch (e) {
    console.log('DeviceMasterListManagement getDeviceUploadToken', e);
    return false;
  }
}

export async function importOrders(token, payload) {
  try {
    return await ModuspaceService.ImportOrders(token, payload);
  } catch (e) {
    console.log('DeviceMasterListManagement importDevice', e);
    return false;
  }
}
