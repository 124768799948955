import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Grid, Card, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDLoading from 'components/MDLoading';

import { downloadS3file } from 'utils/s3Uploader';

const ImportNotificationModal = ({
  open,
  onClose,
  getSession,
  importFilePath
}) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    try {
      setLoading('downloading');
      const session = await getSession();
      await downloadS3file({ session, file: importFilePath });
      setLoading('');
    } catch (e) {
      setLoading('');
      console.log(e.message);
      toast.error('Opps, somethings was wrong.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '450px',
        minHeight: '180px'
      }}>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card style={{ width: '450px', height: '180px' }}>
              <MDBox pt={1} px={2}>
                <Grid
                  container
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Imported Fail Data
                    </MDTypography>
                  </Grid>
                  <Grid item style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <MDTypography variant="subtitle2" color="secondary">
                    The download file will be display the imported fail data
                    with each reason.
                  </MDTypography>
                </Grid>
              </MDBox>
              <MDBox
                py={2}
                px={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="large"
                  style={{ float: 'right' }}
                  sx={{ mx: 2 }}
                  onClick={downloadFile}>
                  Download
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="secondary"
                  size="large"
                  style={{ float: 'right' }}
                  onClick={onClose}>
                  Close
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Modal>
  );
};

export default ImportNotificationModal;
