import ModuspaceService from 'services/moduspace.service';

export async function getDeviceUploadToken(token) {
  try {
    return await ModuspaceService.GetDeviceUploadToken(token);
  } catch (e) {
    console.log('DeviceMasterListManagement getDeviceUploadToken', e);
    return false;
  }
}

export async function importDevice(token, payload) {
  try {
    return await ModuspaceService.ImportDeviceBySerial(token, payload);
  } catch (e) {
    console.log('DeviceMasterListManagement importDevice', e);
    return false;
  }
}
