import React from 'react';
import AuthenticationStore from './authentication.store';
import PageControlStore from './page-control.store';
import localeStore from './locale.store';
import IdeaboardStore from './ideaboard';

export const StoreContext = React.createContext();

/**
 *
 * @returns {{
 *  authentication: AuthenticationStore
 *  pageControl: PageControlStore
 * }}
 */
export const useStores = () => {
  const store = React.useContext(StoreContext);
  return store;
};

export default function StoreProvider(props) {
  return (
    <StoreContext.Provider
      {...props}
      value={{
        authentication: AuthenticationStore,
        pageControl: PageControlStore,
        locale: localeStore,
        ideaboard: IdeaboardStore
      }}
    />
  );
}
