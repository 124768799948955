/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

function Header({ tabValue = 0, onChange = () => 0, children }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sx={{ ml: 'auto' }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={onChange}>
              <Tab label="Billing" />
              <Tab label="Payments" />
              <Tab label="Shipping" />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      {children}
    </>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: ''
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node
};

export default Header;
