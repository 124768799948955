const UserAddressSchema = (
  countries = [],
  states = [],
  addressType = [],
  formData = {}
) => [
  {
    type: 'string',
    name: 'firstName',
    label: 'First Name',
    initialValue: formData?.firstName ?? null,
    placeholder: 'Alec',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last Name',
    initialValue: formData?.lastName ?? null,
    placeholder: 'Thompson',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'company',
    label: 'Company Name',
    initialValue: formData?.company ?? null,
    placeholder: 'Moducase Pte Ltd',
    required: false,
    gridSpacing: 6
  },
  {
    type: 'phone-number',
    name: 'phone',
    label: 'Phone Number',
    initialValue: formData?.phone ?? null,
    placeholder: '+65 8895 6228',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'addressLine1',
    label: 'Adress Line 1',
    initialValue: formData?.addressLine1 ?? null,
    placeholder: 'No. 1, Street Test 1, Resident 1',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'addressLine2',
    label: 'Adress Line 2',
    initialValue: formData?.addressLine2 ?? null,
    placeholder: 'No. 2, Street Test 2, Resident 2',
    required: false,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'city',
    label: 'Suburb/City',
    initialValue: formData?.city ?? null,
    placeholder: 'Singapore',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'dropdown',
    name: 'country',
    label: 'Country',
    initialValue: formData?.country ?? null,
    options: countries,
    getOptionLabel: (option) => option?.label ?? '',
    isOptionEqual: (a, b) => a.id === b.id,
    placeholder: 'Select a country',
    required: true,
    gridSpacing: 6
  },
  states?.length
    ? {
        type: 'dropdown',
        name: 'state',
        label: 'State/Province',
        initialValue:
          typeof formData.state === 'object' ? formData.state : null,
        options: states,
        getOptionLabel: (option) => option?.label ?? '',
        isOptionEqual: (a, b) => a.id === b.id,
        placeholder: 'Select a state',
        required: true,
        gridSpacing: 6
      }
    : {
        type: 'string',
        name: 'state',
        label: 'State/Province',
        initialValue:
          typeof formData.state === 'string' ? formData.state : null,
        placeholder: 'Singapore',
        required: true,
        gridSpacing: 6
      },
  {
    type: 'string',
    name: 'postalCode',
    label: 'Zip/postalCode',
    initialValue: formData?.postalCode ?? null,
    placeholder: 'S628705',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'dropdown',
    name: 'addressType',
    label: 'Address Type',
    initialValue: formData?.addressType ?? null,
    options: addressType,
    getOptionLabel: (option) => option?.label ?? '',
    isOptionEqual: (a, b) => a.id === b.id,
    placeholder: 'Select an address type',
    required: true,
    gridSpacing: 6
  }
];

export default UserAddressSchema;
