import Grid from "@mui/material/Grid";
import { useState } from "react";
import Billing from "./Billing";
import Customer from "./Customer";
import Header from "./Header";
import OrderDetails from "./OrderDetail";
import Payments from "./Payment";
import Product from "./ProductInformation";
import Shipping from "./Shipping";

function BasicInfo({ info, setRefetchToggle, updateOrder }) {
  const [tabValue, setTabValue] = useState(0);

  const handleSubmit = (e, values) => {
    e.preventDefault();
    updateOrder(values);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <OrderDetails info={info} handleSubmit={handleSubmit} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
            <Customer info={info} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
            <Header
              tabValue={tabValue}
              onChange={(_, newValue) => setTabValue(newValue)}>
              {tabValue === 0 ? (
                <Billing info={info} handleSubmit={handleSubmit} />
              ) : tabValue === 1 ? (
                <Payments info={info} />
              ) : tabValue === 2 ? (
                <Shipping info={info} handleSubmit={handleSubmit} />
              ) : null}
            </Header>
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
            <Product info={info} setRefetchToggle={setRefetchToggle} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BasicInfo;
