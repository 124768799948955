/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
// import Divider from "@mui/material/Divider";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";

// Settings page components
import FormField from "layouts/components/FormField";

// Data
import selectData from "pages/users/customers/components/BasicInfo/data/selectData";
import { isFalsyExceptNumber } from "utils/falsy";
import { displayDate } from "utils/date";
import { toast } from "react-toastify";
var validator = require("email-validator");

function BasicInfo({
  userInfo,
  onChangeSubmit = () => void 0,
  onChange = () => void 0,
  createMode = false,
  countries,
  roles,
  fandoms,
  scales,
  brands,
  onSubmitRef,
}) {
  const [currentUserInfo, setCurrentUserInfo] = React.useState({});
  const [currentDob, setCurrentDob] = React.useState();
  const [currentCountries, setCurrentCountries] = React.useState([]);
  const [currentRoles, setCurrentRoles] = React.useState([]);
  const [confirmEmail, setConfirmEmail] = React.useState("");

  //profile questions
  const [currentFandoms, setCurrentFandoms] = React.useState([]);
  const [currentScales, setCurrentScales] = React.useState([]);
  const [currentBrands, setCurrentBrands] = React.useState([]);

  React.useEffect(() => {
    const info = userInfo ?? {};
    setCurrentUserInfo(info);
    setCurrentDob(info?.dob ?? null);
  }, [userInfo]);

  React.useEffect(() => {
    onChange(currentUserInfo);
  }, [currentUserInfo]);

  React.useEffect(() => {
    setCurrentUserInfo((f) => ({
      ...f,
      dob: currentDob,
    }));
  }, [currentDob]);

  React.useEffect(() => {
    const c = countries ?? [];
    setCurrentCountries(c);
  }, [countries]);

  React.useEffect(() => {
    const f = fandoms ?? [];
    setCurrentFandoms(f);
  }, [fandoms]);

  React.useEffect(() => {
    const s = scales ?? [];
    setCurrentScales(s);
  }, [scales]);

  React.useEffect(() => {
    const b = brands ?? [];
    setCurrentBrands(b);
  }, [brands]);

  React.useEffect(() => {
    const r = roles ?? [];
    setCurrentRoles([
      ...r.filter((e) => e.status !== "Inactive"),
      ...r.filter((e) => e.status === "Inactive"),
    ]);
    if (createMode) {
      //if it is create mode, set default role customer
      setCurrentUserInfo({
        ...currentUserInfo,
        applicationRoles: r.filter((e) => e.type === "Customer"),
      });
    }
  }, [roles, createMode]);

  // const getCustomerRoles = () => {
  //   return roles ?? roles?.filter((role) => role.type === "Customer");
  // };

  const getUserCountry = () => {
    if (isFalsyExceptNumber(currentUserInfo.countryId)) return "";
    return (
      currentCountries.find((e) => e.id === currentUserInfo.countryId)?.name ??
      ""
    );
  };

  const onChangeCountry = (country) => {
    if (typeof country !== "string") {
      setCurrentUserInfo({
        ...currentUserInfo,
        countryId: null,
      });
      return;
    }
    const countryObj = currentCountries.find((e) => e.name === country);
    if (!countryObj) return;
    setCurrentUserInfo({
      ...currentUserInfo,
      countryId: countryObj.id,
    });
  };

  const getUserRoles = () => {
    const roles = currentUserInfo.applicationRoles || [];
    return roles;
  };

  const getUserProfileFandoms = () => {
    const fandoms = currentUserInfo?.profile?.fandoms || [];
    return fandoms;
  };

  const getUserProfileScales = () => {
    const scales = currentUserInfo?.profile?.scales || [];
    return scales;
  };

  const getUserProfileBrands = () => {
    const brands = currentUserInfo?.profile?.brands || [];
    return brands;
  };

  const onChangeRoles = (selectedRoles) => {
    setCurrentUserInfo({
      ...currentUserInfo,
      applicationRoles: selectedRoles,
    });
  };

  const onChangeFandoms = (selectedFandoms) => {
    const profile = {
      ...currentUserInfo.profile,
      fandoms: Array.isArray(selectedFandoms)
        ? selectedFandoms
        : [selectedFandoms],
    };
    setCurrentUserInfo({
      ...currentUserInfo,
      profile: profile,
    });
  };

  const onChangeScales = (selectedScales) => {
    const profile = {
      ...currentUserInfo.profile,
      scales: Array.isArray(selectedScales) ? selectedScales : [selectedScales],
    };
    setCurrentUserInfo({
      ...currentUserInfo,
      profile: profile,
    });
  };

  const onChangeBrands = (selectedBrands) => {
    const profile = {
      ...currentUserInfo.profile,
      brands: Array.isArray(selectedBrands) ? selectedBrands : [selectedBrands],
    };
    setCurrentUserInfo({
      ...currentUserInfo,
      profile: profile,
    });
  };

  const validateFormData = () => {
    const newUserInfo = BasicInfo.fillEmptyRequiredFields(currentUserInfo);
    const isNewUserInfoValid = BasicInfo.areRequireFieldsValid(newUserInfo);
    setCurrentUserInfo(newUserInfo);
    // setCurrentDob(typeof newUserInfo.dob === "string" ? new Date(newUserInfo.dob) : newUserInfo.dob === null ? null : undefined);
    if (!isNewUserInfoValid) {
      toast.error("Please fill in all required customer details.");
      return {
        error: true,
      };
    }
    return {
      error: false,
      userInfo: newUserInfo,
    };
  };

  if (onSubmitRef && createMode) {
    onSubmitRef.current = {
      onSubmitListener: validateFormData,
    };
  }

  const handleSubmit = () => {
    const { error, userInfo } = validateFormData();
    if (!error) onChangeSubmit(userInfo);
  };

  // const isUserNameInvalid = () => BasicInfo.isUserNameInvalid(currentUserInfo);
  const isPhoneNumberInvalid = () =>
    BasicInfo.isPhoneNumberInvalid(currentUserInfo);
  const isCountryInvalid = () => BasicInfo.isCountryInvalid(currentUserInfo);
  const isDateOfBirthInvalid = () =>
    BasicInfo.isDateOfBirthInvalid(currentUserInfo);
  const isRolesInvalid = () => BasicInfo.isRolesInvalid(currentUserInfo);
  const isMarketingInvalid = () =>
    BasicInfo.isMarketingInvalid(currentUserInfo);

  const nonEmptyProps = (field) => ({
    error: BasicInfo.isFieldInvalid(field),
    helperText: BasicInfo.isFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : null,
    required: true,
  });

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox pt={3} px={3}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize">
          Customer Details{" "}
          {userInfo && userInfo.status !== "Active" && (
            <MDChip size="small" text={userInfo.status} varient="filled" />
          )}
        </MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {/* hardcode show only customer role */}
          <Grid item xs={12} md={12}>
            <MDBox py={1}>
              <MDInput
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Type *"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDChip size="small" text="Customer" varient="filled" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </MDBox>
          </Grid>

          {/* <Grid item xs={12}>
            <FormField
              label="User Name"
              placeholder="Select a user name"
              value={currentUserInfo.userName ?? ""}
              onChange={
                ({ target: { value: userName } }) => setCurrentUserInfo({
                  ...currentUserInfo,
                  userName
                })
              }
              error={isUserNameInvalid()}
              helperText={isUserNameInvalid() ? <MDTypography variant="caption" color="error">Your username must only contain alphanumeric, an underscore (_), and a hyphen (-).</MDTypography> : " "}
              required
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              placeholder="e.g. John"
              value={currentUserInfo.firstName ?? ""}
              onChange={({ target: { value: firstName } }) =>
                setCurrentUserInfo({
                  ...currentUserInfo,
                  firstName,
                })
              }
              {...nonEmptyProps(currentUserInfo.firstName)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Last Name"
              placeholder="e.g. Doe"
              value={currentUserInfo.lastName ?? ""}
              onChange={({ target: { value: lastName } }) =>
                setCurrentUserInfo({
                  ...currentUserInfo,
                  lastName,
                })
              }
              {...nonEmptyProps(currentUserInfo.lastName)}
            />
          </Grid>
          {/* for create user */}
          {createMode && (
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Email"
                    placeholder="example@email.com"
                    inputProps={{ type: "email" }}
                    value={currentUserInfo.email ?? ""}
                    onChange={({ target: { value: email } }) =>
                      setCurrentUserInfo({
                        ...currentUserInfo,
                        email,
                      })
                    }
                    error={
                      (typeof currentUserInfo.email === "string" &&
                        !validator.validate(currentUserInfo.email)) ||
                      (confirmEmail.length > 0 &&
                        confirmEmail !== currentUserInfo.email)
                    }
                    helperText={
                      typeof currentUserInfo.email === "string" &&
                      !validator.validate(currentUserInfo.email) ? (
                        <MDTypography variant="caption" color="error">
                          Invalid email.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Confirmation Email"
                    placeholder="example@email.com"
                    inputProps={{ type: "email" }}
                    value={confirmEmail}
                    onChange={({ target: { value: email } }) =>
                      setConfirmEmail(email)
                    }
                    error={
                      confirmEmail.length > 0 &&
                      confirmEmail !== currentUserInfo.email
                    }
                    helperText={
                      confirmEmail.length > 0 &&
                      confirmEmail !== currentUserInfo.email ? (
                        <MDTypography variant="caption" color="error">
                          Your email does not match.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* for update user */}
          {!createMode && (
            <Grid item xs={12} sm={12}>
              <FormField
                label="Email"
                placeholder="example@email.com"
                inputProps={{ type: "email" }}
                value={currentUserInfo.email ?? ""}
                onChange={({ target: { value: email } }) =>
                  setCurrentUserInfo({
                    ...currentUserInfo,
                    email,
                  })
                }
                error={
                  typeof currentUserInfo.email === "string" &&
                  !validator.validate(currentUserInfo.email)
                }
                helperText={
                  typeof currentUserInfo.email === "string" &&
                  !validator.validate(currentUserInfo.email) ? (
                    <MDTypography variant="caption" color="error">
                      Invalid email.
                    </MDTypography>
                  ) : null
                }
              />
            </Grid>
          )}

          {/* <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue="Male"
              options={selectData.gender}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Gender"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={currentUserInfo.profile?.gender ?? "Male"}
              onChange={(_, gender) => {
                setCurrentUserInfo({
                  ...currentUserInfo,
                  profile: {
                    ...(currentUserInfo.profile ?? {}),
                    gender
                  }
                })
              }}
            />
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <FormField
              label="Contact Number"
              placeholder="e.g. 00000000"
              value={currentUserInfo.phone ?? ""}
              onChange={({ target: { value: phone } }) =>
                setCurrentUserInfo({
                  ...currentUserInfo,
                  phone,
                })
              }
              error={isPhoneNumberInvalid()}
              helperText={
                isPhoneNumberInvalid() ? (
                  <MDTypography variant="caption" color="error">
                    Your phone number must only contain numeric, dot (.), an
                    space ( ), a hyphen (-), and a plus (+), and has at least 7
                    number characters.
                  </MDTypography>
                ) : null
              }
              required
            />
          </Grid>

          {/* drop down country */}

          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={getUserCountry()}
              options={
                currentCountries.length
                  ? currentCountries.map((e) => e.name)
                  : selectData.country
              }
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Country"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Choose a country"
                  error={isCountryInvalid()}
                  helperText={
                    isCountryInvalid() ? (
                      <MDTypography variant="caption" color="error">
                        This field is required.
                      </MDTypography>
                    ) : null
                  }
                  required
                />
              )}
              onChange={(_, country) => onChangeCountry(country)}
              disableClearable
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MDDatePickerFullWidth
              input={{
                placeholder: "Choose a date of birth",
                error: isDateOfBirthInvalid(),
                helperText: isDateOfBirthInvalid() ? (
                  <MDTypography variant="caption" color="error">
                    Please enter a valid date prior to today
                  </MDTypography>
                ) : null,
                required: true,
              }}
              label="Date of Birth"
              value={currentDob}
              onChange={([date]) => {
                setCurrentDob(date ?? null);
              }}
              options={{
                dateFormat: "d-m-Y",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} sx={{ display: "none" }}>
            <Autocomplete
              multiple
              value={getUserRoles()}
              options={currentRoles}
              getOptionLabel={(option) =>
                option.status === "Inactive"
                  ? `${option.name} (Inactive)`
                  : option.name
              }
              isOptionEqualToValue={(a, b) => a.id === b.id}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Role"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Choose a role"
                    error={isRolesInvalid()}
                    helperText={
                      isRolesInvalid() ? (
                        <MDTypography variant="caption" color="error">
                          You must pick at least one role.
                        </MDTypography>
                      ) : null
                    }
                    required
                  />
                );
              }}
              onChange={(_, selectedRoles) => onChangeRoles(selectedRoles)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <MDBox py={1} pt={3}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize">
                  Profile Questions
                </MDTypography>
              </MDBox> */}

            <MDBox mb={0.5} lineHeight={1.25}>
              <Autocomplete
                // multiple
                value={getUserProfileFandoms()}
                options={currentFandoms}
                getOptionLabel={(option) => {
                  return option.label ?? option.toString();
                }}
                isOptionEqualToValue={(a, b) => a === b}
                renderInput={(params) => {
                  return (
                    <FormField
                      {...params}
                      label="Favourite fandom"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Choose Fandom(s)"
                    />
                  );
                }}
                onChange={(_, selectedFandoms) =>
                  onChangeFandoms(selectedFandoms)
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={0.5} lineHeight={1.25}>
              <Autocomplete
                // multiple
                value={getUserProfileBrands()}
                options={currentBrands}
                getOptionLabel={(option) => {
                  return option.label ?? option.toString();
                }}
                isOptionEqualToValue={(a, b) => a === b}
                renderInput={(params) => {
                  return (
                    <FormField
                      {...params}
                      label="Favourite brand"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Choose Brand(s)"
                    />
                  );
                }}
                onChange={(_, selectedBrands) => onChangeBrands(selectedBrands)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={7}>
            <MDBox mb={0.5} lineHeight={1.25}>
              <Autocomplete
                // multiple
                value={getUserProfileScales()}
                options={currentScales}
                getOptionLabel={(option) => {
                  return option.label ?? option.toString();
                }}
                isOptionEqualToValue={(a, b) => a === b}
                renderInput={(params) => {
                  return (
                    <FormField
                      {...params}
                      label="What are the bulk of your collections?"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Choose Scale(s)"
                    />
                  );
                }}
                onChange={(_, selectedScales) => onChangeScales(selectedScales)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl
              error={isMarketingInvalid() ? true : false}
              variant="standard">
              <FormLabel id="demo-error-radios" sx={{ lineHeight: 0 }}>
                <MDTypography
                  id="demo-error-radios"
                  variant="caption"
                  fontWeight="bold"
                  color={isMarketingInvalid() ? "error" : "text"}
                  textTransform="capitalize">
                  Receive Marketing notification
                </MDTypography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-error-radios"
                defaultValue=""
                name="radio-buttons-group"
                value={
                  currentUserInfo.profile?.activity?.marketing
                    ? "yes"
                    : currentUserInfo.profile?.activity?.marketing === false
                    ? "no"
                    : ""
                }
                onChange={({ target }) =>
                  setCurrentUserInfo({
                    ...currentUserInfo,
                    profile: {
                      ...(currentUserInfo.profile ?? {}),
                      activity: {
                        ...(currentUserInfo.profile?.activity ?? {}),
                        marketing: target.value === "yes",
                      },
                    },
                  })
                }>
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </RadioGroup>
              <FormHelperText>
                {isMarketingInvalid() ? "You must choose one." : null}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {!createMode && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap">
            <MDBox mt={3} ml="auto">
              <MDButton
                variant="gradient"
                color="dark"
                size="large"
                onClick={handleSubmit}>
                update profile
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {{
 * userName?: "",
 * firstName?: "",
 * lastName?: "",
 * email?: "",
 * phone?: "",
 * countryId?: number | null,
 * marketing: undefined,
 * applicationRoles?: [],
 * }} userInfo
 */
BasicInfo.fillEmptyRequiredFields = (userInfo) => {
  return {
    // userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    applicationRoles: [],
    ...userInfo,
    countryId: userInfo.countryId === undefined ? null : userInfo.countryId,
    dob: userInfo.dob === undefined ? null : userInfo.dob,
    marketing:
      userInfo.profile?.activity?.marketing === undefined
        ? ""
        : userInfo.profile?.activity?.marketing,
  };
};

/**
 * @param {{userName?: string}} currentUserInfo
 */
BasicInfo.isUserNameInvalid = (currentUserInfo) =>
  typeof currentUserInfo.userName === "string" &&
  !!(
    currentUserInfo.userName.length === 0 ||
    currentUserInfo.userName.replace(/[a-z0-9_-]/gi, "").length
  );

/**
 * @param {{phone?: string}} currentUserInfo
 */
BasicInfo.isPhoneNumberInvalid = (currentUserInfo) => {
  const phoneRegex =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  if (!currentUserInfo.phone) return true;

  return (
    currentUserInfo.phone.replace(/[0-9_+ -.]/gi, "").length > 0 ||
    currentUserInfo.phone.replace(/[^0-9]/gi, "").length < 7 ||
    !phoneRegex.test(currentUserInfo.phone)
  );
};

/**
 * @param {{countryId: number | null}} currentUserInfo
 */
BasicInfo.isCountryInvalid = (currentUserInfo) => {
  if (!currentUserInfo.countryId) return true;

  return currentUserInfo.countryId === null;
};

/**
 * @param {{DOB: Date | null}} currentUserInfo
 */
BasicInfo.isDateOfBirthInvalid = (currentUserInfo) => {
  // console.log(currentUserInfo.dob);
  // console.log(Date.now())
  // console.log("null", currentUserInfo.dob === null);
  // console.log("date.now()", currentUserInfo.dob > Date.now());

  if (!currentUserInfo.dob) return true;

  return currentUserInfo?.dob > Date.now();
};

/**
 *
 * @param {string | undefined} field
 * @returns
 */
BasicInfo.isFieldInvalid = (field) =>
  typeof field === "string" && field.length === 0;

/**
 *
 * @param {{ applicationRoles?: any[] }} currentUserInfo
 * @returns
 */
BasicInfo.isRolesInvalid = (currentUserInfo) =>
  currentUserInfo.applicationRoles instanceof Array &&
  currentUserInfo.applicationRoles.length === 0;

/**
 *
 * @param {string | undefined} field
 * @returns
 */
BasicInfo.isMarketingInvalid = (currentUserInfo) =>
  currentUserInfo.profile?.activity?.marketing === undefined;

/**
 * Get clean value from `BasicInfo.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  userName: "",
 *  firstName: "",
 *  lastName: "",
 *  email: "",
 *  phone: "",
 *  countryId: number | null,
 *  marketing: undefined,
 *  applicationRoles: [],
 * }} userInfo
 */
BasicInfo.areRequireFieldsValid = (userInfo) => {
  // if (BasicInfo.isUserNameInvalid(userInfo)) return false;
  if (BasicInfo.isPhoneNumberInvalid(userInfo)) return false;
  if (BasicInfo.isCountryInvalid(userInfo)) return false;
  if (BasicInfo.isDateOfBirthInvalid(userInfo)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.firstName)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.lastName)) return false;
  if (BasicInfo.isFieldInvalid(userInfo.email)) return false;
  if (BasicInfo.isRolesInvalid(userInfo.applicationRoles)) return false;
  if (BasicInfo.isMarketingInvalid(userInfo)) return false;
  return true;
};

export default BasicInfo;
