import { useState } from "react";

import MDBox from "components/MDBox";
import Header from "./Header";
import OrderInfo from "./OrderInfo";
import PaymentLogs from "./PaymentLogs";

function OrderInfoAndLogs({
  data,
  setData,
  orderOptions,
  defaultOrder,
  customer,
  setCustomer,
}) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <MDBox mt={2}>
      <Header
        tabValue={tabValue}
        onChange={(_, newValue) => setTabValue(newValue)}>
        {tabValue === 0 ? (
          <OrderInfo
            data={data}
            setData={setData}
            // Only return orders that have the same customer ID as the customer,
            // or have the same billing_address.email as the customer
            orderOptions={orderOptions}
            defaultOrder={defaultOrder}
            customer={customer}
            setCustomer={setCustomer}
          />
        ) : tabValue === 1 && data?.id ? (
          <PaymentLogs paymentId={data.id} />
        ) : null}
      </Header>
    </MDBox>
  );
}

export default OrderInfoAndLogs;
