import initialFilters from '../initial-states/initialFilters';

export default {
  filters: initialFilters.filters,
  pagination: initialFilters.pagination,
  setFilters(newFilters) {
    this.filters = newFilters;
  },
  setPagination(newPagination) {
    this.pagination = newPagination;
  }
};
