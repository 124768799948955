/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';

function DeleteAccount({
  userInfo,
  onDelete = () => void 0,
  onDeactivate = () => void 0,
  onActivate = () => void 0
}) {
  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: 'Confirm Delete Account?',
    content:
      'Once you delete your account, there is no going back. Please be certain.',
    confirmColor: 'error',
    confirmText: 'delete',
    onConfirm: onDelete //() => {console.log("confirm delete", onDelete); onDelete(userInfo)},
  });
  const { prompt: promptDeactivate, MDConfirm: MDConfirmDeactivate } =
    useConfirmModal({
      title: 'Confirm Deactivate Account?',
      content:
        'Deactivating an account is a safer alternative than deleting it. You may reactive the account later.',
      confirmColor: 'dark',
      confirmText: 'deactivate',
      onConfirm: onDeactivate //() => {console.log("confirm deactive", onDeactivate); onDeactivate(userInfo)},
    });
  const { prompt: promptActivate, MDConfirm: MDConfirmActivate } =
    useConfirmModal({
      title: 'Confirm Activate Account?',
      content: 'Activating an account will active the account immediately.',
      confirmText: 'activate',
      onConfirm: onActivate //() => {console.log("confirm activate", onActivate); onActivate(userInfo)},
    });

  return (
    <Card id="delete-account">
      <MDConfirmDelete />
      <MDConfirmDeactivate />
      <MDConfirmActivate />
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Account Actions</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            You can choose to delete or activate/ deactivate this account. Once
            you delete this account, there is no going back.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {userInfo.status === 'Active' && (
            <MDButton
              variant="gradient"
              color="dark"
              size="large"
              onClick={() =>
                promptDeactivate({
                  title: 'Confirm Deactivate Account?',
                  content:
                    'Deactivating an account is a safer alternative than deleting it. You may reactive the account later.',
                  confirmColor: 'black',
                  confirmText: 'deactivate',
                  onConfirm: () => onDeactivate(userInfo)
                })
              }
              style={{ marginLeft: '.5rem' }}>
              deactivate
            </MDButton>
          )}
          {userInfo.status !== 'Active' && (
            <MDButton
              variant="gradient"
              color="success"
              size="large"
              onClick={() =>
                promptActivate({
                  title: 'Confirm Activate Account?',
                  content:
                    'Activating an account will active the account immediately.',
                  confirmText: 'activate',
                  onConfirm: () => onActivate(userInfo)
                })
              }
              style={{ marginLeft: '.5rem' }}>
              Activate
            </MDButton>
          )}
          <MDButton
            variant="gradient"
            color="error"
            size="large"
            onClick={() =>
              promptDelete({
                title: 'Confirm Delete Account?',
                content:
                  'Once you delete your account, there is no going back. Please be certain.',
                confirmColor: 'error',
                confirmText: 'delete',
                onConfirm: () => onDelete(userInfo)
              })
            }
            style={{ marginLeft: '.5rem' }}>
            delete
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DeleteAccount;
