import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import MDBadge from "components/MDBadge";
import { delayResolve } from "utils/delay";

function RolesManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy?.type === '') {
        return;
      }
      const {
        list: { list, pagination },
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListRolesPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            dataFilters,
            ordering: sortBy,
          }),
        }),
        100,
      );
      setData(list);
      setTotalCount(pagination.total);
    } catch (err) {
      console.error("RolesManagement init:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListRolesPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      return list;
    } catch (err) {
      console.error("RolesManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [];

  const tableInfo = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Description",
      id: "description",
    },
    {
      label: "Permissions",
      id: "permissions",
      tableRowMapper: (value) =>
        value
          ? value
              .split(" ")
              .map((item) => (
                <MDBadge
                  style={{ marginLeft: 2.5, marginRight: 2.5 }}
                  size="xs"
                  badgeContent={item}
                  color="light"
                  container
                />
              ))
          : null,
    },
    {
      label: "Created at",
      id: "createdAt",
    },
    {
      label: "Updated at",
      id: "updatedAt",
    },
  ];

  const exportInfo = [];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Manage Roles"
      currentActiveParent="settings"
      currentActiveTab="user-roles"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(RolesManagement);
