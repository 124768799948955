import { displayDate } from 'utils/date';
import dayjs from 'dayjs';

export function importDeviceValidator(data) {
  return data.map((item) => {
    const {
      serialNo,
      deviceModelName,
      mac,
      lotNo,
      productionDate,
      productionFirmware
    } = item || {};
    if (!serialNo?.length) {
      return { ...item, reason: 'Serial Number Missing', valid: false };
    }

    if (!deviceModelName?.length) {
      return { ...item, reason: 'Device Model Missing', valid: false };
    }

    if (!mac?.length) {
      return { ...item, reason: 'Mac Address Missing', valid: false };
    }

    if (!lotNo?.length) {
      return { ...item, reason: 'Lot No Missing', valid: false };
    }

    if (!productionDate) {
      return { ...item, reason: 'Production Date Missing', valid: false };
    }

    if (!dayjs(productionDate).isValid()) {
      return { ...item, reason: 'Invalid Production Date', valid: false };
    }

    if (!productionFirmware?.length) {
      return { ...item, reason: 'Production Firmware Missing', valid: false };
    }

    item.productionDate = displayDate(item.productionDate);
    return { ...item, reason: 'Passed', valid: true };
  });
}
