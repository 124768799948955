export default [
  { label: 'Title', name: 'title' },
  { label: 'Captions', name: 'caption' },
  { label: 'Customer', name: 'userFullName' },
  { label: 'Customer Email', name: 'userEmail' },
  { label: 'Tags', name: 'tags', rowMapper: (value) => value.join(' , ') },
  { label: 'Product Series', name: 'related_series' },
  { label: 'Number of pins', name: 'numOfPins' },
  { label: 'Number of comments', name: 'numOfUserComments' },
  {
    label: 'Image URL',
    name: 'media',
    rowMapper: (value) => value.map((item) => item.link).join(' , ')
  },
  { label: 'Post State', name: 'status' },
  { label: 'Posted on', name: 'createdAt' },
  { label: 'Last Updated', name: 'updatedAt' }
];
