/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

function ReviewInfo({ postReviews }) {
  const [currentReviewsInfo, setCurrentReviewsInfo] = React.useState([]);

  React.useEffect(() => {
    const info = postReviews ?? [];
    setCurrentReviewsInfo(info);
  }, [postReviews]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize">
          Reviews
        </MDTypography>
        {currentReviewsInfo?.map((e, index) => {
          // if (e.status !== "Pending")
          return (
            <MDBox alignItems="center" mb={0.5} key={index}>
              <MDBox mt={0.5}>
                <MDTypography
                  component="p"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  sx={{ marginBottom: '5px' }}>
                  Review Type: <b>{e.type}</b>
                </MDTypography>
                <MDTypography
                  component="p"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                  sx={{ marginBottom: '5px' }}>
                  Review Reason: <b>{e.reason}</b>
                </MDTypography>
                <MDBox mt={0.5} display="flex">
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                    sx={{ marginBottom: '5px' }}>
                    Review Status: <b>{e.status} </b> on{' '}
                    <b>
                      {e?.resolvedAt && displayDate(new Date(e?.resolvedAt))}
                    </b>
                  </MDTypography>
                  <MDTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                    sx={{ marginBottom: '5px' }}>
                    {e?.resolver && (
                      <>
                        &nbsp;by <b>{joinName(e?.resolver)}</b>
                      </>
                    )}
                  </MDTypography>
                </MDBox>
                <MDTypography
                  component="p"
                  variant="button"
                  fontWeight="regular"
                  color="text">
                  Remarks: <b>&nbsp;{e.remarks ? e.remarks : '-'}</b>
                </MDTypography>
                <Divider />
              </MDBox>
            </MDBox>
          );
        })}
      </MDBox>
    </Card>
  );
}

export default ReviewInfo;
