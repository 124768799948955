export function importOrderValidator(data) {
	return data.map((item) => {
		const {
			'Order ID': orderId,
      'Order Status': orderStatus,
      'Order Date': orderDate,
      'Order Time': orderTime,
      'Subtotal (inc tax)': subtotalIncTax,
      'Subtotal (ex tax)': subtotalExTax,
      'Order Total (inc tax)': orderTotalIncTax,
      'Order Total (ex tax)': orderTotalExTax,
      'Payment Method': paymentMethod,
      'Order Currency Code': orderCurrencyCode,
      'Channel Name': channelName,
		} = item || {};

    if (!orderId) {
      return { ...item, reason: 'Order ID is empty', valid: false };
    }
    if (!orderStatus) {
      return { ...item, reason: 'Order Status is empty', valid: false };
    }
    if (!orderDate) {
      return { ...item, reason: 'Order Date is empty', valid: false };
    }
    if (!orderTime) {
      return { ...item, reason: 'Order Time is empty', valid: false };
    }
    if (!subtotalIncTax) {
      return { ...item, reason: 'Subtotal (inc tax) is empty', valid: false };
    }

    if (!subtotalExTax) {
      return { ...item, reason: 'Subtotal (ex tax) is empty', valid: false };
    }
    if (!orderTotalIncTax) {
      return { ...item, reason: 'Order Total (inc tax) is empty', valid: false };
    }
    if (!orderTotalExTax) {
      return { ...item, reason: 'Order Total (ex tax) is empty', valid: false };
    }
    if (!paymentMethod) {
      return { ...item, reason: 'Payment Method is empty', valid: false };
    }
    if (!orderCurrencyCode) {
      return { ...item, reason: 'Order Currency Code is empty', valid: false };
    }
    if (!channelName) {
      return { ...item, reason: 'Channel Name is empty', valid: false };
    }
    return { ...item, reason: 'Passed', valid: true };
	})
}