import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { useNavigate } from 'react-router-dom';

import ModuspaceService from 'services/moduspace.service';
import { delayResolve } from 'utils/delay';

import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import CreateFields from './details/components/BasicInfo/CreateFields';

function PaymentCreate() {
	const [loading, setLoading] = useState(null);
  const [info, setInfo] = useState(null);
  const [orders, setOrders] = useState(null);
  const [paymentModes, setPaymentModes] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const { authentication } = useStores();
  const navigate = useNavigate();

  const fetchFieldOptions = async () => {
    setLoading('Fetching field options...');
    try {
      const { orders: { models }, modes, currencies, statuses } = await delayResolve(
        async () => ({
          orders: await ModuspaceService.GetAllOrders(authentication.jwtToken),
          modes: await ModuspaceService.GetAllPaymentModes(authentication.jwtToken),
          currencies: await ModuspaceService.GetAllCurrencies(authentication.jwtToken),
          statuses: await ModuspaceService.GetAllPaymentStatuses(authentication.jwtToken),
        }),
        100
      );
      // Format data in the required format for the Autocomplete component
      setOrders(models?.map(({ id, referenceOrderId, customerId, billing_address, orderCurrency }) => ({ id, label: `#${referenceOrderId}`, customerId, referenceOrderId, billing_address, orderCurrency })));
      setPaymentModes(modes?.map(({ name }) => ({ id: name, label: name })));
      setStatuses(statuses?.map(({ name }) => ({ id: name, label: name })));
      // Only allow sgd, aud, usd, eur
      setCurrencies(currencies.filter(({ shortName }) => ['SGD', 'AUD', 'USD', 'EUR'].includes(shortName))?.map(({ id, name, shortName }) => ({ id, label: `${name} (${shortName})` })));
    } catch (err) {
      console.error('Error at fetchFieldOptions:', err);
    }
    setLoading(null);
  };

  const createPayment = async (paymentInfo) => {
    setLoading('Submitting');
    try {
      // Remove salesOrders object from the paymentInfo
      delete paymentInfo.salesOrders;
      // Send request
      const info = await delayResolve(() => 
        ModuspaceService.CreateNewPayment(authentication.jwtToken, paymentInfo)
      );
      setInfo(info);
    } catch (err) {
      console.error('Error at createPayment:', err);
      toast.error(err);
    }
    setLoading(null);
  }

  useEffect(() => {
    if (info?.id) {
      navigate(`/dashboard/payments/${info.id}`);
    }
  }, [info]);

  useEffect(() => {
    if (info === null && authentication.jwtToken) {
      fetchFieldOptions();
    }
    // eslint-disable-next-line
  }, [info, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateFields
              info={info}
              orders={orders}
              setOrders={setOrders}
              paymentModes={paymentModes}
              currencies={currencies}
              paymentStatuses={statuses}
              onChangeSubmit={createPayment}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(PaymentCreate);