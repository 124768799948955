/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SignIn from 'pages/authentication/SignIn';
import SignOut from 'pages/authentication/SignOut';
import Profile from 'pages/profile/UserDetails';
import ApplicationKeyManagement from 'pages/application-keys/ApplicationKeyManagement';
import ApplicationKeyCreate from 'pages/application-keys/ApplicationKeyCreate';
import ApplicationKeyDetails from 'pages/application-keys/ApplicationKeyDetails';
import RolesManagement from 'pages/roles/RolesManagement';
import RoleDetails from 'pages/roles/RoleDetails';
import RoleCreate from 'pages/roles/RoleCreate';
import CustomersManagement from 'pages/users/customers/CustomersManagement';
import CustomerCreate from 'pages/users/customers/CustomerCreate';
import CustomerDetails from 'pages/users/customers/CustomerDetails';
import UsersManagement from 'pages/users/admins/UsersManagement';
import UserCreate from 'pages/users/admins/UserCreate';
import UserDetails from 'pages/users/admins/UserDetails';
import Analytics from 'pages/dashboards';
import DeviceModelsManagement from 'pages/products/device-models/DeviceModelsManagement';
import DeviceModelCreate from 'pages/products/device-models/DeviceModelCreate';
import DeviceModelDetails from 'pages/products/device-models/DeviceModelDetails';
import DeviceMasterListManagement from 'pages/products/device-masters/DeviceMasterListManagement';
import SerialNumberManagement from 'pages/products/serial-numbers/SerialNumberManagement';
import SerialNumberCreate from 'pages/products/serial-numbers/SerialNumberCreate';
import SerialNumberDetails from 'pages/products/serial-numbers/SerialNumberDetails';
import DeviceCreate from 'pages/products/device-masters/DeviceCreate';
import DeviceDetails from 'pages/products/device-masters/DeviceDetails';
import PairedDeviceListManagement from 'pages/products/paired-devices/PairedDeviceListManagement';
import PairedDeviceDetail from 'pages/products/paired-devices/PairedDeviceDetail';
import WarrantyPlansManagement from 'pages/warranties/plans/WarrantyPlansManagement';
import WarrantyPlanCreate from 'pages/warranties/plans/WarrantyPlanCreate';
import WarrantyPlanDetails from 'pages/warranties/plans/WarrantyPlanDetails';
import Redirect from './controls/Redirect';
import WarrantyItemsManagement from 'pages/warranties/items/WarrantyItemsManagement';
import WarrantyClaimsManagement from 'pages/warranties/claims/WarrantyClaimsManagement';
import WarrantyItemCreate from 'pages/warranties/items/WarrantyItemCreate';
import WarrantyItemDetails from 'pages/warranties/items/WarrantyItemDetails';
import WarrantyClaimCreate from 'pages/warranties/claims/WarrantyClaimCreate';
import WarrantyClaimDetails from 'pages/warranties/claims/WarrantyClaimDetails';

import PostManagement from 'pages/ideaboard/posts/PostManagement';
import PostDetails from 'pages/ideaboard/posts/PostDetails';
import CommentManagement from 'pages/ideaboard/comments/CommentManagement';
import CommentDetails from 'pages/ideaboard/comments/CommentDetails';

import ReviewPostManagement from 'pages/ideaboard/posts-reviews/ReviewPostManagement';
import ReviewPostDetails from 'pages/ideaboard/posts-reviews/ReviewPostDetails';
import ReviewFlagPostManagement from 'pages/ideaboard/posts-reviews-flag/ReviewFlagPostManagement';
import ReviewFlagPostDetails from 'pages/ideaboard/posts-reviews-flag/ReviewFlagPostDetails';
import ReviewCommentManagement from 'pages/ideaboard/comments-reviews/ReviewCommentManagement';
import ReviewCommentDetails from 'pages/ideaboard/comments-reviews/ReviewCommentDetails';

import ContentManagement from 'pages/content/ContentManagement';
import ContentDetails from 'pages/content/ContentDetails';
import ContentCreate from 'pages/content/ContentCreate';
import TicketManagement from 'pages/tickets/TicketManagement';
import TicketDetails from 'pages/tickets/TicketDetails';
import TicketCreate from 'pages/tickets/TicketCreate';

import OrderManagement from 'pages/orders/OrderManagement';
import OrderDetails from 'pages/orders/OrderDetails';

import CatalogProductManagement from 'pages/catalog/ProductManagement';
import PaymentManagement from 'pages/payments/PaymentManagement';
import PaymentCreate from 'pages/payments/PaymentCreate';
import PaymentDetails from 'pages/payments/PaymentDetails';

import DeliveryOrderManagement from 'pages/delivery-orders/DeliveryOrderManagement';
import DeliveryOrderCreate from 'pages/delivery-orders/DeliveryOrderCreate';
import DeliveryOrderDetails from 'pages/delivery-orders/DeliveryOrderDetails';
import AntiCounterfeitManagement from 'pages/products/anticounterfeit/AntiCounterfeitManagement';

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React pages

const routes = [
  {
    key: '/',
    route: '/',
    component: <Analytics />
  },
  {
    index: true,
    key: '/dashboard',
    route: '/dashboard',
    component: <Analytics />
  },
  {
    key: '/signin',
    route: '/signin',
    public: true,
    component: <SignIn />
  },
  {
    key: '/signout',
    route: '/signout',
    public: true,
    component: <SignOut />
  },
  {
    key: '/profile',
    route: '/profile',
    component: <Profile />
  },
  //customers. flat list
  {
    key: '/dashboard/customers',
    route: '/dashboard/customers',
    component: <CustomersManagement />
  },
  {
    key: '/dashboard/customers/new',
    route: '/dashboard/customers/new',
    component: <CustomerCreate />
  },
  {
    key: '/dashboard/customers/:id',
    route: '/dashboard/customers/:id',
    component: <CustomerDetails />
  },
  // orders
  {
    key: '/dashboard/orders',
    route: '/dashboard/orders',
    component: <OrderManagement />,
  },
  {
    key: '/dashboard/orders/:id',
    route: '/dashboard/orders/:id',
    component: <OrderDetails />
  },
  // payments
  {
    key: '/dashboard/payments',
    route: '/dashboard/payments',
    component: <PaymentManagement />,
  },
  {
    key: '/dashboard/payments/new',
    route: '/dashboard/payments/new',
    component: <PaymentCreate />
  },
  {
    key: '/dashboard/payments/:id',
    route: '/dashboard/payments/:id',
    component: <PaymentDetails />
  },
  //products - models & serial numbers & paired device. collapsable list
  {
    key: '/dashboard/products',
    route: '/dashboard/products',
    component: <Redirect to="/dashboard/products/models" />
  },
  {
    key: '/dashboard/products/models',
    route: '/dashboard/products/models',
    component: <DeviceModelsManagement />
  },
  {
    key: '/dashboard/products/models/new',
    route: '/dashboard/products/models/new',
    component: <DeviceModelCreate />
  },
  {
    key: '/dashboard/products/models/:id',
    route: '/dashboard/products/models/:id',
    component: <DeviceModelDetails />
  },
  {
    key: '/dashboard/products/moducase',
    route: '/dashboard/products/moducase',
    component: <SerialNumberManagement />
  },
  {
    key: '/dashboard/products/moducase/new',
    route: '/dashboard/products/moducase/new',
    component: <SerialNumberCreate />
  },
  {
    key: '/dashboard/products/moducase/:serial_no',
    route: '/dashboard/products/moducase/:serial_no',
    component: <SerialNumberDetails />
  },
  {
    key: '/dashboard/products/serial-numbers',
    route: '/dashboard/products/serial-numbers',
    component: <DeviceMasterListManagement />
  },
  {
    key: '/dashboard/products/serial-numbers/new',
    route: '/dashboard/products/serial-numbers/new',
    component: <DeviceCreate />
  },
  {
    key: '/dashboard/products/serial-numbers/:serial_no',
    route: '/dashboard/products/serial-numbers/:serial_no',
    component: <DeviceDetails />
  },
  {
    key: '/dashboard/products/paired-devices',
    route: '/dashboard/products/paired-devices',
    component: <PairedDeviceListManagement />
  },
  {
    key: '/dashboard/products/paired-devices/:id',
    route: '/dashboard/products/paired-devices/:id',
    component: <PairedDeviceDetail />
  },
  {
    key: '/dashboard/products/anti-counterfeit',
    route: '/dashboard/products/anti-counterfeit',
    component: <AntiCounterfeitManagement />,
  },
  //warranties - plans & registered & claims. collapsable list
  {
    key: '/dashboard/warranties',
    route: '/dashboard/warranties',
    component: <Redirect to="/dashboard/warranties/plans" />
  },
  {
    key: '/dashboard/warranties/plans',
    route: '/dashboard/warranties/plans',
    component: <WarrantyPlansManagement />
  },
  {
    key: '/dashboard/warranties/plans/new',
    route: '/dashboard/warranties/plans/new',
    component: <WarrantyPlanCreate />
  },
  {
    key: '/dashboard/warranties/plans/:id',
    route: '/dashboard/warranties/plans/:id',
    component: <WarrantyPlanDetails />
  },
  {
    key: '/dashboard/warranties/registered',
    route: '/dashboard/warranties/registered',
    component: <WarrantyItemsManagement />
  },
  {
    key: '/dashboard/warranties/registered/new',
    route: '/dashboard/warranties/registered/new',
    component: <WarrantyItemCreate />
  },
  {
    key: '/dashboard/warranties/registered/:id',
    route: '/dashboard/warranties/registered/:id',
    component: <WarrantyItemDetails />
  },
  {
    key: '/dashboard/warranties/claims',
    route: '/dashboard/warranties/claims',
    component: <WarrantyClaimsManagement />
  },
  {
    key: '/dashboard/warranties/claims/new',
    route: '/dashboard/warranties/claims/new',
    component: <WarrantyClaimCreate />
  },
  {
    key: '/dashboard/warranties/claims/:id',
    route: '/dashboard/warranties/claims/:id',
    component: <WarrantyClaimDetails />
  },
  //ideaboards - post & review post & review flag post & comment & review comment
  {
    key: '/dashboard/ideaboard',
    route: '/dashboard/ideaboard',
    component: <Redirect to="/dashboard/ideaboard/posts" />
  },
  {
    key: '/dashboard/ideaboard/posts',
    route: '/dashboard/ideaboard/posts',
    component: <PostManagement />
  },
  {
    key: '/dashboard/ideaboard/posts/:id',
    route: '/dashboard/ideaboard/posts/:id',
    component: <PostDetails />
  },
  {
    key: '/dashboard/ideaboard/review/posts',
    route: '/dashboard/ideaboard/review/posts',
    component: <ReviewPostManagement />
  },
  {
    key: '/dashboard/ideaboard/review/posts/:id',
    route: '/dashboard/ideaboard/review/posts/:id',
    component: <ReviewPostDetails />
  },
  {
    key: '/dashboard/ideaboard/review-flag/posts',
    route: '/dashboard/ideaboard/review-flag/posts',
    component: <ReviewFlagPostManagement />
  },
  {
    key: '/dashboard/ideaboard/review-flag/posts/:id',
    route: '/dashboard/ideaboard/review-flag/posts/:id',
    component: <ReviewFlagPostDetails />
  },
  {
    key: '/dashboard/ideaboard/comments',
    route: '/dashboard/ideaboard/comments',
    component: <CommentManagement />
  },
  {
    key: '/dashboard/ideaboard/comments/:id',
    route: '/dashboard/ideaboard/comments/:id',
    component: <CommentDetails />
  },
  {
    key: '/dashboard/ideaboard/review/comments',
    route: '/dashboard/ideaboard/review/comments',
    component: <ReviewCommentManagement />
  },
  {
    key: '/dashboard/ideaboard/review/comments/:id',
    route: '/dashboard/ideaboard/review/comments/:id',
    component: <ReviewCommentDetails />
  },
  //Ccontent Management - News, flat list

  {
    key: '/dashboard/content/new',
    route: '/dashboard/content/new',
    component: <ContentCreate />
  },
  {
    key: '/dashboard/content/:id',
    route: '/dashboard/content/:id',
    component: <ContentDetails />
  },
  {
    key: '/dashboard/content/',
    route: '/dashboard/content/',
    component: <ContentManagement />
  },
  //support center - ticketing, collapsable list
  {
    key: '/dashboard/support-center',
    route: '/dashboard/support-center',
    component: <Redirect to="/dashboard/support-center/tickets" />
  },
  {
    key: '/dashboard/support-center/tickets',
    route: '/dashboard/support-center/tickets',
    component: <TicketManagement Navkey={`tickets`} />
  },
  {
    key: '/dashboard/support-center/pending-tickets',
    route: '/dashboard/support-center/pending-tickets',
    component: <TicketManagement Navkey={`pending-tickets`} />
  },
  {
    key: '/dashboard/support-center/tickets/new',
    route: '/dashboard/support-center/tickets/new',
    component: <TicketCreate />
  },
  {
    key: '/dashboard/support-center/tickets/:id',
    route: '/dashboard/support-center/tickets/:id',
    component: <TicketDetails />
  },
  // {
  //   key: "/dashboard/support-center/pending-tickets/:id",
  //   route: "/dashboard/support-center/pending-tickets/:id",
  //   component: <TicketDetails />,
  // },
  //settings - roles, admin users & api keys. collapsable list
  {
    key: '/dashboard/settings/admins',
    route: '/dashboard/settings/admins',
    component: <UsersManagement />
  },
  {
    key: '/dashboard/settings/admins/new',
    route: '/dashboard/settings/admins/new',
    component: <UserCreate />
  },
  {
    key: '/dashboard/settings/admins/:id',
    route: '/dashboard/settings/admins/:id',
    component: <UserDetails />
  },
  {
    key: '/dashboard/settings/roles',
    route: '/dashboard/settings/roles',
    component: <RolesManagement />
  },
  {
    key: '/dashboard/settings/roles/new',
    route: '/dashboard/settings/roles/new',
    component: <RoleCreate />
  },

  {
    key: '/dashboard/settings/roles/:id',
    route: '/dashboard/settings/roles/:id',
    component: <RoleDetails />
  },
  {
    key: '/dashboard/settings/application-keys',
    route: '/dashboard/settings/application-keys',
    component: <ApplicationKeyManagement />
  },
  {
    key: '/dashboard/settings/application-keys/new',
    route: '/dashboard/settings/application-keys/new',
    component: <ApplicationKeyCreate />
  },
  {
    key: '/dashboard/settings/application-keys/:key',
    route: '/dashboard/settings/application-keys/:key',
    component: <ApplicationKeyDetails />
  },
  {
    key: '/dashboard/catalog/products',
    route: '/dashboard/catalog/products',
    component: <CatalogProductManagement />
  },
  {
    key: '/dashboard/delivery-orders',
    route: '/dashboard/delivery-orders',
    component: <DeliveryOrderManagement />
  },
  {
    key: '/dashboard/delivery-orders/new',
    route: '/dashboard/delivery-orders/new',
    component: <DeliveryOrderCreate />
  },
  {
    key: '/dashboard/delivery-orders/:id',
    route: '/dashboard/delivery-orders/:id',
    component: <DeliveryOrderDetails />
  },
];

export default routes;
