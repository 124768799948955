import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid, Icon, MenuItem, Card } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDLoading from "components/MDLoading";
import { useConfirmModal } from "components/MDConfirm";
import ModuspaceService from "services/moduspace.service";
import Header from "./components/Header";
import GeneralTable from "controls/Tables/GeneralTable";
import TabModal from "./components/TabModal";
import { DropdownMenuStyleOne } from "style/DropdownMenu";
import { displayDate, displayDateTime } from "utils/date";
import { joinName } from "utils/join-name";

const defaultPagination = {
  currentPage: 1,
  pageSize: 10,
  total: 0,
};

const AdditionalInfo = ({
  authentication = {},
  userInfo = {},
  countries = [],
}) => {
  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: "Confirm Delete Address?",
    content:
      "Delete address won't be able to reverse again, please cofirm again.",
    confirmColor: "warning",
    confirmText: "delete",
  });

  const userId = userInfo?.id || null;
  const [tabValue, setTabValue] = useState(0);
  const [_data, setData] = useState([]);
  const [tabModal, setTabModal] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isRefreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState("");

  const [tablePagination, setTablePagination] = useState(defaultPagination);

  const { jwtToken } = authentication || {};

  const allowCreatedTab = [0];

  useEffect(() => {
    if (userId) fetchingTabInfo();

    // unmount
    return () => setData([]);
  }, [userInfo, userId, tabValue]);

  const fetchingTabInfo = async () => {
    const filteredByUser = [{ id: "userId", value: userId }];
    const filteredByRequester = [{ id: "requestedBy", value: userId }];
    let fetchedData = [];
    let newTablePagination = tablePagination;

    setRefreshing(true);
    if (tabValue === 0) {
      fetchedData = await ModuspaceService.GetCustomerUserAdressesByUserId(
        jwtToken,
        userId,
      );
      newTablePagination = {
        ...newTablePagination,
        total: fetchedData.length,
      };
    } else {
      const { list, pagination } =
        tabValue === 1
          ? await ModuspaceService.ListAllPairedDevices({
              pageSize: Number.MAX_SAFE_INTEGER,
              currentPage: 1,
              filtered: filteredByUser,
            })
          : tabValue === 2
          ? await ModuspaceService.ListTicketsPaginate({
              pageSize: Number.MAX_SAFE_INTEGER,
              currentPage: 1,
              filtered: filteredByRequester,
            })
          : tabValue === 3
          ? await ModuspaceService.ListAllIdeaboardPosts({
              pageSize: Number.MAX_SAFE_INTEGER,
              currentPage: 1,
              filtered: filteredByUser,
            })
          : tabValue === 4
          ? await ModuspaceService.ListAllIdeaboardPostComments({
              pageSize: Number.MAX_SAFE_INTEGER,
              currentPage: 1,
              filtered: filteredByUser,
            })
          : null;
      fetchedData = list;
      newTablePagination = pagination;
    }

    fetchedData =
      fetchedData?.map((item) => {
        if (tabValue === 0) {
          let {
            id,
            phone,
            addressLine1,
            addressLine2,
            city,
            postalCode,
            state,
            country,
          } = item;
          phone = phone || "-";
          const fullAddress = `${addressLine1}  ${
            addressLine2 || ""
          }\n ${state}, ${city} ${postalCode}\n ${country?.name}`;
          return { id, rowData: item, phone, fullAddress };
        }
        if (tabValue === 1) {
          const {
            id,
            name,
            device: { serialNo, mac, warranties },
            lastPairedAt,
          } = item;
          const warrantyDesc = warranties.map(
            ({ warrantyPlan: { name }, endDate }) =>
              `${name} will be expired at ${displayDate(endDate)}`,
          );
          return { id, name, serialNo, mac, warrantyDesc, lastPairedAt };
        }
        if (tabValue === 2) {
          const { id, product, issue, status, createdAt } = item;
          return { id, product, issue, status, createdAt };
        } else if (tabValue === 3) {
          const { id, title, caption, userImpressions, createdAt } = item;
          const numOfPins =
            userImpressions?.filter((item) => item.pin).length ?? 0;
          return { id, title, caption, numOfPins, createdAt };
        } else if (tabValue === 4) {
          const {
            id,
            text,
            userPost: { title },
            createdAt,
          } = item;
          return { id, text, postTitle: title, createdAt };
        } else {
          return {};
        }
      }) || [];

    setData(fetchedData);
    setTablePagination(newTablePagination);
    setRefreshing(false);
  };

  const getStatesByCountryId = async (countryId) => {
    const states = await ModuspaceService.GetStatesByCountryId(
      jwtToken,
      countryId,
    );
    return states?.map((item) => ({ label: item.name, id: item.name })) ?? [];
  };

  const modelButton = (rowData) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePromptUpdate = () => {
      setAnchorEl(null);
      openModal(false, rowData);
    };

    const handlePromptDelete = () => {
      setAnchorEl(null);
      prompDeleteConfirm(rowData.id);
    };

    return (
      <div>
        <MDButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </MDButton>
        <DropdownMenuStyleOne
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handlePromptUpdate} disableRipple>
            <EditIcon /> Edit
          </MenuItem>
          <MenuItem onClick={handlePromptDelete} disableRipple>
            <DeleteIcon /> Delete
          </MenuItem>
        </DropdownMenuStyleOne>
      </div>
    );
  };

  const routeButton = (link) => {
    return (
      <MDButton
        onClick={() => window.open(link, "_blank")}
        size="small"
        color="info"
        style={{ minWidth: "unset" }}>
        <Icon>visibility_outlined</Icon>
      </MDButton>
    );
  };

  const openModal = (isCreateMode = true, rowData) => {
    if (!isCreateMode) setRowData(rowData);

    setCreateMode(isCreateMode);
    setTabModal(true);
  };

  const prompDeleteConfirm = (id) => {
    promptDelete({
      title: "Confirm Delete Address?",
      content:
        "Delete address won't be able to reverse again, please cofirm again.",
      onConfirm: async () => await onDeleteAddress(id),
    });
  };

  const onCreateAddress = async (payload) => {
    try {
      setLoading("Submitting...");
      const response = await ModuspaceService.AddCustomerUserAddress(
        jwtToken,
        userId,
        { userId, ...payload },
      );
      if (response) {
        setTabModal(false);
        fetchingTabInfo();
        toast.success("Your address info has been created successfully.");
      }
    } catch (err) {
      console.error("Error at onCreateAddress:", err);
      toast.error("Fail to create customer address.");
    }
    setLoading(null);
  };

  const onUpdateAddress = async (id, payload) => {
    try {
      setLoading("Submitting...");
      const response = await ModuspaceService.UpdateCustomerUserAddressById(
        jwtToken,
        userId,
        id,
        { userId, ...payload },
      );
      if (response) {
        setTabModal(false);
        fetchingTabInfo();
        toast.success("Your address info has been updated successfully.");
      }
    } catch (err) {
      console.error("Error at onUpdateAddress:", err);
      toast.error("Fail to updated customer address.");
    }
    setLoading(null);
  };

  const onDeleteAddress = async (id) => {
    try {
      setLoading("Submitting...");
      const response = await ModuspaceService.DeleteCustomerUserAddressById(
        jwtToken,
        userId,
        id,
      );
      if (response) {
        setTabModal(false);
        fetchingTabInfo();
        toast.success("Your address info has been deleted successfully.");
      }
    } catch (err) {
      console.error("Error at onDeleteAddress:", err);
      toast.error("Fail to delete customer address.");
    }
    setLoading(null);
  };

  const tableInfo = [
    [
      // User Address
      {
        label: "Action",
        name: "rowData",
        render: (obj, record) => {
          return modelButton(obj);
        },
      },
      {
        label: "Full Name",
        name: "rowData",
        render: (obj, record) => {
          return joinName(obj);
        },
      },
      { label: "Phone", name: "phone" },
      { label: "Address", name: "fullAddress", align: "left" },
    ],
    [
      // Device
      {
        label: "Action",
        name: "id",
        render: (obj) =>
          routeButton(`/dashboard/products/paired-devices/${obj}`),
      },
      { label: "Device Name", name: "name" },
      { label: "Serial Number", name: "serialNo" },
      { label: "Mac Address", name: "mac" },
      {
        label: "Warranty",
        name: "warrantyDesc",
        render: (value) =>
          value
            ? value.map((item) => (
                <span style={{ display: "block" }}>{item}</span>
              ))
            : null,
      },
      {
        label: "Last Paired At",
        name: "lastPairedAt",
        render: (value) => displayDate(value),
        align: "left",
      },
    ],
    [
      // Ticket
      {
        label: "Action",
        name: "id",
        render: (obj) =>
          routeButton(`/dashboard/support-center/tickets/${obj}`),
      },
      { label: "Product", name: "product" },
      { label: "Issue", name: "issue" },
      { label: "Status", name: "status" },
      {
        label: "Date Created",
        name: "createdAt",
        render: (value) => displayDate(value),
        align: "left",
      },
    ],
    [
      // Post
      {
        label: "Action",
        name: "id",
        render: (obj) => routeButton(`/dashboard/ideaboard/posts/${obj}`),
      },
      {
        label: "Title",
        name: "title",
        render: (value) =>
          value?.length > 50 ? `${value.substring(0, 50)}...` : value,
      },
      {
        label: "Caption",
        name: "caption",
        render: (value) =>
          value?.length > 50 ? `${value.substring(0, 50)}...` : value,
      },
      { label: "Pins", name: "numOfPins" },
      {
        label: "Date Created",
        name: "createdAt",
        render: (value) => displayDate(value),
        align: "left",
      },
    ],
    [
      // Comment
      {
        label: "Action",
        name: "id",
        render: (value) =>
          routeButton(`/dashboard/ideaboard/comments/${value}`),
      },
      { label: "Title", name: "postTitle" },
      { label: "Comment", name: "text" },
      {
        label: "Date Commented",
        name: "createdAt",
        render: (value) => displayDateTime(value),
        align: "left",
      },
    ],
    [
      // Order
      // { label: "Device Name", id: "name" },
      // { label: "Serial Number", id: "serialNo" },
      // { label: "Mac Address", id: "mac" },
      // { label: "Warranty", id: "warranry" },
      // { label: "Last Paired At", id: "lastPairedAt" }
    ],
  ];

  return (
    <Card id="additional-info" sx={{ overflow: "visible", mt: 2 }}>
      <MDConfirmDelete />
      <MDLoading show={!!loading} text={loading} />
      <MDBox p={3}>
        <Header
          tabValue={tabValue}
          onChange={(_, v) => {
            setTabValue(v);
            setData([]);
          }}>
          <Grid container justifyContent="flex-end" spacing={{ xs: 1 }}>
            <Grid item my={1}>
              <MDButton
                style={{ minWidth: "unset" }}
                size="small"
                disabled={isRefreshing}
                onClick={fetchingTabInfo}
                color="secondary"
                variant="outlined">
                <Icon fontSize="large">refresh</Icon>
              </MDButton>
            </Grid>
            {allowCreatedTab.includes(tabValue) && (
              <Grid item my={1}>
                <MDButton
                  style={{ minWidth: "unset" }}
                  size="small"
                  disabled={isRefreshing}
                  onClick={() => openModal(true)}
                  color="secondary">
                  <Icon fontSize="large">add</Icon>
                </MDButton>
              </Grid>
            )}
          </Grid>
          {isRefreshing ? (
            <MDTypography
              variant="subtitle2"
              color="secondary"
              style={{ textAlign: "center", margin: "3rem 0" }}>
              Fetching Data...
            </MDTypography>
          ) : _data.length > 0 ? (
            <>
              <GeneralTable
                headColumn={tableInfo[tabValue] ?? []}
                bodyColumn={_data || []}
                pagination={tablePagination}
                onTableChange={(newPagination) =>
                  setTablePagination(newPagination)
                }
              />
            </>
          ) : (
            <MDTypography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 400,
              }}>
              No Data Found
            </MDTypography>
          )}
        </Header>
      </MDBox>

      {tabModal && (
        <TabModal
          open={tabModal}
          onClose={() => setTabModal(false)}
          onCreate={onCreateAddress}
          onUpdate={onUpdateAddress}
          onDelete={onDeleteAddress}
          currentInfo={rowData}
          countries={countries}
          createMode={createMode}
          getStatesByCountryId={getStatesByCountryId}
        />
      )}
    </Card>
  );
};

export default AdditionalInfo;
