import { useEffect, useState } from 'react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import { Link } from 'react-router-dom';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormField from 'layouts/components/FormField';
import MDDatePickerFullWidth from 'components/MDDatePickerFullWidth';

const OrderDetails = ({ order }) => {
  const { authentication, pageControl } = useStores();
  const [currency, setCurrency] = useState(null);

  // Get currency when order is loaded
  useEffect(() => {
    const fetchCurrency = async () => {
      const currencies = await ModuspaceService.GetAllCurrencies(authentication.jwtToken);
      setCurrency(currencies.find((c) => c.id === order.orderCurrencyId));
    };
    if (order) {
      fetchCurrency();
    }
  }, [order]);

  return (
    <Card style={{ marginTop: 20 }}>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="h5" gutterBottom>
            Order Details
          </MDTypography>
          <Link to={`/dashboard/orders/${order?.id}`} className="custom-link" target="_blank">
            <MDTypography variant="h6" color="info">View Order</MDTypography></Link>
        </MDBox>
        <Grid container spacing={3} my={1}>  
          {/* Order status */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Order Status"
              InputProps={{
                disabled: true,
              }}
              value={order?.orderStatus || ''}
            />
          </Grid>

          {/* Order date */}
          <Grid item xs={12} sm={6}>
              <MDDatePickerFullWidth
                input={{
                  disabled: true,
                }}
                options={{
                  dateFormat: "d-m-Y",
                }}
                label="Order Date"
                value={
                  order?.orderDate
                    ? new Date(order.orderDate)
                    : new Date()
                }
                onChange={() => {}}
              />
            </Grid>

            {/* Payment method */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Payment Method"
                value={order?.paymentMethod || ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>

            {/* Currency */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Order Currency"
                value={currency ? `${currency?.name} (${currency?.shortName})` : ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>

            {/* Order subtotal inc. tax */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Subtotal (inc tax)"
                value={order?.subtotalIncTax || ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>

            {/* Order subtotal ex. tax */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Subtotal (ex tax)"
                value={order?.subtotalExTax || ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>

            {/* Handling cost inc. tax */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Handling Cost (inc tax)"
                value={order?.handlingCostIncTax || ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>

            {/* Handling cost ex. tax */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Handling Cost (ex tax)"
                value={order?.handlingCostExTax || ''}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default OrderDetails;