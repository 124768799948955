/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import CoverImages from "pages/content/components/CoverImages";
// Settings page components
import FormField from "layouts/components/FormField";

// Data
import { toast } from "react-toastify";
import { removeElement } from "utils/validation";
import { dateTimeToLocal } from "utils/date";
import { transformHyphenLowercase } from "utils/transform";

function BasicInfo({
  contentInfo,
  onChangeSubmit = () => void 0,
  onChange = () => void 0,
  createMode = false,
  types = [],
  statuses = [],
  clients = [],
  onSubmitRef,
}) {
  const clientSelection =
    clients?.map((item) => ({ label: item.label, id: item.name })) ?? [];
  const [currentInfo, setCurrentInfo] = useState(
    contentInfo ?? { content: "", displayAt: new Date() },
  );
  const [editorValue, setEditorValue] = useState("");
  const [files, setFiles] = useState([]);
  const isDataAndSelectionExisted =
    currentInfo?.sites?.length && clientSelection?.length;

  React.useEffect(() => {
    // console.log(editorValue);
    setCurrentInfo({ ...currentInfo, content: editorValue });
  }, [editorValue]);

  React.useEffect(() => {
    // console.log("useEffect[currentInfo]", currentInfo);
    onChange ?? onChange(currentInfo);
    // eslint-disable-next-line
  }, [currentInfo]);

  React.useEffect(() => {
    if (contentInfo) {
      // console.log("useEffect[contentInfo]", contentInfo);
      const { displayAt, ...rest } = contentInfo;
      setCurrentInfo({
        displayAt: dateTimeToLocal(displayAt),
        ...rest,
      });
    }
    // eslint-disable-next-line
  }, [contentInfo]);

  const validateFormData = () => {
    const newContentInfo = BasicInfo.fillEmptyRequiredFields(currentInfo);
    const isNewInfoValid = BasicInfo.areRequireFieldsValid(newContentInfo);

    setCurrentInfo(newContentInfo);
    if (!isNewInfoValid) {
      toast.error("Fill in the required basic info first!");
      return {
        error: true,
      };
    }

    if (createMode && !files.length && currentInfo?.type !== "Feature") {
      toast.error("Media (cover photos) is required.");
      return {
        error: true,
      };
    }

    if (
      !createMode &&
      !currentInfo?.media.length &&
      !files.length &&
      currentInfo?.type !== "Feature"
    ) {
      toast.error("Media (cover photos) is required.");
      return {
        error: true,
      };
    }

    return {
      error: false,
      info: { ...newContentInfo, files },
    };
  };

  if (onSubmitRef) {
    onSubmitRef.current = {
      onSubmitListener: validateFormData,
    };
  }

  const handleQuillChange = (delta) => {
    setCurrentInfo({
      ...currentInfo,
      content: delta,
    });
  };

  const handleOnImageChange = (uploads) => {
    // console.log("handleOnImageChange: ",files);
    setFiles((files) => [...uploads]);
    if (!createMode) setCurrentInfo({ ...currentInfo, media: uploads });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //request token and upload to s3 temp

    if (
      BasicInfo.isSelectFieldInvalid(currentInfo.sites) ||
      BasicInfo.isSelectFieldInvalid(currentInfo.tags ?? []) ||
      BasicInfo.isSelectFieldInvalid(currentInfo.status) ||
      BasicInfo.isSelectFieldInvalid(currentInfo.type) ||
      BasicInfo.isTextInputFieldInvalid(currentInfo.displayAt) ||
      BasicInfo.isDeltaFieldEmpty(currentInfo.content) ||
      (currentInfo.type?.toLowerCase() !== "article" &&
        BasicInfo.isTextInputFieldInvalid(currentInfo.link))
    ) {
      toast.error("Some fields are missing. Please check all fields are valid");
      return;
    }
    onChangeSubmit();
  };

  const nonEmptyProps = (field) => ({
    error: BasicInfo.isTextInputFieldInvalid(field),
    helperText: BasicInfo.isTextInputFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Content Details</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Title"
              placeholder="Some title"
              value={currentInfo.title ?? ""}
              onChange={({ target: { value: title } }) =>
                setCurrentInfo({
                  ...currentInfo,
                  title,
                  metaTitle: title,
                })
              }
              onBlur={({ target: { value: title } }) => {
                const newUrl = `/articles/${transformHyphenLowercase(title)}/`;
                setCurrentInfo({
                  ...currentInfo,
                  url: newUrl,
                  slugs: transformHyphenLowercase(title),
                });
              }}
              {...nonEmptyProps(currentInfo.title)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={currentInfo.type ?? null}
              options={types ?? []}
              disableClearable={!createMode}
              freeSolo={!createMode}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Type"
                    {...(!createMode && {
                      inputProps: { disabled: true, value: currentInfo.type },
                    })}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Type"
                    error={BasicInfo.isSelectFieldInvalid(currentInfo.type)}
                    helperText={
                      BasicInfo.isSelectFieldInvalid(currentInfo.type) ? (
                        <MDTypography variant="caption" color="error">
                          You must pick at least one type.
                        </MDTypography>
                      ) : (
                        " "
                      )
                    }
                    required
                  />
                );
              }}
              isOptionEqualToValue={(a, b) => a === b}
              onChange={(_, selectedType) => {
                // console.log(currentInfo, type)
                setCurrentInfo({
                  ...currentInfo,
                  type: selectedType,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDDatePickerFullWidth
              options={{
                dateFormat: "d-m-Y",
              }}
              label="Published Date *"
              value={currentInfo.displayAt ?? ""}
              onChange={(date) =>
                setCurrentInfo({
                  ...currentInfo,
                  displayAt: dateTimeToLocal(date[0]),
                })
              }
              disableClearable
            />
            {BasicInfo.isTextInputFieldInvalid(currentInfo.displayAt) ? (
              <MDTypography variant="caption" color="error">
                You must pick display date.
              </MDTypography>
            ) : (
              " "
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={currentInfo.status ?? null}
              options={statuses ?? []}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Status"
                    error={BasicInfo.isSelectFieldInvalid(currentInfo.status)}
                    helperText={
                      BasicInfo.isSelectFieldInvalid(currentInfo.status) ? (
                        <MDTypography variant="caption" color="error">
                          You must pick at least one status.
                        </MDTypography>
                      ) : (
                        " "
                      )
                    }
                    required
                  />
                );
              }}
              isOptionEqualToValue={(a, b) => a === b}
              onChange={(_, selectedStatus) => {
                // console.log(currentInfo, type)
                setCurrentInfo({
                  ...currentInfo,
                  status: selectedStatus,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label="Link"
              placeholder="Some link"
              value={currentInfo.link ?? ""}
              onChange={({ target: { value: link } }) =>
                setCurrentInfo({
                  ...currentInfo,
                  link,
                })
              }
              {...(currentInfo.type?.toLowerCase() !== "article" &&
                nonEmptyProps(currentInfo.link))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              freeSolo
              getOptionLabel={(option) => option}
              value={currentInfo?.tags ?? []}
              options={[
                "news",
                "articles",
                "announcements",
                ...(currentInfo?.tags ?? []),
              ]}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Tags"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Tags"
                    error={BasicInfo.isSelectFieldInvalid(
                      currentInfo.tags ?? [],
                    )}
                    helperText={
                      BasicInfo.isSelectFieldInvalid(currentInfo.tags ?? []) ? (
                        <MDTypography variant="caption" color="error">
                          Please specify at least one tag.
                        </MDTypography>
                      ) : (
                        " "
                      )
                    }
                    required
                  />
                );
              }}
              isOptionEqualToValue={(a, b) => a === b}
              onChange={(_, selectedTags) => {
                setCurrentInfo({
                  ...currentInfo,
                  tags: selectedTags ?? [],
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              value={
                isDataAndSelectionExisted
                  ? currentInfo?.sites?.map((item) =>
                      clientSelection?.find((client) => item === client.id),
                    )
                  : []
              }
              options={clientSelection ?? []}
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Sites"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Sites"
                    error={BasicInfo.isSelectFieldInvalid(currentInfo.sites)}
                    helperText={
                      BasicInfo.isSelectFieldInvalid(currentInfo.sites) ? (
                        <MDTypography variant="caption" color="error">
                          You must pick at least one site.
                        </MDTypography>
                      ) : (
                        " "
                      )
                    }
                    required
                  />
                );
              }}
              isOptionEqualToValue={(a, b) => a === b}
              onChange={(_, selectedClients) => {
                // console.log(currentInfo, type)
                setCurrentInfo({
                  ...currentInfo,
                  sites: selectedClients.map((item) => item.id),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label="Meta Title"
              placeholder="Meta Title"
              value={currentInfo.metaTitle}
              onChange={({ target: { value: metaTitle } }) =>
                setCurrentInfo({
                  ...currentInfo,
                  metaTitle,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              label="Slug"
              placeholder="Enter slug"
              value={currentInfo.slugs ?? ""}
              onChange={({ target: { value: slugs } }) =>
                setCurrentInfo({
                  ...currentInfo,
                  slugs,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              multiple
              freeSolo
              getOptionLabel={(option) => option}
              value={
                currentInfo?.metaKeywords?.length > 0
                  ? currentInfo?.metaKeywords?.split(",")
                  : []
              }
              options={
                currentInfo?.metaKeywords?.length > 0
                  ? currentInfo?.metaKeywords?.split(",")
                  : []
              }
              renderInput={(params) => {
                return (
                  <FormField
                    {...params}
                    label="Meta Keywords"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Meta Keywords"
                  />
                );
              }}
              isOptionEqualToValue={(a, b) => a === b}
              onChange={(_, selectedKeywords) => {
                setCurrentInfo({
                  ...currentInfo,
                  metaKeywords: selectedKeywords.join(","),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Meta Description"
              placeholder="Meta Description"
              value={currentInfo.metaDescription}
              onChange={({ target: { value: metaDescription } }) =>
                setCurrentInfo({
                  ...currentInfo,
                  metaDescription,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text">
                Content&nbsp;*&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text">
                  (Required)
                </MDTypography>
              </MDTypography>
            </MDBox>
            {createMode && (
              <MDEditor
                required
                value={editorValue}
                onChange={setEditorValue}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={[
                  "header",
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                  "video",
                ]}
              />
            )}
            {!createMode && (
              <MDEditor
                required
                value={currentInfo?.content || ""}
                onChange={currentInfo?.content && handleQuillChange}
                // onChange={(content, delta, source, editor) => handleQuillChange(delta, editor)}
                modules={{
                  toolbar: [
                    [
                      { header: "1" },
                      { header: "2" },
                      { header: "3" },
                      { font: [] },
                    ],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                }}
                formats={[
                  "header",
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                  "video",
                ]}
              />
            )}
            {BasicInfo.isDeltaFieldEmpty(currentInfo.content) ? (
              <MDTypography variant="caption" color="error">
                This field is required.
              </MDTypography>
            ) : (
              " "
            )}
          </Grid>
          {currentInfo?.type !== "Feature" && (
            <Grid item xs={12} md={12}>
              <CoverImages
                onChange={handleOnImageChange}
                createMode={createMode}
                media={currentInfo?.media || []}
              />
            </Grid>
          )}
        </Grid>

        {!createMode && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap">
            <MDBox mt={3} ml="auto">
              <MDButton
                variant="gradient"
                color="dark"
                size="large"
                onClick={handleSubmit}>
                update Content
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {{
 * title: "",
 * type: "",
 * status: "",
 * sites: string[],
 * link: "",
 * media: IMedia[] | null,
 * content: "" | null
 * }} userInfo
 */
BasicInfo.fillEmptyRequiredFields = (contentInfo) => {
  return {
    title: "",
    type: [],
    status: [],
    sites: [],
    link: "",
    media: [],
    content: "",
    displayAt: "",
    ...contentInfo,
  };
};

/**
 *
 * @param {string | undefined} field
 * @returns
 */
BasicInfo.isTextInputFieldInvalid = (field) =>
  field !== undefined && typeof field === "string" && field.length === 0;

BasicInfo.isSelectFieldInvalid = (field) =>
  field !== undefined && field instanceof Array && field.length === 0;

BasicInfo.isDeltaFieldEmpty = (field) => removeElement(field).length === 0;
/**
 *
 * @param {{ sites?: string[] }} currentContentInfo
 * @returns
 */

// /**
//  *
//  * @param {{ sites?: string[] }} currentContentInfo
//  * @returns
//  */
// BasicInfo.isMediaInvalid = (currentContentInfo) => currentContentInfo.media instanceof Array && currentContentInfo.media.length === 0;

/**
 * Get clean value from `BasicInfo.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  title: "",
 * }} info
 */
BasicInfo.areRequireFieldsValid = (contentInfo) => {
  if (BasicInfo.isTextInputFieldInvalid(contentInfo.title)) return false;
  if (BasicInfo.isSelectFieldInvalid(contentInfo.type)) return false;
  if (BasicInfo.isSelectFieldInvalid(contentInfo.status)) return false;
  if (BasicInfo.isTextInputFieldInvalid(contentInfo.link)) return false;
  if (BasicInfo.isSelectFieldInvalid(contentInfo.sites)) return false;
  if (BasicInfo.isTextInputFieldInvalid(contentInfo.content)) return false;
  if (BasicInfo.isTextInputFieldInvalid(contentInfo.displayAt)) return false;
  if (BasicInfo.isDeltaFieldEmpty(contentInfo.content)) return false;
  // if (BasicInfo.isMediaInvalid(contentInfo)) return false;

  return true;
};

export default BasicInfo;
