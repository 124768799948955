/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

function CreateContent({ onSubmit = () => void 0 }) {
  return (
    <Card id="create-account">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Create Content</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            Once you create your content, the content will need to be publish to
            activate immediately.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          <MDButton
            m={1}
            variant="gradient"
            color="success"
            onClick={() => onSubmit()}
            size="large">
            create content
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CreateContent;
