/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function TimelineList({ title, dark, children }) {
  return (
    <Card>
      <MDBox
        bgColor={dark ? 'dark' : 'white'}
        variant="gradient"
        borderRadius="xl"
        sx={{ background: ({ palette: { background } }) => background.card }}>
        <MDBox pt={3} px={3}>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            color={dark ? 'white' : 'dark'}>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>{children}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false
};

export default TimelineList;
