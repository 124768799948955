export const getFormSchema = (withModelOptions = []) => [
  {
    type: 'dropdown',
    name: 'model',
    label: 'Model',
    options: withModelOptions,
    getOptionLabel: (option) => option?.name ?? '',
    isOptionEqual: (a, b) => a.id === b.id,
    placeholder: 'Select device model, e.g. LightHub',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'mac',
    label: 'MAC',
    placeholder: 'Write device MAC, e.g. ff:ff:ff:ff:ff:f0',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'serialNo',
    label: 'Serial Number',
    placeholder: 'Write device serial number',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'lotNo',
    label: 'Lot Number',
    placeholder: 'Write device lot number',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'date',
    name: 'productionDate',
    label: 'Production Date',
    placeholder: 'Select production date',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'string',
    name: 'productionFirmware',
    label: 'Production Firmware',
    placeholder: 'Write production firmware',
    required: true,
    gridSpacing: 6
  }
];
