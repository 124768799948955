import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Link } from "react-router-dom";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import MDBadge from "components/MDBadge";
import { getBadgeColorByStatus } from "utils/element";
import { joinPath } from "utils/join-url";
import { joinName } from "utils/join-name";
import { delayResolve } from "utils/delay";

function IdeaboardReviewPostManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [postStatus, setPostStatus] = useState([]);
  const [postReviewType, setPostReviewType] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([
    {
      id: "userPostReview__type",
      value: ["NewPost", "EditPost"],
    },
  ]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      const {
        list: { list, pagination },
        postStatus,
        postReviewType,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListPendingIdeaboardPosts({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          postStatus: await ModuspaceService.GetIdeaboardPostStatus(
            authentication.jwtToken,
          ),
          postReviewType: await ModuspaceService.GetIdeaboardPostReviewType(
            authentication.jwtToken,
          ),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.user.id = data.userId));
      list.forEach(
        (data) =>
          (data.reviewType =
            data.userPostReviews?.map((item) => item.type) ?? []),
      );
      list.forEach(
        (data) =>
          (data.reviewStatus =
            data.userPostReviews?.map((item) => item.status) ?? []),
      );
      list.forEach((data) => (data.customerName = joinName(data.user)));
      list.forEach((data) => (data.customerEmail = data.user?.email));
      list.forEach(
        (data) => (data.numOfUserComments = data.userComments?.length ?? 0),
      );
      setData(list);
      setTotalCount(pagination.total);
      setPostStatus(postStatus?.map((item) => ({ label: item, id: item })));
      setPostReviewType(
        postReviewType?.map((item) => ({ label: item, id: item })),
      );
    } catch (err) {
      console.error("IdeaboardReviewPostManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListPendingIdeaboardPosts({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: dataFilters,
        ordering: sortBy,
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.user.id = data.userId));
      list.forEach(
        (data) =>
          (data.reviewType =
            data.userPostReviews?.map((item) => item.type) ?? []),
      );
      list.forEach(
        (data) =>
          (data.reviewStatus =
            data.userPostReviews?.map((item) => item.status) ?? []),
      );
      list.forEach((data) => (data.customerName = joinName(data.user)));
      list.forEach((data) => (data.customerEmail = data.user?.email));
      list.forEach(
        (data) => (data.numOfUserComments = data.userComments?.length ?? 0),
      );
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      name: "userPost__title,userPost__caption,userPost__tags",
      label: "Keywords",
      type: "string",
      placeholder: "Title, Caption, Tags",
      searchColumn: "userPost__title,userPost__caption,userPost__tags",
    },
    {
      name: "userPost__status",
      label: "Post State",
      type: "multiSelect",
      optionName: "userPost__status",
      options: postStatus,
      searchColumn: "userPost__status",
    },
    {
      name: "userPostReview__type",
      label: "Type",
      type: "multiSelect",
      optionName: "postReviewType",
      options: postReviewType,
      searchColumn: "userPostReview__type",
      defaultValue: "NewPost,EditPost",
    },
    {
      name: "userPost__createdAt",
      label: "Posted On",
      type: "dateRange",
      searchColumn: "userPost__createdAt",
    },
    {
      name: "userPostReview__createdAt",
      label: "Created On",
      type: "dateRange",
      searchColumn: "userPostReview__createdAt",
    },
  ];

  const tableInfo = [
    {
      label: "Type",
      id: "reviewType",
      alias: "userPostReviews.type",
      tableRowMapper: (value) => value.join(", "),
    },
    {
      label: "Status",
      id: "reviewStatus",
      alias: "userPostReviews.status",
      tableRowMapper: (value) =>
        value
          ? value.map((item) => (
              <MDBadge
                size="xs"
                badgeContent={item}
                color={getBadgeColorByStatus(item)}
                container
              />
            ))
          : null,
    },
    {
      label: "Title",
      id: "title",
      tableRowMapper: (value) =>
        value?.length > 50 ? `${value.substring(0, 50)}...` : value,
    },
    {
      label: "Post State",
      id: "status",
      tableRowMapper: (value) =>
        value ? (
          <MDBadge
            size="xs"
            badgeContent={value}
            color={getBadgeColorByStatus(value)}
            container
          />
        ) : null,
    },
    { label: "Comments", id: "numOfUserComments" },
    {
      label: "Customer",
      id: "user",
      alias: "user.firstName",
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath("/dashboard/customers/", value.id)}
          target="_blank">
          {joinName(value)}
        </Link>
      ),
      className: "custom-link",
    },
    { label: "Created On", id: "createdAt" },
  ];

  const sortInfo = [
    {
      name: "user",
      rowMapper: (value) => joinName(value),
    },
  ];

  const exportInfo = [
    {
      label: "Review Type",
      name: "reviewType",
      rowMapper: (value) => value.join(", "),
    },
    {
      label: "Review Status",
      name: "reviewStatus",
      rowMapper: (value) => value.join(", "),
    },
    {
      label: "Title",
      name: "title",
    },
    {
      label: "Captions",
      name: "caption",
    },
    {
      label: "Customer Name",
      name: "customerName",
    },
    {
      label: "Customer Email",
      name: "customerEmail",
    },
    {
      label: "Post State",
      name: "status",
    },
    {
      label: "Created On",
      name: "createdAt",
    },
    {
      label: "Last Updated",
      name: "updatedAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      sortInfo={sortInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Review Posts"
      exportFileNamePrefix="review-posts"
      currentActiveParent="ideaboard"
      currentActiveTab={"ideaboard-post-review-list"}
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(IdeaboardReviewPostManagement);
