/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-images-viewer components
import ImgsViewer from 'react-images-viewer';

// @mui material components
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Images
import defaultImage from 'assets/images/noImage.jpg';

function Images({ media }) {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(defaultImage);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  useEffect(() => {
    // console.log("posts images component, media: ", media);
    if (media) {
      const reducedImages = media.map((file) => {
        return { src: file.link };
      });

      setImages(reducedImages);
      setCurrentImage(reducedImages[0].src);
    }
  }, [media]);
  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <MDBox>
      <ImgsViewer
        imgs={images}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox
            p={1}
            mr={1}
            overflow="auto"
            sx={{
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {
                backgroundColor: '#fff',
                width: '16px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff'
              },
              '&::-webkit-scrollbar-track:hover': {
                backgroundColor: '#f4f4f4'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#babac0',
                borderRadius: '16px',
                border: '5px solid #fff'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#a0a0a5',
                border: '4px solid #f4f4f4'
              },
              '&::-webkit-scrollbar-button': {
                display: 'none'
              }
            }}>
            <Stack direction="row" spacing={2}>
              {images?.map((e, index) => {
                return (
                  <MDBox
                    component="img"
                    key={index}
                    id={index}
                    src={e.src}
                    alt="small image"
                    borderRadius="lg"
                    shadow="md"
                    maxWidth="5rem"
                    height="5rem"
                    minHeight="5rem"
                    sx={{ cursor: 'pointer', objectFit: 'cover' }}
                    onClick={handleSetCurrentImage}
                    onDoubleClick={openImgsViewer}
                  />
                );
              })}
            </Stack>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Images;
