import { Card, Grid, List, ListItem, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useForm } from "components/MDForm";
import MDLoading from "components/MDLoading";
import MDTypography from "components/MDTypography";
import BaseLayout from "layouts/components/BaseLayout";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { dateTimeToZulu, displayDate } from "utils/date";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";
import { getFormSchema } from "./formSchema";
import "./style.css";

function WarrantyItemCreate() {
  const [loading, setLoading] = useState("");
  const [item, setItem] = useState(null);
  const [isCreated, setIsCreated] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);

  const { pageControl, authentication } = useStores();
  const [searchUserInput, setSearchUserInput] = useState();
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [searchUserTypingLoading, setSearchUserTypingLoading] = useState(false);
  const [fetchUserPagination, setFetchUserPagination] = useState({
    currentPage: 1,
    nextPage: 2,
    pageSize: 100,
    total: 0,
    hasMore: true,
  });

  const {
    components: formComponents,
    validateForm,
    updateSchema,
    formData,
  } = useForm(getFormSchema());

  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const fetchWarrantyInfo = async () => {
    setLoading("Fetching metadata...");
    try {
      const { userOptions, deviceOptions, planOptions } = await delayResolve(
        async () => ({
          userOptions: (
            await ModuspaceService.ListCustomers(authentication.jwtToken, 10, 1)
          ).list.map((x) => {
            return {
              label: `${joinName(x)} (${x.email})`,
              id: x.id,
              email: x.email,
            };
          }),
          deviceOptions: (
            await ModuspaceService.GetAllDevices(authentication.jwtToken)
          ).map((x) => {
            return {
              label: `${x.deviceModel?.name} (${x.serialNo})`,
              id: x.id,
              serialNo: x.serialNo,
            };
          }),
          planOptions: await ModuspaceService.GetAllWarrantyPlansNameAndId(
            authentication.jwtToken,
          ),
        }),
      );

      setUserOptions(userOptions);
      setDeviceOptions(deviceOptions);
      setPlanOptions(planOptions);

      setOptionsLoaded(true);
    } catch (err) {
      console.error("Error at fetching metadata:", { err });
    }
    setLoading("");
  };

  useEffect(() => {
    if (authentication.jwtToken && !optionsLoaded) fetchWarrantyInfo();
    // eslint-disable-next-line
  }, [authentication.jwtToken, optionsLoaded]);

  useEffect(() => {
    updateSchema(
      getFormSchema({
        onUserTextSearch: (e) => {
          // keep loading upon typing
          setSearchUserTypingLoading(true);
          // wait for user stop typing then do a recheck after 1s
          debounce(() => {
            setSearchUserInput(e.target.value);
            setSearchUserTypingLoading(false);
          }, 1000)();
        },
        searchUserLoading,
        searchUserTypingLoading,
        triggerFetchMoreUser: fetchUser,
        hasMoreUser: fetchUserPagination.hasMore,
        withUserOptions: userOptions,
        withDeviceOptions: deviceOptions,
        withPlanOptions: planOptions,
      }).filter((x) => x.actions.includes("CREATE")),
    );
  }, [
    userOptions,
    deviceOptions,
    planOptions,
    searchUserLoading,
    searchUserTypingLoading,
  ]);

  useEffect(() => {
    fetchUser();
  }, [searchUserInput]);

  const fetchUser = (isFetchMore = false) => {
    if (searchUserLoading) return;
    try {
      let fullText = searchUserInput?.trim() ?? "";
      if (fullText?.match(" ")) {
        // only unique array, include the fullText Search as well
        fullText = splitStringIntoSubsetCombinations(fullText, " ", 1);
      }
      setSearchUserLoading(true);
      ModuspaceService.ListCustomers(
        authentication.jwtToken,
        fetchUserPagination.pageSize,
        isFetchMore ? fetchUserPagination.nextPage : 1,
        [
          {
            id: ["email", "firstName", "lastName", "phone"],
            value: fullText,
          },
        ],
      )
        .then((res) => {
          if (res.pagination)
            setFetchUserPagination({
              ...fetchUserPagination,
              ...res.pagination,
              nextPage: res.pagination.currentPage + 1,
              hasMore:
                res.pagination.currentPage * res.pagination.pageSize <
                res.pagination.total,
            });
          return res.list.map((x) => {
            return {
              label: `${joinName(x)} (${x.email})`,
              id: x.id,
              email: x.email,
            };
          });
        })
        .then((res) => {
          if (!isFetchMore) setUserOptions(res);
          else setUserOptions((prev) => [...prev, ...res]);
        })
        .then(() => {
          setSearchUserLoading(false);
        });
    } catch (err) {
      console.error("WarrantyItem fetchUser", { err });
    }
  };

  const [warrantyPlanInfo, setWarrantyPlanInfo] = useState(null);

  const renderSelectedWarrantyInfo = async () => {
    if (!formData?.warranty?.id) {
      setWarrantyPlanInfo(null);
      return;
    }
    setLoading("Fetching warranty info...");
    try {
      // console.log("Fetching warranty info for:", formData)
      const warrantyPlan = await ModuspaceService.GetWarrantyPlanById(
        authentication.jwtToken,
        formData?.warranty?.id,
      );
      setWarrantyPlanInfo(warrantyPlan);
    } catch (err) {
      console.error("Error at renderSelectedWarrantyInfo:", { formData, err });
    }

    setLoading("");
  };

  useEffect(() => {
    if (authentication.jwtToken) renderSelectedWarrantyInfo();
  }, [formData?.warranty, authentication.jwtToken]);

  const navigate = useNavigate();
  const location = useLocation();
  const navigateToId = (id) => {
    let routes = location.pathname.split("/");
    routes[routes.length - 1] = id;
    navigate(routes.join("/"));
  };

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error("Fill in the required fields.");
      return;
    }
    const updatedFormData = {
      ...formData,
      userId: formData.user.id,
      userEmail: formData.user.email,
      serialNo: formData.device.serialNo,
      warrantyPlanId: formData.warranty.id,
      startDate: dateTimeToZulu(formData.startDate),
      endDate: moment(dateTimeToZulu(formData.startDate), "YYYY-MM-DD").add(
        formData.warranty.dayDuration,
        "days",
      ),
      active: formData.active === "Inactive" ? false : true,
      voided: formData.voided === "Voided" ? true : false,
    };
    delete updatedFormData.warranty;

    setLoading("Submitting...");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.CreateNewWarrantyItem(
          authentication.jwtToken,
          updatedFormData,
        ),
      );
      if (response) {
        setItem(response);
        setIsCreated(true);
        toast.success("Warranty Item has been created successfully.");
      }
    } catch (err) {
      console.error("Error at submitting:", { formData, err });
      toast.error("Fail to create warranty item.");
    }
    setLoading(null);
  };

  useEffect(() => {
    if (isCreated) navigateToId(item.id);
    // eslint-disable-next-line
  }, [isCreated]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      New Warranty Info
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Register a new warranty after a purchase of a product
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5">Register Warranty</MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Once a warranty is registered, the warranty will take effect
                  on the start date of the registration form.
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography
                      variant="subtitle"
                      style={{ fontWeight: "bold" }}>
                      Selected warranty info:
                    </MDTypography>
                  </Grid>
                  {!warrantyPlanInfo && (
                    <Grid item key="warranty-plan-info" xs={12}>
                      <MDTypography variant="subtitle2">
                        Please select a warranty plan to view warranty plan info
                      </MDTypography>
                    </Grid>
                  )}
                  {warrantyPlanInfo && (
                    <Grid item key="warranty-plan-info" xs={12}>
                      <List className="warranty-info-list">
                        <ListItem>
                          <ListItemText
                            primary="Name"
                            secondary={warrantyPlanInfo.name}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Type"
                            secondary={warrantyPlanInfo.type}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Amount (SGD)"
                            secondary={warrantyPlanInfo.amount}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Warranty Duration (Days)"
                            secondary={warrantyPlanInfo.dayDuration}
                          />
                        </ListItem>
                        {formData.startDate && (
                          <ListItem>
                            <ListItemText
                              primary="Start Date"
                              secondary={displayDate(
                                new Date(formData.startDate),
                              )}
                            />
                          </ListItem>
                        )}
                        {formData.startDate && (
                          <ListItem>
                            <ListItemText
                              primary="End Date"
                              secondary={(function () {
                                const endDate = new Date(formData.startDate);
                                endDate.setDate(
                                  endDate.getDate() +
                                    warrantyPlanInfo.dayDuration,
                                );
                                return displayDate(endDate);
                              })()}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                  )}
                </Grid>
              </MDBox>
              <MDBox
                py={3}
                px={3}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}>
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={handleSubmit}
                  size="large"
                  style={{ marginLeft: "auto" }}>
                  register warranty
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(WarrantyItemCreate);
