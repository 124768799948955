/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';
function BasicAdditionalInfo({ postInfo, customerInfo, impressionInfo }) {
  const [currentUserInfo, setCurrentUserInfo] = React.useState({});

  React.useEffect(() => {
    const info = customerInfo ?? {};
    setCurrentUserInfo(info);
  }, [customerInfo]);

  return (
    <Card id="basic-customer-info" sx={{ overflow: 'visible' }}>
      {/* <MDBox p={3} pb={0}>
        <MDTypography variant="h6">Customer</MDTypography>
      </MDBox> */}
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={1} pt={1}>
          {/* impression information */}
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize">
              {/* Impressions */}
              <Icon
                color={'secondary'}
                fontSize={'small'}
                sx={{ fontWeight: 'bold', verticalAlign: 'middle' }}>
                push_pin_outlined
              </Icon>
            </MDTypography>
            <MDBox mt={0.5} pt={0.5}>
              <MDTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize">
                Pin:&nbsp;{' '}
                <b>{impressionInfo?.pin ? impressionInfo?.pin : 0}</b>
              </MDTypography>
            </MDBox>
          </Grid>
          {/* basic information */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Customer information
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox key="name" display="flex" mt={0.5} mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Name: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {joinName(currentUserInfo)}
                    </MDTypography>
                  </MDBox>
                  <MDBox key="email" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Email: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserInfo?.email}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="country" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Country: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentUserInfo?.country?.name}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {/* activities */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Created Since:{' '}
                      <b>
                        {currentUserInfo?.createdAt
                          ? displayDate(new Date(currentUserInfo?.createdAt))
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Last Updated:{' '}
                      <b>
                        {currentUserInfo?.updatedAt
                          ? displayDate(new Date(currentUserInfo?.updatedAt))
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Last Login:{' '}
                      <b>
                        {currentUserInfo?.lastLoginAt
                          ? displayDate(new Date(currentUserInfo?.lastLoginAt))
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {/* post information */}
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center">
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Posted:&nbsp;{' '}
                      <b>
                        {postInfo?.createdAt
                          ? displayDate(new Date(postInfo?.createdAt))
                          : '-'}
                      </b>{' '}
                      {postInfo?.creator && (
                        <>
                          by <b>{joinName(postInfo?.creator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Edited:&nbsp;{' '}
                      <b>
                        {postInfo?.updatedAt
                          ? displayDate(new Date(postInfo?.updatedAt))
                          : '-'}
                      </b>{' '}
                      {postInfo?.updator && (
                        <>
                          by <b>{joinName(postInfo?.updator)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Published:&nbsp;{' '}
                      <b>
                        {postInfo?.publishedAt
                          ? displayDate(new Date(postInfo?.publishedAt))
                          : '-'}{' '}
                      </b>{' '}
                      {postInfo?.publisher && (
                        <>
                          by <b>{joinName(postInfo?.publisher)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Flagged:&nbsp;{' '}
                      <b>
                        {postInfo?.flaggedAt
                          ? displayDate(new Date(postInfo?.flaggedAt))
                          : '-'}{' '}
                      </b>{' '}
                      {postInfo?.flagger && (
                        <>
                          by <b>{joinName(postInfo?.flagger)}</b>
                        </>
                      )}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Remarks
                </MDTypography>
                <MDBox alignItems="center">
                  <MDBox mt={0.5}>
                    {postInfo?.remarks ? (
                      <MDTypography
                        component="p"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        <b style={{ whiteSpace: 'pre-line' }}>
                          {postInfo?.remarks || '-'}
                        </b>
                      </MDTypography>
                    ) : (
                      '-'
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicAdditionalInfo;
