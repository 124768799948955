import MDBadge from "components/MDBadge";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import { getBadgeColorByStatus } from "utils/element";
import { joinName } from "utils/join-name";

function UsersManagement() {
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [roleStatus, setRoleStatus] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      const {
        list: { list, pagination },
        roleStatus,
        role,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListUsersPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          roleStatus: await ModuspaceService.GetRoleStatus(),
          role: await ModuspaceService.ListRolesPaginate({}),
        }),
        100,
      );
      // Add `__checked` property to each serial object
      list.forEach((user) => (user.__checked = false));
      list.forEach((user) => (user.name = joinName(user)));
      setData(list);
      setTotalCount(pagination.total);
      setRole(
        role?.list?.map((item) => ({ label: item.name, id: String(item.id) })),
      );
      setRoleStatus(roleStatus?.map((item) => ({ label: item, id: item })));
    } catch (err) {
      console.error("UsersManagement init:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListUsersPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      // Add new `property` to each object
      list.forEach((user) => (user.name = joinName(user)));
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const tableInfo = [
    {
      label: "Name",
      id: "name",
      alias: "firstName",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Status",
      id: "status",
      tableRowMapper: (value) => (
        <MDBadge
          size="xs"
          badgeContent={value}
          color={getBadgeColorByStatus(value)}
          container
        />
      ),
    },
    {
      label: "Last Login",
      id: "lastLoginAt",
    },
    {
      label: "Created At",
      id: "createdAt",
    },
    {
      label: "Updated At",
      id: "updatedAt",
    },
  ];

  const filterInfo = [
    {
      name: "user__firstName,user__lastName,user__email,user__notes",
      label: "Keywords",
      type: "string",
      placeholder: "First Name, Last Name, Email, Notes, Contact No",
      searchColumn: "user__firstName,user__lastName,user__email,user__notes",
    },
    {
      name: "user__createdAt",
      label: "Registration Date",
      type: "dateRange",
      searchColumn: "user__createdAt",
    },
    {
      name: "user__status",
      label: "Status",
      type: "multiSelect",
      optionName: "status",
      options: roleStatus,
      searchColumn: "user__status",
    },
    {
      name: "applicationRole__id",
      label: "Roles",
      type: "multiSelect",
      optionName: "applicationRolesId",
      options: role,
      searchColumn: "applicationRole__id",
    },
  ];

  const exportInfo = [
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Last Login",
      name: "lastLoginAt",
    },
    {
      label: "Created At",
      name: "createdAt",
    },
    {
      label: "Updated At",
      name: "updatedAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["lastLoginAt", "createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Manage Administrators"
      currentActiveParent="settings"
      currentActiveTab="user-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(UsersManagement);
