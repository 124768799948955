import { Close } from "@mui/icons-material";
import { Card, Grid, IconButton, Modal, TextField } from "@mui/material";
import MDAutocomplete from "components/MDAutocomplete";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";
import MDLoading from "components/MDLoading";
import MDTypography from "components/MDTypography";
import GeneralTable from "controls/Tables/GeneralTable";
import moment from "moment";
import { useEffect, useState } from "react";
import { joinName } from "utils/join-name";
import { status } from "utils/option";
import CustomerSearchFilter from "./CustomerSearchFilter";
import { delayResolve } from "utils/delay";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { splitStringIntoSubsetCombinations } from "utils/transform";

const CustomerSearchModal = ({ open, onClose, modalTitle, onSearch }) => {
  const { authentication } = useStores();

  const [tableHeaders] = useState([
    {
      label: "Name",
      id: "name",
      name: "name",
      render: (obj, row) => joinName(row),
    },
    { label: "Email", id: "email", name: "email" },
    { label: "Contact No", id: "phone", name: "phone" },
    {
      label: "Status",
      id: "status",
      render: (obj) => {
        return (
          <MDBadge
            color={obj === "Active" ? "success" : "error"}
            badgeContent={obj}
          />
        );
      },
      name: "status",
    },
    {
      label: "Country",
      id: "countryName",
      name: "countryName",
      render: (obj, row) => row.country?.name,
    },
    {
      label: "Last Login",
      id: "lastLoginAt",
      name: "lastLoginAt",
      render: (obj) => {
        return (
          <>
            {obj && obj !== "Invalid date"
              ? moment(obj).format("YYYY-MM-DD")
              : null}
          </>
        );
      },
    },
    {
      label: "Registration Date",
      id: "createdAt",
      name: "createdAt",
      render: (obj) => (
        <>
          {obj && obj !== "Invalid date"
            ? moment(obj).format("YYYY-MM-DD")
            : null}
        </>
      ),
    },
  ]);

  const [tablePagination, setTablePagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
  });

  const [isRefreshing, setRefreshing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [countries, setCountries] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState([]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize) {
        return;
      }
      const {
        list: { list, pagination },
        countries,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListCustomers(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            filters,
          ),
          countries: await ModuspaceService.GetCountries(),
        }),
        100,
      );

      /* Flatten customer info into a string for easy filtering */

      setTableData(list);
      setCountries(
        countries.map((c) => ({
          label: c.name,
          id: String(c.id),
        })),
      );
      if (pagination.total !== tablePagination.total)
        setTablePagination({
          ...tablePagination,
          total: pagination.total,
        });
    } catch (err) {
      console.error("CustomerSearchModal:", err);
    }
    setRefreshing(false);
  }

  useEffect(() => {
    init();
  }, [filters, tablePagination]);

  const handleSearch = () => {
    // check keyword
    // if (activeFilters["firstName,lastName,email,notes,phone"]?.length < 3) {
    //   alert("Keyword length must be greater than 2");
    //   return;
    // }
    let filters = [];
    Object.keys(activeFilters).map((key) => {
      
      if (
        key !== "createdAt" ||
        (key === "createdAt" &&
          activeFilters[key].filter((x) => !!x).length > 0)
      )
        filters.push({
          id: key.match(",") ? key.split(",") : key,
          value: activeFilters[key],
        });
    });
    setFilters(filters);
  };

  const filterComponents = [
    {
      key: "keywords",
      render: () => (
        <Grid item xs={6} key="keywords">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Keywords (Min 3)"
            name="keywords"
            variant="standard"
            placeholder="First Name, Last Name, Email, Notes, Contact No"
            onChange={(e) => {
              let fullText = e.target.value?.trim();

              if (fullText?.match(" ")) {
                // only unique array, include the fullText Search as well
                fullText = splitStringIntoSubsetCombinations(fullText, " ", 1);
              }
              setActiveFilters((prev) => {
                return {
                  ...prev,
                  "firstName,lastName,email,notes,phone": fullText,
                };
              });
            }}
          />
        </Grid>
      ),
    },
    {
      key: "createdAt",
      render: () => (
        <Grid
          key="createdAt"
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}>
          <MDDatePickerFullWidth
            options={{
              dateFormat: "d-m-Y",
            }}
            label="From"
            value={activeFilters?.createdAt?.[0] ?? null}
            onChange={(date) => {
              let from = null;
              if (date[0]) {
                from = date[0].setHours(0, 0, 0, 0);
                from = new Date(from);
              }

              setActiveFilters((prev) => {
                return {
                  ...prev,
                  createdAt: [from, activeFilters.createdAt?.[1]],
                };
              });
            }}
          />
          <MDDatePickerFullWidth
            options={{
              dateFormat: "d-m-Y",
            }}
            label="To"
            value={activeFilters?.createdAt?.[1] ?? null}
            onChange={(date) => {
              let to = null;
              if (date[0]) {
                to = date[0].setHours(23, 59, 59, 999);
                to = new Date(to);
              }

              setActiveFilters((prev) => {
                return {
                  ...prev,
                  createdAt: [activeFilters.createdAt?.[0], to],
                };
              });
            }}
          />
        </Grid>
      ),
    },
    {
      key: "countryId",
      render: () => (
        <Grid item xs={6} key="countryId">
          <MDAutocomplete
            label="Country"
            options={countries}
            multiple
            onChange={(e, val) => {
              if (val.length === 0) {
                delete activeFilters.countryId;
                setActiveFilters(activeFilters);
              } else {
                setActiveFilters((prev) => {
                  return {
                    ...prev,
                    countryId: val.map((v) => v?.id),
                  };
                });
              }
            }}
          />
        </Grid>
      ),
    },
    {
      key: "status",
      render: () => (
        <Grid item xs={6} key="status">
          <MDAutocomplete
            label="Status"
            options={status}
            multiple
            onChange={(e, val) => {
              if (val.length === 0) {
                delete activeFilters.status;
                setActiveFilters(activeFilters);
              } else {
                setActiveFilters((prev) => {
                  return {
                    ...prev,
                    status: val.map((v) => v?.id),
                  };
                });
              }
            }}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
      }}>
      <MDBox
        style={{
          height: "95%",
          overflowY: "auto",
        }}>
        <MDLoading show={isRefreshing} />
        <Grid
          container
          spacing={3}
          style={{
            overflowY: "auto",
          }}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid
                  container
                  style={{ display: "flex", alignItems: "center" }}>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      {modalTitle || "Customer Search"}
                    </MDTypography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </MDBox>

              <CustomerSearchFilter
                loading={isRefreshing}
                filterComponents={filterComponents}
                onSearch={handleSearch}
              />
              <MDBox py={2} px={2}>
                <GeneralTable
                  width={210}
                  height={300}
                  selectable={true}
                  selectedItems={selectedItems}
                  pagination={tablePagination}
                  onSelect={(checked, item) => {
                    if (checked)
                      setSelectedItems([
                        ...selectedItems,
                        {
                          label: `${joinName(item)} (${item.email})`,

                          id: String(item.id),
                        },
                      ]);
                    else
                      setSelectedItems((prev) =>
                        prev.filter((u) => u.id !== "" + item.id),
                      );
                  }}
                  onTableChange={(newPagination) =>
                    setTablePagination(newPagination)
                  }
                  headColumn={tableHeaders}
                  bodyColumn={tableData}
                />
              </MDBox>

              <MDBox pb={2} px={3}>
                <Grid
                  justifyContent="center"
                  container
                  spacing={3}
                  px={1}
                  py={3}>
                  <Grid item xs={2}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      size="large"
                      onClick={() => {
                        onSearch(selectedItems);
                        onClose();
                      }}>
                      Select
                    </MDButton>
                  </Grid>
                  <Grid item xs={2}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="large"
                      onClick={onClose}>
                      Cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Modal>
  );
};

export default CustomerSearchModal;
