import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'stores';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDDatePickerFullWidth from 'components/MDDatePickerFullWidth';
import FormField from 'layouts/components/FormField';
import ModuspaceService from 'services/moduspace.service';

function CreateFields({
  info,
  orders,
  statuses,
  shippingMethods,
  submitHandler,
}) {
  const [currentInfo, setCurrentInfo] = useState(info ?? {});
  const [customer, setCustomer] = useState(info?.customer ?? {});
  const { authentication } = useStores();
  
  // If the ID is present, it is an edit page
  const { id } = useParams();

  // Get salesOrderId from url query param if it is present in the url
  const [urlParamSalesOrderId, setUrlParamSalesOrderId] = useState(0);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const salesOrderId = Number(urlParams.get('salesOrderId') ?? 0);
    const customerId = Number(urlParams.get('customerId') ?? 0);
    if (salesOrderId && customerId) {
      setUrlParamSalesOrderId(salesOrderId);
      onChangeOrder({ id: salesOrderId, customerId });
    }
  }, []);

  useEffect(() => {
    setCurrentInfo(info ?? {});
  }, [info]);

  /* Update customer fields when order is selected */
  const onChangeOrder = async (order) => {
    if (order) {
      if (order.customerId) {
        // Get customer info
        const customer = await ModuspaceService.GetCustomerByUserId(authentication.jwtToken, order.customerId);
        setCustomer(customer);
      } else if (order.billing_address) {
        // Set customer from billing address
        setCustomer({
          firstName: order.billing_address.first_name,
          lastName: order.billing_address.last_name,
          company: order.billing_address.company,
          phone: order.billing_address.phone,
          email: order.billing_address.email,
        });
      }
      // Set customer ID and order ID to current info
      setCurrentInfo((prevState) => ({
        ...prevState,
        customerId: order.customerId ?? 0,
        salesOrderId: order.id,
      }));
    }
  };

  const handleChange = (e) => {
    setCurrentInfo({
      ...currentInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitHandler(currentInfo);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5" gutterBottom>
          Delivery Order Details
        </MDTypography>
        <MDBox component="form" onSubmit={onSubmit}>
          <Grid container spacing={3} my={1}>
            {/* Sales order ID */}
            <Grid item xs={12} sm={6}>
              <Autocomplete 
                options={orders || []}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                disableClearable
                renderInput={(params) =>(
                  <FormField
                    {...params}
                    label="Order"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Add an order"
                  />
                )}
                value={orders?.find(order => order?.id === currentInfo?.salesOrderId || order?.id === urlParamSalesOrderId) || null}
                onChange={(e, newValue) => onChangeOrder(newValue)}
              />
            </Grid>


            {/* Amount */}
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                label="Amount"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter amount"
                name="amount"
                onChange={handleChange}
                value={currentInfo?.amount || ''}
              />
            </Grid>


            {/* Status */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={statuses || []}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                disableClearable
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select status"
                  />
                )}
                value={statuses?.find(status => status?.id === currentInfo?.status) || null}
                onChange={(_, value) => setCurrentInfo({
                  ...currentInfo,
                  status: value?.id,
                })}
              />
            </Grid>

            {/* Shipment date */}
            <Grid item xs={12} sm={6}>
              <MDDatePickerFullWidth
                label="Shipment Date"
                value={currentInfo?.shipmentDate || null}
                name="shipmentDate"
                onChange={([date]) => setCurrentInfo({
                  ...currentInfo,
                  shipmentDate: date,
                })}
              />
            </Grid>
            
            {/* Receiver name */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Receiver Name"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter receiver name"
                name="receiverName"
                onChange={handleChange}
                value={currentInfo?.receiverName || ''}
              />
            </Grid>
            {/* Receiver phone */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Receiver Phone"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter receiver phone"
                name="receiverPhone"
                onChange={handleChange}
                value={currentInfo?.receiverPhone || ''}
              />
            </Grid>

            {/* Shipping method */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={shippingMethods || []}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                disableClearable
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Shipping Method"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select shipping method"
                  />
                )}
                value={shippingMethods?.find(method => method?.id === currentInfo?.shippingMethod) || null}
                onChange={(_, value) => setCurrentInfo({
                  ...currentInfo,
                  shippingMethod: value?.id,
                })}
              />
            </Grid>

            {/* Shipper name */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Shipper Name"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter shipper name"
                name="shipperName"
                onChange={handleChange}
                value={currentInfo?.shipperName || ''}
              />
            </Grid>

            {/* Tracking code */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Tracking Code"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter tracking code"
                name="trackingCode"
                onChange={handleChange}
                value={currentInfo?.trackingCode || ''}
              />
            </Grid>

            {/* Shipper phone */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Shipper Phone"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter shipper phone"
                name="shipperPhone"
                onChange={handleChange}
                value={currentInfo?.shipperPhone || ''}
              />
            </Grid>

            {/* Reference */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Shipping Reference"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter reference"
                name="reference"
                onChange={handleChange}
                value={currentInfo?.reference || ''}
              />
            </Grid>

            {/* Delivery date */}
            <Grid item xs={12} sm={6}>
              <MDDatePickerFullWidth
                label="Delivery Date"
                name="deliveryDate"
                value={currentInfo?.deliveryDate || null}
                onChange={([date]) => setCurrentInfo({
                  ...currentInfo,
                  deliveryDate: date,
                })}
              />
            </Grid>

            {/* Internal notes */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Internal Notes"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter internal notes"
                name="notes"
                onChange={handleChange}
                value={currentInfo?.notes || ''}
              />
            </Grid>

            {/* Remarks */}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Remarks"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter remarks"
                name="remarks"
                onChange={handleChange}
                value={currentInfo?.remarks || ''}
              />
            </Grid>
          </Grid>

          {/* Submit button */}
          <Grid item xs={12} sm={6} mt={4}>
            <MDButton
              type="submit"
              variant="contained"
              color="success"
              style={{
                width: '100%',
              }}
            >
              {id ? 'Update Delivery Order' : 'Create Delivery Order'}
            </MDButton>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default CreateFields;