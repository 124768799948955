import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { useConfirmModal } from 'components/MDConfirm';
import { getFormSchema } from './formSchema';
import { useForm } from 'components/MDForm';
import { dateTimeToLocal, dateTimeToZulu } from 'utils/date';

function DeviceDetails() {
  const { pageControl, authentication } = useStores();

  const { prompt: promptDeleteDevice, MDConfirm: MDConfirmDeleteDeviceModal } =
    useConfirmModal({
      title: 'Delete Device',
      content:
        'Device can only be deleted if there is no existing association (e.g. warranty has been registered, etc.).',
      confirmText: 'Delete',
      confirmColor: 'error'
    });

  const [id, setId] = React.useState();
  const {
    components: formComponents,
    validateForm,
    updateSchema,
    setFormData
  } = useForm(getFormSchema());
  const [loading, setLoading] = React.useState('');
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { serial_no } = useParams();
  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = serial_no || 'Device Info';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location, serial_no]);

  const fetchInfo = async () => {
    setLoading('Fetching metadata...');
    try {
      const { newDeviceInfo, newModelOptions } = await delayResolve(
        async () => ({
          newDeviceInfo: await ModuspaceService.GetDeviceBySerial(
            authentication.jwtToken,
            serial_no
          ),
          newModelOptions: await ModuspaceService.GetAllDevicesModelsNameAndId(
            authentication.jwtToken
          )
        })
      );
      // updateSchema(getFormSchema(newModelOptions))
      setFormData({
        model: newModelOptions.find(
          (e) => e.name === newDeviceInfo?.deviceModel?.name
        ),
        mac: newDeviceInfo?.mac,
        serialNo: newDeviceInfo?.serialNo,
        lotNo: newDeviceInfo?.lotNo ?? null,
        productionDate: dateTimeToLocal(newDeviceInfo?.productionDate),
        productionFirmware: newDeviceInfo?.productionFirmware ?? null
      });
      setId(newDeviceInfo?.id);
    } catch (err) {
      console.error('Error at fetching metadata:', { err });
    }
    setLoading('');
  };

  React.useEffect(() => {
    if (authentication.jwtToken) fetchInfo();
    // eslint-disable-next-line
  }, [authentication.jwtToken]);

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const newFormData = {
        ...formData,
        model: formData.model.model,
        productionDate: dateTimeToZulu(formData.productionDate)
      };

      const response = await delayResolve(() =>
        ModuspaceService.UpdateDeviceById(
          authentication.jwtToken,
          id,
          newFormData
        )
      );
      if (response) {
        setUpdated(true);
        toast.success('Device has been updated successfully.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to update device.');
    }
    setLoading('');
  };

  const submitDeleteDevice = async () => {
    setLoading('Deleting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteDeviceById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success('Device has been deleted.');
      }
    } catch (err) {
      console.error('Cannot delete device:', { err });
      toast.error('Fail to delete device.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/products/serial-numbers');
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteDeviceModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Device Info
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  A production device with individual serial number
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap">
                  <MDBox mt={3} ml="auto">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      onClick={handleSubmit}>
                      Update Device
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Delete Device</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Device can only be deleted if there is no existing
                    association (e.g. warranty has been registered, etc.).
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() =>
                      promptDeleteDevice({ onConfirm: submitDeleteDevice })
                    }
                    size="large">
                    delete device
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(DeviceDetails);
