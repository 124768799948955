import dayjs from 'dayjs';
import timeZoneConverter from 'countries-and-timezones';
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

const { timeZone = 'Asia/Singapore' } = JSON.parse(
  localStorage.getItem('LocaleStore') || ''
);

export function displayDate(date = null, format = 'DD-MM-YYYY') {
  return date ? dayjs.utc(date).tz(timeZone).format(format) : null;
}

export function displayDateTime(date = null, format = 'DD-MM-YYYY hh:mm A') {
  return date ? dayjs.utc(date).tz(timeZone).format(format) : null;
}

export function dateTimeToLocal(date = null) {
  return date ? dayjs.utc(date).tz(timeZone).format() : null;
}

export function dateTimeToZulu(date = null, onlyDate = false) {
  if (date && onlyDate) {
    date = dayjs(date).utc().startOf('date').format();
  }

  if (date && !onlyDate) {
    date = dayjs(date).utc().format();
  }

  return date;
}

/**
 * This function is to CUT off time, using substring.
 * regardless the timezone. only keep date. workaround to by pass the timezone issue on convert utc for api payload
 *
 * @param {date} date the input date of birth, valid date (utc or tz or js date).
 * @param {boolean} toUtc date shall be convert as utc, if false date is on tz(timeZone)
 */
export function dateTimeToBirthDate(date = null, toUtc = false) {
  // todo: clean console.log after UTA test pass
  console.log('utcDateTimeToBirthDate before: ', date);
  if (date) {
    // toISOString: YYYY-MM-DDTHH:mm:ss.sssZ
    const dateString = dayjs(date).format('YYYY-MM-DD').substring(0, 10);
    console.log('utcDateTimeToBirthDate dateString: ', dateString);
    date = toUtc
      ? dayjs.utc(`${dateString}T00:00:00.000Z`).toDate()
      : dayjs
          .utc(`${dateString}T00:00:00.000Z`)
          .tz(timeZone, false)
          .startOf('date')
          .toDate();
  }
  console.log(`utcDateTimeToBirthDate after UTC = ${toUtc}: `, date);
  return date;
}

export function utcOffset() {
  return timeZone ? timeZoneConverter.getTimezone(timeZone)?.utcOffsetStr : '';
}
