/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// react-router-dom components
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BaseLayout from './components/BaseLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

import { useStores } from 'stores';
import { observer } from 'mobx-react';
import { delayResolve } from 'utils/delay';
import jsonFetch from 'utils/json-fetch';
import { client, ModuspaceEndpoints } from 'utils/constants';
import MDAlert from 'components/MDAlert';
import { joinName } from 'utils/join-name';

function ModuspaceSignIn() {
  const urlProp = new URLSearchParams(window.location.href.split('?')[1]);
  const { authentication } = useStores();
  const [loginInfo, setLoginInfo] = React.useState({
    email: '',
    password: '',
    loginError: '',
    warning: urlProp.has('warning')
      ? decodeURIComponent(urlProp.get('warning'))
      : ''
  });
  const [loading, setLoading] = React.useState(false);
  // const pathname = useLocation();
  // const navigate = useNavigate();

  React.useEffect(() => {
    console.log('signin useEffect[]');
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    let jwtToken = '';
    let id = '';
    let name = '';
    let permissions = [];

    try {
      await delayResolve(async () => {
        const { email, password } = loginInfo;
        try {
          const response = await jsonFetch(ModuspaceEndpoints.DASHBOARD_LOGIN, {
            method: 'POST',
            body: { client, email, password }
          });
          if (!response.ok) {
            setLoginInfo({
              ...loginInfo,
              loginError:
                response?.json?.message ??
                response?.json?.reason ??
                response.json?.data?.message ??
                response.json?.data?.reason ??
                'Unknown Request Error'
            });
          } else {
            setLoginInfo({
              ...loginInfo,
              loginError: ''
            });
            jwtToken = response.json?.data?.user?.token ?? '';
            id = response.json?.data?.user?.id ?? '';
            name = joinName(response.json?.data?.user) ?? '';
            permissions = response.json?.data?.user?.permissions ?? [];
          }
        } catch (err) {
          console.error('Login error:', { err });
          setLoginInfo({
            ...loginInfo,
            loginError: 'Unknown Server Error'
          });
        }
      }, 1000);
    } catch (err) {
      //do nothing if error
    } finally {
      setLoading(false);
      authentication.setJwtToken(jwtToken);
      authentication.setId(id);
      authentication.setName(name);
      authentication.setPermissions([...permissions]);
    }
  };

  // token is already avaiable, check token from another component
  if (authentication.jwtToken) return <Navigate to="/" />;

  return (
    <BaseLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography
            variant="subtitle2"
            fontWeight="light"
            color="white"
            mt={1}>
            Moduspace Dashboard
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {!loading && loginInfo.loginError.length > 0 && (
              <MDBox mb={2}>
                <MDAlert color="error">{loginInfo.loginError}</MDAlert>
              </MDBox>
            )}
            {!loading &&
              loginInfo.warning.length > 0 &&
              loginInfo.loginError.length === 0 && (
                <MDBox mb={2}>
                  <MDAlert color="warning">{loginInfo.warning}</MDAlert>
                </MDBox>
              )}
            <MDBox mb={2}>
              <MDInput
                disabled={loading}
                type="email"
                label="Email"
                fullWidth
                onChange={({ target: { value } }) =>
                  setLoginInfo({
                    ...loginInfo,
                    email: value
                  })
                }
                value={loginInfo.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                disabled={loading}
                type="password"
                label="Password"
                fullWidth
                onChange={({ target: { value } }) =>
                  setLoginInfo({
                    ...loginInfo,
                    password: value
                  })
                }
                value={loginInfo.password}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={loading}
                variant="gradient"
                color={loading ? 'secondary' : 'info'}
                fullWidth
                onClick={handleLogin}>
                {loading ? 'signing in...' : 'sign in '}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BaseLayout>
  );
}

export default observer(ModuspaceSignIn);
