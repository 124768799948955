import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

const pageStore = makeAutoObservable({
  currentActiveTab: '',
  currentActiveParent: '',
  defaultPageSize: 10,
  defaultSortProperties: [],

  // non persistent state
  updatedPageId: {},

  // constants
  pageSizeOptions: [5, 10, 50, 100, 200, 500],

  // routes actions
  overwriteBreadcrumbsRoutes(currentRoute, highlight) {
    pageStore.updatedPageId[currentRoute] = highlight;
  },
  setCurrentActiveTab: (currentActiveTab, currentActiveParent) => {
    pageStore.currentActiveTab = currentActiveTab;
    pageStore.currentActiveParent = currentActiveParent;
  },

  // pagination actions
  setDefaultPageSize: (size) => {
    pageStore.defaultPageSize = size;
  },

  // sort actions
  setSort: (sortProperties) => {
    let newSortProperties = pageStore.defaultSortProperties.filter(
      (item) => item.tab !== sortProperties.tab
    );
    pageStore.defaultSortProperties = [...newSortProperties, sortProperties];
  }
});

makePersistable(pageStore, {
  name: 'PageControlStore',
  properties: [
    'currentActiveTab',
    'currentActiveParent',
    'defaultPageSize',
    'defaultSortProperties'
  ],
  storage: window.localStorage
});

export default pageStore;
