import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ModuspaceService from "services/moduspace.service";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { delayResolve } from "utils/delay";

function ApplicationKeyManagement() {
  const [data, setData] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      const {
        list: { list, pagination },
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListApiKeysPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
        }),
        100,
      );
      setData(list);
      setTotalCount(pagination.total);
    } catch (err) {
      console.error("ApplicationKeyManagement init:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListApiKeysPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      return list;
    } catch (err) {
      console.error("ApplicationKeyManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [];

  const tableInfo = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Key",
      id: "clearText",
    },
    {
      label: "Scope",
      id: "scope",
    },
    {
      label: "Created at",
      id: "createdAt",
    },
    {
      label: "Updated at",
      id: "updatedAt",
    },
  ];

  const exportInfo = [];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="clearText"
      viewOnly={true}
      createActionButtons={() => null}
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="API Keys"
      currentActiveParent="settings"
      currentActiveTab="api-management"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(ApplicationKeyManagement);
