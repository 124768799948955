export const defaultPermissions = {
  superuser: 'permission for moduspace full access.',
  customers: 'permission for moduspace customers.',
  products: 'permission for moduspace products.',
  warranty: 'permission for moduspace warranty.',
  ideaboard: 'permission for moduspace ideaboard.',
  'content-management': 'permission for moduspace content-management.',
  'support-center': 'permission for moduspace support-center.',
  settings: 'permission for moduspace settings.'
};

/**
 * @typedef {Object} PermissionContext
 * @property {string} permission - permission name
 * @property {string} description - permission description
 * @property {boolean} allowEdit - dashboard should allow edit of permission
 * @property {string[]} action - additional action of the permissions
 * @property {boolean} allPermissions - action is all permissions
 * @param {string} permissions
 * @returns PermissionContext[]
 */
function parsePermissionsArray(permissions) {
  permissions = permissions ?? '';
  const permissionsArray = permissions ? permissions.split(' ') : [];
  const permissionsContext = Object.entries(defaultPermissions).map(
    ([permission, description]) => ({
      permission,
      description,
      allowEdit: true,
      action: [],
      allPermissions: false
    })
  );
  permissionsArray.forEach((permissionString) => {
    const [permission, action] = permissionString.split(':');
    const permissionDescription = defaultPermissions[permission];
    let permissionIdx = permissionsContext.findIndex(
      (e) => e.permission === permission
    );
    if (permissionIdx === -1) {
      permissionIdx = appendNewPermissionToContext(
        permissionIdx,
        permissionsContext,
        permission,
        permissionDescription
      );
    }

    if (action) {
      permissionsContext[permissionIdx].action.push(action);
    } else {
      permissionsContext[permissionIdx].allPermissions = true;
    }
  });
  return permissionsContext;
}
function appendNewPermissionToContext(
  permissionIdx,
  permissionsContext,
  permission,
  permissionDescription
) {
  permissionIdx = permissionsContext.length;
  permissionsContext.push({
    permission,
    description:
      permissionDescription ?? `Default server permision for ${permission}`,
    allowEdit: !!permissionDescription,
    action: [],
    allPermissions: false
  });
  return permissionIdx;
}

/**
 * @typedef {Object} PermissionContext
 * @property {string} permission - permission name
 * @property {string} description - permission description
 * @property {boolean} allowEdit - dashboard should allow edit of permission
 * @property {string[]} action - additional action of the permissions
 * @property {boolean} allPermissions - action is all permissions
 * @param {PermissionContext[]} permissionsContext
 * @param {string} permissionString
 * @param {boolean} add
 * @returns PermissionContext[]
 */
function applyPermissionChange(permissionsContext, permissionString, add) {
  permissionString = permissionString ?? '';
  if (!permissionString) return permissionsContext;
  const [permission, action] = permissionString.split(':');

  let permissionIdx = permissionsContext.findIndex(
    (e) => e.permission === permission
  );
  if (permissionIdx === -1) {
    permissionIdx = appendNewPermissionToContext(
      permissionIdx,
      permissionsContext,
      permission,
      'Custom permission'
    );
  }

  // add permission to permission context
  if (add) {
    if (action) {
      // specific action is granted
      const actionSet = new Set(permissionsContext[permissionIdx].action);
      actionSet.add(action);
      if (actionSet.has('read') && actionSet.has('write')) {
        // all permission is granted
        permissionsContext[permissionIdx].action = [];
        permissionsContext[permissionIdx].allPermissions = true;
      } else {
        permissionsContext[permissionIdx].action = [...actionSet];
        permissionsContext[permissionIdx].allPermissions = false;
      }
    } else {
      // all permission is granted
      permissionsContext[permissionIdx].action = [];
      permissionsContext[permissionIdx].allPermissions = true;
    }
  } else {
    // remove permission from permission context
    const wasAllPermissions = permissionsContext[permissionIdx].allPermissions;
    if (wasAllPermissions) {
      permissionsContext[permissionIdx].action = ['read', 'write'];
    }

    permissionsContext[permissionIdx].allPermissions = false;

    if (action) {
      permissionsContext[permissionIdx].action = permissionsContext[
        permissionIdx
      ].action.filter((e) => e !== action);
    } else {
      // all permission is revoked
      permissionsContext[permissionIdx].action = [];
    }
  }

  return permissionsContext;
}

/**
 * @typedef {Object} PermissionContext
 * @property {string} permission - permission name
 * @property {string} description - permission description
 * @property {boolean} allowEdit - dashboard should allow edit of permission
 * @property {string[]} action - additional action of the permissions
 * @property {boolean} allPermissions - action is all permissions
 * @param {PermissionContext[]} permissionsContext
 * @returns string
 */
function createPermissionsString(permissionsContext) {
  const permissionsStrings = permissionsContext.map((e) =>
    e.allPermissions
      ? [e.permission]
      : e.action.map((f) => `${e.permission}:${f}`)
  );
  return permissionsStrings.reduce((a, b) => [...a, ...b]).join(' ');
}

const PermissionHelper = {
  parsePermissionsArray,
  applyPermissionChange,
  createPermissionsString
};

export default PermissionHelper;
