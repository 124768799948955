export default [
  {
    key: 'title',
    index: 'title',
    title: 'Title',
    visible: true,
    sort: null,
    render: (obj) => {
      return obj;
    }
  },
  {
    key: 'userId',
    index: 'userId',
    title: 'Customer',
    visible: true,
    sort: null,
    render: (obj) => {
      return obj;
    }
  },
  {
    key: 'numOfUserComments',
    title: 'Commments',
    visible: true,
    sort: null,
    render: (obj) => {
      return obj;
    }
  },
  {
    key: 'numOfPins',
    title: 'Pins',
    visible: true,
    sort: null
  },
  {}
];
