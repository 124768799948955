import MDBadge from 'components/MDBadge';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';
import { delayResolve } from 'utils/delay';
import { joinName } from 'utils/join-name';

function ProductManagement() {
    const { authentication } = useStores();

    const [data, setData] = useState([]);
    const [isRefreshing, setRefreshing] = useState(false);
    const [tablePagination, setTablePagination] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [dataFilters, setDataFilters] = useState([]);
    const [categories, setCategories] = useState([])

    
    useEffect(() => {
        init();
    }, [tablePagination]);

    async function init() {
        setRefreshing(true);
        try {
            if (!tablePagination?.pageSize) {
                return;
            }
            const {
                list: { list, pagination },
                categories
            } = await delayResolve(
                async () => ({
                    list: await ModuspaceService.ListCatalogProductsPaginate(
                        authentication.jwtToken,
                        tablePagination.pageSize,
                        tablePagination.currentPage,
                        dataFilters
                    ),
                    categories: await ModuspaceService.GetAllCategories(),
                }),
                100
            );

            /* Flatten customer info into a string for easy filtering */
            const orders = list.map(({ customer, ...rest }) => {
                if (customer) {
                    customer.name = joinName(customer);
                }
                const customerInfo = `${customer?.name ?? ''} ${customer?.email ?? ''
                    } ${customer?.country ?? ''} ${customer?.phone ?? ''}`;
                return { customerInfo, customer, ...rest };
            });
            setData(orders);
            setCategories(categories.map((item) => ({ label: item.name, id: String(item.id) })))
            setTotalCount(pagination.total);
        } catch (err) {
            console.error('ProductManagement:', err);
        }
        setRefreshing(false);
    }

    async function fetchAllExportData(appliedFilter = false) {
        try {
            const { list } = await ModuspaceService.ListAllOrders(
                authentication.jwtToken,
                Number.MAX_SAFE_INTEGER,
                1,
                appliedFilter ? dataFilters : []
            );
            return list;
        } catch (err) {
            console.error('ProductManagement:', err);
        }
    }

    const tableInfo = [
        {
            label: 'Name',
            id: 'name'
        },
        {
            label: 'SKU',
            id: 'sku',
            tableRowMapper: (value, row) => value ?? '-'
        },
        {
            label: 'Category',
            id: 'category',
            tableRowMapper: (value, row) => {
                if (row?.productCategories)
                    return row.productCategories.map(cat => cat.category?.name).join(", ")

                return '-'
            }
        },
        {
            label: 'Price',
            id: 'price',
            tableRowMapper: (value, row) => {
                if (row?.applicationClientProducts)
                    return row.applicationClientProducts.map(cp => <div>
                        <p>{cp.applicationClient?.label}</p>
                        <p>{cp.referencePrice}</p>
                    </div>)

                return '-'
            }
        },
        {
            label: 'Visibility',
            id: 'is_visible',
            tableRowMapper: (value, row) => {
                if (row?.applicationClientProducts)
                    return row.applicationClientProducts.map(cp => <div>
                        <MDBadge
                            size="xs"
                            badgeContent={cp.referenceIsVisible ? `${cp.applicationClient?.label} (VISIBLE)` : `${cp.applicationClient?.label} (INVISIBLE)`}
                            color={cp.referenceIsVisible ? "success" : "dark"}
                            container
                        />
                    </div>)

                return '-'
            }
        },
    ];

    /* TODO */
    const importInfo = [];

    const filterInfo = [
        {
            name: "name",
            label: "Name",
            type: "string",
            placeholder: "Name",
            searchColumn: "name",
        },
        {
            name: "category",
            label: "Category",
            type: "multiSelect",
            optionName: "categoryId",
            options: categories,
            searchColumn: "categoryId",
        }
    ];

    const exportInfo = [];

    return (
        <>
            <MDGeneralListingV3
                // for filtering
                filterInfo={filterInfo}
                setDataFilters={setDataFilters}
                // for table rendering
                _data={data}
                tableInfo={tableInfo}
                tablePagination={tablePagination}
                setTablePagination={setTablePagination}
                totalCount={totalCount} // all available data count
                // for action
                rowIDAccessor="id"
                allowCreate={false}
                createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
                    null
                }
                // for exports
                exportInfo={exportInfo}
                fetchAllExportData={fetchAllExportData}
                // for common usage
                title="Products"
                currentActiveParent="products"
                currentActiveTab="products"
                init={init}
                isRefreshing={isRefreshing}
                importInfo={importInfo}
            />
        </>
    );
}

export default observer(ProductManagement);
