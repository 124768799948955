import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';
import { dateTimeToZulu } from 'utils/date';

function DeviceCreate() {
  const { pageControl, authentication } = useStores();

  const {
    components: formComponents,
    validateForm,
    updateSchema
  } = useForm(getFormSchema());

  const [loading, setLoading] = React.useState('');
  const [device, setDevice] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToId = (id) => {
    let routes = location.pathname.split('/');
    routes[routes.length - 1] = id;
    navigate(routes.join('/'));
  };

  const [modelOptionsLoaded, setModelOptionsLoaded] = React.useState(false);

  const fetchDeviceInfo = async () => {
    setLoading('Fetching metadata...');
    try {
      const modelOptions = await delayResolve(() =>
        ModuspaceService.GetAllDevicesModelsNameAndId(authentication.jwtToken)
      );
      updateSchema(getFormSchema(modelOptions));
      setModelOptionsLoaded(true);
    } catch (err) {
      console.error('Error at fetching metadata:', { err });
    }
    setLoading('');
  };

  React.useEffect(() => {
    if (authentication.jwtToken && !modelOptionsLoaded) fetchDeviceInfo();
    // eslint-disable-next-line
  }, [authentication.jwtToken, modelOptionsLoaded]);

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const newFormData = {
        ...formData,
        name: formData.model.name,
        productionDate: dateTimeToZulu(formData.productionDate)
      };

      const response = await delayResolve(() =>
        ModuspaceService.CreateNewDevice(authentication.jwtToken, newFormData)
      );
      if (response) {
        setIsCreated(true);
        setDevice(response);
        toast.success('Device has been created successfully.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to create device.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (isCreated && device !== null) navigateToId(device.serialNo);
    // eslint-disable-next-line
  }, [isCreated, device]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Register New Device
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  A production device with individual serial number
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Create Device</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    A device should have a unique serial number.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleSubmit}
                    size="large">
                    create device
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(DeviceCreate);
