import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useNavigate } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from 'pages/users/customers/components/BasicInfo';
import ChangePassword from 'pages/users/customers/components/ChangePassword';
import CreateAccount from 'pages/users/customers/components/CreateAccount';
import { dateTimeToBirthDate } from 'utils/date';
// import Address from 'pages/users/customers/components/Address';

function UserCreate() {
  const [loading, setLoading] = React.useState(null);
  const [customer, setCustomer] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const basicInfoRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const [countries, setCountries] = React.useState(null);
  const [fandoms, setFandoms] = React.useState(null);
  const [scales, setScales] = React.useState(null);
  const [brands, setBrands] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const { authentication } = useStores();
  const navigate = useNavigate();
  // const [addresses, setAddresses] = React.useState([])

  const fetchCustomerInfo = async () => {
    setLoading('Fetching metadata...');
    try {
      const { countries, fandoms, scales, brands, roles } = await delayResolve(
        async () => ({
          countries: await ModuspaceService.GetCountries(
            authentication.jwtToken
          ),
          fandoms: await ModuspaceService.GetFandoms(authentication.jwtToken),
          scales: await ModuspaceService.GetScales(authentication.jwtToken),
          brands: await ModuspaceService.GetBrands(authentication.jwtToken),
          roles: await ModuspaceService.GetCustomerRolesIncludingInactive(
            authentication.jwtToken
          )
        })
      );
      setCountries(countries);
      setFandoms(fandoms);
      setScales(scales);
      setBrands(brands);
      setRoles(roles);
    } catch (err) {
      console.error('Error at fetchCustomerInfo:', err);
    }
    setLoading(null);
  };

  const createCustomer = async () => {
    setLoading('Submitting');
    try {
      let error = false;
      let userInfo;
      if (basicInfoRef.current.onSubmitListener) {
        const { error: validationError, userInfo: validatedUserInfo } =
          basicInfoRef.current.onSubmitListener();
        const isExistedEmail = await ModuspaceService.VerifyCustomerEmailExists(
          authentication.jwtToken,
          validatedUserInfo
        );

        error = isExistedEmail;
        error = validationError;
        userInfo = validatedUserInfo;
      } else {
        // flow should not trigger if ref is successfully passed
        error = true;
      }
      let password;
      if (!error && passwordRef.current.onSubmitListener) {
        const { error: validationError, password: validatedPassword } =
          passwordRef.current.onSubmitListener();
        error = validationError;
        password = validatedPassword;
      } else {
        // flow should not trigger if ref is successfully passed
        error = true;
      }

      if (!error) {
        const payload = {
          ...userInfo,
          dob: dateTimeToBirthDate(userInfo.dob, true), // convert into utc timezone with 00:00:00.000 time
          password,
          require_change: false,
          generatePassword: false
          // addresses: [
          //     ...addresses
          // ]
        };

        const data = await delayResolve(() =>
          ModuspaceService.CreateNewCustomer(authentication.jwtToken, payload)
        );
        if (data) {
          setIsCreated(true);
          setCustomer(data);
          toast.success('Your account has been created successfully.');
        }
      }
    } catch (err) {
      console.error('Error at createCustomer:', err);
      toast.error('Fail to create customer.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if ((roles === null || countries === null) && authentication.jwtToken)
      fetchCustomerInfo();
    // eslint-disable-next-line
  }, [roles, countries, authentication.jwtToken]);

  React.useEffect(() => {
    if (isCreated && customer !== null)
      navigate('/dashboard/customers/' + customer.id);
    // eslint-disable-next-line
  }, [isCreated, customer]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              countries={countries}
              fandoms={fandoms}
              scales={scales}
              brands={brands}
              roles={roles}
              onSubmitRef={basicInfoRef}
              createMode
            />
          </Grid>
          {/* <Grid item xs={12}>
                    <Address 
                        countries={countries}
                        isCreateNew={true}
                        setAddresses={setAddresses}
                    />
                </Grid> */}
          <Grid item xs={12}>
            <ChangePassword onSubmitRef={passwordRef} createMode />
          </Grid>
          <Grid item xs={12}>
            <CreateAccount onSubmit={createCustomer} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(UserCreate);
