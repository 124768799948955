/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDInput from 'components/MDInput';
import MDChip from 'components/MDChip';
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from 'layouts/components/FormField';

// Data
// import { getFormSchema } from "./formSchema";
// import { useForm } from "components/MDForm";
import { displayDate } from 'utils/date';
import { joinName } from 'utils/join-name';

function PostInfo({
  postInfo = {},
  setPostInfo = () => void 0,
  onChangeSubmit = () => void 0,
  tagsOptions = [],
  relatedProducts = [],
  statuses = [],
  onSubmitRef
}) {
  // const [currentInfo, setCurrentInfo] = React.useState({ title: "Best Display ever", caption: "The Jedi Return", relatedSeries: ["df"], status: "pending" });
  // const [currentTags, setCurrentTags] = React.useState([]);

  React.useEffect(() => {
    console.log('PostInfo: ', postInfo);
  }, [postInfo]);
  // React.useEffect(() => {
  //   const info = postInfo ?? {};
  //   const postTags = info?.tags ?? [];
  //   setCurrentInfo(info);
  //   setCurrentTags([...currentTags, ...postTags]);
  //   // setFormData(info);
  // }, [postInfo]);

  // React.useEffect(() => {
  //   const f = tags ?? [];
  //   setCurrentTags(f);
  // }, [tags]);

  // React.useEffect(() => {
  //   const s = relatedSeries ?? [];
  //   setCurrentRelatedSeries(s);
  // }, [relatedSeries]);

  // const validateFormData = () => {
  //   const newContentInfo = PostInfo.fillEmptyRequiredFields(currentInfo);
  //   const isNewInfoValid = PostInfo.areRequireFieldsValid(newContentInfo);

  //   setCurrentInfo(newContentInfo);
  //   if (!isNewInfoValid) {
  //     toast.error("Fill in the required basic info first!");
  //     return {
  //       error: true,
  //     };
  //   }

  //   return {
  //     error: false,
  //     info: { ...newContentInfo },
  //   };
  // };

  // if (onSubmitRef) {
  //   onSubmitRef.current = {
  //     onSubmitListener: validateFormData,
  //   };
  // }

  // const getTags = () => {
  //   const tags = currentInfo?.tags || [];
  //   return tags;
  // };

  // const getRelatedSeries = () => {
  //   const relatedSeries = currentInfo?.related_series || [];
  //   return relatedSeries;
  // };

  // const onChangeTags = (selectedTags) => {
  //   const profile = { ...currentInfo.profile, tags: selectedTags };
  //   setCurrentInfo({
  //     ...currentInfo,
  //     profile: profile,
  //   });
  // };

  // const onChangeRelatedProducts = (selectedRelatedProducts) => {
  //   const profile = { ...currentInfo.profile, relatedProducts: selectedRelatedProducts };
  //   setCurrentInfo({
  //     ...currentInfo,
  //     profile: profile,
  //   });
  // };

  // const handleSubmit = () => {
  //   onChangeSubmit(currentInfo);
  // };

  const isRelatedSeriesInvalid = () =>
    PostInfo.isRelatedSeriesInvalid(postInfo);
  const isTagsInvalid = () => PostInfo.isTagsInvalid(postInfo);
  const isStatusInvalid = () => PostInfo.isTextInputFieldInvalid(postInfo);

  const nonEmptyProps = (field = '') => ({
    error: PostInfo.isTextInputFieldInvalid(field),
    helperText: PostInfo.isTextInputFieldInvalid(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : (
      ' '
    ),
    required: true
  });

  return (
    <Card id="basic-info" sx={{ minHeight: '350px' }}>
      <MDBox p={3}>
        <MDTypography variant="h6">Ideaboard Post Information</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {postInfo && (
            <>
              <Grid item xs={12}>
                <FormField
                  label="Title"
                  placeholder="Some title"
                  value={postInfo?.title ?? ''}
                  onChange={({ target: { value: title } }) =>
                    setPostInfo({
                      ...postInfo,
                      title
                    })
                  }
                  inputProps={{ readOnly: true }}
                  {...nonEmptyProps(postInfo.title ?? '')}
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="Caption"
                  placeholder="Some Caption"
                  value={postInfo.caption ?? ''}
                  onChange={({ target: { value: caption } }) =>
                    setPostInfo({
                      ...postInfo,
                      caption
                    })
                  }
                  inputProps={{ readOnly: true }}
                  {...nonEmptyProps(postInfo.caption ?? '')}
                  rows={4}
                  multiline
                />
              </Grid>

              {postInfo?.tags && (
                <Grid item xs={12}>
                  <MDBox py={1}>
                    <MDInput
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Tags"
                      InputProps={{
                        startAdornment: postInfo?.tags.map((item) => (
                          <InputAdornment position="start">
                            <MDChip size="small" text={item} varient="filled" />
                          </InputAdornment>
                        )),
                        readOnly: true
                      }}
                    />
                  </MDBox>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormField
                  label="Post State"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Select Status"
                  value={postInfo.status ?? ''}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <MDBox py={1}>
                  <MDInput
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Related Series"
                    InputProps={{
                      startAdornment: postInfo?.related_series.map((item) => (
                        <InputAdornment position="start">
                          <MDChip size="small" text={item} varient="filled" />
                        </InputAdornment>
                      )),
                      readOnly: true
                    }}
                  />
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

/**
 *
 * @param {{
 * title: "",
 * type: "",
 * status: "",
 * sites: string[],
 * link: "",
 * media: IMedia[] | null,
 * content: "" | null
 * }} userInfo
 */
PostInfo.fillEmptyRequiredFields = (contentInfo) => {
  return {
    title: '',
    type: '',
    status: '',
    sites: [],
    link: '',
    media: [],
    content: '',
    ...contentInfo
  };
};

/**
 *
 * @param {string | undefined} field
 * @returns
 */
PostInfo.isTextInputFieldInvalid = (field = '') =>
  typeof field === 'string' && field.length === 0;

/**
 *
 * @param {{ relatedSeries?: string[] }} currentContentInfo
 * @returns
 */
PostInfo.isRelatedSeriesInvalid = (currentContentInfo) =>
  currentContentInfo.related_series instanceof Array &&
  currentContentInfo.related_series.length === 0;

/**
 *
 * @param {{ tags?: string[] }} currentContentInfo
 * @returns
 */
PostInfo.isTagsInvalid = (currentContentInfo) =>
  currentContentInfo.tags instanceof Array &&
  currentContentInfo.tags.length === 0;

// /**
//  *
//  * @param {{ sites?: string[] }} currentContentInfo
//  * @returns
//  */
// PostInfo.isMediaInvalid = (currentContentInfo) => currentContentInfo.media instanceof Array && currentContentInfo.media.length === 0;

/**
 * Get clean value from `PostInfo.fillEmptyRequiredFields` before
 * passing to the validator.
 * @param {{
 *  title: "",
 * }} info
 */
PostInfo.areRequireFieldsValid = (contentInfo) => {
  if (PostInfo.isTextInputFieldInvalid(contentInfo.title)) return false;
  if (PostInfo.isTextInputFieldInvalid(contentInfo.type)) return false;
  if (PostInfo.isTextInputFieldInvalid(contentInfo.status)) return false;
  if (PostInfo.isTextInputFieldInvalid(contentInfo.link)) return false;
  if (PostInfo.isTextInputFieldInvalid(contentInfo.content)) return false;
  if (PostInfo.isSitesInvalid(contentInfo)) return false;
  // if (PostInfo.isMediaInvalid(contentInfo)) return false;

  return true;
};

export default PostInfo;
