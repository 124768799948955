/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React pages

// Material Dashboard 2 PRO React components
import { rolePermissionEnums } from 'utils/constants';
// @mui icons
import Icon from '@mui/material/Icon';
export const sidebarRoutes = [
  // {
  //   type: "flat",
  //   name: "Dashboard",
  //   key: rolePermissionEnums.dashboard,
  //   permission: "", //empty is no permission needed
  //   route: "/dashboard",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  // },
  {
    type: 'flat',
    name: 'Customers',
    key: 'customer-list',
    permission: rolePermissionEnums.customers,
    route: '/dashboard/customers',
    icon: <Icon fontSize="medium">group</Icon>
  },
  {
    type: 'flat',
    name: 'Orders',
    key: 'orders',
    permission: rolePermissionEnums.orders,
    route: '/dashboard/orders',
    icon: <Icon fontSize="medium">receipt</Icon>
  },
  {
    type: 'flat',
    name: 'Delivery Orders',
    key: 'delivery-orders',
    permission: rolePermissionEnums.deliveryOrders,
    route: '/dashboard/delivery-orders',
    icon: <Icon fontSize="medium">local_shipping</Icon>
  },
  {
    type: 'flat',
    name: 'Payments',
    key: 'payments',
    permission: rolePermissionEnums.payments,
    route: '/dashboard/payments',
    icon: <Icon fontSize="medium">payments</Icon>
  },
  {
    type: 'collapse',
    name: 'Catalog',
    key: 'catalog',
    permission: rolePermissionEnums.catalog,
    icon: <Icon fontSize="medium">category</Icon>,
    collapse: [
      {
        name: 'Products',
        key: 'products',
        route: '/dashboard/catalog/products'
      },
    ]
  },
  {
    type: 'collapse',
    name: 'Products',
    key: 'products',
    permission: rolePermissionEnums.products,
    icon: <Icon fontSize="medium">airplay</Icon>,
    collapse: [
      {
        name: 'Models',
        key: 'devices-models',
        route: '/dashboard/products/models'
      },
      {
        name: 'Moducase',
        key: 'devices-serial-numbers',
        route: '/dashboard/products/moducase'
      },
      {
        name: 'Modulink',
        key: 'devices-master-list',
        route: '/dashboard/products/serial-numbers'
      },
      {
        name: 'Paired Devices',
        key: 'paired-devices-list',
        route: '/dashboard/products/paired-devices'
      },
      {
        name: 'AntiCounterfeit',
        key: 'anticounterfeit',
        route: '/dashboard/products/anti-counterfeit'
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Warranty',
    key: 'warranties',
    permission: rolePermissionEnums.warranty,
    icon: <Icon fontSize="medium">money</Icon>,
    collapse: [
      {
        name: 'Plans',
        key: 'plans',
        route: '/dashboard/warranties/plans'
      },
      {
        name: 'Registration List',
        key: 'items',
        route: '/dashboard/warranties/registered'
      }
      // {
      //   name: "Claim",
      //   key: "claims",
      //   route: "/dashboard/warranties/claims",
      // },
    ]
  },
  {
    type: 'collapse',
    name: 'Ideaboard',
    key: 'ideaboard',
    permission: rolePermissionEnums.ideaboard,
    icon: <Icon fontSize="medium">push_pin</Icon>,
    collapse: [
      {
        name: 'Posts',
        key: 'ideaboard-post-list',
        route: '/dashboard/ideaboard/posts'
      },
      {
        name: 'Review Posts',
        key: 'ideaboard-post-review-list',
        route: '/dashboard/ideaboard/review/posts'
      },
      {
        name: 'Flagged Posts',
        key: 'ideaboard-post-review-flag-list',
        route: '/dashboard/ideaboard/review-flag/posts'
      },
      {
        name: 'Comments',
        key: 'ideaboard-comment-list',
        route: '/dashboard/ideaboard/comments'
      },
      {
        name: 'Flagged Comments',
        key: 'ideaboard-comment-review-list',
        route: '/dashboard/ideaboard/review/comments'
      }
    ]
  },
  {
    type: 'flat',
    name: 'Content Management',
    key: 'cms',
    permission: rolePermissionEnums.contentManagement,
    route: '/dashboard/content',
    icon: <Icon fontSize="medium">book</Icon>
  },
  {
    type: 'flat',
    name: 'Support Center',
    key: 'tickets',
    permission: rolePermissionEnums.supportCenter,
    route: '/dashboard/support-center/tickets',
    icon: <Icon fontSize="medium">support_agent</Icon>
  },
  // {
  //   type: "collapse",
  //   name: "Support Center",
  //   key: "support-center",
  //   permission: rolePermissionEnums.supportCenter,
  //   icon: <Icon fontSize="medium">support_agent</Icon>,
  //   collapse: [
  //     {
  //       name: "Tickets",
  //       key: "tickets",
  //       route: "/dashboard/support-center/tickets",
  //     },
  //     {
  //       name: "Open Tickets",
  //       key: "pending-tickets",
  //       route: "/dashboard/support-center/pending-tickets", // query params to filter record
  //     },
  //   ],
  // },
  {
    type: 'collapse',
    name: 'Settings',
    key: 'settings',
    permission: rolePermissionEnums.settings,
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: 'Manage Admins',
        key: 'user-list',
        route: '/dashboard/settings/admins'
        // icon: <Icon fontSize="medium">group</Icon>,
      },
      {
        name: 'Manage Roles',
        key: 'user-roles',
        route: '/dashboard/settings/roles'
        // icon: <Icon fontSize="medium">add_moderator</Icon>,
      },
      {
        name: 'API Key',
        key: 'api-management',
        route: '/dashboard/settings/application-keys'
        // icon: <Icon fontSize="medium">key</Icon>,
      }
    ]
  },
  {
    type: 'flat',
    name: 'My Profile',
    key: 'profile',
    permission: '',
    route: `/profile`,
    icon: <Icon fontSize="medium">person</Icon>
  },
  {
    type: 'flat',
    name: 'Sign Out',
    key: 'signout',
    permission: '',
    route: '/signout',
    icon: <Icon fontSize="medium">logout</Icon>
  }
];
export function createSidebarRoutes({ name, permissions }) {
  // console.log("login permissions: ", JSON.stringify(permissions));

  if (permissions.includes(rolePermissionEnums.superuser)) return sidebarRoutes;

  // console.log(sidebarRoutes)
  const permissionsRoutes = permissions?.map((permission) => {
    return [...sidebarRoutes.filter((r) => r.permission === permission)];
  });

  return []
    .concat(...sidebarRoutes.filter((r) => r.key === 'home'))
    .concat(...permissionsRoutes)
    .concat(...sidebarRoutes.filter((r) => r.key === 'profile'))
    .concat(...sidebarRoutes.filter((r) => r.key === 'signout'));
}
