import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { displayDate } from "utils/date";
import GeneralTable from "controls/Tables/GeneralTable";
import ModuspaceService from "services/moduspace.service";
import MDTypography from "components/MDTypography";
import { Skeleton } from "@mui/material";

function PaymentIntents({ paymentId }) {
  const [loading, setLoading] = useState(false);
  const [paymentIntents, setPaymentIntents] = useState([]);

  useEffect(() => {
    if (paymentId) {
      getPaymentIntentsByPaymentId(paymentId);
    }
  }, [paymentId]);

  const getPaymentIntentsByPaymentId = async (paymentId) => {
    try {
    
      setLoading(true);
      const paymentIntents =
        await ModuspaceService.GetPaymentIntentsByPaymentId(paymentId);
      setPaymentIntents(
        paymentIntents?.sort((a, b) => (a.paidAt ? -1 : b.cancelledAt ? -1 : 1)),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error at getPaymentIntentsByPaymentId", error);
      toast.error(error);
    }
  };

  const tableInfo = [
    {
      label: "Id",
      name: "id",
    },
    {
      label: "Mode",
      name: "mode",
    },
    {
      label: "Status",
      name: "status",
      render: (value, row) =>
        row.paidAt ? "Paid" : row.cancelledAt ? "Cancelled" : "Pending",
    },
    {
      label: "Paid Date",
      name: "paidAt",
      render: (field) => displayDate(field, "DD MMMM YYYY, hh:mm A"),
    },
    {
      label: "Cancelled Date",
      name: "cancelledAt",
      render: (field) => displayDate(field, "DD MMMM YYYY, hh:mm A"),
    },
    {
      label: "Created Date",
      name: "createdAt",
      render: (field) => displayDate(field, "DD MMMM YYYY, hh:mm A"),
    },
  ];

  return (
    <MDBox mt={2}>
      <MDTypography variant="h5">Payment Intent</MDTypography>
      {loading ? (
        <Skeleton animation="wave" height={118} />
      ) : (
        <GeneralTable
          headColumn={tableInfo}
          bodyColumn={paymentIntents || []}
          disablePagination={true}
        />
      )}
    </MDBox>
  );
}

export default PaymentIntents;
