export default {
  pagination: {
    current: 1,
    pageSize: 10,
    total: 100
  },
  filters: {
    title: {
      visible: true,
      isExactMatch: false,
      searchQuery: null
    },
    userName: {
      visible: true,
      isExactMatch: false,
      searchQuery: null
    },
    userEmail: {
      visible: true,
      isExactMatch: false,
      searchQuery: null
    },
    numOfPins: {
      visible: true,
      isExactMatch: false,
      searchQuery: null
    },
    status: {
      visible: true,
      isExactMatch: false,
      searchQuery: null
    }
  }
};
