import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { delayResolve } from 'utils/delay';

function WarrantyClaimsManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);

  
  async function init() {
    setRefreshing(true);
    try {
      const {
        list: { list }
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListWarrantyClaim(
            authentication.jwtToken
          )
        }),
        100
      );

      const warrantyClaim = list.map(
        ({ id, warranty, amount, createdAt, updatedAt }) => {
          const fullName = joinName(warranty?.user);
          const serialNo =
            warranty?.deviceWarranties[0]?.device?.serialNo ?? '';
          return { id, fullName, serialNo, amount, createdAt, updatedAt };
        }
      );

      setData(warrantyClaim);
    } catch (err) {
      console.error('WarrantyClaimsManagement:', err);
    }
    setRefreshing(false);
  }

  const filterInfo = [];

  const tableInfo = [
    { label: 'Customer', id: 'fullName' },
    { label: 'Device', id: 'serialNo' },
    { label: 'Amount', id: 'amount' },
    { label: 'Created at', id: 'createdAt' },
    { label: 'Updated at', id: 'updatedAt' }
  ];

  const exportInfo = [];

  return (
    <MDGeneralListingV3
      // display texts
      title="Warranty Claim Management"
      // for filtering
      filterInfo={filterInfo}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={['createdAt', 'updatedAt']}
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      // for exports
      exportInfo={exportInfo}
      exportFileNamePrefix="warranty-claims"
      currentActiveParent="warranties"
      currentActiveTab="claims"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(WarrantyClaimsManagement);
