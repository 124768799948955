import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';
import { delayResolve } from 'utils/delay';
import BaseLayout from 'components/MDBaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { useConfirmModal } from 'components/MDConfirm';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';

function ApplicationKeyCreate() {
  const [loading, setLoading] = React.useState('');
  const [deleted, setDeleted] = React.useState(false);
  const { pageControl, authentication } = useStores();
  const location = useLocation();
  const { key } = useParams();
  const navigate = useNavigate();

  const { prompt: promptDeleteApiKey, MDConfirm: MDConfirmDeleteApiKeyModal } =
    useConfirmModal({
      title: 'Delete API Key?',
      content: 'Deleting an API key may disable certain application process.',
      confirmText: 'Delete',
      confirmColor: 'error'
    });

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = formData?.name ?? 'API Key';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location]);

  const {
    components: formComponents,
    validateForm,
    formData,
    setFormData
  } = useForm(getFormSchema());

  React.useEffect(() => {
    if (authentication.jwtToken) fetchApiInfo();
    // eslint-disable-next-line
  }, [authentication.jwtToken]);

  const fetchApiInfo = async () => {
    setLoading('Fetching info...');
    try {
      const apiInfo = await delayResolve(() =>
        ModuspaceService.GetApiKeyByKey(authentication.jwtToken, key)
      );
      setFormData(apiInfo);
    } catch (err) {
      console.error('Error at fetching apiKey:', err);
    }
    setLoading('');
  };

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      await delayResolve(() =>
        ModuspaceService.UpdateApiKeyByKey(
          authentication.jwtToken,
          key,
          formData
        )
      );
    } catch (err) {
      console.error('Error at submitCreateApiKeys:', { err });
    } finally {
      setLoading(null);
      setDeleted(true);
    }
  };

  const submitDeleteApiKey = async () => {
    setLoading('Deleting...');
    try {
      await delayResolve(() =>
        ModuspaceService.DeleteApiKeyByKey(authentication.jwtToken, key)
      );
    } catch (err) {
      console.error('Error at submitDeleteApiKey:', { err });
    } finally {
      setLoading(null);
      setDeleted(true);
    }
  };

  React.useEffect(() => {
    if (deleted) navigate('/dashboard/settings/application-keys');
    // eslint-disable-next-line
  }, [deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteApiKeyModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>

                {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                                <MDBox mt={3} ml="auto">
                                    <MDButton variant="gradient" color="dark" size="large" onClick={handleSubmit}>
                                        update key
                                    </MDButton>
                                </MDBox>
                            </MDBox> */}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
                    <Card sx={{ overflow: "visible" }}>
                        <MDBox
                            pr={3}
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <MDBox p={3} lineHeight={1}>
                                <MDBox mb={1}>
                                    <MDTypography variant="h5">Delete API Key</MDTypography>
                                </MDBox>
                                <MDTypography variant="button" color="text">
                                    Deleting an API key may disable certain application process.
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                                <MDButton variant="gradient" color="error" onClick={() => promptDeleteApiKey({ onConfirm: submitDeleteApiKey })} size="large">
                                    delete key
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid> */}
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

ApplicationKeyCreate.isFieldInvalid = (field) =>
  typeof field === 'string' && field.length === 0;

export default observer(ApplicationKeyCreate);
