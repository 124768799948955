import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
// export default class AuthenticationStore {
//     jwtToken = ''
//     name = ''

//     constructor() {
//         makeAutoObservable(this)
//         makePersistable(this, {
//             name: 'AuthenticationStore',
//             properties: ['jwtToken', 'name'],
//             storage: window.localStorage
//         });
//     }

//     setJwtToken(token) {
//         this.jwtToken = token
//     }

//     setName(name) {
//         this.name = name
//     }
// }

const authStore = makeAutoObservable({
  jwtToken: '',
  id: '',
  name: '',
  permissions: [],
  setJwtToken: (token) => {
    authStore.jwtToken = token;
  },
  setId: (id) => {
    authStore.id = id;
  },
  setName: (name) => {
    authStore.name = name;
  },
  setPermissions: (permissions = []) => {
    authStore.permissions = permissions;
  },
  clear: () => {
    authStore.jwtToken = '';
    authStore.id = '';
    authStore.name = '';
    authStore.permissions = [];
  }
});

makePersistable(authStore, {
  name: 'AuthenticationStore',
  properties: ['jwtToken', 'id', 'name', 'permissions'],
  storage: window.localStorage
});

export default authStore;
