import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import Map from "./components/Map";
import BaseLayout from "components/MDBaseLayout";

function AntiCounterfeitManagement() {
  const [data, setData] = useState([]);
  const [country, setCountry] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [mapData, setMapData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);

  useEffect(() => {
    init();
  }, [tablePagination]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize) {
        return;
      }
      const {
        analytic: { data: mapData },
        list: { list, pagination },
        country,
      } = await delayResolve(
        async () => ({
          analytic: await ModuspaceService.GetAntiCounterfeitAnalytic({
            pageSize: Number.MAX_SAFE_INTEGER,
            currentPage: 1,
            filtered: dataFilters,
          }),
          list: await ModuspaceService.ListProductSerialCounterfeitReportPaginate(
            {
              pageSize: tablePagination.pageSize,
              currentPage: tablePagination.currentPage,
              filtered: dataFilters,
            },
          ),
          country: await ModuspaceService.GetCountries(),
        }),
        100,
      );
      list.forEach(
        (data) => (data.countryName = data.session?.countryInfo?.name),
      );
      list.forEach((data) => (data.country = data.session?.country));
      list.forEach((data) => (data.serialNo = data.serial?.serial));
      list.forEach((data) => (data.product = data.serial?.product));

      setData(list);
      setTotalCount(pagination.total);
      setCountry(
        country?.map((item) => ({ label: item.name, id: item.shortName })),
      );
      setMapData(mapData);
    } catch (err) {
      console.error("AntiCounterfeitManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } =
        await ModuspaceService.ListProductSerialCounterfeitReportPaginate({
          pageSize: Number.MAX_SAFE_INTEGER,
          currentPage: 1,
          filtered: appliedFilter ? dataFilters : [],
        });
      return list;
    } catch (err) {
      console.error("AntiCounterfeitManagement:", err);
    }
  }

  const tableInfo = [
    {
      label: "Country",
      id: "countryName",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Reporter Name",
      id: "reporterName",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Reporter Email",
      id: "reporterEmail",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Store Name",
      id: "storeName",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Description",
      id: "reason",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Product",
      id: "product",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Serial Number",
      id: "serialNo",
      tableRowMapper: (value, row) => value ?? "N/A",
    },
    {
      label: "Date",
      id: "createdAt",
    },
  ];

  /* TODO */
  const importInfo = [];

  const filterInfo = [
    {
      name: "session__country",
      label: "Country",
      type: "multiSelect",
      optionName: "country",
      options: country,
      searchColumn: "session__country",
    },
    {
      name: "storeName",
      label: "Store Name",
      type: "string",
      placeholder: "Store Name",
      searchColumn: "storeName",
    },
    {
      name: "reporterName",
      label: "Reporter Name",
      type: "string",
      placeholder: "Reporter Name",
      searchColumn: "reporterName",
    },
    {
      name: "reporterEmail",
      label: "Reporter Email",
      type: "string",
      placeholder: "Reporter Email",
      searchColumn: "reporterEmail",
    },
    {
      name: "reason",
      label: "Description",
      type: "string",
      placeholder: "Description",
      searchColumn: "reason",
    },
    {
      name: "createdAt",
      label: "Date",
      type: "dateRange",
      searchColumn: "createdAt",
    },
  ];

  const exportInfo = [
    { label: "Country", name: "session.countryInfo.name" },
    { label: "Reporter Name", name: "reporterName" },
    { label: "Reporter Email", name: "reporterEmail" },
    { label: "Store Name", name: "storeName" },
    { label: "Description", name: "reason" },
    { label: "Product", name: "serial.product" },
    { label: "Serial Number", name: "serial.serial" },
    { label: "Date", name: "createdAt" },
  ];

  return (
    <BaseLayout>
      <Map mapData={mapData} />
      <MDGeneralListingV3
        // for filtering
        shouldWrapBaseLayout={false}
        filterInfo={filterInfo}
        setDataFilters={setDataFilters}
        // for table rendering
        _data={data}
        tableInfo={tableInfo}
        dateColumn={["createdAt", "updatedAt"]}
        tablePagination={tablePagination}
        setTablePagination={setTablePagination}
        totalCount={totalCount} // all available data count
        // for action
        rowIDAccessor="id"
        viewOnly={false}
        allowCreate={false}
        createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
          null
        }
        // for exports
        exportInfo={exportInfo}
        fetchAllExportData={fetchAllExportData}
        // for common usage
        title="AntiCounterfeit"
        currentActiveParent="counterfeit-review"
        currentActiveTab="counterfeit-review"
        init={init}
        isRefreshing={isRefreshing}
        importInfo={importInfo}
      />
    </BaseLayout>
  );
}

export default observer(AntiCounterfeitManagement);
