import { TableBody, TableCell, TableRow } from '@mui/material';

const TableBodyCustom = ({ columns = [], rows = [] }) => {
  const displayRowData = (data) => {
    if (typeof data === 'string') return data || '-';
    if (typeof data !== 'string')
      return data !== '' && data !== null ? data : '-';
  };

  return rows.map((row, idx) => (
    <tbody key={idx}>
      <TableRow>
        {columns.map((column, columnIdx) => (
          <TableCell
            className={column?.className || null}
            component="th"
            scope="row"
            key={`${column.id}-${idx}-${columnIdx}`}>
            {displayRowData(row[column.id])}
          </TableCell>
        ))}
      </TableRow>
    </tbody>
  ));
};

export default TableBodyCustom;
