const warrantyTypes = ['Standard', 'Extended', 'Bundle'];

export const getFormSchema = () => [
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    placeholder: 'e.g. Standard 1 Year 3000SGD',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'dropdown',
    name: 'type',
    label: 'Warranty Type',
    placeholder: 'Select a warranty type',
    options: warrantyTypes,
    required: true,
    gridSpacing: 6
  },
  {
    type: 'number',
    name: 'amount',
    label: 'Amount',
    placeholder: 'Write amount in SGD',
    required: true,
    gridSpacing: 6
  },
  {
    type: 'number',
    name: 'dayDuration',
    label: 'Warranty Duration (Days)',
    placeholder: 'Write warranty duration in days',
    required: true,
    gridSpacing: 6
  }
];
