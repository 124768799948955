import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import MDButton from './MDButton';

export default function MDConfirm({
  show = false,
  title = 'Confirm Action?',
  content = <div>Confirm with this action?</div>,
  onCancel = () => void 0,
  onConfirm = () => void 0,
  confirmText = 'Confirm',
  confirmColor = 'success'
}) {
  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        ) : (
          { content }
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onCancel} autoFocus>
          Cancel
        </MDButton>
        <MDButton color={confirmColor} onClick={onConfirm}>
          {confirmText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export function useConfirmModal({
  title = 'Confirm Action?',
  content = 'Confirm with this action?',
  onCancel = () => void 0,
  onConfirm = () => void 0,
  confirmText = 'Confirm',
  confirmColor = 'success'
} = {}) {
  const [show, setShow] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    title,
    content,
    onCancel,
    onConfirm,
    confirmText,
    confirmColor
  });

  const applyOptional = ({
    title,
    content,
    onCancel,
    onConfirm,
    confirmText,
    confirmColor
  }) => {
    setModalContent({
      title: title ?? modalContent.title,
      content: content ?? modalContent.content,
      onCancel: onCancel ?? modalContent.onCancel,
      onConfirm: onConfirm ?? modalContent.onConfirm,
      confirmText: confirmText ?? modalContent.confirmText,
      confirmColor: confirmColor ?? modalContent.confirmColor
    });
  };

  const Component = () => (
    <MDConfirm
      show={show}
      title={modalContent.title}
      content={modalContent.content}
      onCancel={async () => {
        await modalContent.onCancel();
        setShow(false);
      }}
      onConfirm={async () => {
        await modalContent.onConfirm();
        setShow(false);
      }}
      confirmColor={modalContent.confirmColor}
      confirmText={modalContent.confirmText}
    />
  );

  return {
    prompt: ({
      title,
      content,
      onCancel,
      onConfirm,
      confirmText,
      confirmColor
    }) => {
      applyOptional({
        title,
        content,
        onCancel,
        onConfirm,
        confirmText,
        confirmColor
      });
      setShow(true);
    },
    MDConfirm: Component
  };
}
