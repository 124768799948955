import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import { useStores } from 'stores';
import { delay, delayResolve } from 'utils/delay';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { toast } from 'react-toastify';
import { hostName, client } from 'utils/constants';
import joinUrl from 'utils/join-url';
import { CopyAll } from '@mui/icons-material';
import { fromBase64 } from 'utils/base64';
import { useForm } from 'components/MDForm';
import { getFormSchema } from './formSchema';

function ApplicationKeyCreate() {
  const [loading, setLoading] = React.useState('');
  const { pageControl, authentication } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToApiKeyId = (id) => {
    let routes = location.pathname.split('/');
    routes[routes.length - 1] = id;
    navigate(routes.join('/'));
  };

  React.useEffect(() => {
    let routes = location.pathname.split('/').slice(2);
    routes[routes.length - 1] = 'Create New API Key';
    pageControl.overwriteBreadcrumbsRoutes(location.pathname, routes);
    // eslint-disable-next-line
  }, [location]);

  const { components: formComponents, validateForm } = useForm(getFormSchema());

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const { key } = await delayResolve(() =>
        ModuspaceService.CreateApiKey(authentication.jwtToken, {
          ...formData,
          client
        })
      );
      const [hashed, decoded] = fromBase64(key).split(':', 2);
      setModalContent((f) => ({
        ...f,
        key,
        hashed,
        decoded
      }));
      setModalOpen(true);
    } catch (err) {
      console.error('Error at submitCreateApiKeys:', { err });
    }
    setLoading('');
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    hashed: '',
    decoded: '',
    key: '',
    copiedKey: false,
    copiedCurl: false
  });

  const handleModalClose = () => {
    setModalOpen(false);
    navigateToApiKeyId(modalContent.decoded);
  };

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <Dialog
        open={modalOpen}
        onClose={() => void 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Your New API Key</DialogTitle>
        <DialogContent
          style={{
            width: 550
          }}>
          <DialogContentText id="alert-dialog-description">
            Your API key consists of a decoded key and a hashed key:
          </DialogContentText>
          <TextField
            variant="outlined"
            size="small"
            value={modalContent.hashed}
            label="hashed"
            fullWidth
            margin="normal"
          />

          <TextField
            variant="outlined"
            size="small"
            value={modalContent.decoded}
            label="decoded"
            fullWidth
          />

          <Divider />

          <DialogContentText id="alert-dialog-description">
            This is your new API Key:
          </DialogContentText>
          <TextField
            variant="outlined"
            size="small"
            value={modalContent.key}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      setModalContent({
                        ...modalContent,
                        copiedKey: true,
                        copiedCurl: false
                      });

                      navigator.clipboard.writeText(modalContent.key);

                      await delay(5000);
                      setModalContent({
                        ...modalContent,
                        copiedKey: false,
                        copiedCurl: false
                      });
                    }}>
                    <CopyAll />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
          {modalContent.copiedKey && (
            <DialogContentText
              style={{
                fontSize: '.8rem',
                color: 'maroon'
              }}>
              Text copied to clipboard!
            </DialogContentText>
          )}
          <Divider />
          <DialogContentText id="alert-dialog-description">
            Sample cURL Request
          </DialogContentText>
          <TextField
            variant="outlined"
            size="small"
            value={`curl ${joinUrl(
              hostName,
              'api/v1/dashboard/*'
            )} -H "Authorization: ApiKey ${modalContent.key}"`}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      setModalContent({
                        ...modalContent,
                        copiedKey: false,
                        copiedCurl: true
                      });

                      navigator.clipboard.writeText(
                        `curl ${joinUrl(
                          hostName,
                          'api/v1/dashboard/*'
                        )} -H "Authorization: ApiKey ${modalContent.key}"`
                      );

                      await delay(5000);
                      setModalContent({
                        ...modalContent,
                        copiedKey: false,
                        copiedCurl: false
                      });
                    }}>
                    <CopyAll />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
          {modalContent.copiedCurl && (
            <DialogContentText
              style={{
                fontSize: '.8rem',
                color: 'maroon'
              }}>
              Text copied to clipboard!
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description">
            Note: Your API key will only be shown once.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleModalClose}>Done</MDButton>
        </DialogActions>
      </Dialog>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Create API Key</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Once the API key is created, the complete API key will be
                    shown only once. Please keep this API key somewhere safe.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={handleSubmit}
                    size="large">
                    create key
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

ApplicationKeyCreate.isFieldInvalid = (field) =>
  typeof field === 'string' && field.length === 0;

export default observer(ApplicationKeyCreate);
