const DEFAULT_TIMEOUT = 30000; // 30 seconds request timeout

export default async function jsonFetch(url, params = {}) {
  let requestParams = {
    ...params,
    headers: {
      ...(params.headers || {}),
      'Content-Type': 'application/json'
    }
  };

  if (requestParams.body) {
    requestParams.body =
      typeof requestParams.body === 'string'
        ? requestParams.body
        : JSON.stringify(requestParams.body);
  }

  //fetch api cache burst
  const request = await fetch(
    url.includes('?') ? `${url}&${Date.now()}` : `${url}?${Date.now()}`,
    requestParams
  );

  const text = await request.text();
  const requestMeta = {
    ok: request.ok,
    status: request.status
  };
  try {
    return request.headers.get('content-type').includes('application/json')
      ? {
          json: JSON.parse(text),
          ...requestMeta
        }
      : {
          text,
          ...requestMeta
        };
  } catch (err) {
    console.error('Cannot transform text into JSON:', err);
    return {
      text,
      ...requestMeta
    };
  }
}
