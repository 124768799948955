import MDBox from "components/MDBox";
import GeneralTable from "controls/Tables/GeneralTable";
import { displayDate } from 'utils/date';

const OrderInfo = ({ data }) => {
    const tableInfo = [
        {
            label: "Order ID",
            name: "referenceOrderId",

        },
        {
            label: "Store",
            name: "store",
            render: (object, row) => {
                return row?.applicationClient?.label;
            },
        },
        {
            label: "Currency",
            name: "currency",
            render: (object, row) => {
                return row?.orderCurrency?.shortName;
            },
        },
        {
            label: "Amount",
            name: "orderTotalExTax",
            render: (object, row) => {
                return `${row?.orderCurrency?.symbol}${row?.orderTotalExTax}`
            },
        },
        {
            label: "Shipping",
            name: "shippingCostIncTax",
            render: (object, row) => {
                return `${row?.orderCurrency?.symbol}${row?.shippingCostIncTax}`
            },
        },
        {
            label: "Status",
            name: "orderStatus",

        },
        {
            label: 'Order Date',
            name: 'orderDate',
            render: (object) => (
                `${displayDate(new Date(object))}`
            )
        },
    ];

    return (
        <MDBox mt={3}>
            <GeneralTable
                headColumn={tableInfo}
                bodyColumn={data || []}
                disablePagination={true}
            />
        </MDBox>
    );
};

export default OrderInfo;
