import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useNavigate } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from 'pages/users/admins/components/BasicInfo';
import ChangePassword from 'pages/users/admins/components/ChangePassword';
import CreateAccount from 'pages/users/admins/components/CreateAccount';

function UserCreate() {
  const [loading, setLoading] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const basicInfoRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const [countries, setCountries] = React.useState(null);
  const [fandoms, setFandoms] = React.useState(null);
  const [scales, setScales] = React.useState(null);
  const [brands, setBrands] = React.useState(null);
  const [roles, setRoles] = React.useState(null);
  const { authentication } = useStores();
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    setLoading('Fetching metadata...');
    try {
      const { countries, fandoms, scales, brands, roles } = await delayResolve(
        async () => ({
          countries: await ModuspaceService.GetCountries(
            authentication.jwtToken
          ),
          fandoms: await ModuspaceService.GetFandoms(authentication.jwtToken),
          scales: await ModuspaceService.GetScales(authentication.jwtToken),
          brands: await ModuspaceService.GetBrands(authentication.jwtToken),
          roles: await ModuspaceService.GetUserRolesIncludingInactive(
            authentication.jwtToken
          )
        })
      );
      setCountries(countries);
      setFandoms(fandoms);
      setScales(scales);
      setBrands(brands);
      setRoles(roles);
    } catch (err) {
      console.error('Error at fetchUserInfo:', err);
    }
    setLoading(null);
  };

  const createUser = async () => {
    let error = false;
    let userInfo;
    if (basicInfoRef.current.onSubmitListener) {
      const { error: validationError, userInfo: validatedUserInfo } =
        basicInfoRef.current.onSubmitListener();
      const isExistedEmail = await ModuspaceService.VerifyUserEmailExists(
        authentication.jwtToken,
        validatedUserInfo
      );

      error = isExistedEmail;
      error = validationError;
      userInfo = validatedUserInfo;
    } else {
      // flow should not trigger if ref is successfully passed
      error = true;
    }
    let password;
    if (passwordRef.current.onSubmitListener) {
      const { error: validationError, password: validatedPassword } =
        passwordRef.current.onSubmitListener();
      error = validationError;
      password = validatedPassword;
    } else {
      // flow should not trigger if ref is successfully passed
      error = true;
    }

    if (error) {
      return;
    }

    setLoading('Submitting');
    const payload = {
      ...userInfo,
      password,
      require_change: false,
      generatePassword: false
    };

    try {
      const response = await delayResolve(() =>
        ModuspaceService.CreateNewUser(authentication.jwtToken, payload)
      );
      if (response) {
        setIsCreated(true);
        setUser(response);
        toast.success('Administrator data has been created successfully.');
      }
    } catch (err) {
      console.error('Error at createUser:', err);
      toast.error('Fail to created administrator.');
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if ((roles === null || countries === null) && authentication.jwtToken)
      fetchUserInfo();
    // eslint-disable-next-line
  }, [roles, countries, authentication.jwtToken]);

  React.useEffect(() => {
    if (isCreated && user !== null)
      navigate('/dashboard/settings/admins/' + user.id);
    // eslint-disable-next-line
  }, [isCreated, user]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              countries={countries}
              fandoms={fandoms}
              scales={scales}
              brands={brands}
              roles={roles}
              onSubmitRef={basicInfoRef}
              createMode
            />
          </Grid>
          <Grid item xs={12}>
            <ChangePassword onSubmitRef={passwordRef} createMode />
          </Grid>
          <Grid item xs={12}>
            <CreateAccount onSubmit={createUser} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(UserCreate);
