/*
 * MDGeneralListingV3 has some more features over MDGeneralListingV2.
 * 1. Bulk actions
 * 2. Add other buttons (eg. generate numbers)
 *
 * For full usage example, refer to `SerialNumberManagement.js`
 *
 * For bulk actions, add a checkbox column in the tableInfo array:
 * {
 *   label: <Checkbox style={{ padding: 0 }} onClick={selectAllRows} />,
 *   id: 'checkBox',
 *   width: '2%',
 *   disableSortBy: true
 * }
 */
import { useTheme } from "@emotion/react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStores } from "stores";

import {
  Description,
  FileDownload,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Checkbox,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDAutocomplete from "components/MDAutocomplete";
import MDBadge from "components/MDBadge";
import BaseLayout from "components/MDBaseLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";
import FormField from "components/MDFormField";
import MDLoading from "components/MDLoading";
import MDTypography from "components/MDTypography";
import DataTableV3 from "controls/Tables/DataTableV3";
import ImportConfirmationModal from "./components/ImportConfirmationModal";
import ImportNotificationModal from "./components/ImportNotificationModal";
import dayjs from "dayjs";
import Papa from "papaparse";
import { downloadToBrowser } from "utils/download-to-browser";
import ModuspaceService from "services/moduspace.service";
import { displayDate } from "utils/date";
import { joinPath } from "utils/join-url";
import { ImportMessage } from "utils/toast";
import { checkValidImportFile } from "utils/validation";
import { uploadS3file } from "utils/s3Uploader";
import { isDeepEqual } from "utils/is-deep-equal";
import { splitStringIntoSubsetCombinations } from "utils/transform";

const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

function MDGeneralListingV3(props) {
  const {
    title = "",
    shouldWrapBaseLayout = true,
    _data = [],
    sortInfo = [],
    disabledSortColumns = [],
    filterInfo = [],
    allowCreate = false,
    allowedPageSizes = [],
    dateColumn = [],
    init = () => 0,
    isRefreshing = false,
    importDataValidation = () => 0,
    getAWSSession = () => 0,
    importData = () => 0,
    importInfo = [],
    importDataExample = {},
    exportInfo = [],
    isTreeExport = false,
    onFilterReset = () => 0,

    /* V3 props */
    /* Other buttons usage:
     * otherButtons = [{
     *  title: 'Button title',
     *  onClick: () => 0,
     * }]
     */
    otherButtons = [],
    /* Bulk actions usage:
     * bulkActions = {
     * selectedData: [] -> useState array to store selected data
     * setSelectedData: () => 0 -> setState method to set selected data
     * bulkActionHandler: () => 0 -> method to handle bulk actions
     */
    bulkActions,
    totalCount = 0,
    showBulkActions = bulkActions?.options ? true : false,
    tablePagination,
    setTablePagination = () => 0,
    dataFilters = null,
    setDataFilters = () => 0,
    /*
     * Add a `sortBy` state variable in the parent component to store the sort column and type.
     * This enables sorting in the API instead of sorting in the frontend among the available data.
     * In the `tableInfo` items, add an `alias` property for foreign key columns.
     * Eg. `customer.firstName` for sorting by the customer column. See an example in the "orders" page.
     */
    setSortBy = () => 0,
    hideCheckbox = false,
    fetchAllExportData = () => 0,
  } = props;

  let { tableInfo } = props;

  let selectedData = [];
  let setSelectedData = () => 0;
  let bulkActionHandler = () => 0;

  if (bulkActions) {
    selectedData = bulkActions?.selectedData;
    setSelectedData = bulkActions?.setSelectedData;
    bulkActionHandler = bulkActions?.bulkActionHandler;
  }

  const navigate = useNavigate();
  const location = useLocation();
  const getLinkToSubPage = (id) => joinPath(location.pathname, id);
  const navigateToCreatePage = () => navigate(getLinkToSubPage("new"));
  const viewOnly = props?.viewOnly ?? false;
  const allowImport = props?.importInfo?.length > 0 ?? false;
  const allowExport = props?.exportInfo?.length > 0 ?? false;
  const importFileNamePrefix = props.importFileNamePrefix ?? "import";
  const exportFileNamePrefix =
    props.exportFileNamePrefix ?? !!title
      ? title.toLowerCase().replace(/ /g, "_")
      : "export";

  const { authentication, pageControl } = useStores();
  const rowIDAccessor = props.rowIDAccessor || "id";
  const rowIDAccessorForDeletion =
    props.rowIDAccessorForDeletion || rowIDAccessor;
  const rowNameAccessor = props.rowNameAccessor || "name";
  const rowAsLinkAccessor = props.rowAsLinkAccessor || rowNameAccessor;

  const defaultPagination = {
    total: totalCount,
    pageSize: pageControl.defaultPageSize,
    currentPage: 1,
  };

  // Add actions column if createActionButtons is not null. If bulkActions is true, add actions column to the end. Otherwise, add actions column to the front.
  tableInfo =
    props.createActionButtons() === null
      ? [...tableInfo]
      : bulkActions
      ? [...tableInfo, { label: "Action", id: "action", width: "5%" }]
      : [{ label: "Action", id: "action", width: "5%" }, ...tableInfo];

  const [treeExportAnchorEl, setTreeExportAnchorEl] = useState(null);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectionData, setSelectionData] = useState([]);
  const defaultFilterState = useRef(
    filterInfo?.map((item) => {
      let newItem = { ...item };
      if (item.value && !item.defaultValue) newItem.value = item.value;
      else {
        if (item.type === "string" || item.type === "date") newItem.value = "";
        if (item.type === "select" || item.type === "multiSelect")
          newItem.value = item.defaultValue ?? null;
        if (item.type === "dateRange") newItem.value = ",";
      }
      return newItem;
    }) || [],
  );
  const [filterState, setfilterState] = useState(defaultFilterState.current);
  const [sort, setSort] = useState({ column: null, type: "" });
  const [pagination, setPagination] = useState(defaultPagination);
  const [showFilter, setShowFilter] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(false);
  // Observed filters are filters that have options fetched from API
  const [observedFilters, setObservedFilters] = useState(null);

  const [importState, setImportState] = useState({
    confirmationShow: false,
    notificationShow: false,
    importIndex: null,
    importFile: null,
    filePath: "",
  });
  const [loading, setLoading] = useState(false);
  const [importTemplateDropdown, setImportTemplateDropdown] = useState(null);

  const { total, pageSize, currentPage } = pagination;
  const {
    confirmationShow: importConfirmationShow,
    notificationShow: importNotificationShow,
    importFile,
    filePath: importFilePath,
    importIndex,
  } = importState;

  const { column: sortColumn, type: sortType } = sort;
  const prevFilterStateRef = useRef();
  const stringInputRef = useRef({});

  useEffect(() => {
    if (JSON.stringify(pagination) !== JSON.stringify(tablePagination)) {
      setTablePagination(pagination);
    }
  }, [pagination]);

  useEffect(() => fetchingDataWithPagination(), [data, sort, pagination]);

  // Store only filterInfo that is multiSelect or select
  useEffect(() => {
    setObservedFilters(
      filterInfo.filter(
        (item) => item.type === "multiSelect" || item.type === "select",
      ),
    );
    setfilterState(
      filterInfo?.map((item) => {
        let newItem = { ...item };
        let previousItem = filterState.find((i) => i.name === item.name);
        if (previousItem) {
          item = {
            ...(previousItem.value != null && previousItem),
            ...(item.value != null && item),
          };
        }
        if (item.value && !item.defaultValue) newItem.value = item.value;
        else {
          if (item.type === "string" || item.type === "date")
            newItem.value = "";
          if (item.type === "select" || item.type === "multiSelect")
            newItem.value = item.value ?? null;
          if (item.type === "dateRange") newItem.value = ",";
        }
        return newItem;
      }) || [],
    );
  }, [filterInfo]);

  useEffect(() => fetching(), [_data, appliedFilter, observedFilters]);

  const sortedColumnTitle = sort?.column
    ? tableInfo.find(
        (item) => item.id === sort?.column || item.alias === sort?.column,
      )?.label
    : null;

  useEffect(() => {
    pageControl.setCurrentActiveTab(
      props.currentActiveTab,
      props.currentActiveParent,
    );

    const selectedSort = toJS(pageControl.defaultSortProperties).find(
      (item) => item.tab === props.currentActiveTab,
    );
    if (selectedSort) setSort(selectedSort);
  }, [props.currentActiveParent, props.currentActiveTab]);

  const setImportTimeout = async (jobId, quantity = 0) => {
    if (quantity === 0) setLoading("0 %");

    if (quantity > 99) {
      setImportState({
        ...importState,
        importFile: null,
        confirmationShow: false,
      });

      toast.warning(<ImportMessage />);
    } else {
      const job = await ModuspaceService.GetJobById(
        authentication.jwtToken,
        jobId,
      );

      if (job) {
        const { status, total, processed, succeed, failed, filePath } = job;
        if (status === "Pending") {
          setTimeout(() => setImportTimeout(jobId, quantity + 1), 2500);
          return;
        }

        if (status === "Processing") {
          const importPercent = parseInt((processed / total) * 100);
          setLoading(`${importPercent} %`);
          setTimeout(() => setImportTimeout(jobId, quantity + 1), 2500);
          return;
        }

        if (status === "Completed" && failed === 0) {
          setImportState({
            ...importState,
            importFile: null,
            confirmationShow: false,
          });
          toast.success(<ImportMessage job={job} />);
        }

        if (status === "Uncompleted" || failed > 0) {
          setImportState({
            ...importState,
            confirmationShow: false,
            notificationShow: true,
            importFile: null,
            filePath,
          });
          toast.warning(<ImportMessage job={job} />);
        }

        if (status === "Failed") {
          toast.warning(
            "Your import data has been failed, please try again later.",
          );
        }
      }
    }

    refreshPage();
    setLoading(false);
  };
  useEffect(() => {
    // Set filters t setDataFilters if any filter item has a value that is not null or ''
    // Also check if dateRange fields do not just have a comma
    let filters = filterState.map((item) => {
      let filterId = item.searchColumn;
      let filterValue = item.value;
      let filterType = item.type;
      // allow empty string to be passed in
      if (filterValue != null) {
        if (typeof filterValue === "string") filterValue = filterValue.trim();

        if (filterId.includes(","))
          filterId = filterId.split(",").map((i) => i.trim());

        // Step 1: Turn value into array if got comma
        if (filterValue.includes(","))
          filterValue = filterValue.split(",").map((i) => i.trim());
        // Step 2: Check if type is dateRange and value = ["", ""] or not an array, just ignore it
        if (
          (filterType === "dateRange" && !Array.isArray(filterValue)) ||
          (filterType === "dateRange" &&
            Array.isArray(filterValue) &&
            filterValue.filter((x) => !!x).length === 0)
        ) {
          filterValue = null;
        }
        if (filterType === "multiSelect" && !Array.isArray(filterValue)) {
          if (filterValue.length > 0) filterValue = [filterValue];
          else filterValue = null;
        }
        // just dont pass filter if array length is 0
        if (Array.isArray(filterValue) && filterValue.length === 0) {
          filterValue = null;
        }
      }

      return {
        id: filterId,
        value: filterValue ?? "",
      };
    });

    if (
      prevFilterStateRef.current &&
      !isDeepEqual(filters, prevFilterStateRef.current)
    ) {
      // we need to grab the customer search filter: userId, so check against previous state and make sure no duplicate filter pass in and prioritise latest filter
      setDataFilters((prev) =>
        [
          ...prev.filter(
            (prevItem) =>
              !filters
                .map((currItem) => JSON.stringify(currItem?.id))
                .includes(JSON.stringify(prevItem?.id)),
          ),
          ...filters,
        ].filter((x) => x?.value),
      );
    }
    prevFilterStateRef.current = filters;
  }, [filterState]);

  // Refresh on applyFilter/resetFilter clicked
  useEffect(() => {
    refreshPage();
  }, [appliedFilter]);

  async function fetching() {
    const fetchedData = _data;
    let fetchedSelectionData = [];
    filterInfo.forEach((filter) => {
      const { optionName, type, options } = filter;
      if (type === "select" || type === "multiSelect") {
        fetchedSelectionData = {
          ...fetchedSelectionData,
          [optionName]: options,
        };
      }
    });
    setData(fetchedData);
    setSelectionData(fetchedSelectionData);
    setPagination({ ...pagination, total: totalCount });
  }

  function restructureTableStyleData(passedData) {
    return (
      passedData?.map((row) =>
        (tableInfo || []).reduce(
          (a, b) => {
            const key = b.id;
            const value = row[key];
            const mappedValue = b.tableRowMapper
              ? b.tableRowMapper(value, row)
              : value;
            a[key] = mappedValue;
            return a;
          },
          {
            __checked: false,
            __rowID: row[rowIDAccessor],
            [rowIDAccessor]: row[rowIDAccessor],
          },
        ),
      ) || []
    );
  }

  function fetchingDataWithPagination() {
    const _tableData = sortDataLogic(data, sort.column, sort.type);
    setTableData([..._tableData]);
  }

  function onPageChange(currentPage) {
    const newPagination = { ...pagination, currentPage };
    setPagination(newPagination);
  }

  function onPageSizeChange(pageSize) {
    const newPagination = { ...pagination, pageSize, currentPage: 1 };
    setPagination(newPagination);
    pageControl.setDefaultPageSize(pageSize);
  }

  function onFilterChange(name, type, value) {
    const newfilterState = filterState.map((item) => {
      if (name === item.name) {
        if (type === "string" || type === "date" || type === "select") {
          item.value = value;
        } else if (type === "multiSelect") {
          const newValue = value.map((item) => item.id);
          item.value = String(newValue);
        }
      }
      return item;
    });

    setfilterState(newfilterState);
  }

  function onDateRangeFilterChange(name, position, value) {
    const newfilterState = filterState.map((item) => {
      if (name === item.name) {
        let separatedValue = item.value.split(",");
        separatedValue[position] = value
          ? position === 0
            ? new Date(value.setHours(0, 0, 0, 0)).toISOString()
            : new Date(value.setHours(23, 59, 59, 999)).toISOString()
          : null;
        item.value = String(separatedValue);
      }
      return item;
    });

    setfilterState(newfilterState);
  }

  function validateFilter() {
    let validFilteredState = filterState.filter((item) => item.value);
    for (let i = 0; i < validFilteredState.length; i++) {
      if (validFilteredState[i].type === "dateRange") {
        const dateRange = validFilteredState[i].value
          .split(",")
          .filter((d) => d != "");
        // If date to is before date from, show alert
        if (dateRange[0] && dateRange[1]) {
          if (dayjs(dateRange[0]).isAfter(dayjs(dateRange[1]))) {
            alert(
              `There is an error for the ${validFilteredState[i].label} Date filter. The End Date should not be earlier than the Start Date`,
            );
            return false;
          }
        }
      }
    }
    return true;
  }

  function applyFilter() {
    if (!validateFilter()) return;
    setPagination({ ...pagination, currentPage: 1 });
    setAppliedFilter(!appliedFilter);
  }

  function resetFilter() {
    onFilterReset();
    setfilterState([...defaultFilterState.current]);
    setPagination({ ...defaultPagination, total: totalCount });
    setAppliedFilter(!appliedFilter);
    setDataFilters([]);
    // clear the string input ref
    if (stringInputRef.current)
      Object.keys(stringInputRef.current).map((key) => {
        if (stringInputRef.current?.[key]?.value)
          stringInputRef.current[key].value = "";
      });
  }

  function onSortChange(column, type) {
    setData(sortDataLogic(data, column, type));
    setSort({ column, type });
    pageControl.setSort({ tab: props.currentActiveTab, column, type });
  }

  useEffect(() => {
    // Update sortBy in parent component when sort changes
    setSortBy({ id: sort?.column, desc: sort?.type === "desc" });
  }, [sort]);

  function sortDataLogic(_data, column, type) {
    const requiredSortData = _data ?? data;

    if (column && type) {
      const columnMapper =
        sortInfo.find((item) => item.name === column)?.rowMapper ?? null;

      return requiredSortData.sort((x, y) => {
        let a = type === "asc" ? x[column] : y[column];
        let b = type === "asc" ? y[column] : x[column];

        // handle Object/Special type data
        if (columnMapper) {
          a = columnMapper(a);
          b = columnMapper(b);
        } else {
          a = Array.isArray(a) ? a.join("") : a;
          b = Array.isArray(b) ? b.join("") : b;
        }

        if (dateColumn.includes(column)) {
          if (a === null || a === "") {
            return -1;
          } else if (b === null || b === "") {
            return 1;
          } else {
            return new Date(a) - new Date(b);
          }
        } else if (
          (typeof a === "string" && typeof b === "string") ||
          a === null ||
          b === null
        ) {
          if (a === null || a === "") {
            return -1;
          } else if (b === null || b === "") {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        } else {
          return a - b;
        }
      });
    } else {
      return requiredSortData.sort((x, y) => x.id - y.id);
    }
  }

  function showImportExcel(event, index) {
    const { files } = event.target;
    const file = files[0];

    if (!file) {
      return;
    }

    if (!checkValidImportFile(file)) {
      return toast.error("Only csv file extension is allowed.");
    }

    setImportState({
      ...importState,
      confirmationShow: true,
      importFile: file,
      importIndex: index,
    });
  }

  async function importExcel(config) {
    setLoading("Importing");

    try {
      const importFeature = importInfo[importIndex];

      const session = await importFeature.getSession();
      if (!session) {
        throw session;
      }

      const fileName = `${importFileNamePrefix}-${new Date().valueOf()}.${
        importFile.type.split("/")[1]
      }`;
      const result = await uploadS3file({
        session,
        file: importFile,
        fileName,
        onError: (err) => {
          console.log(err);
        },
        onProgress: (progress) => {
          console.log(progress);
        },
        onSuccess: async (result) => {
          console.log(result);
        },
      });

      const payload = {
        filePath: result.Key,
        fileUrl: result.Location,
        config,
      };
      const response = await importFeature.importData(payload);
      if (response) {
        setImportTimeout(response.id);
      } else {
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  }

  function downloadImportExcelExample(index) {
    try {
      const importTarget = importInfo[index];

      const filename = `${importTarget.fileName}-example.csv`;
      const csvHeader = importTarget.importColumn.map((item) => item.name);
      const importExampleData = [importTarget.importDataExample.data].map(
        (item) => {
          return Object.keys(item).map((key) => item[key]);
        },
      );

      const csv = Papa.unparse({ fields: csvHeader, data: importExampleData });
      downloadToBrowser(csv, filename, "text/csv");

      setImportTemplateDropdown(null);
    } catch (err) {
      console.error("Error at downloadImportExcelExample:", { err });
    }
  }

  async function exportExcel(
    exportInfo,
    exportFileNamePrefix,
    exportTargetKey,
  ) {
    setLoading(true);

    try {
      const filename = `${exportFileNamePrefix}_${dayjs().format(
        "DDMMYYYY",
      )}.csv`;

      const dateColumn = props?.dateColumn ?? [];

      // Check selected rows first. Then if filteres applied, export all filtered data
      let rows =
        bulkActions?.selectedData?.length > 0
          ? _data.filter((item) => bulkActions?.selectedData?.includes(item.id))
          : await fetchAllExportData(dataFilters?.length > 0);

      // to grab only certain field from child
      if (exportTargetKey)
        rows = rows
          ?.map((r) => r?.[exportTargetKey]?.map((x) => ({ base: r, ...x })))

          .filter((x) => x)
          .flat();

      const csvHeader = exportInfo?.map((item) => item.label);
      const exportData = rows?.map((item) => {
        const obj = [];

        exportInfo.forEach((info) => {
          const { name, rowMapper, dateFormat } = info;
          /* When prop name includes a dot, it means it accesses a nested object property: eg. requester.email */
          let itemData = null;
          if (name.includes(".")) {
            const nestedProps = name.split(".");
            const lastNestedProp = nestedProps.pop();
            itemData = // iterate over the remaining array value
              // and define the object if not already defined
              nestedProps.reduce(function (o, key) {
                // define the object if not defined and return
                return (o[key] = o[key] || {});
                // set initial value as object
                // and set the property value
              }, item);
            // is an array fields
            if (Array.isArray(itemData)) {
            } else {
              itemData = itemData[lastNestedProp] || "-";
            }
          } else {
            itemData = item[name];
          }

          if (typeof itemData === "boolean")
            itemData = itemData ? "TRUE" : "FALSE";

          if (rowMapper) {
            obj.push(info.rowMapper(itemData, item) || "-");
          } else if (
            dateColumn.includes(name) &&
            itemData !== null &&
            itemData !== "" &&
            dayjs(itemData).isValid()
          ) {
            obj.push(
              dateFormat
                ? dayjs(itemData).format(dateFormat)
                : displayDate(new Date(itemData)),
            );
          } else {
            obj.push(itemData || "-");
          }
        });
        return obj;
      });

      const csv = Papa.unparse({ fields: csvHeader, data: exportData });
      downloadToBrowser(csv, filename, "text/csv");
    } catch (err) {
      console.error("Error at exportExcel:", { err });
    } finally {
      setLoading(false);
    }
  }

  function refreshPage() {
    init();
  }

  function promptComponentInformation() {
    toast.info("General Listing V3");
  }

  //rendering elements
  const renderFilterableComponents = () => {
    return (
      filterState?.map((item, index) => {
        const { name, options, optionName, label, type, placeholder, render } =
          item;

        if (render) {
          return (
            <Grid
              item
              xs={6}
              key={`${label}-${type}`}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
              }}>
              {render()}
            </Grid>
          );
        }

        if (type === "string") {
          return (
            <Grid item xs={6} key={`${label}-${type}`}>
              <FormField
                type="search"
                label={label}
                name={name}
                inputRef={(el) => (stringInputRef.current[index] = el)}
                placeholder={placeholder}
                // dont set a value here will cause performance lag
                // improve string performance on blur search
                onBlur={(event, val) => {
                  let fullText = event.target.value.trim();
                  if (fullText?.match(" ")) {
                    // only unique array, include the fullText Search as well
                    fullText = splitStringIntoSubsetCombinations(
                      fullText,
                      " ",
                      1,
                    );
                  }
                  onFilterChange(name, type, fullText);
                }}
              />
            </Grid>
          );
        }

        if (type === "select") {
          return (
            <Grid item xs={6} key={`${label}-${type}`}>
              <MDAutocomplete
                label={label}
                name={name}
                placeholder={placeholder}
                // Resolve Warning: A component is changing an uncontrolled Autocomplete to be controlled
                defaultValue={null}
                value={
                  options?.find((select) => select.id === item.value) || null
                }
                options={options || []}
                onChange={(event, val) => {
                  onFilterChange(name, type, val.id);
                }}
              />
            </Grid>
          );
        }

        if (type === "multiSelect") {
          return (
            <Grid item xs={6} key={`${label}-${type}`}>
              <MDAutocomplete
                label={label}
                name={name}
                placeholder={placeholder}
                // Resolve Warning: A component is changing an uncontrolled Autocomplete to be controlled
                defaultValue={[]}
                value={options?.filter((select) =>
                  (item.value?.split(",") || []).includes(select.id),
                )}
                options={options || []}
                onChange={(event, val) => onFilterChange(name, type, val)}
                multiple
              />
            </Grid>
          );
        }

        if (type === "date") {
          return (
            <Grid item xs={6} key={`${label}-${type}`}>
              <MDDatePickerFullWidth
                input={{
                  placeholder: placeholder || "",
                }}
                options={{
                  dateFormat: "d-m-Y",
                }}
                label={label}
                value={item.value || ""}
                onChange={(date) => onFilterChange(name, type, date)}
              />
            </Grid>
          );
        }

        if (type === "dateRange") {
          const dateRangeValue = item.value.split(",");
          const convertedDateFrom = dateRangeValue?.[0]
            ? dayjs(dateRangeValue[0]).format("DD-MM-YYYY")
            : null;
          const convertedDateTo = dateRangeValue?.[1]
            ? dayjs(dateRangeValue[1]).format("DD-MM-YYYY")
            : null;
          return (
            <Grid
              item
              xs={6}
              key={`${label}-${type}`}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
              }}>
              <MDDatePickerFullWidth
                input={{
                  placeholder: "from",
                  value: convertedDateFrom || "",
                }}
                options={{
                  dateFormat: "d-m-Y",
                }}
                label={label}
                value={dateRangeValue?.[0] ? new Date(dateRangeValue?.[0]) : ""}
                onChange={(date) => onDateRangeFilterChange(name, 0, date[0])}
              />
              <MDDatePickerFullWidth
                input={{
                  placeholder: "to",
                  value: convertedDateTo || "",
                }}
                options={{
                  dateFormat: "d-m-Y",
                }}
                value={dateRangeValue?.[1] ? new Date(dateRangeValue?.[1]) : ""}
                onChange={(date) => onDateRangeFilterChange(name, 1, date[0])}
              />
            </Grid>
          );
        }
      }) || null
    );
  };

  const createActionButtons = (rowIDForEdit, rowIDForDeletion, rowName) => {
    const viewButton = (
      <Grid item>
        <MDButton
          onClick={() => navigate(getLinkToSubPage(rowIDForEdit))}
          size="small"
          color="info"
          style={{ minWidth: "unset" }}>
          <Icon>visibility_outlined</Icon>
        </MDButton>
      </Grid>
    );
    const editButton = (
      <Grid item>
        <MDButton
          onClick={() => navigate(getLinkToSubPage(rowIDForEdit))}
          size="small"
          color="secondary"
          style={{ minWidth: "unset" }}>
          <Icon>edit</Icon>
        </MDButton>
      </Grid>
    );
    const deleteButton = (
      <Grid item>
        <MDButton
          onClick={() =>
            promptDeleteRow({
              title: deleteSpecificRowTextSupplier(rowName),
              content: deleteSpecificRowDescTextSupplier(rowName),
              onConfirm: () => deleteSpecificRow(rowIDForDeletion),
            })
          }
          size="small"
          color="error"
          style={{ minWidth: "unset" }}>
          <Icon>delete</Icon>
        </MDButton>
      </Grid>
    );
    return (
      <Grid container spacing={1}>
        {viewOnly && viewButton}
        {!viewOnly &&
          props.createActionButtons &&
          props.createActionButtons(
            rowIDForEdit,
            rowName,
            editButton,
            deleteButton,
            data?.find((e) => e[rowIDAccessor] === rowIDForEdit),
          )}
        {!viewOnly && !props.createActionButtons && editButton}
        {!viewOnly && !props.createActionButtons && deleteButton}
      </Grid>
    );
  };

  //React Table data structure
  const MUITableData = {
    columns: [...(tableInfo || []).map((item) => ({ ...item }))],

    rows: restructureTableStyleData(
      tableData?.map((item, index) => {
        let newItem = { ...item };
        Object.keys(item).forEach((key) => {
          if (
            dateColumn.includes(key) &&
            newItem[key] &&
            dayjs(newItem[key]).isValid()
          ) {
            newItem[key] = displayDate(new Date(newItem[key]));
          }
        });
        const checked = selectedData.includes(newItem[rowIDAccessor]);
        newItem.__checked = checked;
        return {
          ...newItem,
          [rowAsLinkAccessor]: (
            <Link to={getLinkToSubPage(newItem[rowIDAccessor])}>
              {typeof newItem[rowAsLinkAccessor] === "object" &&
              newItem[rowAsLinkAccessor].name
                ? newItem[rowAsLinkAccessor].name
                : newItem[rowAsLinkAccessor]}
            </Link>
          ),
          checkBox: hideCheckbox ? null : (
            <Checkbox
              style={{ padding: 0 }}
              disabled={isRefreshing}
              onChange={({ target: { checked } }) => {
                if (checked) {
                  // Add to selectedData
                  setSelectedData([...selectedData, newItem[rowIDAccessor]]);
                  newItem.__checked = true;
                } else {
                  // Remove from selectedData
                  setSelectedData(
                    selectedData.filter((e) => e !== newItem[rowIDAccessor]),
                  );
                  newItem.__checked = false;
                }
              }}
              checked={newItem.__checked}
            />
          ),
          action: createActionButtons(
            newItem[rowIDAccessor],
            newItem[rowIDAccessorForDeletion],
            newItem[rowNameAccessor],
          ),
        };
      }),
    ),
  };

  const renderTabPanel = (
    <>
      <MDBox pt={3} px={3}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <MDTypography variant="h4" fontWeight="medium">
              {title}{" "}
              {sortColumn && sortType && sortedColumnTitle && (
                <MDBadge
                  size="xs"
                  color="light"
                  badgeContent={`${sortType} sort by ${sortedColumnTitle}`}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                {filterState.length > 0 && (
                  <MDButton
                    style={{ minWidth: "unset" }}
                    size="small"
                    disabled={isRefreshing}
                    onClick={() => setShowFilter(!showFilter)}
                    color="secondary"
                    variant={showFilter ? undefined : "outlined"}
                    startIcon={<Icon fontSize="large">filter_alt</Icon>}>
                    filter
                  </MDButton>
                )}
              </Grid>
              {otherButtons?.map(
                (item, index) =>
                  (
                    <Grid item key={index}>
                      <MDButton
                        style={{ minWidth: "unset" }}
                        size="small"
                        disabled={isRefreshing}
                        onClick={item.onClick}
                        color="secondary"
                        variant="outlined"
                        startIcon={<FileDownload />}>
                        {item.title}
                      </MDButton>
                    </Grid>
                  ) ?? null,
              )}
              {allowImport && (
                <>
                  {importInfo.map((item, index) => {
                    const importFileRef = useRef();

                    return (
                      <Grid item key={index}>
                        <input
                          ref={importFileRef}
                          type="file"
                          hidden
                          onChange={(event) => {
                            showImportExcel(event, index);
                            if (importFileRef?.current?.value) {
                              importFileRef.current.value = "";
                            }
                          }}
                        />
                        <MDButton
                          style={{ minWidth: "unset" }}
                          size="small"
                          disabled={isRefreshing}
                          onClick={() => importFileRef.current.click()}
                          color="secondary"
                          variant="outlined"
                          startIcon={<FileDownload />}>
                          {item.title ?? "Import"}
                        </MDButton>
                      </Grid>
                    );
                  })}

                  <Grid item>
                    <Tooltip title="Import CSV Template">
                      <span>
                        <MDButton
                          style={{ minWidth: "unset" }}
                          size="small"
                          disabled={isRefreshing}
                          color="secondary"
                          variant="outlined"
                          onClick={(event) =>
                            setImportTemplateDropdown(event.currentTarget)
                          }>
                          <Description />
                        </MDButton>
                      </span>
                    </Tooltip>

                    <Menu
                      id="basic-menu"
                      anchorEl={importTemplateDropdown}
                      open={Boolean(importTemplateDropdown)}
                      onClick={() => setImportTemplateDropdown(null)}
                      onClose={() => setImportTemplateDropdown(null)}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}>
                      {importInfo.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => downloadImportExcelExample(index)}>
                          {item.importDataExample.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </>
              )}
              {allowExport && (
                <Grid item>
                  <MDButton
                    style={{ minWidth: "unset" }}
                    size="small"
                    disabled={isRefreshing}
                    onClick={(e) =>
                      isTreeExport
                        ? setTreeExportAnchorEl(e.currentTarget)
                        : props.exportExcel
                        ? props.exportExcel()
                        : exportExcel(exportInfo, exportFileNamePrefix)
                    }
                    color="secondary"
                    variant="outlined"
                    startIcon={<FileDownload />}>
                    export
                  </MDButton>
                </Grid>
              )}
              {allowExport && isTreeExport && (
                <Menu
                  id="basic-menu"
                  anchorEl={treeExportAnchorEl}
                  open={treeExportAnchorEl ? true : false}
                  onClose={() => setTreeExportAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  {exportInfo.map((ei) => (
                    <MenuItem
                      key={ei.buttonName}
                      onClick={() => {
                        if (props.exportExcel) props.exportExcel();
                        else
                          exportExcel(
                            ei.fields,
                            ei.exportFileNamePrefix,
                            ei.exportTargetKey,
                          );

                        setTreeExportAnchorEl(null);
                      }}>
                      {ei.buttonName}
                    </MenuItem>
                  ))}
                </Menu>
              )}
              <Grid item>
                <MDButton
                  style={{ minWidth: "unset" }}
                  size="small"
                  disabled={isRefreshing}
                  onClick={refreshPage}
                  color="secondary"
                  variant="outlined">
                  <Icon fontSize="large">refresh</Icon>
                </MDButton>
              </Grid>
              {!viewOnly && (
                <Grid item>
                  {allowCreate && (
                    <MDButton
                      style={{ minWidth: "unset" }}
                      size="small"
                      disabled={isRefreshing}
                      onClick={navigateToCreatePage}
                      color="secondary"
                      key="create-or-delete">
                      <Icon fontSize="large">add</Icon>
                    </MDButton>
                  )}
                </Grid>
              )}
              <Grid item>
                <MDButton
                  style={{ minWidth: "unset" }}
                  size="small"
                  onClick={promptComponentInformation}
                  color="secondary"
                  variant="outlined">
                  <Icon fontSize="large">info</Icon>
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Accordion
          expanded={showFilter}
          sx={{
            boxShadow: "none",
            border: "none",
          }}>
          {!showFilter && (
            <AccordionSummary
              sx={{
                border: "none",
                minHeight: "0",
              }}></AccordionSummary>
          )}
          <AccordionDetails
            style={{
              background: "#FAFAFA",
              marginTop: 20,
            }}>
            <Grid container spacing={3} px={1} py={3}>
              {renderFilterableComponents()}
            </Grid>
            <Grid container spacing={3} px={1} py={3}>
              <Grid item xs={2}>
                <MDButton
                  variant="outlined"
                  color="secondary"
                  onClick={applyFilter}
                  disabled={isRefreshing}>
                  apply filter
                </MDButton>
              </Grid>
              <Grid item xs={2}>
                <MDButton
                  variant="outlined"
                  color="secondary"
                  onClick={resetFilter}
                  disabled={isRefreshing}>
                  clear filter
                </MDButton>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
    </>
  );

  const renderDataTable = (
    <>
      <MDBox py={1}>
        {isRefreshing ? (
          <MDTypography
            variant="subtitle2"
            color="secondary"
            style={{ textAlign: "center", margin: "3rem 0" }}>
            Fetching Data...
          </MDTypography>
        ) : (MUITableData.rows.length < 1 && appliedFilter) ||
          _data.length < 1 ? (
          <MDTypography
            variant="subtitle2"
            color="secondary"
            style={{ textAlign: "center", margin: "3rem 0" }}>
            No Data Found
          </MDTypography>
        ) : (
          <DataTableV3
            table={MUITableData}
            sort={sort}
            disabledSortColumns={disabledSortColumns}
            onSortChange={onSortChange}
            loading={isRefreshing}
            showBulkActions={showBulkActions}
            bulkActions={bulkActions?.options}
            bulkActionHandler={bulkActionHandler}
          />
        )}
      </MDBox>
      <MDBox py={1}>
        <TableContainer style={{ boxShadow: "none" }}>
          <Table>
            <TableFooter style={{ borderTop: "0.0625rem solid #f0f2f5" }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={
                    allowedPageSizes.length
                      ? pageControl.pageSizeOptions.filter((size) =>
                          allowedPageSizes.includes(size),
                        )
                      : pageControl.pageSizeOptions
                  }
                  colSpan={3}
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={currentPage - 1}
                  onPageChange={(page) => onPageChange(page + 1)}
                  onRowsPerPageChange={({ target: { value: rpp } }) =>
                    onPageSizeChange(Number(rpp))
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </MDBox>
    </>
  );

  const content = (
    <>
      <MDLoading show={!!loading} text={loading} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            {renderTabPanel}
            {renderDataTable}
          </Card>
        </Grid>
      </Grid>
      {importConfirmationShow && (
        <ImportConfirmationModal
          open={importConfirmationShow}
          onClose={() =>
            setImportState({
              ...importState,
              confirmationShow: false,
              importFile: null,
            })
          }
          importFile={importFile}
          importInfo={importInfo[importIndex]}
          onSubmit={importExcel}
        />
      )}
      {importNotificationShow && (
        <ImportNotificationModal
          open={importNotificationShow}
          onClose={() =>
            setImportState({
              ...importState,
              notificationShow: false,
              failData: null,
            })
          }
          getSession={importInfo[importIndex].getSession}
          importFilePath={importFilePath}
        />
      )}
    </>
  );
  return shouldWrapBaseLayout ? <BaseLayout>{content}</BaseLayout> : content;
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <MDBox sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </MDBox>
  );
}

export default observer(MDGeneralListingV3);
