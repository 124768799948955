import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { delayResolve } from 'utils/delay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModuspaceService from 'services/moduspace.service';
import BaseLayout from 'layouts/components/BaseLayout';
import MDLoading from 'components/MDLoading';
import { useConfirmModal } from 'components/MDConfirm';
import { getFormSchema } from './formSchema';
import { useForm } from 'components/MDForm';

function WarrantyPlanDetails() {
  const [loading, setLoading] = React.useState('');
  const [updated, setUpdated] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const { id } = useParams();
  const { pageControl, authentication } = useStores();

  const {
    components: formComponents,
    validateForm,
    formData,
    setFormData
  } = useForm(getFormSchema());
  const navigate = useNavigate();

  const fetchInfo = async () => {
    setLoading('Fetching Info...');
    try {
      const {
        name = null,
        type = null,
        amount = null,
        dayDuration = null
      } = await delayResolve(() =>
        ModuspaceService.GetWarrantyPlanById(authentication.jwtToken, id)
      );
      setFormData({
        name,
        type,
        amount,
        dayDuration
      });
    } catch (err) {
      console.error('Error at fetchInfo:', { err });
    }
    setLoading('');
  };

  React.useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line
  }, []);

  const handleUpdate = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error('Fill in the required fields.');
      return;
    }
    setLoading('Submitting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateWarrantyPlanById(
          authentication.jwtToken,
          id,
          formData
        )
      );
      if (response) {
        setUpdated(true);
        toast.success('Warranty Plan has been updated.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to update warranty plan.');
    }
    setLoading('');
  };

  const handleDelete = async () => {
    setLoading('Deleting...');
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteWarrantyPlanById(authentication.jwtToken, id)
      );
      if (response) {
        setDeleted(true);
        toast.success('Warranty Plan has been deleted.');
      }
    } catch (err) {
      console.error('Error at submitting:', { formData, err });
      toast.error('Fail to delete warranty plan.');
    }
    setLoading(null);
  };

  const {
    prompt: promptDeleteWarrantyPlan,
    MDConfirm: MDConfirmDeleteWarrantyPlanModal
  } = useConfirmModal({
    title: 'Delete ',
    content:
      'Once deleted, the plan cannot be retrieved and must be recreated.',
    confirmText: 'Delete',
    confirmColor: 'error',
    onConfirm: handleDelete
  });

  React.useEffect(() => {
    if (updated || deleted) navigate('/dashboard/warranties/plans');
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteWarrantyPlanModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Update Warranty Plan
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Warranty plan containing the template for new warranties
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap">
                  <MDBox mt={3} ml="auto">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      onClick={handleUpdate}>
                      Update plan
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">
                      Delete Warranty Plan
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    Only warranty plan that is not associated (e.g. with another
                    active warranty) can be deleted.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={promptDeleteWarrantyPlan}
                    size="large">
                    delete plan
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(WarrantyPlanDetails);
