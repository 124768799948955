import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import { displayImageApplicationClient } from 'utils/data-display';
import { getBadgeColorByStatus } from '../../../../../../utils/element';

function ApplicationClientList({ applicationClientUsers = [] }) {
  const renderApplicationClients = applicationClientUsers.map((item, index) => (
    <MDBox
      key={index}
      component="li"
      display="flex"
      alignItems="center"
      py={1}
      mb={1}>
      <MDBox mr={2}>
        <MDAvatar
          src={displayImageApplicationClient(item?.applicationClient?.ssoType)}
          alt={item?.applicationClient?.ssoType}
          shadow="md"
        />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {item?.applicationClient?.label}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {item?.applicationClient?.name}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDBadge
          size="xs"
          badgeContent={item?.status}
          color={getBadgeColorByStatus(item?.status)}
        />
      </MDBox>
    </MDBox>
  ));

  return (
    <MDBox sx={{ py: 3, pl: 1 }} style={{ width: '100%' }}>
      <MDBox component="ul" display="flex" flexDirection="column">
        {renderApplicationClients}
      </MDBox>
    </MDBox>
  );
}

export default ApplicationClientList;
