import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";
import MDChip from "components/MDChip";
import MDBadge from "components/MDBadge";

import FormField from "layouts/components/FormField";

import { toast } from "react-toastify";

import ModuspaceService from "services/moduspace.service";
import { joinName } from "utils/join-name";
import OrderInfoAndLogs from "./OrderInfoAndLogs";
import PaymentIntents from "./PaymentIntents";

function CreateFields({
  info,
  orders,
  paymentModes,
  currencies,
  onChangeSubmit = () => 0,
  onChange = () => 0,
}) {
  const [currentInfo, setCurrentInfo] = useState(info ?? {});
  const [customer, setCustomer] = useState(null);
  const [defaultOrder, setDefaultOrder] = useState(null);
  const { authentication } = useStores();
  // If ID is present, it is an edit page
  const { id } = useParams();

  useEffect(() => {
    setCurrentInfo(info ?? {});
  }, [info]);

  useEffect(() => {
    onChange(currentInfo);
    // eslint-disable-next-line
  }, [currentInfo]);

  // Get order ID from query parameter if ?order= is present
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = Number(urlParams.get("order"));
    if (orderId) {
      // Get order info
      getOrderInfo(orderId);
    }
  }, []);

  const validateFormData = (info) => {
    const errors = CreateFields.areRequiredFieldsValid(info ?? currentInfo);
    if (errors.length > 0) {
      toast.error(errors.join("\n"));
      return false;
    }
    return true;
  };

  const sendEmailReminder = async () => {
    try {
      const response = await ModuspaceService.SendPaymentEmailReminder(
        authentication.jwtToken,
        currentInfo.id,
      );
      if (response?.success) {
        toast.success("Email reminder sent");
      } else {
        toast.error("Failed to send email reminder");
      }
    } catch (err) {
      console.error("Error at sendEmailReminder:", err);
      toast.error(err);
    }
  };

  const getOrderInfo = async (orderId) => {
    try {
      const order = await ModuspaceService.GetOrderById(
        authentication.jwtToken,
        orderId,
      );
      // Set order info
      setCurrentInfo({
        ...currentInfo,
        salesOrders: [order],
        // Set customer ID
        customerId: order.customerId,
      });
      // If customer ID not present, set billing address field to customer
      if (!order.customerId) {
        setCustomer({
          firstName: order.billing_address?.first_name,
          lastName: order.billing_address?.last_name,
          email: order.billing_address?.email,
          phone: order.billing_address?.phone,
          country: order.billing_address?.country,
        });
      }
    } catch (err) {
      console.error("Error at getOrderInfo:", err);
      toast.error(err);
    }
  };

  const getCustomer = async (customerId) => {
    try {
      const customer = await ModuspaceService.GetCustomerByUserId(
        authentication.jwtToken,
        customerId,
      );
      // If phone or country is not set, set it to the order's billing address
      setCustomer({
        ...customer,
        phone: customer?.phone || defaultOrder?.billing_address?.phone,
        country: customer?.country || defaultOrder?.billing_address?.country,
      });
    } catch (err) {
      console.error("Error at getCustomer:", err);
      toast.error(err);
    }
  };

  useEffect(() => {
    // Set default order for order picker
    if (currentInfo?.salesOrders?.length > 0) {
      setDefaultOrder(currentInfo?.salesOrders?.[0]);
    } else if (currentInfo?.salesOrderPayments?.length > 0) {
      getOrderInfo(currentInfo.salesOrderPayments[0].salesOrderId);
    }
    // Get customer info
    if (currentInfo?.customerId) {
      getCustomer(currentInfo.customerId);
    } else {
      // Get customer name & email from salesOrder.billing_address or selected order.billing_address
      const order =
        currentInfo?.salesOrders?.[0] ??
        orders?.find(
          (order) =>
            order.id === currentInfo?.salesOrderPayments?.[0]?.salesOrderId,
        ) ??
        defaultOrder;
      if (!order) return;
      setCustomer({
        id: order?.customerId ?? null,
        firstName: order?.billing_address?.first_name,
        lastName: order?.billing_address?.last_name,
        email: order?.billing_address?.email,
        phone: order?.billing_address?.phone,
        country: order?.billing_address?.country,
      });
    }
    // Set currency to order currency
    if (currentInfo?.salesOrders?.[0]?.orderCurrencyId) {
      setCurrentInfo({
        ...currentInfo,
        currencyId: currentInfo?.salesOrders?.[0]?.orderCurrencyId,
      });
    }
    // eslint-disable-next-line
  }, [currentInfo?.salesOrders, currentInfo?.salesOrderPayments, defaultOrder]);

  const handleSubmit = async (e, status) => {
    e.preventDefault();
    if (status === "Email Reminder") {
      sendEmailReminder();
    } else {
      const info = { ...currentInfo, status };
      if (!validateFormData(info)) return;
      // Show confirmation dialog
      if (
        (currentInfo?.status === "Pending" && status === "Draft") ||
        status === "Void"
      ) {
        const confirmationText =
          status === "Draft"
            ? "This will convert this payment request back to Draft mode. The payment request email sent earlier will no longer be valid. Please confirm if you wish to go ahead."
            : "This will void the payment transaction. This action cannot be undone. Please confirm if you wish to go ahead.";
        const confirmation = window.confirm(confirmationText);
        if (confirmation) {
          onChangeSubmit(info);
        }
      } else {
        onChangeSubmit(info);
      }
    }
  };

  const onChangePaymentMode = (selectedPaymentMode) => {
    setCurrentInfo({
      ...currentInfo,
      mode: selectedPaymentMode?.label,
    });
  };

  const onChangePaymentStatus = (selectedPaymentStatus) => {
    setCurrentInfo({
      ...currentInfo,
      status: selectedPaymentStatus?.label,
    });
  };

  const onChangeCurrency = (selectedCurrency) => {
    setCurrentInfo({
      ...currentInfo,
      currencyId: selectedCurrency?.id,
    });
  };

  const onChangeRemarks = (value) => {
    setCurrentInfo({
      ...currentInfo,
      remarks: value,
    });
  };

  const onChangeNotes = (value) => {
    setCurrentInfo({
      ...currentInfo,
      notes: value,
    });
  };

  useEffect(() => {
    /* Add sales order payment from query parameter if ?amount= and ?description= is present */
    const urlParams = new URLSearchParams(window.location.search);
    const amount = Number(urlParams.get("amount"));
    const description = urlParams.get("description");
    if (amount && description && defaultOrder?.id) {
      // Check if sales order payment with same amount & description already exists
      const existingSalesOrderPayment = currentInfo?.salesOrderPayments?.find(
        (item) =>
          item.amount === amount.toFixed(2) && item.description === description,
      );
      if (existingSalesOrderPayment) return;
      // Add sales order payment
      setCurrentInfo((prevInfo) => {
        return {
          ...prevInfo,
          salesOrderPayments: [
            ...(prevInfo?.salesOrderPayments ?? []),
            {
              salesOrderId: defaultOrder?.id,
              referenceOrderId: defaultOrder?.referenceOrderId,
              amount: amount.toFixed(2),
              description,
            },
          ],
          // Update total amount
          amount: (Number(prevInfo.amount || 0) + Number(amount)).toFixed(2),
        };
      });
    }
  }, [defaultOrder]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
        <Card id="payment-info">
          <MDBox p={3}>
            <MDTypography variant="h5" mb={2}>
              Customer Details
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDInput
                  variant="standard"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Customer *"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MDChip
                          size="small"
                          text={
                            customer?.email
                              ? `${joinName(customer)} (${customer?.email})`
                              : "Not set"
                          }
                          varient="filled"
                        />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  value={customer?.firstName || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  value={customer?.lastName || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  value={customer?.email || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Contact Number"
                  InputLabelProps={{ shrink: true }}
                  value={
                    customer?.phone ||
                    defaultOrder?.billing_address?.phone ||
                    ""
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Country"
                  InputLabelProps={{ shrink: true }}
                  value={
                    customer?.country ||
                    defaultOrder?.billing_address?.country ||
                    ""
                  }
                  required
                />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox p={3}>
            <MDTypography variant="h5" mb={2}>
              Payment Information
            </MDTypography>
            <Grid container spacing={3}>
              {/* Payment status: pending, processing, paid, void */}
              <Grid item xs={12} sm={6}>
                <MDInput
                  variant="standard"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Status"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MDBadge
                          size="xs"
                          badgeContent={currentInfo?.status ?? "Draft"}
                          color={
                            currentInfo?.status === "Paid"
                              ? "success"
                              : currentInfo?.status === "Pending" ||
                                currentInfo?.status === "Draft" ||
                                !currentInfo?.status
                              ? "warning"
                              : currentInfo?.status === "Processing"
                              ? "info"
                              : "error"
                          }
                          container={true}
                        />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Grid>
              {/* Payment mode: Stripe, Partial.ly, etc */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={paymentModes ?? []}
                  getOptionLabel={(option) => option.label ?? option}
                  isOptionEqualToValue={(a, b) => a.id === b.id}
                  disableClearable
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Payment Mode"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select a payment mode"
                      required
                    />
                  )}
                  value={
                    paymentModes?.find(
                      (mode) => mode.label === currentInfo?.mode,
                    ) ?? null
                  }
                  onChange={(_, selected) => onChangePaymentMode(selected)}
                />
              </Grid>
              {/* Currency: sgd, usd, eur, aud */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={currencies ?? []}
                  getOptionLabel={(option) => option.label ?? option}
                  isOptionEqualToValue={(a, b) => a.id === b.id}
                  disableClearable
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Currency"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select a currency"
                      required
                    />
                  )}
                  value={
                    currencies?.find(
                      (currency) => currency.id === currentInfo?.currencyId,
                    ) ?? null
                  }
                  onChange={(_, selected) => onChangeCurrency(selected)}
                />
              </Grid>
              {/* Amount */}
              <Grid item xs={12} sm={6}>
                <FormField
                  type="number"
                  label="Amount"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter amount"
                  value={currentInfo?.amount || "0.00"}
                  required
                />
              </Grid>
              {/* Payment Ref */}
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Payment Ref"
                  InputLabelProps={{ shrink: true }}
                  value={currentInfo?.paymentGatewayRef || ""}
                  disabled
                />
              </Grid>
              {/* Payment date */}
              <Grid item xs={12} sm={12}>
                <MDDatePickerFullWidth
                  label="Payment Date"
                  disableClearable={true}
                  value={currentInfo?.paymentDate || ""}
                  onChange={([date]) =>
                    setCurrentInfo({
                      ...currentInfo,
                      paymentDate: date,
                    })
                  }
                  options={{
                    dateFormat: "d-m-Y",
                  }}
                  input={{ required: currentInfo?.status === "Processing" }}
                />
              </Grid>
              {/* Payment remarks */}
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Remarks"
                  InputLabelProps={{ shrink: true }}
                  value={currentInfo?.remarks || ""}
                  onChange={(e) => onChangeRemarks(e.target.value)}
                />
              </Grid>
              {/* Payment internal notes */}
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Notes (For internal use only)"
                  InputLabelProps={{ shrink: true }}
                  value={currentInfo?.notes || ""}
                  onChange={(e) => onChangeNotes(e.target.value)}
                />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox
            p={3}
            display="flex"
            justifyContent="flex-end"
            style={{ textAlign: "right" }}>
            {/* Request payment button */}
            {info?.status === "Draft" ? (
              <MDButton
                type="submit"
                variant="contained"
                color="success"
                onClick={(e) => handleSubmit(e, "Pending")}>
                Request Payment
              </MDButton>
            ) : null}

            {/* Send email reminder button */}
            {info?.status === "Pending" ? (
              <MDButton
                type="button"
                variant="contained"
                color="success"
                onClick={(e) => handleSubmit(e, "Email Reminder")}>
                Send Email Reminder
              </MDButton>
            ) : null}

            {/* Mark paid button */}
            {info?.status === "Processing" ? (
              <MDButton
                type="submit"
                variant="contained"
                color="success"
                onClick={(e) => handleSubmit(e, "Paid")}>
                Mark Paid
              </MDButton>
            ) : null}

            {/* Save as draft/Make changes button */}
            {!id || info?.status === "Draft" || info?.status === "Pending" ? (
              <MDButton
                type="submit"
                variant="contained"
                color="dark"
                onClick={(e) => handleSubmit(e, "Draft")}
                style={{ marginLeft: 12 }}>
                {info?.status === "Pending" ? "Make Changes" : "Save as Draft"}
              </MDButton>
            ) : null}

            {/* Mark void button */}
            {info?.status === "Processing" || info?.status === "Paid" ? (
              <MDButton
                type="submit"
                variant="contained"
                color="error"
                onClick={(e) => handleSubmit(e, "Void")}
                style={{ marginLeft: 12 }}>
                Mark Void
              </MDButton>
            ) : null}
          </MDBox>
        </Card>
      </Grid>

      {currentInfo ? (
        <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
          <Card id="order-details">
            <MDBox p={3}>
              <OrderInfoAndLogs
                data={currentInfo ?? []}
                setData={setCurrentInfo}
                // Only return orders that have the same customer ID as the customer,
                // or have the same billing_address.email as the customer
                orderOptions={orders ?? []}
                defaultOrder={defaultOrder}
                customer={customer}
                setCustomer={setCustomer}
              />
            </MDBox>
          </Card>
          <Card id="order-details" style={{ marginTop: 10 }}>
            <MDBox p={3}>
              <PaymentIntents paymentId={currentInfo?.id ?? []} />
            </MDBox>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
}

CreateFields.areRequiredFieldsValid = (info) => {
  const errors = [];
  if (CreateFields.isTextInputFieldInvalid(info?.currencyId))
    errors.push("The currency field is required.");
  if (CreateFields.isTextInputFieldInvalid(info?.amount))
    errors.push("The amount field is required.");
  if (!CreateFields.areSalesOrderPaymentFieldsValid(info))
    errors.push("The sales order payment fields are required.");
  if (
    info?.status === "Void" &&
    CreateFields.isTextInputFieldInvalid(info?.notes)
  )
    errors.push("The notes field is required when marking a payment as void.");
  if (
    info?.status === "Paid" &&
    CreateFields.isTextInputFieldInvalid(info?.paymentDate)
  )
    errors.push(
      "The payment date field is required when marking a payment as paid.",
    );
  return errors;
};

CreateFields.isTextInputFieldInvalid = (field) => {
  return (
    typeof field === "undefined" ||
    field === null ||
    field === 0 ||
    0.0 ||
    field === "" ||
    (typeof field === "string" && field.trim().length === 0)
  );
};

CreateFields.areSalesOrderPaymentFieldsValid = (info) => {
  info.salesOrderPayments?.forEach((item) => {
    if (CreateFields.isTextInputFieldInvalid(item?.salesOrderId)) return false;
    if (CreateFields.isTextInputFieldInvalid(item?.amount)) return false;
    if (CreateFields.isTextInputFieldInvalid(item?.description)) return false;
  });
  return true;
};

export default CreateFields;
