import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import FormField from "layouts/components/FormField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DeliveryOrders from "./DeliveryOrders";

export default function Shipping({ info, handleSubmit }) {
  const [shippingAddresses, setShippingAddresses] = useState(info?.shipping_addresses ?? null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  
  useEffect(() => {
    setShippingAddresses(info?.shipping_addresses);
  }, [info]);

  const shippingAddressOnChange = (e, id) => {
    const { name, value } = e.target;
    setShippingAddresses(prev => prev.map((item) => item.id === id || item.street_1 === id ? { ...item, [name]: value } : item));
    if (!showSaveButton) {
      setShowSaveButton(true);
    }
  };

  return (
    <Card id="shipping">
      <MDBox 
        component="form" 
        pb={3} 
        px={3} 
        pt={2}
        onSubmit={e => handleSubmit(e, { shipping_addresses: shippingAddresses })}
      >
        <MDTypography variant="h6" fontWeight="bold" mb={2}>Shipping address</MDTypography>
        <Card variant="outlined" sx={{ p: 3 }}>
          {shippingAddresses?.map((shippingAddress) => (
            <Grid container spacing={3} key={shippingAddress.id || shippingAddress.street_1}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="First Name"
                  name="first_name"
                  value={shippingAddress.first_name}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Last Name"
                  name="last_name"
                  value={shippingAddress.last_name}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField 
                  label="Email" 
                  name="email"
                  value={shippingAddress.email}
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Company Name"
                  name="company"
                  value={shippingAddress.company}
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Phone Number"
                  name="phone"
                  value={shippingAddress.phone}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Address Line 1"
                  name="street_1"
                  value={shippingAddress.street_1}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label="Address Line 2"
                  name="street_2"
                  value={shippingAddress.street_2}
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormField
                  label="Suburb/City"
                  name="city"
                  value={shippingAddress.city}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormField
                  label="Country"
                  name="country"
                  value={shippingAddress.country}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormField
                  label="State/Province"
                  name="state"
                  value={shippingAddress.state}
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormField
                  label="Zip/Postcode"
                  name="zip"
                  value={shippingAddress.zip}
                  required
                  onChange={e => shippingAddressOnChange(e, shippingAddress.id || shippingAddress.street_1)}
                />
              </Grid>
              {/* Save button */}
              <Grid item xs={12} sm={12}>
                <MDButton
                  type="submit"
                  variant="contained"
                  color={showSaveButton ? "success" : "dark"}
                  disabled={!showSaveButton}
                >
                  Save Shipping Address
                </MDButton>
              </Grid>
            </Grid>
          ))}
          </Card>
      </MDBox>
      <DeliveryOrders 
        salesOrderId={info?.id}
        customerId={info?.customerId}
      />
    </Card>
  );
}
