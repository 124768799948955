import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Link } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MDAutocomplete from "components/MDAutocomplete";
import MDButton from "components/MDButton";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import CustomerSearchModal from "components/MDCustomersSearch/CustomerSearchModal";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";
import { joinPath } from "utils/join-url";

function PairedDeviceListManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [customerSearchModalVisible, setCustomerSearchModalVisible] =
    useState(false);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '', // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy?.type === '') {
        return;
      }

      const {
        list: { list, pagination },
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListAllPairedDevices({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => {
        if (data.user) data.user.id = data.userId;
      });
      list.forEach((data) => (data.serialNo = data.device.serialNo));
      list.forEach((data) => (data.mac = data.device.mac));
      list.forEach((data) => (data.firstName = data.user?.firstName));
      list.forEach((data) => (data.lastName = data.user?.lastName));
      list.forEach((data) => (data.email = data.user?.email));
      list.forEach((data) => (data.phone = data.user?.phone));
      setData(list);
      setTotalCount(pagination.total);
    } catch (err) {
      console.error("PairedDeviceListManagement init", { err });
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListAllPairedDevices({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.user.id = data.userId));
      list.forEach((data) => (data.serialNo = data.device.serialNo));
      list.forEach((data) => (data.mac = data.device.mac));
      list.forEach((data) => (data.firstName = data.user.firstName));
      list.forEach((data) => (data.lastName = data.user.lastName));
      list.forEach((data) => (data.email = data.user.email));
      list.forEach((data) => (data.phone = data.user.phone));
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const tableInfo = [
    {
      label: "Serial Number",
      id: "serialNo",
      alias: "device.serialNo",
      tableRowMapper: (value, row) => row.serialNo,
    },
    {
      label: "Mac Address",
      id: "mac",
      alias: "device.mac",
      tableRowMapper: (value, row) => row.mac,
    },
    {
      label: "Customer",
      id: "user",
      alias: "user.firstName",
      tableRowMapper: (value, row) => {
        return value && !value.deletedAt ? (
          <p className="custom-link">
            <Link
              className="custom-link"
              to={joinPath("/dashboard/customers/", row?.userId)}
              target="_blank">
              {joinName(value)}
            </Link>
          </p>
        ) : (
          <span
            style={{
              color: "#EF5350",
              fontWeight: "bold",
            }}>
            {joinName(value)}
          </span>
        );
      },
    },
    {
      label: "Last Paired",
      id: "lastPairedAt",
    },
    {
      label: "Last Updated",
      id: "updatedAt",
    },
  ];

  const filterInfo = [
    {
      type: "multiField",
      label: "Keywords",
      name: "device__serialNo,device__mac",
      type: "string",
      placeholder: "Serial No, Mac",
      searchColumn: "device__serialNo,device__mac",
    },
    {
      type: "multiField",
      label: "IP",
      name: "userDevice__ip",
      type: "string",
      placeholder: "IP Address",
      searchColumn: "userDevice__ip",
    },
    {
      type: "multiSelect",
      label: "Customer",
      name: "userDevice__userId",
      searchColumn: "userDevice__userId",
      value: String(user.map((u) => u.id)),
      render: () => (
        <>
          {customerSearchModalVisible && (
            <CustomerSearchModal
              open={customerSearchModalVisible}
              onClose={() =>
                setCustomerSearchModalVisible(!customerSearchModalVisible)
              }
              onSearch={(selectedUsers) => {
                setUser(selectedUsers);
              }}
            />
          )}
          <MDAutocomplete
            label="Customer"
            name="userId"
            value={user || []}
            options={user || []}
            multiple
            readOnly
          />
          <MDButton
            variant="outlined"
            size="small"
            iconOnly
            style={{ fontSize: 25, color: "#D2D6DA", border: "none" }}
            onClick={() => setUser([])}>
            <CloseOutlined />
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            iconOnly
            onClick={() => setCustomerSearchModalVisible(true)}>
            <SearchOutlined />
          </MDButton>
        </>
      ),
    },
    {
      name: "userDevice__lastPairedAt",
      label: "Last Paired",
      type: "dateRange",
      searchColumn: "userDevice__lastPairedAt",
    },
  ];

  const exportInfo = [
    {
      label: "Customer First Name",
      name: "firstName",
    },
    {
      label: "Customer Last Name",
      name: "lastName",
    },
    {
      label: "Customer Email",
      name: "email",
    },
    {
      label: "Customer ID",
      name: "userId",
    },
    {
      label: "Device Name",
      name: "name",
    },
    {
      label: "Wifi Network",
      name: "wifi",
    },
    {
      label: "Wifi IP Address",
      name: "wifi",
    },
    {
      label: "Current Modulink Firmware",
      name: "firmware",
    },
    {
      label: "Power",
      name: "power",
    },
    {
      label: "Favorite",
      name: "fav",
    },
    // MISSING: need to grab theme
    {
      label: "Theme (Color)",
      name: "theme",
    },
    // MISSING: need to grab group
    {
      label: "Group",
      name: "group",
    },
    {
      label: "Serial Number",
      name: "serialNo",
    },
    {
      label: "MAC",
      name: "mac",
    },
    // MISSING: need to grab lot number
    {
      label: "Production Lot Number",
      name: "device.lotNo",
    },
    // MISSING: need to grab group
    {
      label: "Production Date",
      name: "device.productionDate",
    },
    {
      label: "Production Firmware",
      name: "device.productionFirmware",
    },
    {
      label: "Model",
      name: "device.deviceModel.name",
    },
    {
      label: "SKU",
      name: "device.deviceModel.sku",
    },
    {
      label: "UPC",
      name: "device.deviceModel.upc",
    },
    {
      label: "EAN",
      name: "device.deviceModel.ean",
    },
    {
      label: "Created Since",
      name: "createdAt",
    },
    {
      label: "Last Paired",
      name: "lastPairedAt",
    },
    {
      label: "Last Updated",
      name: "updatedAt",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["lastPairedAt", "createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      onFilterReset={() => setUser([])}
      // for common usage
      title="Paired Devices"
      currentActiveParent="products"
      currentActiveTab="paired-devices-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(PairedDeviceListManagement);
