export const checkValidField = (field) => {
  if (typeof field === 'string') {
    return field?.length === 0;
  }
  return (
    (typeof field === 'undefined' && field?.length === 0) || field === null
  );
};

export const removeElement = (delta = '') => {
  if (delta !== '' && delta !== null) {
    const specialElement = delta.replace(/<[\S]+><\/[\S]+>/gim, '');
    const normalElement = delta.replace(
      /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
      ''
    );
    return specialElement || normalElement;
  }
  return '';
};

export const checkValidImportFile = (file = null) => {
  if (file) {
    return ['text/csv'].includes(file.type);
  }
  return false;
};
