import fetchIntercept from 'fetch-intercept';

const DEFAULT_TIMEOUT = 30000; // 30 seconds request timeout

function registerFetchInterceptor(token) {
  fetchIntercept.register({
    request: async function (url, config) {
      // Modify the url or config here
      if (url.includes(process.env.REACT_APP_MODUSPACE_URL) && token?.length) {
        config.headers.authorization = `Bearer ${token}`;
        config.signal = AbortSignal.timeout(DEFAULT_TIMEOUT);
      }

      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return error;
    },

    response: function (response) {
      // Modify the reponse object
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return error;
    }
  });
}

export default (token) => {
  registerFetchInterceptor(token);
};
