export const getFormSchema = () => ({
  searchForm: [
    {
      type: 'string',
      name: 'user_email',
      label: 'Customer Email',
      placeholder: 'example@email.com',
      gridSpacing: 6
    },
    {
      type: 'string',
      name: 'device_serial_no',
      label: 'Device Serial Number',
      placeholder: '0000-0000',
      gridSpacing: 6
    }
  ],
  claimForm: [
    {
      type: 'number',
      name: 'amount',
      label: 'Claim Amount (SGD)',
      placeholder: 'Write amount in SGD',
      required: true,
      gridSpacing: 12
    }
  ]
});
