import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import FormField from "layouts/components/FormField";
import MDButton from "components/MDButton";

export default function Billing({ info, handleSubmit }) {
  const [billingAddress, setBillingAddress] = useState(info?.billing_address ?? null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  
  useEffect(() => {
    setBillingAddress(info?.billing_address);
  }, [info]);

  const billingAddressOnChange = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
    if (!showSaveButton) {
      setShowSaveButton(true);
    }
  };

  return (
    <Card id="billing">
      <MDBox 
        component="form" 
        pb={3} 
        px={3} 
        pt={2} 
        onSubmit={e => handleSubmit(e, { billing_address: billingAddress })}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              name="first_name"
              value={billingAddress?.first_name}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Last Name"
              name="last_name"
              value={billingAddress?.last_name}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField 
              label="Email" 
              name="email"
              value={billingAddress?.email}
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField 
              label="Company Name" 
              name="company"
              value={billingAddress?.company}
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              name="phone"
              value={billingAddress?.phone}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Address Line 1"
              name="street_1"
              value={billingAddress?.street_1}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="Address Line 2"
              name="street_2"
              value={billingAddress?.street_2}
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label="Suburb/City"
              name="city"
              value={billingAddress?.city}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label="Country"
              name="country"
              value={billingAddress?.country}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label="State/Province"
              name="state"
              value={billingAddress?.state}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              label="Zip/Postcode"
              name="zip"
              value={billingAddress?.zip}
              required
              onChange={billingAddressOnChange}
            />
          </Grid>
          {/* Save button */}
          <Grid item xs={12} sm={12}>
            <MDButton
              variant="contained"
              color={showSaveButton ? "success" : "dark"}
              type="submit"
              disabled={!showSaveButton}
            >
              Save Billing Address
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
