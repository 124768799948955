export function permutator(inputArr) {
  var results = [];

  function permute(arr, memo) {
    var cur,
      memo = memo || [];

    for (var i = 0; i < arr.length; i++) {
      cur = arr.splice(i, 1);
      if (arr.length === 0) {
        results.push(memo.concat(cur));
      }
      permute(arr.slice(), memo.concat(cur));
      arr.splice(i, 0, cur[0]);
    }

    return results;
  }

  return permute(inputArr);
}

export function splitStringIntoSubsetCombinations(
  inputString,
  delimiter = " ",
  minSubsetLength = 1,
) {
  var fn = function (n, src, got, all) {
    if (n == 0) {
      if (got.length > 0) {
        all[all.length] = got;
      }
      return;
    }
    for (var j = 0; j < src.length; j++) {
      fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
    }
    return;
  };
  var all = [];

  let inputArray = inputString.split(delimiter);
  for (var i = minSubsetLength; i < inputArray?.length; i++) {
    fn(i, inputArray, [], all);
  }
  all.push(inputArray);
  all = all.map((item) => item.join(delimiter));

  var reverseAll = [];

  // reverse
  let reversedInputArray = inputArray.reverse();
  for (var i = minSubsetLength; i < reversedInputArray?.length; i++) {
    fn(i, reversedInputArray, [], reverseAll);
  }
  reverseAll.push(reversedInputArray);
  reverseAll = reverseAll.map((item) => item.join(delimiter));

  all = [...new Set([...all, ...reverseAll])];

  return all;
}

/**
 * @author CW
 * @version 0.0.1
 * Used to convert name to url (Used in product/category name webhook to simulate how Bigcommerce name it by dash)
 * @param {string} name
 * @return {string}
 * @example
 * classifyFilterToObject('MAX Series Adjustable Full Shelf')
 * returns 'max-series-adjustable-full-shelf'
 */
export function transformHyphenLowercase(word) {
  return `${word
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s/g, "-")}`;
}
