import MDBadge from "components/MDBadge";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";

function ContentManagement() {
  const [data, setData] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [contentStatus, setContentStatus] = useState([]);
  const [applicationClient, setApplicationClient] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: "",
    type: "", // asc or desc
  });

  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === "") {
        return;
      }
      const {
        list: { list, pagination },
        contentType,
        contentStatus,
        applicationClient,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListContentsPaginate({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          contentType: await ModuspaceService.GetContentTypes(),
          contentStatus: await ModuspaceService.GetContentStatus(),
          applicationClient:
            await ModuspaceService.GetContentApplicationClients(),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.updatorName = joinName(data?.updator)));
      setData(list);
      setTotalCount(pagination.total);
      setContentType(contentType?.map((item) => ({ label: item, id: item })));
      setContentStatus(
        contentStatus?.map((item) => ({ label: item, id: item })),
      );
      setApplicationClient(
        applicationClient?.map((item) => ({
          label: item.label,
          id: item.name,
        })),
      );
    } catch (err) {
      console.error("ContentManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListContentsPaginate({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.updatorName = joinName(data?.updator)));
      return list;
    } catch (err) {
      console.error("ContentManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      name: "news__title,news__content,news__link",
      label: "Keywords",
      type: "string",
      placeholder: "Title, Content, Link",
      searchColumn: "news__title,news__content,news__link",
    },
    {
      name: "news__type",
      label: "Type",
      type: "multiSelect",
      optionName: "news__type",
      options: contentType,
      searchColumn: "news__type",
    },
    {
      name: "news__status",
      label: "Status",
      type: "multiSelect",
      optionName: "news__status",
      options: contentStatus,
      searchColumn: "news__status",
    },
    {
      name: "news__sites",
      label: "Site",
      type: "multiSelect",
      optionName: "news__sites",
      options: applicationClient,
      searchColumn: "news__sites",
    },
    {
      name: "news__displayAt",
      label: "Display Date",
      type: "dateRange",
      searchColumn: "news__displayAt",
    },
  ];

  const tableInfo = [
    {
      label: "Title",
      id: "title",
    },
    {
      label: "Type",
      id: "type",
    },
    {
      label: "Status",
      id: "status",
      tableRowMapper: (obj) => {
        return (
          <MDBadge
            style={{ marginLeft: 2.5, marginRight: 2.5 }}
            size="xs"
            badgeContent={obj}
            color={obj?.toUpperCase() === "PUBLISHED" ? "success" : "light"}
            container
          />
        );
      },
    },
    {
      label: "Tags",
      id: "tags",
      width: 100,
      tableRowMapper: (obj) => {
        return obj
          ? obj.map((item) => (
              <MDBadge
                style={{ marginLeft: 2.5, marginRight: 2.5 }}
                size="xs"
                badgeContent={item}
                color="light"
                container
              />
            ))
          : null;
      },
    },
    {
      label: "Slugs",
      id: "slugs",
    },
    {
      label: "Meta Keywords",
      id: "metaKeywords",
      tableRowMapper: (obj) => {
        return obj
          ? obj
              .split(",")
              .map((item) => (
                <MDBadge
                  style={{ marginLeft: 2.5, marginRight: 2.5 }}
                  size="xs"
                  badgeContent={item}
                  color="light"
                  container
                />
              ))
          : null;
      },
    },
    {
      label: "Meta Description",
      id: "metaDescription",
    },
    {
      label: "Display on",
      id: "displayAt",
    },
    {
      label: "Updated By",
      id: "updatorName",
      alias: "updator.firstName",
    },
    {
      label: "Last Updated on",
      id: "updatedAt",
    },
  ];

  const exportInfo = [
    {
      label: "Title",
      name: "title",
    },
    {
      label: "Type",
      name: "type",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Sites",
      name: "sites",
      rowMapper: (value) => value.join(" , "),
    },
    {
      label: "Slug",
      name: "url",
    },
    {
      label: "Meta Keywords",
      name: "metaKeywords",
    },
    {
      label: "Meta Description",
      name: "metaDescription",
    },
    {
      label: "Tags",
      name: "tags",
    },
    {
      label: "Display on",
      name: "displayAt",
    },
    {
      label: "Last Updated At",
      name: "updatedAt",
    },
    {
      label: "Link",
      name: "link",
    },
    {
      label: "Content",
      name: "content",
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={["displayAt", "createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={false}
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Content Management"
      currentActiveParent="cms"
      currentActiveTab="cms"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(ContentManagement);
