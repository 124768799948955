import { useState, useMemo } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDDatePickerFullWidth from "components/MDDatePickerFullWidth";
import MDTypography from "components/MDTypography";
import FormField from "layouts/components/FormField";
import MDButton from "components/MDButton";

// Calculate the total amount paid for an order
const getTotalPaid = (payments) => {
  return payments
    ?.filter((payment) => payment.status === "Paid")
    .reduce((acc, cur) => acc + Number(cur.amount), 0);
};

export default function OrderDetails({ info, handleSubmit }) {
  const [orderDetails, setOrderDetails] = useState(info ?? null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  // Update order details when info is available
  if (info && !orderDetails) {
    setOrderDetails(info);
  }

  const orderDetailsOnChange = (e) => {
    setOrderDetails({ ...orderDetails, [e.target.name]: e.target.value });
    if (!showSaveButton) {
      setShowSaveButton(true);
    }
  };

  // Get total amount paid
  const totalPaid = useMemo(() => getTotalPaid(info.payments), [info.payments]);

  return (
    <>
      <Card id="order-details">
        <MDBox p={3}>
          <MDTypography variant="h5">
            Order Details: {info?.referenceOrderId}
          </MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          // @TODO: implement onSubmit for order details
          onSubmit={(e) => 0}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDDatePickerFullWidth
                input={{
                  placeholder: "Order Date",
                  disabled: true,
                }}
                options={{
                  dateFormat: "d-m-Y",
                }}
                label="Order Date"
                name="orderDate"
                value={
                  orderDetails?.orderDate
                    ? new Date(orderDetails?.orderDate)
                    : null
                }
                onChange={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                label="Order Status"
                name="orderStatus"
                InputProps={{
                  disabled: true,
                }}
                value={orderDetails?.orderStatus}
                required
                onChange={orderDetailsOnChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Channel Name"
                value={orderDetails?.applicationClient?.label}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Order Currency Name"
                value={orderDetails?.orderCurrency?.name}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Subtotal (inc tax)"
                name="subtotalIncTax"
                value={orderDetails?.subtotalIncTax}
                required
                InputProps={{
                  disabled: true,
                }}
                onChange={orderDetailsOnChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Subtotal (ex tax)"
                name="subtotalExTax"
                value={orderDetails?.subtotalExTax}
                InputProps={{
                  disabled: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Shipping Cost (inc tax)"
                name="shippingCostIncTax"
                value={orderDetails?.shippingCostIncTax}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Shipping Cost (ex tax)"
                name="shippingCostExTax"
                value={orderDetails?.shippingCostExTax}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Handling Cost (inc tax)"
                name="handlingCostIncTax"
                value={orderDetails?.handlingCostIncTax}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Handling Cost (ex tax)"
                name="handlingCostExTax"
                value={orderDetails?.handlingCostExTax}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Store Credit Redeemed"
                name="storeCreditRedeemed"
                value={orderDetails?.storeCreditRedeemed}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Git Certificate Amount"
                name="giftCertificateAmount"
                value={orderDetails?.giftCertificateAmountRedeemed}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Tax Total"
                name="taxTotal"
                value={orderDetails?.taxTotal}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Refunded Amount"
                name="refundedAmount"
                value={orderDetails?.refundedAmount}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                label="Payment Method"
                name="paymentMethod"
                value={orderDetails?.paymentMethod}
                required
                onChange={orderDetailsOnChange}
                InputProps={{
                  disabled: true,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      <Card id="amounts" style={{ marginTop: 10 }}>
        <MDBox p={3}>
          <Grid container spacing={1}>
            {/* Total amount paid to date */}
            <Grid item xs={12} mb={2}>
              <MDTypography variant="body2">
                <b>
                  Total Amount Paid to Date:{" "}
                  {orderDetails?.orderCurrency?.symbol}
                  {(totalPaid ?? 0).toFixed(2)}
                </b>
              </MDTypography>
            </Grid>
            {/* Amounts with and w/o shipping */}
            <Grid item xs={6}>
              <MDTypography variant="body2">
                <b>Amounts with Shipping</b>
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="body2">
                <b>Amounts w/o Shipping</b>
              </MDTypography>
            </Grid>
            {/* Total amount with shipping */}
            <Grid item xs={6}>
              <MDTypography variant="button">
                <b>
                  Total Amount: {orderDetails?.orderCurrency?.symbol}
                  {orderDetails?.orderTotalIncTax}
                </b>
              </MDTypography>
            </Grid>
            {/* Total amount without shipping */}
            <Grid item xs={6}>
              <MDTypography variant="button">
                <b>
                  Total Amount: {orderDetails?.orderCurrency?.symbol}
                  {(
                    orderDetails?.orderTotalIncTax -
                      orderDetails?.shippingCostIncTax ?? 0
                  ).toFixed(2)}
                </b>
              </MDTypography>
            </Grid>

            {/* Balance amount with shipping */}
            <Grid item xs={6}>
              <MDTypography variant="button">
                <b>
                  Balance Amount: {orderDetails?.orderCurrency?.symbol}
                  {(orderDetails?.orderTotalIncTax - totalPaid).toFixed(2)}
                </b>
              </MDTypography>
            </Grid>

            {/* Balance amount without shipping */}
            <Grid item xs={6}>
              <MDTypography variant="button">
                <b>
                  Balance Amount: {orderDetails?.orderCurrency?.symbol}
                  {(
                    (orderDetails?.orderTotalIncTax -
                      orderDetails?.shippingCostIncTax ?? 0) - totalPaid
                  ).toFixed(2)}
                </b>
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      <Card id="amounts" style={{ marginTop: 10 }}>
        <MDBox p={3}>
          <Grid container spacing={1}>
            <Grid container item xs={12} sm={12}>
              <MDTypography variant="h5">Additional Information</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                label="Order Notes"
                name="orderNotes"
                value={orderDetails?.orderNotes}
                onChange={(e) => orderDetailsOnChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                label="Customer Message"
                name="customerMessage"
                value={orderDetails?.customerMessage}
                onChange={(e) => orderDetailsOnChange(e)}
              />
            </Grid>
            {/* Save button */}
            <Grid item xs={12} sm={12}>
              <MDButton
                type="submit"
                variant="contained"
                color={showSaveButton ? "success" : "dark"}
                disabled={!showSaveButton}
                onClick={(e) =>
                  handleSubmit(e, {
                    orderNotes: orderDetails.orderNotes,
                    customerMessage: orderDetails.customerMessage,
                  })
                }>
                Save Order Details
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  );
}
