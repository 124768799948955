import React from 'react';
import { joinName } from 'utils/join-name';
import moment from 'moment';
import Media from './Media';
import TimelineItem from 'components/MDTimelineItem';
import MDTypography from 'components/MDTypography';

const TicketMessage = ({ message, lastItem }) => {
  return (
    <TimelineItem
      color={message?.user?.type === 'Customer' ? 'grey' : 'black'}
      icon="person"
      title={message?.user ? joinName(message.user) : ''}
      dateTime={
        message?.createdAt
          ? moment(message.createdAt).format('MMM D, YYYY, hh:mm a')
          : ''
      }
      lastItem={lastItem}>
      {message?.media?.length ? (
        <>
          <MDTypography variant="button" color="dark">
            {message?.message}
          </MDTypography>
          <Media media={message?.media} />
        </>
      ) : (
        <MDTypography variant="button" color="dark">
          {message?.message}
        </MDTypography>
      )}
    </TimelineItem>
  );
};

export default TicketMessage;
