export function downloadToBrowser(content, filename, type = 'text/plain') {
  if (!content) return;
  if (!filename) return;

  const element = document.createElement('a');
  const file = new Blob([content], {
    type
  });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}
