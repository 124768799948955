import {
  Autocomplete,
  Grid,
  createFilterOptions,
  Tooltip,
  Chip,
} from "@mui/material";
import { validate as validateEmail } from "email-validator";
import React from "react";
import PermissionHelper from "utils/permission-parser";
import {
  isFieldInvalidString,
  isFieldInvalidNumber,
  isFieldInvalidEmail,
  isFieldInvalidPhoneNumber,
} from "utils/required-field";
import MDDatePicker from "./MDDatePickerFullWidth";
import FormField from "./MDFormField";
import MDInput from "./MDInput";
import MDTypography from "./MDTypography";
import { dateTimeToLocal } from "utils/date";
import { LoadingButton } from "@mui/lab";
import MDAutocompleteLazy from "./MDAutocompleteLazy";

/**
 * @typedef {{label: string; permission: string}} PermissionOption
 * @type {(options: PermissionOption[], state: FilterOptionsState<PermissionOption>)
 *      => PermissionOption[]}
 */
const permissionFilter = createFilterOptions();

const nan = Number(undefined);
/**
 * @typedef {{
 *  type: "multiple-dropdown"
 *  options: any[]
 *  isOptionEqual: (option1: any, option2: any) => boolean
 *  valueToFormTransformer: (data: any) => { value: string, label: string }[]
 *  formToValueTransformer: (data: { value: string, label: string }[]) => any
 *  name: string
 *  label: string
 *  required: boolean
 *  readonly?: boolean
 *  placeholder: string
 *  gridSpacing: number
 * }} MultipleDropdownSchema
 * @typedef {{
 *  type: "dropdown"
 *  options: any[]
 *  isOptionEqual: (option1: any, option2: any) => boolean
 *  getOptionLabel: (option: any) => string
 *  name: string
 *  label: string
 *  required: boolean
 *  readonly?: boolean
 *  placeholder: string
 *  gridSpacing: number
 * }} SingleDropdownSchema
 * @typedef {{
 *  type: "string" | "number" | "password" | "date" | "email" | "permission"
 *  name: string
 *  label: string
 *  required: boolean
 *  readonly?: boolean
 *  placeholder: string
 *  gridSpacing: number
 *  validateWith?: string
 * }} PrimitiveSchema
 * @typedef {PrimitiveSchema | SingleDropdownSchema | MultipleDropdownSchema} Schema
 * @param {Schema[]} initialSchema schema
 * @param {boolean} disableAllForms
 * @param {{[key:string]: string | number | boolean | null | undefined}} initialData fillData
 * @param {boolean} freezeAllForms freezes data instead of disabling form
 */
export function useForm(
  initialSchema,
  disableAllForms = false,
  initialData,
  freezeAllForms = false,
) {
  const [formData, setFormData] = React.useState(initialData);
  const [schema, updateSchema] = React.useState(initialSchema);

  /** @type {[string, Schema][]} */
  const allPermissionsField = schema
    .map((e) => [e.name, e])
    .filter(([, value]) => value.type === "permission");

  /** @type {[{ [permissionField: string]: import('utils/permission-parser').PermissionContext[] }, React.Dispatch<React.SetStateAction<PermissionContext[]>>]} */
  const [permissionContexts, setPermissionContexts] = React.useState(
    allPermissionsField.reduce((a, [permissionFieldName]) => {
      const value = String(formData ? formData[permissionFieldName] ?? "" : "");
      a[permissionFieldName] = PermissionHelper.parsePermissionsArray(value);
      return a;
    }, {}),
  );

  const permissionObjects = React.useMemo(() => {
    /**
     * TODO: refactor permission context to a utility function usePermissionContext()
     * @typedef {import('utils/permission-parser').PermissionContext} PermissionContext
     * @type {{
     *  [name: string]: {
     *    permissionArray: PermissionContext[]
     *    setPermissionArray: (state: PermissionContext[]) => void 0
     *    handlePermissionChange: (name: string, checked: boolean) => void 0
     *    renderPermissionTags: (ignored: unknown, getChipProps: import('@mui/material').ChipProps) => JSX.Element[]
     *    permissionOptions: PermissionOption[]
     *    removeAllPermissions: () => void 0
     *    suggestionsToAppend: (permission: string) => ("all" | "read" | "write")[]
     * }
     * }} */

    const permissionObjects = {};

    for (const [permissionFieldName] of allPermissionsField) {
      const permissionArray = permissionContexts[permissionFieldName] ?? [];
      /** @type {typeof permissionObjects[string]["setPermissionArray"]} */
      const setPermissionArray = (state) =>
        setPermissionContexts((f) => ({
          ...f,
          [permissionFieldName]: state,
        }));
      const handlePermissionChange = (name, checked) => {
        const newPermissionArray = PermissionHelper.applyPermissionChange(
          permissionArray,
          name,
          checked,
        );
        setPermissionArray(newPermissionArray);
        setFormData((f) =>
          freezeAllForms
            ? f
            : {
                ...f,
                [permissionFieldName]:
                  PermissionHelper.createPermissionsString(newPermissionArray),
              },
        );
      };

      const suggestionsToAppend = (permission) => {
        const idx = permissionArray.findIndex(
          (e) => e.permission === permission,
        );
        if (idx < 0) return ["all", "read", "write"];
        const permissionContext = permissionArray[idx];
        if (permissionContext.allPermissions) {
          return [];
        } else {
          return ["all", "read", "write"].filter(
            (e) => !permissionContext.action.includes(e),
          );
        }
      };

      /**
       * @typedef {{permission: string}} PermissionOption
       * @type {PermissionOption[]}
       */
      const permissionOptions = permissionArray
        .map((e) => e.permission)
        .map((permission) => ({
          permission,
          /** @type {["all", "read", "write"]} <- subset of this type */
          actions: suggestionsToAppend(permission),
        }))
        .map((e) =>
          e.actions.map((a) =>
            a === "all" ? e.permission : `${e.permission}:${a}`,
          ),
        )
        .reduce((a, b) => [...a, ...b], [])
        .map((e) => ({
          label: e,
          permission: e,
        }));

      const renderPermissionTags = (_, getChipProps) => {
        const tags = [];

        permissionArray.forEach((e) => {
          if (e.allPermissions || e.action.includes("read")) {
            tags.push(
              <Tooltip
                key={`permission-group-${e.permission}:read`}
                title={e.description}>
                <Chip
                  {...getChipProps({ index: 0 })}
                  onDelete={() =>
                    handlePermissionChange(`${e.permission}:read`, false)
                  }
                  label={`${e.permission}:read`}
                />
              </Tooltip>,
            );
          }

          if (e.allPermissions || e.action.includes("write")) {
            tags.push(
              <Tooltip
                key={`permission-group-${e.permission}:write`}
                title={e.description}>
                <Chip
                  {...getChipProps({ index: 0 })}
                  onDelete={() =>
                    handlePermissionChange(`${e.permission}:write`, false)
                  }
                  label={`${e.permission}:write`}
                />
              </Tooltip>,
            );
          }
        });

        return tags;
      };

      const removeAllPermissions = () => {
        for (const permissionContext of permissionArray) {
          handlePermissionChange(permissionContext.permission, false);
        }
      };

      permissionObjects[permissionFieldName] = {
        permissionArray,
        setPermissionArray,
        handlePermissionChange,
        renderPermissionTags,
        permissionOptions,
        removeAllPermissions,
        suggestionsToAppend,
      };
    }

    return permissionObjects;
  }, [allPermissionsField, permissionContexts]);

  // React.useEffect(() => {
  //   const newInitialData = schema.reduce((a, b) => {
  //     const { name, initialValue } = b;
  //     a[name] = initialValue
  //       ? initialValue
  //       : initialData
  //       ? initialData[name]
  //       : null;
  //     return a;
  //   }, {});

  //   setFormData(newInitialData);
  // }, [initialData, schema]);

  React.useEffect(() => {
    for (const [permissionFieldName] of allPermissionsField) {
      const { setPermissionArray } = permissionObjects[permissionFieldName];
      setPermissionArray(
        PermissionHelper.parsePermissionsArray(
          formData ? formData[permissionFieldName] ?? "" : "",
        ),
      );
    }
  }, [formData]);

  const nonEmptyString = (field) => ({
    error: isFieldInvalidString(field),
    helperText: isFieldInvalidString(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const nonEmptyStringCrossCheck = (field, validationField) => ({
    error: isFieldInvalidString(field),
    helperText: isFieldInvalidString(field) ? (
      <MDTypography variant="caption" color="error">
        This field is required.
      </MDTypography>
    ) : typeof validationField === "string" && field !== validationField ? (
      <MDTypography variant="caption" color="error">
        This field does not match.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const nonEmptyEmail = (field) => ({
    error: isFieldInvalidEmail(field),
    helperText: isFieldInvalidEmail(field) ? (
      <MDTypography variant="caption" color="error">
        This field must be a valid email.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const nonEmptyEmailCrossCheck = (field, validationField) => ({
    error: isFieldInvalidEmail(field),
    helperText: isFieldInvalidEmail(field) ? (
      <MDTypography variant="caption" color="error">
        This field must be a valid email.
      </MDTypography>
    ) : typeof validationField === "string" && field !== validationField ? (
      <MDTypography variant="caption" color="error">
        This field does not match.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const nonEmptyNumber = (field) => ({
    error: isFieldInvalidNumber(field),
    helperText: isFieldInvalidNumber(field) ? (
      <MDTypography variant="caption" color="error">
        This field must be a valid number.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const nonEmptyPhoneNumber = (field) => ({
    error: isFieldInvalidPhoneNumber(field),
    helperText: isFieldInvalidPhoneNumber(field) ? (
      <MDTypography variant="caption" color="error">
        Your phone number must only contain numeric, dot (.), an space ( ), a
        hyphen (-), and a plus (+), and has at least 7 number characters.
      </MDTypography>
    ) : (
      " "
    ),
    required: true,
  });

  const defaultEmailProps = (field) => ({
    error:
      typeof field === "string" && field.length > 0 && !validateEmail(field),
    helperText:
      typeof field === "string" && field.length > 0 && !validateEmail(field) ? (
        <MDTypography variant="caption" color="error">
          This field must be a valid email.
        </MDTypography>
      ) : (
        " "
      ),
  });

  /** @type {JSX.Element[]} */
  const components = schema.reduce((a, b) => {
    const {
      required,
      type,
      name,
      label,
      placeholder,
      onTextSearch,
      hasMore,
      triggerFetchMore = () => {},
      loading,
      gridSpacing,
      validateWith,
      getOptionLabel = (e) => e,
      readonly,
    } = b;
    const key = name;
    const formValue = formData ? formData[name] ?? null : null;

    const validationValue =
      formData && validateWith ? formData[validateWith] : null;
    switch (type) {
      case "string":
        {
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <FormField
                label={label}
                placeholder={placeholder}
                value={formValue ?? ""}
                onChange={({ target: { value } }) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value,
                        },
                  )
                }
                {...(required ? nonEmptyString(formValue) : {})}
                disabled={disableAllForms}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>,
          );
        }
        break;
      case "password":
        {
          a.push(
            <Grid item xs={gridSpacing}>
              <MDInput
                fullWidth
                label={label}
                inputProps={{ type: "password", autoComplete: "" }}
                value={formValue}
                onChange={({ target: { value } }) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value,
                        },
                  )
                }
                {...(required
                  ? validateWith
                    ? nonEmptyStringCrossCheck(formValue, validationValue)
                    : nonEmptyString(formValue)
                  : {})}
                disabled={disableAllForms}
              />
            </Grid>,
          );
        }
        break;
      case "email":
        {
          a.push(
            <Grid item xs={gridSpacing}>
              <FormField
                label={label}
                placeholder={placeholder}
                inputProps={{ type: "email" }}
                value={formValue}
                onChange={({ target: { value } }) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value,
                        },
                  )
                }
                {...defaultEmailProps(formValue)}
                {...(required
                  ? validateWith
                    ? nonEmptyEmailCrossCheck(formValue, validationValue)
                    : nonEmptyEmail(formValue)
                  : {})}
                disabled={disableAllForms}
              />
            </Grid>,
          );
        }
        break;
      case "number":
        {
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <FormField
                label={label}
                placeholder={placeholder}
                value={isNaN(formValue) ? null : formValue}
                onChange={({ target: { value } }) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value,
                        },
                  )
                }
                {...(required ? nonEmptyNumber(formValue) : {})}
                disabled={disableAllForms}
              />
            </Grid>,
          );
        }
        break;
      case "dropdown":
        {
          const { options, isOptionEqual = (a, b) => a === b } = b;
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <Autocomplete
                value={formValue || ""}
                options={options}
                isOptionEqualToValue={isOptionEqual}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    placeholder={placeholder}
                    {...(required ? nonEmptyString(formValue) : {})}
                    disabled={disableAllForms}
                  />
                )}
                onChange={(_, value) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value ?? "",
                        },
                  )
                }
              />
            </Grid>,
          );
        }
        break;
      case "dropdown-lazy":
        {
          const { options, isOptionEqual = (a, b) => a === b } = b;
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <MDAutocompleteLazy
                value={formValue || ""}
                options={loading ? [] : options}
                loading={loading}
                loadingText={
                  <>
                    Searching {label}{" "}
                    <LoadingButton
                      loading
                      variant="standard"
                      style={{
                        margin: "0px -15px",
                      }}
                    />
                  </>
                }
                hasMore={hasMore}
                triggerFetchMore={triggerFetchMore}
                isOptionEqualToValue={isOptionEqual}
                getOptionLabel={getOptionLabel}
                onBlur={onTextSearch}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    placeholder={placeholder}
                    onChange={onTextSearch}
                    {...(required ? nonEmptyString(formValue) : {})}
                    disabled={disableAllForms}
                  />
                )}
                onChange={(_, value) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value ?? "",
                        },
                  )
                }
              />
            </Grid>,
          );
        }
        break;
      case "multiple-dropdown":
        {
          const {
            options,
            isOptionEqual = (a, b) => a === b,
            valueToFormTransformer,
            formToValueTransformer,
          } = b;
          const transformedFormValue = valueToFormTransformer
            ? valueToFormTransformer(formValue)
            : formValue || [];
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <Autocomplete
                multiple
                value={transformedFormValue}
                options={options}
                isOptionEqualToValue={isOptionEqual}
                // getOptionLabel={getOptionLabel}
                renderInput={(params) => {
                  return (
                    <FormField
                      {...params}
                      label={label}
                      InputLabelProps={{ shrink: true }}
                      placeholder={placeholder}
                      {...(required ? nonEmptyString(formValue) : {})}
                      disabled={disableAllForms}
                    />
                  );
                }}
                onChange={(_, values) => {
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: formToValueTransformer
                            ? formToValueTransformer(values ?? [])
                            : values ?? [],
                        },
                  );
                }}
              />
            </Grid>,
          );
        }
        break;
      case "date":
        {
          a.push(
            <Grid item xs={gridSpacing}>
              <MDDatePicker
                input={{
                  placeholder: placeholder,
                  ...(required ? nonEmptyString(formValue) : {}),
                }}
                label={label}
                value={formValue}
                onChange={([date]) => {
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          // [name]: date?.toISOString() ?? "",
                          [name]: dateTimeToLocal(date) ?? "",
                        },
                  );
                }}
              />
            </Grid>,
          );
        }
        break;
      case "permission":
        {
          const {
            handlePermissionChange,
            renderPermissionTags,
            permissionOptions,
            suggestionsToAppend,
            removeAllPermissions,
          } = permissionObjects[name];
          a.push(
            <Grid item xs={gridSpacing}>
              <Autocomplete
                multiple
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      onClick={() =>
                        handlePermissionChange(option.permission, true)
                      }>
                      {option.label}
                    </li>
                  );
                }}
                renderTags={renderPermissionTags}
                /**
                 * value is set to `[""]` to enable renderTags function,
                 * [reference]{@link ./node_modules/@mui/material/Autocomplete/Autocomplete.js:464}
                 */
                value={formValue?.length ? [""] : []}
                options={permissionOptions}
                filterOptions={(options, params) => {
                  const filtered = permissionFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.title,
                  );

                  if (inputValue !== "" && !isExisting) {
                    suggestionsToAppend(inputValue).forEach((e) => {
                      if (e === "all") {
                        filtered.push({
                          permission: inputValue,
                          label: `Add "${inputValue}"`,
                        });
                      } else {
                        filtered.push({
                          permission: `${inputValue}:${e}`,
                          label: `Add "${inputValue}:${e}"`,
                        });
                      }
                    });
                  }

                  return filtered;
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    placeholder={formValue?.length ? "" : placeholder}
                    {...(required ? nonEmptyString(formValue) : {})}
                  />
                )}
                onChange={removeAllPermissions}
              />
            </Grid>,
          );
        }
        break;
      case "phone-number":
        {
          a.push(
            <Grid item xs={gridSpacing} key={key}>
              <FormField
                label={label}
                placeholder={placeholder}
                value={formValue}
                onChange={({ target: { value } }) =>
                  setFormData((f) =>
                    freezeAllForms
                      ? f
                      : {
                          ...f,
                          [name]: value,
                        },
                  )
                }
                {...(required ? nonEmptyPhoneNumber(formValue) : {})}
                disabled={disableAllForms}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Grid>,
          );
        }
        break;
    }
    return a;
  }, []);

  const validateForm = () => {
    const newFormData = schema.reduce((a, b) => {
      const { required, type, name } = b;
      const typeIsNumber = type === "number";
      if (typeIsNumber) {
        // for input type number:
        // if required, use NaN if field is empty to trigger field error message
        // else, use null
        const defaultValue = required ? nan : null;
        const value = formData[name] || defaultValue;
        a[name] = value;
      } else {
        const defaultValue = required ? "" : null;
        const value = formData[name] || defaultValue;
        a[name] = value;
      }
      return a;
    }, {});
    setFormData(newFormData);

    const invalidFields = schema
      .map(({ type, name }) => {
        let invalid = false;
        const value = newFormData[name];

        if (type === "email") {
          invalid = value?.length ? !validateEmail(value) : false;
          invalid |= isFieldInvalidString(value);
        } else if (type === "number") {
          invalid = isFieldInvalidNumber(value);
        } else {
          invalid = isFieldInvalidString(value);
        }

        return {
          type,
          name,
          value,
          invalid,
        };
      })
      .filter((e) => e.invalid);

    if (invalidFields.length) {
      return {
        error: true,
        invalidFields,
      };
    }

    return {
      error: false,
      formData: newFormData,
    };
  };

  const clearForm = () => {
    setFormData({});
  };

  return {
    components,
    validateForm,
    clearForm,
    formData,
    setFormData,
    updateSchema,
  };
}
