import { LoadingButton } from "@mui/lab";
import { Autocomplete, Typography } from "@mui/material";
import { useRef } from "react";
import MDBox from "../MDBox";
import MDButton from "../MDButton";

const AutocompleteRenderOptionComponent = ({
  option,
  index,
  showLoadingBelow,
  hasMore,
  isLastItem,
  triggerFetchMore,
  ...props
}) => {
  const lastItemRef = useRef();

  return (
    <>
      <MDBox {...props}>{option.label}</MDBox>
      {isLastItem && <div ref={lastItemRef}></div>}
      {isLastItem && showLoadingBelow && (
        <Typography color="secondary" variant="overline">
          Fetching now...{" "}
          <LoadingButton loading={true} style={{ color: "blue" }} />
        </Typography>
      )}
      {isLastItem && !showLoadingBelow && hasMore && (
        <MDButton
          variant="gradient"
          color="light"
          onClick={triggerFetchMore}
          style={{ marginLeft: "auto" }}>
          load more
        </MDButton>
      )}
    </>
  );
};

const MDAutocompleteLazy = ({
  label,
  options,
  hasMore,
  triggerFetchMore,
  loading,
  value,
  ...props
}) => {
  return (
    <Autocomplete
      value={value}
      options={options}
      loading={loading}
      renderOption={(props, option, { index }) => {
        return (
          <AutocompleteRenderOptionComponent
            {...props}
            hasMore={hasMore}
            showLoadingBelow={loading}
            isLastItem={props["data-option-index"] === options.length - 1}
            option={option}
            triggerFetchMore={triggerFetchMore}
          />
        );
      }}
      {...props}
    />
  );
};

export default MDAutocompleteLazy;
