/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from 'react';

// react-router components
import {
  RouterProvider,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
// import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React kits
// import Sidenav from "controls/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme';
// import themeRTL from 'assets/theme/theme-rtl';

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from 'assets/theme-dark';
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// import rtlPlugin from 'stylis-plugin-rtl';
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from 'context';

// import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useMediaQuery } from '@mui/material';

// Images
// import brandWhite from "assets/images/moduspace-logo.png";
// import brandDark from "assets/images/moduspace-logo-dark.png";

import routes from 'routes';
// import createSidebarRoutes from "sidebar.route";
import { useStores } from './stores';
import { observer } from 'mobx-react';

import Layout from './layout';
import MDTypography from './components/MDTypography';
import registerAuthTokenInterceptor from './registerAuthTokenInterceptor';

function App() {
  const [controller, dispatch] = useMaterialUIController();
  // const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { direction, darkMode } = controller;
  // const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  // const { pathname } = useLocation();

  // // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  const stores = useStores();
  // const { authentication, pageControl } = stores;

  useEffect(() => {
    if (!!stores?.authentication?.jwtToken) {
      registerAuthTokenInterceptor(stores?.authentication.jwtToken);
    }
  }, [stores.authentication.jwtToken]);

  useEffect(() => {
    window.getStoreState = () => JSON.parse(JSON.stringify(stores));
    return () => delete window.getStoreState;
  }, [stores]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  const getPublicRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log(route.key)
      if (route.collapse) {
        return getPublicRoutes(route.collapse);
      }

      if (route.public) {
        if (route.route === '/') {
          return (
            <Route index element={route.component} key={route.key}></Route>
          );
        } else {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}></Route>
          );
        }
      } else return null;
    });

  const getNonPublicRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log(route.key)
      if (route.collapse) {
        return getNonPublicRoutes(route.collapse);
      }

      if (!route.public) {
        if (route.route === '/') {
          return (
            <Route index element={route.component} key={route.key}></Route>
          );
        } else {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}></Route>
          );
        }
      } else return null;
    });

  let router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {getPublicRoutes(routes)}
        <Route path="/" element={<Layout />}>
          {getNonPublicRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </>
    )
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default observer(App);
