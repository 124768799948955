import { toast } from "react-toastify";
import { client, ModuspaceEndpoints } from "utils/constants";
import { utcOffset } from "utils/date";
import jsonFetch from "utils/json-fetch";
import { objectToQueryParams } from "utils/object-to-query";

function genericReportError(response) {
  console.error("Error on request:", { response });

  if (response.status === 401) {
    toast.error("Unauthorized. Session Expired");
    return null;
  }

  const errorMessage =
    typeof response.json?.data === "string"
      ? response.json?.data
      : response.json?.data?.message ??
        response.json?.data?.reason ??
        response.json?.data?.error ??
        response.json?.message ??
        response.json?.reason ??
        response.json?.error ??
        response.message ??
        response.reason ??
        response.error ??
        response.text ??
        "Invalid  Request.";
  toast.error(errorMessage);
  return null;
}

async function GetAnalyticsData(token) {
  const response = await jsonFetch(
    `${ModuspaceEndpoints.ANALYTICS_DATA}?tz=${encodeURIComponent(
      utcOffset(),
    )}`,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: {
        countryData,
        memberRegistrationGraph,
        pairedDeviceGraph,
        supportTicketGraph,
        createdAt,
      },
    },
  } = response;
  return {
    countryData,
    memberRegistrationGraph,
    pairedDeviceGraph,
    supportTicketGraph,
    createdAt,
  };
}

async function GetRoles(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ROLES_LIST_ALL +
      "?" +
      objectToQueryParams({
        includeInactive: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { data: roles },
    },
  } = response;
  return roles;
}

async function ListRolesPaginate({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
} = {}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ROLES_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        includeInactive: true,
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportRoles(token, { filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ROLES_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: {
        data: { headers, rows },
      },
    },
  } = response;
  return { headers, rows };
}

async function GetCustomerRolesIncludingInactive(token) {
  const response = await jsonFetch(
    `${ModuspaceEndpoints.ROLES_LIST_ALL}?${objectToQueryParams({
      includeInactive: true,
      type: "Customer", // hardcode enum type
    })}`,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: roles },
  } = response;
  return roles;
}

async function GetUserRolesIncludingInactive(token) {
  const response = await jsonFetch(
    `${ModuspaceEndpoints.ROLES_LIST_ALL}?${objectToQueryParams({
      includeInactive: true,
      type: "User", // hardcode enum type
    })}`,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: roles },
  } = response;
  return roles;
}

async function GetCountries(token) {
  const response = await jsonFetch(ModuspaceEndpoints.COUNTRIES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { countries },
    },
  } = response;
  return countries;
}

async function GetStatesByCountryId(token, countryId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.STATES_LIST_BY_COUNTRY_ID(countryId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { states },
    },
  } = response;
  return states;
}

async function GetFandoms(token) {
  const response = await jsonFetch(ModuspaceEndpoints.FANDOMS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { fandoms },
    },
  } = response;

  const data = await fandoms.map(function (fandom) {
    return fandom.name;
  });

  return data;
}

async function GetScales(token) {
  const response = await jsonFetch(ModuspaceEndpoints.SCALES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { scales },
    },
  } = response;
  const data = await scales.map(function (scale) {
    return scale.name;
  });

  return data;
}

async function GetBrands(token) {
  const response = await jsonFetch(ModuspaceEndpoints.BRANDS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { brands },
    },
  } = response;
  const data = await brands.map(function (brand) {
    return brand.name;
  });

  return data;
}

async function GetTicketProducts(token) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_PRODUCTS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { products },
    },
  } = response;
  const data = await products.map((product) => product.name);
  return data;
}

async function GetRoleStatus() {
  const response = await jsonFetch(ModuspaceEndpoints.ROLE_STATUS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetRolePermissions(token) {
  const response = await jsonFetch(ModuspaceEndpoints.ROLE_PERMISSION_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { permissions },
    },
  } = response;

  const data = await permissions.map(function (permission) {
    return permission.name;
  });

  return data;
}

async function GetRoleById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.ROLES_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { role },
    },
  } = response;

  //workaround string split into array for multiselect control to work
  role.permissions = role.permissions?.split(" ");
  return role;
}

async function UpdateRoleById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.ROLES_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client, permissions: payload.permissions.join(" ") },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { role },
    },
  } = response;
  //workaround string split into array for multiselect control to work
  role.permissions = role.permissions?.split(" ");
  return role;
}

async function DeleteRoleById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.ROLES_GET_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

/**
 * @typedef {{name: string; description: string; status: string; permissions: string}} CreateRoleRequest
 * @typedef {CreateRoleRequest & { id: number, createdAt: string, updatedAt: string}} RoleInfo
 * @param {string} token JWT token
 * @param {{name: string; description: string; status: string; permissions: string}} payload Create role request
 * @returns {Promise<RoleInfo>} New role info
 */
async function CreateNewRole(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.ROLES_GET_BY_ID("new"), {
    method: "PUT",
    body: { ...payload, client, permissions: payload.permissions.join(" ") },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { role },
    },
  } = response;
  //workaround string split into array for multiselect control to work
  role.permissions = role.permissions?.split(" ");
  return role;
}

async function GetAllCustomers(token) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function GetCustomerUploadToken(token) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMER_UPLOAD_TOKEN);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { session },
    },
  } = response;

  const data = session;

  return data;
}

async function ListCustomers(
  token,
  pageSize = 10,
  currentPage = 1,
  filtered = [],
  ordering
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMERS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
        filtered: JSON.stringify(filtered),
        // roles_filtered: roles_filtered.map(e => JSON.stringify(e)),
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportCustomers(token, { filtered = [], roles_filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMERS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        roles_filtered: roles_filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: {
        data: { headers, rows },
      },
    },
  } = response;
  return { headers, rows };
}

async function ImportCustomers(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_IMPORT, {
    method: "POST",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function GetCustomerByUserId(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateCustomerByUserId(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateCustomerStatusByUserId(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMERS_STATUS_UPDATE_BY_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { user },
    },
  } = response;
  return user;
}

async function UpdateCustomerPasswordByUserId(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMERS_GET_BY_ID(id) + "/password",
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function DeleteCustomerByUserId(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_GET_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { message },
  } = response;
  return message;
}

async function VerifyCustomerEmailExists(token, { email }) {
  const response = await jsonFetch(ModuspaceEndpoints.CUSTOMERS_VERIFY_EMAIL, {
    method: "POST",
    body: {
      email,
      client,
    },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      message,
      data: {
        result: { exists },
      },
    },
  } = response;
  return exists;
}

async function CreateNewCustomer(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMERS_GET_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function GetCustomerUserAdressesByUserId(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMER_ADDRESSES_GET_BY_USER_ID(id),
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { addresses },
    },
  } = response;
  return addresses;
}

async function ImportCustomerAddresses(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMER_ADDRESSES_IMPORT,
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function DeleteCustomerUserAddressById(token, userId, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMER_ADDRESSES_DELETE_BY_ID(userId, id),
    {
      method: "DELETE",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function UpdateCustomerUserAddressById(token, userId, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMER_ADDRESSES_UPDATE_BY_ID(userId, id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { address },
    },
  } = response;
  return address;
}

async function AddCustomerUserAddress(token, userId, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CUSTOMER_ADDRESSES_ADD_BY_USER_ID(userId),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { address },
    },
  } = response;
  return address;
}

async function GetAllUsers(token) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function ListUsersPaginate({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  // console.log(roles_filtered)
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportUsers(token, { filtered = [], roles_filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        roles_filtered: roles_filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: {
        data: { headers, rows },
      },
    },
  } = response;
  return { headers, rows };
}

async function GetUserById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateUserById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateUserStatusByUserId(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_STATUS_UPDATE_BY_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { user },
    },
  } = response;
  return user;
}

async function UpdateUserPasswordByUserId(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_GET_BY_ID(id) + "/password",
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function DeleteUserById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_GET_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { message },
  } = response;
  return message;
}

async function CreateNewUser(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_GET_BY_ID("new"), {
    method: "PUT",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function EmailResetPasswordUserByID(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_EMAIL_RESET_PASSWORD_BY_ID(id),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function EmailGenerateNewPasswordUserByIDForAdmin(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_EMAIL_GENERATE_NEW_PASSWORD_BY_ID_FOR_ADMIN(id),
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function EmailGenerateNewPasswordUserByIDForCustomer(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.USERS_EMAIL_GENERATE_NEW_PASSWORD_BY_ID_FOR_CUSTOMER(id),
    {
      method: "POST",
      body: { ...payload },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function VerifyUserEmailExists(token, { email }) {
  const response = await jsonFetch(ModuspaceEndpoints.USERS_VERIFY_EMAIL, {
    method: "POST",
    body: {
      email,
      client,
    },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      message,
      data: {
        result: { exists },
      },
    },
  } = response;
  return exists;
}

async function GetProfileById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.PROFILE_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateProfileById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.PROFILE_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function UpdateProfilePasswordByUserId(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PROFILE_GET_BY_ID(id) + "/password",
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: user },
  } = response;
  return user;
}

async function ListApiKeysPaginate({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.API_KEY_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: filtered.map((e) => JSON.stringify(e)),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportApiKeys(token, { filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.API_KEY_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: {
        data: { headers, rows },
      },
    },
  } = response;
  return { headers, rows };
}

/**
 *
 * @param {string} token JWT Token
 * @param {{name: string, scope: string}} payload
 * @returns
 */
async function CreateApiKey(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.API_KEY_BY_ID("new"), {
    method: "PUT",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { apiKey: key },
    },
  } = response;
  return key;
}

/**
 *
 * @param {string} token JWT Token
 * @param {string} key api key clear text part
 * @returns
 */
async function GetApiKeyByKey(token, key) {
  const response = await jsonFetch(ModuspaceEndpoints.API_KEY_BY_ID(key));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { apiKey: newKey },
    },
  } = response;
  return newKey;
}

/**
 *
 * @param {string} token JWT Token
 * @param {string} key api key clear text part
 * @param {{name: string, scope: string}} payload field
 * @returns
 */
async function UpdateApiKeyByKey(token, key, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.API_KEY_BY_ID(key), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { apiKey: newKey },
    },
  } = response;
  return newKey;
}

/**
 * @param {string} token JWT token
 * @param {string} key api key to delete
 * @returns status
 */
async function DeleteApiKeyByKey(token, key) {
  const response = await jsonFetch(ModuspaceEndpoints.API_KEY_BY_ID(key), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

/**
 * @returns {Promise<{ name: string; id: number }[]>}
 */
async function GetDeviceUploadToken(token) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_UPLOAD_TOKEN);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { session },
    },
  } = response;

  const data = session;

  return data;
}

async function GetAllDevicesModelsNameAndId(token) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_MODEL_OPTIONS);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { models },
    },
  } = response;
  return models;
}

async function ListDevicesModels(
  token,
  pageSize,
  currentPage,
  filtered,
  ordering,
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DEVICE_MODEL_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportDevicesModels(token, { filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DEVICE_MODEL_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { headers, rows },
    },
  } = response;
  return { headers, rows };
}

async function CreateNewDeviceModel(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DEVICE_MODEL_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: model },
  } = response;
  return model;
}

async function GetDeviceModelById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_MODEL_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { model },
    },
  } = response;
  return model;
}

async function GetPairedDeviceById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.PAIRED_DEVICE_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }

  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function GetPairedDeviceGroupById(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAIRED_DEVICE_GROUP_BY_ID(id),
  );
  if (!response.ok) {
    return genericReportError(response);
  }

  const {
    json: {
      data: { groups: data },
    },
  } = response;
  return data;
}

async function UpdateDeviceModelById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_MODEL_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: model },
  } = response;
  return model;
}

async function UpdatePairedDeviceById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.PAIRED_DEVICE_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function DeleteDeviceModelById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_MODEL_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function GetAllDevices(token) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE, {
    method: "GET",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { devices },
    },
  } = response;
  return devices;
}

async function ListDeviceSerialsPaginate({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DEVICE_SERIAL_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ListAllPairedDevices({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAIRED_DEVICE_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportAllDevices(token, { filtered = [], model_filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DEVICE_SERIAL_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        model_filtered: model_filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { headers, rows },
    },
  } = response;
  return { headers, rows };
}

async function ImportDeviceBySerial(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_SERIAL_IMPORT, {
    method: "POST",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function GetDeviceBySerial(token, serial) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_BY_SERIAL(serial));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function CreateNewDevice(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_ITEM, {
    method: "PUT",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function CreatePairedDevice(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.PAIRED_DEVICE, {
    method: "PUT",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function GetDeviceById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_ITEM_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function GetDeviceGroupById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_ITEM_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { group: data },
    },
  } = response;
  return data;
}

/**
 *
 * @param {string} token
 * @param {string} id
 * @param {{
 *      model: string;
 *      mac: string;
 *      serialNo: string;
 *      lotNo: string;
 *      productionDate: string;
 *      productionFirmware: string;
 * }} payload
 * @returns
 */
async function UpdateDeviceById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_ITEM_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { device },
    },
  } = response;
  return device;
}

async function DeleteDeviceById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.DEVICE_ITEM_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function DeletePairedDeviceById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.PAIRED_DEVICE_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

/**
 * @param {string} token JWT Token
 * @param {object} payload The form data
 * @returns {Promise<{job}>}
 */
async function GenerateSerialNumbers(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBERS_GENERATE,
    {
      headers: {
        authorization: token,
      },
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function ListAllSerialNumbers(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  isExport = false,
  ordering
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBERS_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
        ...(isExport && { export: true }),
      }),
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function BulkUpdateSerialNumbers(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBERS_BULK_ACTION,
    {
      headers: {
        authorization: token,
      },
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { serials },
    },
  } = response;
  return serials;
}

async function DeleteSerialNumber(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBER_DELETE,
    {
      headers: {
        authorization: token,
      },
      body: { id },
      method: "DELETE",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function GetSerialNumberStatus(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBER_STATUS_LIST_ALL,
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetSerialNumberProducts(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_NUMBER_PRODUCTS_LIST_ALL,
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { products },
    },
  } = response;
  const data = await products.map((product) => product.name);
  return data;
}

/**
 *
 * @param {string} token JWT Token
 * @returns {Promise<{id: number, name: string}[]>}
 */
async function GetAllWarrantyPlansNameAndId(token) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_PLAN_OPTIONS);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { plans },
    },
  } = response;
  return plans;
}

async function ListWarrantyPlans(
  token,
  pageSize,
  currentPage,
  filtered,
  ordering,
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_PLAN_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportWarrantyPlans(token, { filtered = [] }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_PLAN_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { headers, rows },
    },
  } = response;
  return { headers, rows };
}

async function CreateNewWarrantyPlan(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_PLAN_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { plan },
    },
  } = response;
  return plan;
}

async function GetWarrantyPlanById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_PLAN_BY_ID(id), {
    method: "GET",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { plan },
    },
  } = response;
  return plan;
}

async function UpdateWarrantyPlanById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_PLAN_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { plan },
    },
  } = response;
  return plan;
}

async function DeleteWarrantyPlanById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_PLAN_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function ListWarrantyItemsPagination({
  pageSize,
  currentPage,
  filtered,
  device_filtered,
  warranty_plan_filtered,
  ordering
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_ITEM_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        device_filtered: JSON.stringify(device_filtered),
        warranty_plan_filtered: JSON.stringify(warranty_plan_filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ExportWarrantyItem(
  token,
  { filtered = [], user_filtered = [], device_filtered = [] },
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_ITEM_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        user_filtered: user_filtered.map((e) => JSON.stringify(e)),
        device_filtered: device_filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { headers, rows },
    },
  } = response;
  return { headers, rows };
}

async function CreateNewWarrantyItem(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_ITEM_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { warranty },
    },
  } = response;
  return warranty;
}

async function GetWarrantyItemById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_ITEM_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { warranty },
    },
  } = response;
  return warranty;
}

async function UpdateWarrantyItemById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_ITEM_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { warranty },
    },
  } = response;
  return warranty;
}

async function DeleteWarrantyItemById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_ITEM_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function ListWarrantyClaim(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_CLAIM_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize: 5000,
        currentPage: 1,
        // ordering: ordering.map(e => JSON.stringify(e)),
        // filtered: filtered.map(e => JSON.stringify(e)),
        // user_filtered: user_filtered.map(e => JSON.stringify(e)),
        // device_filtered: device_filtered.map(e => JSON.stringify(e)),
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list },
    },
  } = response;
  return { list };
}

async function ExportWarrantyClaim(
  token,
  { filtered = [], user_filtered = [], device_filtered = [] },
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_CLAIM_PAGINATE +
      "?" +
      objectToQueryParams({
        filtered: filtered.map((e) => JSON.stringify(e)),
        user_filtered: user_filtered.map((e) => JSON.stringify(e)),
        device_filtered: device_filtered.map((e) => JSON.stringify(e)),
        export: true,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { headers, rows },
    },
  } = response;
  return { headers, rows };
}

async function CreateNewWarrantyClaim(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_CLAIM_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { claim },
    },
  } = response;
  return claim;
}

async function GetWarrantyClaimById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.WARRANTY_CLAIM_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { claim },
    },
  } = response;
  return claim;
}

async function UpdateWarrantyClaimById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_CLAIM_BY_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { claim },
    },
  } = response;
  return claim;
}

async function DeleteWarrantyClaimById(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.WARRANTY_CLAIM_BY_ID(id),
    {
      method: "DELETE",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function GetIdeaboardPostStatus(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_STATUS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetIdeaboardPostReviewType(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_REVIEW_TYPE_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetIdeaboardPostReviewStatus(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_REVIEW_STATUS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetIdeaboardPostTags(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_TAGS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { tags },
    },
  } = response;

  const data = await tags.map(function (tag) {
    return tag.name;
  });

  return data;
}

async function GetIdeaboardPostProductSeries(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_PRODUCT_SERIES_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { series },
    },
  } = response;

  const data = await series.map(function (s) {
    return s.name;
  });

  return data;
}

async function GetIdeaboardPostCommentStatus(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POST_COMMENT_STATUS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetAllIdeaboardPosts(token) {
  const response = await jsonFetch(ModuspaceEndpoints.IDEABOARD_POSTS_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function ListAllIdeaboardPosts({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ListPendingIdeaboardPosts({
  pageSize = 10,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_LIST_PENDING_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetIdeaboardPostById(token, id) {
  // if (true) return publishedPosts?.data?.list && publishedPosts.data.list[0]
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_BY_ID(id),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPost: data },
    },
  } = response;
  return data;
}

async function UpdateIdeaboardPostById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_BY_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPost: data },
    },
  } = response;
  return data;
}

async function UpdateIdeaboardPostFlagById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_FLAG_BY_POST_ID(id),
    {
      method: "POST",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPost: data },
    },
  } = response;
  return data;
}
async function UpdateIdeaboardPostReviewById(token, id, postReviewId, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_REVIEWS_BY_POST_REVIEW_ID(
      id,
      postReviewId,
    ),
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPost: data },
    },
  } = response;
  return data;
}

async function UpdateIdeaboardPostReviewsById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_REVIEWS_BY_POST_ID(id),
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPost: data },
    },
  } = response;
  return data;
}

async function DeleteIdeaboardPostById(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_BY_ID(id),
    {
      method: "DELETE",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { message },
  } = response;
  return message;
}

async function GetIdeaboardPostCommentsByPostId(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_BY_POST_ID(id),
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPostComments: data },
    },
  } = response;
  return data;
}

async function GetIdeaboardPostReviewsByPostId(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_REVIEWS_BY_POST_ID(id),
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPostReviews: data },
    },
  } = response;
  return data;
}

async function GetIdeaboardPostImpressionByPostId(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_IMPRESSIONS_BY_POST_ID(id),
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userPostImpression: data },
    },
  } = response;
  return data;
}

async function GetIdeaboardPostComments(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_LIST_ALL,
  );

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function ListAllIdeaboardPostComments({
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ListPendingIdeaboardPostComments({
  pageSize = 10,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_LIST_PENDING_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetIdeaboardPostCommentById(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_BY_COMMNET_ID(id),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userComment: data },
    },
  } = response;
  return data;
}

async function GetIdeaboardPostCommentReviewsByPostId(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_REVIEWS_BY_POST_COMMENT_ID(id),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userCommentReviews: data },
    },
  } = response;
  return data;
}

async function UpdateIdeaboardPostCommentById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_BY_COMMNET_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { userComment: data },
    },
  } = response;
  return data;
}

async function UpdateIdeaboardPostCommentFlagById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_BY_COMMNET_ID(id) + "/flag",
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { posts },
    },
  } = response;
  return posts;
}

async function UpdateIdeaboardPostCommentReviewById(
  token,
  id,
  commentReviewId,
  payload,
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_REVIEWS_BY_POST_COMMENT_REVIEW_ID(
      id,
      commentReviewId,
    ),
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { posts },
    },
  } = response;
  return posts;
}

async function UpdateIdeaboardPostCommentReviewsById(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_REVIEWS_BY_POST_COMMENT_ID(id),
    {
      method: "POST",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { posts },
    },
  } = response;
  return posts;
}

async function DeleteIdeaboardPostCommentById(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.IDEABOARD_POSTS_COMMENTS_BY_COMMNET_ID(id),
    {
      method: "DELETE",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  return true;
}

async function GetAllContents() {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { contents },
    },
  } = response;
  return contents;
}

async function ListContentsPaginate({
  pageSize = 10,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CONTENT_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetContentById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { content },
    },
  } = response;
  return content;
}

async function UpdateContentById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { content },
    },
  } = response;
  return content;
}

async function DeleteContentById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_GET_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { message },
  } = response;
  return message;
}

async function CreateNewContent(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CONTENT_GET_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { content },
    },
  } = response;
  return content;
}

async function GetContentTypes(token) {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_TYPES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { types },
    },
  } = response;

  const data = await types.map(function (type) {
    return type.name;
  });

  return data;
}

async function GetContentStatus(token) {
  const response = await jsonFetch(ModuspaceEndpoints.CONTENT_STATUS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetContentApplicationClients(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CONTENT_APPLICATION_CLIENT_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { clients },
    },
  } = response;

  const data = await clients.map((client) => ({
    name: client.name,
    label: client.label,
  }));

  return data;
}

async function GetContentMediaUploadToken(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CONTENT_MEDIA_UPLOAD_TOKEN,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { session },
    },
  } = response;

  const data = session;

  return data;
}

async function GetAllTickets(token) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { contents },
    },
  } = response;
  return contents;
}

async function ListTicketsPaginate({
  pageSize = 10,
  currentPage = 1,
  filtered = [],
  ordering = {},
}) {
  // If ordering contains an id with `duration`, we need to use the `createdAt` field and compare with the current date for the duration
  if (ordering?.id === "duration") {
    const now = new Date();
    const type = ordering?.desc ? "lte" : "gte";
    ordering = { id: "createdAt", [type]: now.toISOString() };
  }
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetTicketsByCustomerId(token, customerId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_GET_BY_CUSTOMER_ID(customerId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { counts },
    },
  } = response;
  return counts;
}

async function CreateNewTicket(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_GET_BY_ID("new"), {
    method: "PUT",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { ticket },
    },
  } = response;
  return ticket;
}

async function GetTicketById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { ticket },
    },
  } = response;
  return ticket;
}

async function UpdateTicketById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { ticket },
    },
  } = response;
  return ticket;
}

async function DeleteTicketById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_GET_BY_ID(id), {
    method: "DELETE",
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { message },
  } = response;
  return message;
}

async function GetTicketPriorities(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_PRIORITIES_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { priorities },
    },
  } = response;

  const data = await priorities.map(function (priority) {
    return priority.name;
  });

  return data;
}

async function GetTicketStatus(token) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_STATUS_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetTicketIssues(token) {
  const response = await jsonFetch(ModuspaceEndpoints.TICKET_ISSUES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { issues },
    },
  } = response;

  const data = await issues.map(function (issue) {
    return issue.name;
  });

  return data;
}

async function GetTicketMediaUploadToken(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_MEDIA_UPLOAD_TOKEN,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { session },
    },
  } = response;

  const data = session;

  return data;
}

async function GetTicketMessages(token, ticketId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_MESSAGES_GET_BY_TICKET_ID(ticketId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { messages },
    },
  } = response;
  return messages;
}

async function CreateNewTicketMessage(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.TICKET_MESSAGE(payload.ticketId),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data: message },
  } = response;
  return message;
}

async function GetJobById(token, jobId) {
  const response = await jsonFetch(ModuspaceEndpoints.JOB_GET_BY_ID(jobId));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function GetAllCategories() {
  const response = await jsonFetch(
    ModuspaceEndpoints.CATALOG_CATEGORIES_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { categories },
    },
  } = response;
  return categories;
}

async function ListCatalogCategoriesPaginate() {
  const response = await jsonFetch(
    ModuspaceEndpoints.CATALOG_CATEGORIES_LIST_PAGINATE,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetAllProducts(token, pageSize, currentPage, filtered) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CATALOG_PRODUCTS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { products },
    },
  } = response;
  return products;
}

async function ListCatalogProductsPaginate(
  token,
  pageSize,
  currentPage,
  filtered,
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.CATALOG_PRODUCTS_LIST_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetAntiCounterfeitAnalytic({ pageSize, currentPage, filtered }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ANTI_COUNTERFEIT_ANALYTIC +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }

  const {
    json: { data },
  } = response;
  return { data };
}

async function ListProductSerialCounterfeitReportPaginate({
  pageSize,
  currentPage,
  filtered,
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PRODUCT_SERIAL_COUNTERFEIT_REPORT_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
      }),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function ListAllOrders(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  sortBy = [],
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ORDERS_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: sortBy?.id ? JSON.stringify(sortBy) : undefined,
      }),
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetAllOrders(token) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDERS_LIST_ALL);

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function GetOrderBigCommerceStatuses(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.ORDER_BIGCOMMERCE_STATUSES_LIST_ALL,
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;

  const data = await statuses.map(function (status) {
    return status.name;
  });

  return data;
}

async function GetOrderUploadToken(token) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDER_UPLOAD_TOKEN);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { session },
    },
  } = response;

  const data = session;

  return data;
}

async function ImportOrders(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDERS_IMPORT, {
    method: "POST",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { job },
    },
  } = response;
  return job;
}

async function GetOrderById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDER_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { order },
    },
  } = response;
  return order;
}

async function UpdateSalesOrderById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDER_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { order },
    },
  } = response;
  return order;
}

async function BulkUpdateSalesOrders(token, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.ORDERS_BULK_UPDATE, {
    headers: {
      authorization: token,
    },
    method: "POST",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { orders },
    },
  } = response;
  return orders;
}

async function ListAllPayments(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  ordering
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENTS_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: ordering?.id ? JSON.stringify(ordering) : undefined,
      }),
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { payments, pagination },
    },
  } = response;
  return { payments, pagination };
}

async function GetPaymentById(token, id) {
  const response = await jsonFetch(ModuspaceEndpoints.PAYMENT_GET_BY_ID(id));
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { payment },
    },
  } = response;
  return payment;
}

async function CreateNewPayment(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_GET_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { payment },
    },
  } = response;
  return payment;
}

async function UpdatePaymentById(token, id, payload) {
  const response = await jsonFetch(ModuspaceEndpoints.PAYMENT_GET_BY_ID(id), {
    method: "PATCH",
    body: { ...payload, client },
  });
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { payment },
    },
  } = response;
  return payment;
}

async function SendPaymentEmailReminder(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_SEND_EMAIL_REMINDER(id),
    {
      method: "POST",
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function GetAllPaymentModes(token) {
  const response = await jsonFetch(ModuspaceEndpoints.PAYMENT_MODES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { modes },
    },
  } = response;
  return modes;
}

async function GetAllPaymentStatuses(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_STATUSES_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;
  return statuses;
}

async function ListAllPaymentLogs(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_LOGS_LIST_ALL +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
      }),
    {
      headers: {
        authorization: token,
      },
    },
  );

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list, pagination },
    },
  } = response;
  return { list, pagination };
}

async function GetPaymentLogsByPaymentId(token, paymentId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_LOGS_GET_BY_PAYMENT_ID(paymentId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { paymentLogs },
    },
  } = response;
  return paymentLogs;
}

async function GetPaymentIntentsByPaymentId(paymentId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.PAYMENT_INTENTS_GET_BY_PAYMENT_ID(paymentId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list },
    },
  } = response;
  return list;
}

async function GetAllCurrencies(token) {
  const response = await jsonFetch(ModuspaceEndpoints.CURRENCIES_LIST_ALL);
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { currencies },
    },
  } = response;
  return currencies;
}

async function ListBigcommerceCatalogProducts({ client }) {
  const response = await jsonFetch(
    ModuspaceEndpoints.BIGCOMMERCE_CATALOG_PRODUCTS(client),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list },
    },
  } = response;
  return list;
}

async function ListBigcommerceCatalogProductOptionsByProductId({
  client,
  productId,
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.BIGCOMMERCE_CATALOG_PRODUCT_OPTIONS_BY_PRODUCT_ID(
      client,
      productId,
    ),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { list },
    },
  } = response;
  return list;
}

async function UpsertBigcommerceSalesOrderProducts({
  client,
  orderId,
  payload,
}) {
  const response = await jsonFetch(
    ModuspaceEndpoints.BIGCOMMERCE_ORDER_PRODUCTS(client, orderId),
    {
      method: "PUT",
      body: { ...payload },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: { data },
  } = response;
  return data;
}

async function ListDeliveryOrders(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
  sortBy = {},
) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDERS_PAGINATE +
      "?" +
      objectToQueryParams({
        pageSize,
        currentPage,
        filtered: JSON.stringify(filtered),
        ordering: sortBy?.id ? JSON.stringify(sortBy) : undefined,
      }),
    {
      headers: {
        authorization: token,
      },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrders, pagination },
    },
  } = response;
  return { deliveryOrders, pagination };
}

async function GetAllDeliveryOrders(
  token,
  pageSize = 500,
  currentPage = 1,
  filtered = [],
) {
  const response = await jsonFetch(ModuspaceEndpoints.DELIVERY_ORDERS_PAGINATE + "?" + objectToQueryParams({
    pageSize,
    currentPage,
    filtered: JSON.stringify(filtered),
  }), {
    headers: {
      authorization: token,
    },
  });

  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrders },
    },
  } = response;
  return deliveryOrders;
}

async function GetDeliveryOrdersBySalesOrderId(token, salesOrderId) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDERS_BY_SALES_ORDER_ID(salesOrderId),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrders },
    },
  } = response;
  return deliveryOrders;
}

async function GetDeliveryOrder(token, id) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDER_GET_BY_ID(id),
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrder },
    },
  } = response;
  return deliveryOrder;
}

async function UpdateDeliveryOrder(token, id, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDER_GET_BY_ID(id),
    {
      method: "PATCH",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrder },
    },
  } = response;
  return deliveryOrder;
}

async function CreateDeliveryOrder(token, payload) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDER_GET_BY_ID("new"),
    {
      method: "PUT",
      body: { ...payload, client },
    },
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { deliveryOrder },
    },
  } = response;
  return deliveryOrder;
}

async function GetDeliveryOrderStatuses(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDER_STATUSES_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { statuses },
    },
  } = response;
  return statuses;
}

async function GetDeliveryOrderShippingMethods(token) {
  const response = await jsonFetch(
    ModuspaceEndpoints.DELIVERY_ORDER_SHIPPING_METHODS_LIST_ALL,
  );
  if (!response.ok) {
    return genericReportError(response);
  }
  const {
    json: {
      data: { shippingMethods },
    },
  } = response;
  return shippingMethods;
}

const ModuspaceService = {
  //Dashboard
  GetAnalyticsData,

  // reference tables
  GetCountries,
  GetStatesByCountryId,
  GetFandoms,
  GetScales,
  GetBrands,
  GetTicketProducts,

  // roles
  GetRoleStatus,
  GetRolePermissions,
  GetRoles,
  ListRolesPaginate,
  ExportRoles,
  GetRoleById,
  UpdateRoleById,
  CreateNewRole,
  DeleteRoleById,

  //for user & customer screens
  GetCustomerRolesIncludingInactive,
  GetUserRolesIncludingInactive,

  // generic endpoint used by back office user & customer
  EmailResetPasswordUserByID,

  // generate new password and email user used by back office only
  EmailGenerateNewPasswordUserByIDForAdmin,
  EmailGenerateNewPasswordUserByIDForCustomer,

  // profile
  GetProfileById,
  UpdateProfileById,
  UpdateProfilePasswordByUserId,

  // user (allow to login app & ecommerce and other public sites)
  ListUsersPaginate,
  ExportUsers,
  UpdateUserPasswordByUserId,
  GetUserById,
  UpdateUserById,
  UpdateUserStatusByUserId,
  CreateNewUser,
  DeleteUserById,
  GetAllUsers,
  VerifyUserEmailExists,

  // customers
  GetCustomerUploadToken,
  ListCustomers,
  ExportCustomers,
  ImportCustomers,
  UpdateCustomerPasswordByUserId,
  GetCustomerByUserId,
  UpdateCustomerByUserId,
  UpdateCustomerStatusByUserId,
  CreateNewCustomer,
  DeleteCustomerByUserId,
  VerifyCustomerEmailExists,

  // customer addresses
  ImportCustomerAddresses,
  GetCustomerUserAdressesByUserId,
  DeleteCustomerUserAddressById,
  UpdateCustomerUserAddressById,
  AddCustomerUserAddress,
  GetAllCustomers,

  // api keys
  ListApiKeysPaginate,
  GetApiKeyByKey,
  UpdateApiKeyByKey,
  ExportApiKeys,
  CreateApiKey,
  DeleteApiKeyByKey,

  // devices models
  GetDeviceUploadToken,
  GetAllDevicesModelsNameAndId,
  ListDevicesModels,
  ExportDevicesModels,
  CreateNewDeviceModel,
  GetDeviceModelById,
  UpdateDeviceModelById,
  DeleteDeviceModelById,
  GetPairedDeviceById,
  GetPairedDeviceGroupById,

  // devices by serial number
  ListDeviceSerialsPaginate,
  ExportAllDevices,
  ImportDeviceBySerial,
  GetDeviceBySerial,
  CreateNewDevice,
  UpdateDeviceById,
  DeleteDeviceById,
  ListAllPairedDevices,
  DeletePairedDeviceById,
  UpdatePairedDeviceById,
  CreatePairedDevice,
  GetAllDevices,
  GetDeviceById,
  GenerateSerialNumbers,
  ListAllSerialNumbers,
  BulkUpdateSerialNumbers,
  DeleteSerialNumber,
  GetSerialNumberStatus,
  GetSerialNumberProducts,

  // antiCounterfeit module
  ListProductSerialCounterfeitReportPaginate,
  GetAntiCounterfeitAnalytic,

  // warranty plan
  GetAllWarrantyPlansNameAndId,
  ListWarrantyPlans,
  ExportWarrantyPlans,
  CreateNewWarrantyPlan,
  GetWarrantyPlanById,
  UpdateWarrantyPlanById,
  DeleteWarrantyPlanById,

  // warranty item
  ListWarrantyItemsPagination,
  ExportWarrantyItem,
  CreateNewWarrantyItem,
  GetWarrantyItemById,
  UpdateWarrantyItemById,
  DeleteWarrantyItemById,

  // warranty claim
  ListWarrantyClaim,
  ExportWarrantyClaim,
  CreateNewWarrantyClaim,
  GetWarrantyClaimById,
  UpdateWarrantyClaimById,
  DeleteWarrantyClaimById,

  // ideaboard
  GetIdeaboardPostStatus,
  GetIdeaboardPostReviewType,
  GetIdeaboardPostReviewStatus,
  GetIdeaboardPostTags,
  GetIdeaboardPostProductSeries,
  GetIdeaboardPostCommentStatus,

  // posts
  GetAllIdeaboardPosts,
  ListAllIdeaboardPosts,
  ListPendingIdeaboardPosts,
  GetIdeaboardPostById,

  // get impression, comments & reviews
  GetIdeaboardPostCommentsByPostId,
  GetIdeaboardPostReviewsByPostId,
  GetIdeaboardPostImpressionByPostId,
  UpdateIdeaboardPostFlagById,
  UpdateIdeaboardPostById, //superuser only
  DeleteIdeaboardPostById, //superuser only
  UpdateIdeaboardPostReviewById,
  UpdateIdeaboardPostReviewsById, //group review update

  // comments
  GetIdeaboardPostComments,
  ListAllIdeaboardPostComments,
  ListPendingIdeaboardPostComments,
  GetIdeaboardPostCommentById,
  GetIdeaboardPostCommentReviewsByPostId,
  UpdateIdeaboardPostCommentFlagById,
  UpdateIdeaboardPostCommentById,
  DeleteIdeaboardPostCommentById,
  UpdateIdeaboardPostCommentReviewById,
  UpdateIdeaboardPostCommentReviewsById, //group review update

  // content management
  GetContentMediaUploadToken,
  GetAllContents,
  ListContentsPaginate,
  GetContentById,
  UpdateContentById,
  DeleteContentById,
  CreateNewContent,
  GetContentTypes,
  GetContentStatus,
  GetContentApplicationClients,

  // support ticket
  GetTicketMediaUploadToken,
  GetAllTickets,
  ListTicketsPaginate,
  GetTicketsByCustomerId,
  GetTicketById,
  CreateNewTicket,
  UpdateTicketById,
  DeleteTicketById,
  GetTicketPriorities,
  GetTicketStatus,
  GetTicketIssues,
  GetTicketMessages,
  CreateNewTicketMessage,

  // job
  GetJobById,

  // catalog
  GetAllCategories,
  ListCatalogCategoriesPaginate,
  GetAllProducts,
  ListCatalogProductsPaginate,

  // catalog categories
  GetAllCategories,
  ListCatalogCategoriesPaginate,

  // order
  ListAllOrders,
  GetOrderBigCommerceStatuses,
  GetOrderUploadToken,
  ImportOrders,
  GetOrderById,
  GetAllOrders,
  UpdateSalesOrderById,
  BulkUpdateSalesOrders,
  ListBigcommerceCatalogProducts,
  ListBigcommerceCatalogProductOptionsByProductId,
  UpsertBigcommerceSalesOrderProducts,

  // payments
  ListAllPayments,
  GetPaymentById,
  GetAllPaymentModes,
  GetAllPaymentStatuses,
  CreateNewPayment,
  UpdatePaymentById,
  SendPaymentEmailReminder,
  GetPaymentLogsByPaymentId,
  GetPaymentIntentsByPaymentId,
  ListAllPaymentLogs,

  // currencies
  GetAllCurrencies,

  // Delivery orders
  ListDeliveryOrders,
  GetAllDeliveryOrders,
  GetDeliveryOrdersBySalesOrderId,
  GetDeliveryOrder,
  UpdateDeliveryOrder,
  CreateDeliveryOrder,
  GetDeliveryOrderStatuses,
  GetDeliveryOrderShippingMethods,
};

export default ModuspaceService;
