import React, { forwardRef } from "react";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

const MDAutocomplete = forwardRef(({ label, ...rest }, ref) => {
  return (
    <Autocomplete
      {...rest}
      ref={ref}
      fullWidth
      renderInput={(params) => (
        <MDInput {...params} label={label} variant="standard" />
      )}
    />
  );
});

export default MDAutocomplete;
