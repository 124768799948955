import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useConfirmModal } from "components/MDConfirm";
import { useForm } from "components/MDForm";
import MDLoading from "components/MDLoading";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import BaseLayout from "layouts/components/BaseLayout";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { dateTimeToLocal, dateTimeToZulu, displayDate } from "utils/date";
import { delayResolve } from "utils/delay";
import { joinName } from "utils/join-name";
import { splitStringIntoSubsetCombinations } from "utils/transform";
import { getFormSchema } from "./formSchema";
import "./style.css";

function WarrantyItemCreate() {
  const [loading, setLoading] = useState("");
  const [updated, setUpdated] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [formDataRaw, setFormDataRaw] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);

  const { pageControl, authentication } = useStores();
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [searchUserTypingLoading, setSearchUserTypingLoading] = useState(false);
  const [fetchUserPagination, setFetchUserPagination] = useState({
    currentPage: 1,
    nextPage: 2,
    pageSize: 100,
    total: 0,
    hasMore: true,
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    components: formComponents,
    validateForm,
    updateSchema,
    formData,
    setFormData,
  } = useForm(getFormSchema());
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [initialWarrantyDate, setInitialWarrantyDate] = useState("");

  const fetchFormData = async () => {
    setLoading("Fetching info...");
    try {
      const { formDataRaw, userOptions, deviceOptions, planOptions } =
        await delayResolve(async () => ({
          formDataRaw: await ModuspaceService.GetWarrantyItemById(
            authentication.jwtToken,
            id,
          ),
          userOptions: (
            await ModuspaceService.ListCustomers(
              authentication.jwtToken,
              fetchUserPagination.pageSize,
              fetchUserPagination.currentPage,
            )
          ).list.map((x) => {
            return {
              label: `${joinName(x)} (${x.email})`,
              id: x.id,
              email: x.email,
            };
          }),
          deviceOptions: (
            await ModuspaceService.GetAllDevices(authentication.jwtToken)
          ).map((x) => {
            return {
              label: `${x.deviceModel?.name} (${x.serialNo})`,
              id: x.id,
              serialNo: x.serialNo,
            };
          }),
          planOptions: await ModuspaceService.GetAllWarrantyPlansNameAndId(
            authentication.jwtToken,
          ),
        }));

      // delete newFormData.user
      // delete newFormData.devices
      // delete newFormData.warrantyPlan
      setFormDataRaw(formDataRaw);
      setUserOptions([
        {
          label: `${joinName(formDataRaw.user)} (${formDataRaw.user?.email})`,
          email: formDataRaw.user?.email,
          id: formDataRaw.userId,
        },
        ...userOptions.filter((x) => x.id !== formDataRaw.userId),
      ]);
      setDeviceOptions(deviceOptions);
      setPlanOptions(planOptions);

      setOptionsLoaded(true);
    } catch (err) {
      console.error("Error at fetchFormData:", { err });
    }
    setLoading("");
  };

  useEffect(() => {
    if (authentication.jwtToken && !optionsLoaded) fetchFormData();
    // eslint-disable-next-line
  }, [authentication.jwtToken, optionsLoaded]);

  useEffect(() => {
    updateSchema(
      getFormSchema({
        onUserTextSearch: (e) => {
          // keep loading upon typing
          setSearchUserTypingLoading(true);
          // wait for user stop typing then do a recheck after 1s
          debounce(() => {
            setSearchUserInput(e.target.value);
            setSearchUserTypingLoading(false);
          }, 1000)();
        },
        searchUserLoading,
        searchUserTypingLoading,
        triggerFetchMoreUser: fetchUser,
        hasMoreUser: fetchUserPagination.hasMore,
        withUserOptions: userOptions,
        withDeviceOptions: deviceOptions,
        withPlanOptions: planOptions,
      }).filter((x) => x.actions.includes("UPDATE")),
    );
  }, [
    userOptions,
    deviceOptions,
    planOptions,
    searchUserLoading,
    searchUserTypingLoading,
  ]);

  useEffect(() => {
    if (!optionsLoaded) return;
    const newFormData = {
      ...formDataRaw,
      user: userOptions.find((e) => e.id === formDataRaw?.userId),
      device: deviceOptions.find((e) => e.id == formDataRaw?.devices[0].id),
      userId: formDataRaw.userId,
      serialNo: formDataRaw.devices?.[0].serialNo,
      active: formDataRaw.active ? "Active" : "Inactive",
      voided: formDataRaw.voided ? "Voided" : "Non-voided",
      warranty: {
        id: formDataRaw.warrantyPlan?.id,
        name: formDataRaw.warrantyPlan?.name,
      },
      startDate: dateTimeToLocal(formDataRaw.startDate),
    };
    setFormData(newFormData);
    setInitialWarrantyDate((" " + newFormData.startDate).slice(1));
  }, [optionsLoaded, deviceOptions, formDataRaw]);

  useEffect(() => {
    fetchUser();
  }, [searchUserInput]);

  const fetchUser = (isFetchMore = false) => {
    if (searchUserLoading) return;
    try {
      let fullText = searchUserInput?.trim() ?? "";
      if (fullText?.match(" ")) {
        // only unique array, include the fullText Search as well
        fullText = splitStringIntoSubsetCombinations(fullText, " ", 1);
      }
      setSearchUserLoading(true);
      ModuspaceService.ListCustomers(
        authentication.jwtToken,
        fetchUserPagination.pageSize,
        isFetchMore ? fetchUserPagination.nextPage : 1,
        [
          {
            id: ["email", "firstName", "lastName", "phone"],
            value: Array.isArray(fullText)
              ? [formDataRaw.user?.email, ...fullText]
              : fullText,
          },
        ],
      )
        .then((res) => {
          if (res.pagination)
            setFetchUserPagination({
              ...fetchUserPagination,
              ...res.pagination,
              nextPage: res.pagination.currentPage + 1,
              hasMore:
                res.pagination.currentPage * res.pagination.pageSize <
                res.pagination.total,
            });
          return res.list.map((x) => {
            return {
              label: `${joinName(x)} (${x.email})`,
              id: x.id,
              email: x.email,
            };
          });
        })
        .then((res) => {
          if (!isFetchMore) setUserOptions(res);
          else setUserOptions((prev) => [...prev, ...res]);
        })
        .then(() => {
          setSearchUserLoading(false);
        });
    } catch (err) {
      console.error("WarrantyItem fetchUser", { err });
    }
  };

  const [warrantyPlanInfo, setWarrantyPlanInfo] = useState(null);

  const renderSelectedWarrantyInfo = async () => {
    if (!formData?.warranty?.id) {
      setWarrantyPlanInfo(null);
      return;
    }
    setLoading("Fetching warranty info...");
    try {
      // console.log("Fetching warranty info for:", formData)
      const warrantyPlan = await ModuspaceService.GetWarrantyPlanById(
        authentication.jwtToken,
        formData?.warranty?.id,
      );
      setWarrantyPlanInfo(warrantyPlan);
    } catch (err) {
      console.error("Error at renderSelectedWarrantyInfo:", { formData, err });
    }

    setLoading("");
  };

  useEffect(() => {
    if (authentication.jwtToken) renderSelectedWarrantyInfo();
  }, [formData?.warranty, authentication.jwtToken]);

  const handleSubmit = async () => {
    const { formData, error } = validateForm();
    if (error) {
      toast.error("Fill in the required fields.");
      return;
    }

    const updatedFormData = {
      ...formData,
      userId: formData.user.id,
      warrantyPlanId: formData.warranty.id,
      active: formData.active === "Inactive" ? false : true,
      voided: formData.voided === "Voided" ? true : false,
      startDate: dateTimeToZulu(formData.startDate),
    };
    delete updatedFormData.warranty;

    setLoading("Submitting...");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.UpdateWarrantyItemById(
          authentication.jwtToken,
          id,
          updatedFormData,
        ),
      );
      if (response) {
        setUpdated(true);
        setInitialWarrantyDate(updatedFormData.startDate);
        toast.success("Warranty Item has been updated.");
      }
    } catch (err) {
      console.error("Error at submitting:", { formData, err });
      toast.error("Fail to update warranty item.");
    }
    setLoading("");
  };

  const handleDelete = async () => {
    setLoading("Submitting...");
    try {
      const response = await delayResolve(() =>
        ModuspaceService.DeleteWarrantyItemById(authentication.jwtToken, id),
      );
      if (response) {
        setDeleted(true);
        toast.success("Warranty Item has been deleted.");
      }
    } catch (err) {
      console.error("Error at submitting:", { formData, err });
      toast.error("Fail to delete warranty item.");
    }
    setLoading(null);
  };

  const {
    prompt: promptDeleteWarranty,
    MDConfirm: MDConfirmDeleteWarrantyModal,
  } = useConfirmModal({
    title: "Delete ",
    content:
      "Once deleted, the warranty cannot be retrieved and must be recreated.",
    confirmText: "Delete",
    confirmColor: "error",
    onConfirm: handleDelete,
  });

  useEffect(() => {
    if (updated || deleted) navigate("/dashboard/warranties/registered");
    // eslint-disable-next-line
  }, [updated, deleted]);

  return (
    <BaseLayout>
      <MDLoading show={!!loading} text={loading} />
      <MDConfirmDeleteWarrantyModal />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5" fontWeight="medium">
                      Warranty Info
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Update an existing warranty after a purchase of a product
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={3}>
                  {formComponents}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5">Device Information</MDTypography>
                  </Grid>
                </Grid>
                {formDataRaw?.devices?.map((data) => (
                  <MDBox alignItems="center" mb={0.5}>
                    <MDBox key="mac" display="flex" mt={0.5} mb={0.5} pr={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        Mac: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text">
                        {data.mac}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      key="serialNo"
                      display="flex"
                      mt={0.5}
                      mb={0.5}
                      pr={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        Serial No: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text">
                        {data.serialNo}
                      </MDTypography>
                    </MDBox>
                    <MDBox key="lotNo" display="flex" mt={0.5} mb={0.5} pr={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        Lot No: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text">
                        {data.lotNo}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      key="productionFirmware"
                      display="flex"
                      mt={0.5}
                      mb={0.5}
                      pr={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        Production Firmware: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text">
                        {data.productionFirmware}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      key="productionDate"
                      display="flex"
                      mt={0.5}
                      mb={0.5}
                      pr={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize">
                        Production Date: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text">
                        {displayDate(data.productionDate)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ))}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card id="basic-customer-info" sx={{ overflow: "visible" }}>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={1} pt={1}>
                  {/* basic information */}
                  <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                    <Card sx={{ boxShadow: "none" }}>
                      <MDBox pb={2} lineHeight={1.25}>
                        <MDTypography
                          variant="button"
                          fontWeight="bold"
                          textTransform="capitalize">
                          Currently Warranty Duration
                        </MDTypography>
                        <MDBox alignItems="center" mb={0.5}>
                          <MDBox
                            key="name"
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            pr={2}>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize">
                              Start Date: &nbsp;
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="bold"
                              color="text">
                              {displayDate(initialWarrantyDate)}
                            </MDTypography>
                          </MDBox>
                          <MDBox key="email" display="flex" mb={0.5} pr={2}>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize">
                              End Date: &nbsp;
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="bold"
                              color="text">
                              {displayDate(
                                dayjs(initialWarrantyDate).add(
                                  warrantyPlanInfo?.dayDuration,
                                  "days",
                                ),
                              )}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                  {/* activities */}
                  <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                    <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />
                    <Card sx={{ boxShadow: "none" }}>
                      <MDBox pb={2} lineHeight={1.25}>
                        <MDTypography
                          variant="button"
                          fontWeight="bold"
                          textTransform="capitalize">
                          New Warranty Duration
                        </MDTypography>
                        <MDBox alignItems="center" mb={0.5}>
                          <MDBox
                            key="name"
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            pr={2}>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize">
                              Start Date: &nbsp;
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="bold"
                              color="text">
                              {displayDate(formData?.startDate)}
                            </MDTypography>
                          </MDBox>
                          <MDBox key="email" display="flex" mb={0.5} pr={2}>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize">
                              End Date: &nbsp;
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="bold"
                              color="text">
                              {displayDate(
                                dayjs(formData?.startDate).add(
                                  warrantyPlanInfo?.dayDuration,
                                  "days",
                                ),
                              )}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography variant="h5">Update Warranty</MDTypography>
                  </Grid>
                </Grid>
                <MDTypography variant="subtitle2" color="secondary">
                  Warranty plan info will be auto loaded as you pick from the
                  selection above.
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={3}>
                <Grid container>
                  <Grid item>
                    <MDTypography
                      variant="subtitle"
                      style={{ fontWeight: "bold" }}>
                      Selected warranty info:
                    </MDTypography>
                  </Grid>
                  {!warrantyPlanInfo && (
                    <Grid item key="warranty-plan-info" xs={12}>
                      <MDTypography variant="subtitle2">
                        Please select a warranty plan to view warranty plan info
                      </MDTypography>
                    </Grid>
                  )}
                </Grid>
              </MDBox>
              <MDBox
                py={3}
                px={3}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}>
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={handleSubmit}
                  size="large"
                  style={{ marginLeft: "auto" }}>
                  update warranty
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}>
                <MDBox p={3} lineHeight={1}>
                  <MDBox mb={1}>
                    <MDTypography variant="h5">Delete Warranty</MDTypography>
                  </MDBox>
                  <MDTypography variant="button" color="text">
                    You may not be able to retrive a deleted warranty.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={promptDeleteWarranty}
                    size="large">
                    delete warranty
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(WarrantyItemCreate);
