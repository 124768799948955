import MDBox from "components/MDBox";
import GeneralTable from "controls/Tables/GeneralTable";
import { displayDate } from 'utils/date';

const OrderInfo = ({ data }) => {
  const tableInfo = [
    {
      label: 'Date',
      name: 'createdAt',
      render: (field) => displayDate(field, 'DD MMMM YYYY, hh:mm A'),
    },
    {
      label: 'Description',
      name: 'description',
      align: false,
    }
  ]
  return (
    <MDBox mt={3}>
      <GeneralTable
        headColumn={tableInfo}
        bodyColumn={data || []}
        disablePagination={true}
        height={300}
      />
    </MDBox>
  );
};

export default OrderInfo;
