import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import ModuspaceService from 'services/moduspace.service';
import MDGeneralListingV3 from 'components/MDGeneralListingV3';
import { delayResolve } from 'utils/delay';

function WarrantyPlansManagement() {
  const { authentication } = useStores();

  const [data, setData] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  
  useEffect(() => {
    init();
  }, [tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }
      const {
        list: { list, pagination }
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListWarrantyPlans(
            authentication.jwtToken,
            tablePagination.pageSize,
            tablePagination.currentPage,
            dataFilters,
            sortBy,
          )
        }),
        100
      );
      // Add `__checked` property to each serial object
      list.forEach((plan) => (plan.__checked = false));
      setData(list);
      setTotalCount(pagination.total);
    } catch (err) {
      console.error('WarrantyPlansManagement init:', err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListWarrantyPlans(
        authentication.jwtToken,
        Number.MAX_SAFE_INTEGER,
        1,
        appliedFilter ? dataFilters : []
      );
      return list;
    } catch (err) {
      console.error('WarrantyPlansManagement fetchAllExportData:', err);
    }
  };

  const filterInfo = [];

  const tableInfo = [
    { 
      label: 'Name', 
      id: 'name' 
    },
    { 
      label: 'Type', 
      id: 'type' 
    },
    { 
      label: 'Amount', 
      id: 'amount' 
    },
    { 
      label: 'Duration (days)', 
      id: 'dayDuration' 
    },
    { 
      label: 'Created at', 
      id: 'createdAt' 
    },
    { 
      label: 'Updated at', 
      id: 'updatedAt' 
    }
  ];

  const exportInfo = [];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      dateColumn={['createdAt', 'updatedAt']}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      allowCreate={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) => editButton}
      showBulkAction={false}
      // for exports
      exportInfo={exportInfo}
      fetchAllExportData={fetchAllExportData}
      // for common usage
      title="Warranty Plan Management"
      currentActiveParent="warranties"
      currentActiveTab="plans"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(WarrantyPlansManagement);
