/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';

function DeleteNews({
  onConfirm = () => void 0
  // onDeactivate = () => void 0,
  // onReactivate = () => void 0,
  // showDeactivateOption = true,
}) {
  // const [showDeactivate, setShowDeactivate] = React.useState(showDeactivateOption)
  // React.useEffect(() => {
  //   setShowDeactivate(showDeactivateOption)
  // }, [showDeactivateOption])
  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: 'Confirm Delete News?',
    content:
      'Once you delete your news, there is no going back. Please be certain.',
    confirmColor: 'error',
    confirmText: 'delete',
    onConfirm
  });
  // const { prompt: promptDeactivate, MDConfirm: MDConfirmDeactivate } = useConfirmModal({
  //   title: "Confirm Deactivate News?",
  //   content: "Deactivating a news is a safer alternative than deleting it. You may recover the news later.",
  //   confirmText: "deactivate",
  //   onConfirm: onDeactivate,
  // });
  return (
    <Card id="delete-news">
      <MDConfirmDelete />
      {/* <MDConfirmDeactivate /> */}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Delete Content</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            You can choose to delete this content. Once you delete this content,
            there is no going back.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {/* {showDeactivate && (
            <MDButton variant="gradient" color="dark" size="large" onClick={promptDeactivate} style={{ marginLeft: ".5rem" }}>
              deactivate
            </MDButton>
          )}
          {!showDeactivate && (
            <MDButton variant="gradient" color="success" size="large" onClick={onReactivate} style={{ marginLeft: ".5rem" }}>
              reactivate
            </MDButton>
          )} */}
          <MDButton
            variant="gradient"
            color="error"
            size="large"
            onClick={promptDelete}
            style={{ marginLeft: '.5rem' }}>
            delete
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DeleteNews;
