/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useConfirmModal } from 'components/MDConfirm';

function DeleteAccount({
  onConfirm = () => void 0,
  onDeactivate = () => void 0,
  onReactivate = () => void 0,
  showDeactivateOption = true
}) {
  const [showDeactivate, setShowDeactivate] =
    React.useState(showDeactivateOption);
  React.useEffect(() => {
    setShowDeactivate(showDeactivateOption);
  }, [showDeactivateOption]);
  const { prompt: promptDelete, MDConfirm: MDConfirmDelete } = useConfirmModal({
    title: 'Confirm Delete Account?',
    content:
      'Once you delete your account, there is no going back. Please be certain.',
    confirmColor: 'error',
    confirmText: 'delete',
    onConfirm
  });
  const { prompt: promptDeactivate, MDConfirm: MDConfirmDeactivate } =
    useConfirmModal({
      title: 'Confirm Deactivate Account?',
      content:
        'Deactivating an account is a safer alternative than deleting it. You may recover the account later.',
      confirmText: 'deactivate',
      onConfirm: onDeactivate
    });
  return (
    <Card id="delete-account">
      <MDConfirmDelete />
      <MDConfirmDeactivate />
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Additional Information</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            You can choose to delete or deactivate this account. Once you delete
            this account, there is no going back.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {showDeactivate && (
            <MDButton
              variant="gradient"
              color="dark"
              size="large"
              onClick={promptDeactivate}
              style={{ marginLeft: '.5rem' }}>
              update status to inactive
            </MDButton>
          )}
          {!showDeactivate && (
            <MDButton
              variant="gradient"
              color="success"
              size="large"
              onClick={onReactivate}
              style={{ marginLeft: '.5rem' }}>
              update status to active
            </MDButton>
          )}
          <MDButton
            variant="gradient"
            color="error"
            size="large"
            onClick={promptDelete}
            style={{ marginLeft: '.5rem' }}>
            delete
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DeleteAccount;
