/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-flatpickr components
import Flatpickr from 'react-flatpickr';

// react-flatpickr styles
import 'flatpickr/dist/flatpickr.css';

// Material Dashboard 2 PRO React components
import MDInput from 'components/MDInput';
import './index.css';
import MDButton from '../MDButton';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';

function MDDatePicker({ input, label, onChange, disableClearable, ...rest }) {
  return (
    <Flatpickr
      onChange={onChange}
      {...rest}
      render={({ value, defaultValue }, ref) => {
        if (disableClearable) {
          return (
            <MDInput
              {...input}
              inputRef={ref}
              variant="standard"
              label={label}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          );
        } else {
          return (
            <MDBox
              px={1}
              style={{ display: 'flex', alignItems: 'end', width: '100%' }}>
              <MDInput
                {...input}
                inputRef={ref}
                variant="standard"
                label={label}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <MDButton
                variant="outlined"
                size="small"
                iconOnly
                style={{ fontSize: 25, color: '#D2D6DA', border: 'none' }}
                onClick={() => onChange('')}>
                <CloseIcon fontSize="25" />
              </MDButton>
            </MDBox>
          );
        }
      }}
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {}
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any)
};

export default MDDatePicker;
