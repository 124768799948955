import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import MDGeneralListingV3 from "components/MDGeneralListingV3";
import CustomerSearchModal from "components/MDCustomersSearch/CustomerSearchModal";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModuspaceService from "services/moduspace.service";
import { useStores } from "stores";
import { delayResolve } from "utils/delay";
import { getBadgeColorByStatus } from "utils/element";
import { joinName } from "utils/join-name";
import { joinPath } from "utils/join-url";
import MDAutocomplete from "components/MDAutocomplete";
import MDButton from "components/MDButton";

function IdeaboardPostManagement() {
  const { authentication, ideaboard: ideaboardStore } = useStores();
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [postStatus, setPostStatus] = useState([]);
  const [productSeries, setProductSeries] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [tablePagination, setTablePagination] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataFilters, setDataFilters] = useState([]);
  const [sortBy, setSortBy] = useState({
    id: '',
    type: '' // asc or desc
  });
  const [customerSearchModalVisible, setCustomerSearchModalVisible] =
    useState(false);

  useEffect(() => {
    init();
  }, [ideaboardStore, tablePagination, sortBy]);

  async function init() {
    setRefreshing(true);
    try {
      if (!tablePagination?.pageSize || sortBy.type === '') {
        return;
      }

      const {
        list: { list, pagination },
        postStatus,
        productSeries,
      } = await delayResolve(
        async () => ({
          list: await ModuspaceService.ListAllIdeaboardPosts({
            pageSize: tablePagination.pageSize,
            currentPage: tablePagination.currentPage,
            filtered: dataFilters,
            ordering: sortBy,
          }),
          postStatus: await ModuspaceService.GetIdeaboardPostStatus(),
          productSeries: await ModuspaceService.GetIdeaboardPostProductSeries(),
        }),
        100,
      );
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.user.id = data.userId));
      list.forEach((data) => (data.userFullName = joinName(data.user)));
      list.forEach((data) => (data.userEmail = data.user?.email));
      list.forEach(
        (data) =>
          (data.reviewType =
            data.userPostReviews?.map((item) => item.type) ?? []),
      );
      list.forEach(
        (data) =>
          (data.reviewStatus =
            data.userPostReviews?.map((item) => item.status) ?? []),
      );
      list.forEach(
        (data) => (data.numOfUserComments = data.userComments?.length ?? 0),
      );
      list.forEach(
        (data) =>
          (data.numOfUserImpressions =
            data.userImpressions?.filter((item) => item.pin).length ?? 0),
      );
      setData(list);
      setTotalCount(pagination.total);
      setPostStatus(postStatus?.map((item) => ({ label: item, id: item })));
      setProductSeries(
        productSeries?.map((item) => ({ label: item, id: item })),
      );
    } catch (err) {
      console.error("IdeaboardPostManagement:", err);
    }
    setRefreshing(false);
  }

  async function fetchAllExportData(appliedFilter = false) {
    try {
      const { list } = await ModuspaceService.ListAllIdeaboardPosts({
        pageSize: Number.MAX_SAFE_INTEGER,
        currentPage: 1,
        filtered: appliedFilter ? dataFilters : [],
      });
      // Add new property to each data object
      list.forEach((data) => (data.__checked = false));
      list.forEach((data) => (data.user.id = data.userId));
      list.forEach((data) => (data.userFullName = joinName(data.user)));
      list.forEach((data) => (data.userEmail = data.user?.email));
      list.forEach(
        (data) =>
          (data.reviewType =
            data.userPostReviews?.map((item) => item.type) ?? []),
      );
      list.forEach(
        (data) =>
          (data.reviewStatus =
            data.userPostReviews?.map((item) => item.status) ?? []),
      );
      list.forEach(
        (data) => (data.numOfUserComments = data.userComments?.length ?? 0),
      );
      list.forEach(
        (data) =>
          (data.numOfUserImpressions =
            data.userImpressions?.filter((item) => item.pin).length ?? 0),
      );
      return list;
    } catch (err) {
      console.error("UsersManagement fetchAllExportData:", err);
    }
  }

  const filterInfo = [
    {
      name: "userPost__title,userPost__caption,userPost__tags",
      label: "Keywords",
      type: "string",
      placeholder: "Title, Caption, Tags",
      searchColumn: "userPost__title,userPost__caption,userPost__tags",
    },
    {
      type: "multiSelect",
      label: "Poster",
      name: "userPost__userId",
      value: String(user.map((u) => u.id)),
      options: user || [],
      searchColumn: "userPost__userId",
      render: () => (
        <>
          {customerSearchModalVisible && (
            <CustomerSearchModal
              open={customerSearchModalVisible}
              onClose={() =>
                setCustomerSearchModalVisible(!customerSearchModalVisible)
              }
              onSearch={(selectedUsers) => {
                setUser(selectedUsers);
              }}
            />
          )}
          <MDAutocomplete
            label="Customer"
            name="userPost__userId"
            value={user || []}
            options={user || []}
            multiple
            readOnly
          />
          <MDButton
            variant="outlined"
            size="small"
            iconOnly
            style={{ fontSize: 25, color: "#D2D6DA", border: "none" }}
            onClick={() => setUser([])}>
            <CloseOutlined />
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            iconOnly
            onClick={() => setCustomerSearchModalVisible(true)}>
            <SearchOutlined />
          </MDButton>
        </>
      ),
    },
    {
      name: "status",
      label: "Post State",
      type: "multiSelect",
      optionName: "status",
      options: postStatus,
      searchColumn: "userPost__status",
    },
    {
      name: "related_series",
      label: "Product Series",
      type: "multiSelect",
      optionName: "related_series",
      options: productSeries,
      searchColumn: "userPost__related_series",
    },
    {
      name: "createdAt",
      label: "Posted on",
      type: "dateRange",
      searchColumn: "userPost__createdAt",
    },
  ];

  const tableInfo = [
    {
      label: "Title",
      id: "title",
      tableRowMapper: (value) =>
        value?.length > 50 ? `${value.substring(0, 50)}...` : value,
    },
    {
      label: "Customer",
      id: "user",
      alias: "user.firstName",
      tableRowMapper: (value) => (
        <Link
          className="custom-link"
          to={joinPath("/dashboard/customers/", value.id)}
          target="_blank">
          {joinName(value)}
        </Link>
      ),
      className: "custom-link",
    },
    {
      label: "Comments",
      id: "numOfUserComments",
    },
    {
      label: "Pins",
      id: "numOfUserImpressions",
    },
    {
      label: "Post State",
      id: "status",
      tableRowMapper: (value) =>
        value ? (
          <MDBadge
            size="xs"
            badgeContent={value}
            color={getBadgeColorByStatus(value)}
            container
          />
        ) : null,
    },
    {
      label: "Posted on",
      id: "createdAt",
    },
    {
      label: "Last Updated on",
      id: "updatedAt",
    },
  ];

  const sortInfo = [
    {
      name: "user",
      rowMapper: (value) => joinName(value),
    },
  ];

  return (
    <MDGeneralListingV3
      // for filtering
      filterInfo={filterInfo}
      dataFilters={dataFilters}
      setDataFilters={setDataFilters}
      // for sorting
      sortBy={sortBy}
      setSortBy={setSortBy}
      // for table rendering
      _data={data}
      tableInfo={tableInfo}
      sortInfo={sortInfo}
      dateColumn={["createdAt", "updatedAt"]}
      tablePagination={tablePagination}
      setTablePagination={setTablePagination}
      totalCount={totalCount} // all available data count
      // for action
      rowIDAccessor="id"
      viewOnly={true}
      createActionButtons={(rowId, rowName, editButton, deleteButton, row) =>
        editButton
      }
      showBulkAction={false}
      // for exports
      exportInfo={ideaboardStore.posts.fileExportMap}
      fetchAllExportData={fetchAllExportData}
      onFilterReset={() => setUser([])}
      // for common usage
      title="Posts"
      exportFileNamePrefix="posts"
      currentActiveParent="ideaboard"
      currentActiveTab="ideaboard-post-list"
      init={init}
      isRefreshing={isRefreshing}
    />
  );
}

export default observer(IdeaboardPostManagement);
