import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import { delayResolve } from 'utils/delay';
import ModuspaceService from 'services/moduspace.service';
import { useNavigate } from 'react-router-dom';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from 'pages/content/components/BasicInfo';
import CreateContent from 'pages/content/components/CreateContent';
import { uploadS3file } from 'utils/s3Uploader';
import { dateTimeToZulu } from 'utils/date';

function ContentCreate() {
  const [loading, setLoading] = React.useState(null);
  const contentInfoRef = React.useRef(null);
  const [contentInfo, setContentInfo] = React.useState(null);
  const [types, setTypes] = React.useState(null);
  const [statuses, setStatuses] = React.useState(null);
  const [clients, setClients] = React.useState(null);
  const [isCreated, setIsCreated] = React.useState(false);
  const { authentication } = useStores();
  const navigate = useNavigate();

  const fetchContentEnums = async () => {
    setLoading('Fetching metadata...');
    try {
      const { types, statuses, clients } = await delayResolve(async () => ({
        types: await ModuspaceService.GetContentTypes(authentication.jwtToken),
        statuses: await ModuspaceService.GetContentStatus(
          authentication.jwtToken
        ),
        clients: await ModuspaceService.GetContentApplicationClients(
          authentication.jwtToken
        )
      }));
      setTypes(types);
      setStatuses(statuses);
      setClients(clients);
    } catch (err) {
      console.error('Error at fetchContentEnums:', err);
    }
    setLoading(null);
  };

  const createContent = async () => {
    let error = false;
    let info;
    if (contentInfoRef.current.onSubmitListener) {
      const { error: validationError, info: validatedContentInfo } =
        contentInfoRef.current.onSubmitListener();
      error = validationError;
      info = validatedContentInfo;
    } else {
      // flow should not trigger if ref is successfully passed
      error = true;
    }

    if (error) {
      console.log(error);
      return;
    }

    setLoading('Submitting');
    //upload all files to s3 and populate media prop
    try {
      const session = await ModuspaceService.GetContentMediaUploadToken(
        authentication.jwtToken
      );
      const media = await Promise.all(
        info.files.map(async (file) => {
          const {
            type
            // upload: { filename, uuid },
          } = file;
          // const dataUri = await fileToDataUri(file)
          const result = await uploadS3file({
            session,
            file: file,
            // data: dataUri,
            onError: (err) => {
              console.log(err);
            },
            onProgress: (progress) => {
              console.log(progress);
            },
            onSuccess: (result) => {
              // console.log(result);
            }
          });

          const { Location, Key, Bucket } = result;
          return { type, link: Location };
        })
      );

      delete info.files;

      const payload = {
        ...info,
        displayAt: dateTimeToZulu(info.displayAt),
        media: media
      };

      // console.log("create content: ", payload);
      //console.log("create content JSON string: ", JSON.stringify(payload));

      try {
        const response = await delayResolve(() =>
          ModuspaceService.CreateNewContent(authentication.jwtToken, payload)
        );
        // console.log("ModuspaceService.CreateNewContent: result - ",JSON.stringify(data))
        if (response) {
          setIsCreated(true);
          setContentInfo(response);
          toast.success(
            'Your promotion/feature/article is created successfully.'
          );
        }
      } catch (err) {
        console.error('Error at createContent:', err);
        toast.error('Fail to create promotion/feature/article.');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(null);
  };

  React.useEffect(() => {
    if (authentication.jwtToken) fetchContentEnums();
    // eslint-disable-next-line
  }, [authentication.jwtToken]);

  React.useEffect(() => {
    if (isCreated && contentInfo !== null)
      navigate('/dashboard/content/' + contentInfo.id);
    // eslint-disable-next-line
  }, [isCreated, contentInfo]);

  return (
    <BaseLayout>
      <MDBox>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicInfo
              contentInfo={{ content: '', displayAt: new Date() }}
              types={types}
              statuses={statuses}
              clients={clients}
              onSubmitRef={contentInfoRef}
              createMode
            />
          </Grid>
          <Grid item xs={12}>
            <CreateContent onSubmit={createContent} />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(ContentCreate);
