import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Media = ({ media }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    if (media[current + 1] !== undefined) {
      setCurrent(current + 1);
    }
  };

  const previous = () => {
    if (media[current - 1] !== undefined) {
      setCurrent(current - 1);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrent(0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'auto'
      }}>
      {media?.map((file, index) => (
        <Box
          key={file?.fileName}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '4px',
            paddingLeft: '0',
            width: '88px',
            cursor: 'pointer'
          }}>
          <img
            style={{
              width: '80px',
              height: '80px',
              objectFit: 'cover',
              borderRadius: '16px'
            }}
            src={file?.link}
            onClick={() => setModalIsOpen(true)}
          />
          <Typography variant="caption" sx={{ marginTop: '4px' }}>
            {file?.fileName}
          </Typography>
          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            fullWidth
            maxWidth="lg">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <DialogTitle>{file?.fileName}</DialogTitle>
              <IconButton onClick={() => setModalIsOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: '16px'
              }}>
              <Fab
                aria-label="Previous image"
                onClick={previous}
                disabled={media && media[current - 1] == undefined}
                sx={{ flexShrink: 0 }}>
                <ArrowBackIcon fontSize="medium" />
              </Fab>

              <DialogContent sx={{ textAlign: 'center' }}>
                <img
                  style={{
                    width: 'auto',
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'cover'
                  }}
                  src={media[current]?.link}
                />
              </DialogContent>

              <Fab
                aria-label="Next image"
                onClick={next}
                disabled={media && media[current + 1] == undefined}
                sx={{ flexShrink: 0 }}>
                <ArrowForwardIcon fontSize="medium" />
              </Fab>
            </Box>
          </Dialog>
        </Box>
      ))}
    </Box>
  );
};

export default Media;
