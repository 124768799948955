import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { useNavigate } from 'react-router-dom';

import ModuspaceService from 'services/moduspace.service';
import { delayResolve } from 'utils/delay';

import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import BaseLayout from 'layouts/components/BaseLayout';
import CreateFields from './components/CreateFields';

function DeliveryOrderCreate() {
	const [loading, setLoading] = useState(null);
	const [info, setInfo] = useState(null);
	const [orders, setOrders] = useState([]);
	const [statuses, setStatuses] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const { authentication } = useStores();
  const navigate = useNavigate();

  const fetchFieldOptions = async () => {
    setLoading('Fetching field options...');
    try {
      const { orders: { models: orders }, statuses, shippingMethods } = await delayResolve(
        async () => ({
          orders: await ModuspaceService.GetAllOrders(authentication.jwtToken),
          statuses: await ModuspaceService.GetDeliveryOrderStatuses(authentication.jwtToken),
          shippingMethods: await ModuspaceService.GetDeliveryOrderShippingMethods(authentication.jwtToken),
        }),
        100,
      );
      setOrders(orders?.map((order) => ({ label: `#${order.referenceOrderId}`, ...order })));
      setStatuses(statuses?.map(({ name }) => ({ id: name, label: name })));
      setShippingMethods(shippingMethods?.map(({ name }) => ({ id: name, label: name })));
    } catch (err) {
      console.error('Error at fetchFieldOptions:', err);
    }
    setLoading(null);
  };

  const createDeliveryOrder = async (data) => {
    setLoading('Submitting');
    try {
      const info = await ModuspaceService.CreateDeliveryOrder(authentication.jwtToken, data);
      setInfo(info);
    } catch (err) {
      console.error('Error at createDeliveryOrder:', err);
      toast.error(err);
    }
    setLoading(null);
  }

  useEffect(() => {
    if (info?.id) {
      navigate(`/dashboard/delivery-orders/${info.id}`);
    }
  }, [info]);

  useEffect(() => {
    if (info === null && authentication.jwtToken) {
      fetchFieldOptions();
    }
  }, [info, authentication.jwtToken]);

  return (
    <BaseLayout>
      <MDBox mt={3}>
        <MDLoading show={!!loading} text={loading} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateFields 
              info={info}
			        orders={orders}
              statuses={statuses}
              shippingMethods={shippingMethods}
              submitHandler={createDeliveryOrder}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default observer(DeliveryOrderCreate);
