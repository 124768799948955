import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  Icon,
  Skeleton,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormField from "layouts/components/FormField";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { presetPaymentDescriptionOptions } from "utils/constants";
import { joinPath } from "utils/join-url";
import ModuspaceService from "services/moduspace.service";
import { delayResolve } from "utils/delay";
import { useStores } from "stores";

function OrderDetail({
  summary,
  getData,
  transformData,
  actionButtons = [],
  extraButtons = [],
  isRefreshable,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetching = () => {
    setLoading(true);
    getData()
      .then((salesOrder) => {
        setData(transformData(salesOrder));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetching();
  }, [getData]);

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius="lg"
      mb={1}>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item flex={1}>
              {summary}
            </Grid>
            <Grid item>
              {actionButtons.map((btn) => (
                <MDBox key={btn.label} mr={1}>
                  <MDButton
                    variant="text"
                    color={btn.color}
                    onClick={btn.onClick}>
                    {btn.icon}&nbsp;{btn.label}
                  </MDButton>
                </MDBox>
              ))}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox width="100%" display="flex">
            <MDBox width="100%" display="flex" flexDirection="column">
              <Grid container width="100%">
                {loading
                  ? Array.from(Array(15).keys()).map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={12}
                        md={5}
                        mx={1}
                        lineHeight={0}>
                        <Skeleton animation="wave" height={20} />
                      </Grid>
                    ))
                  : Object.keys(data ?? {}).map((key) => (
                      <Grid item xs={12} md={6} lineHeight={0}>
                        {data.render ? (
                          data.render()
                        ) : (
                          <MDTypography
                            key={key}
                            variant="caption"
                            color="text">
                            {key}:&nbsp;&nbsp;&nbsp;
                            <MDTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize">
                              {data[key]}
                            </MDTypography>
                          </MDTypography>
                        )}
                      </Grid>
                    ))}
              </Grid>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="space-between"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}>
              {isRefreshable && (
                <MDBox mr={1}>
                  <MDButton variant="text" color="info" onClick={fetching}>
                    <Icon>refresh</Icon>&nbsp;refresh
                  </MDButton>
                </MDBox>
              )}
              {extraButtons.map((btn) => (
                <MDBox key={btn.label} mr={1}>
                  <MDButton
                    variant="text"
                    color={btn.color}
                    onClick={btn.onClick}>
                    {btn.icon}&nbsp;{btn.label}
                  </MDButton>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
}

function OrderInfo({
  data,
  setData,
  orderOptions,
  defaultOrder,
  customer,
  setCustomer,
}) {
  const { authentication } = useStores();

  const [orders, setOrders] = useState(orderOptions);
  const [salesOrderPaymentData, setSalesOrderPaymentData] = useState({
    salesOrderId: null,
    referenceOrderId: null,
    amount: 0,
    description: "",
  });

  const onAddOrder = (e) => {
    e.preventDefault();
    // Make sure all fields are filled and amount is not 0
    if (
      !salesOrderPaymentData.salesOrderId ||
      !salesOrderPaymentData.description ||
      !salesOrderPaymentData.amount ||
      salesOrderPaymentData.amount === "0.00"
    ) {
      alert("Please fill in all fields");
      return;
    }
    // Make sure order ID, amount, and description are unique
    if (
      data?.salesOrderPayments?.some(
        (item) =>
          item.salesOrderId === salesOrderPaymentData.salesOrderId &&
          item.description === salesOrderPaymentData.description &&
          item.amount === salesOrderPaymentData.amount,
      )
    ) {
      alert(
        "An order with the same order ID, amount, and description has already been added",
      );
      return;
    }
    // Set data
    setData((prevInfo) => ({
      ...prevInfo,
      salesOrderPayments: [
        ...(prevInfo.salesOrderPayments || []),
        salesOrderPaymentData,
      ],
      // Update total amount
      amount: (
        Number(prevInfo.amount || 0) + Number(salesOrderPaymentData.amount)
      ).toFixed(2),
    }));
    // Clear data
    setSalesOrderPaymentData({
      salesOrderId: defaultOrder?.id ?? orders?.[0]?.id,
      referenceOrderId:
        defaultOrder?.referenceOrderId ?? orders?.[0]?.referenceOrderId,
      amount: 0,
      description: "",
    });
  };

  const setCustomerData = (order) => {
    if (order?.customerId) {
      setData({ ...data, customerId: order.customerId });
    }
    setCustomer({
      id: order?.customerId ?? null,
      firstName:
        customer?.id && customer?.id === order?.customerId
          ? customer.firstName
          : order.billing_address.first_name,
      lastName:
        customer?.id && customer?.id === order?.customerId
          ? customer.lastName
          : order.billing_address.last_name,
      email:
        customer?.id && customer?.id === order?.customerId
          ? customer.email
          : order.billing_address.email,
      phone:
        customer?.id && customer?.id === order?.customerId
          ? customer.phone
          : order.billing_address.phone,
      country:
        customer?.id && customer?.id === order?.customerId
          ? customer.country
          : order.billing_address.country,
    });
  };

  useEffect(() => {
    // Set order options to only include orders from the customer once an order is selected and added
    if (
      customer?.email &&
      ((data?.salesOrderPayments && data.salesOrderPayments.length > 0) ||
        defaultOrder?.id)
    ) {
      setOrders(
        orderOptions.filter(
          (order) =>
            (customer?.id && order.customerId === customer?.id) ||
            order.billing_address.email === customer.email,
        ),
      );
    } else {
      // Set order options to include all orders
      setOrders(orderOptions);
    }
  }, [customer, orderOptions, data?.salesOrderPayments]);

  useEffect(() => {
    // Set auto-populated order to default order or first order in the list
    setSalesOrderPaymentData({
      salesOrderId: defaultOrder?.id ?? orders?.[0]?.id,
      referenceOrderId:
        defaultOrder?.referenceOrderId ?? orders?.[0]?.referenceOrderId,
      amount: 0,
      description: "",
    });
  }, [defaultOrder, orders]);

  const getOrderDetail = async (id) => {
    const orderDetails = await delayResolve(() =>
      ModuspaceService.GetOrderById(authentication.jwtToken, id),
    );
    return orderDetails;
  };

  const onChangeOrder = (salesOrder) => {
    setSalesOrderPaymentData({
      ...salesOrderPaymentData,
      salesOrderId: salesOrder?.id,
    });
    // If data.salesOrderPayments is empty, set customer data to the customer of the selected order
    if (!data?.salesOrderPayments || data.salesOrderPayments.length === 0) {
      setCustomerData(salesOrder);
    }
  };

  const onChangeDescription = (description) => {
    setSalesOrderPaymentData({ ...salesOrderPaymentData, description });
  };

  const onChangeAmount = (value) => {
    // Remove non-numeric characters and leading zeroes
    let amount = value.replace(/\D|^0+/g, "");
    // Pad amount with leading zeroes if less than 3 digits
    if (amount.length < 3) {
      amount = amount.padStart(3, "0");
    }
    // Insert decimal point
    amount = amount.slice(0, -2) + "." + amount.slice(-2);
    setSalesOrderPaymentData({ ...salesOrderPaymentData, amount });
  };

  return (
    <>
      {data?.salesOrderPayments?.map((salesOrderPayment) => {
        const order = orderOptions.find(
          (order) => order.id === salesOrderPayment.salesOrderId,
        );
        if(!order)
          return null;
        return (
          <OrderDetail
            key={salesOrderPayment.id}
            summary={
              <Grid container>
                <Link
                  className="custom-link"
                  to={joinPath("/dashboard/orders", order?.id)}
                  target="_blank">
                  #{order?.referenceOrderId}
                </Link>
                <Grid container md={12}>
                  {Object.entries({
                    Amount: `${order?.orderCurrency?.symbol}${salesOrderPayment.amount}`,
                    Description: salesOrderPayment.description,
                  }).map(([key, value]) => (
                    <Grid item xs={12} md={6}>
                      <MDTypography key={key} variant="caption" color="text">
                        {key}:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          textTransform="capitalize">
                          {value}
                        </MDTypography>
                      </MDTypography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            }
            isRefreshable
            actionButtons={
              !data?.status || data?.status === "Draft"
                ? [
                    {
                      label: "delete",
                      icon: <Icon>delete</Icon>,
                      color: "error",
                      onClick: () => {
                        setData({
                          ...data,
                          // Remove items that have the same salesOrderId, amount, and description
                          salesOrderPayments: data.salesOrderPayments.filter(
                            (item) =>
                              item.salesOrderId !==
                                salesOrderPayment.salesOrderId ||
                              item.amount !== salesOrderPayment.amount ||
                              item.description !==
                                salesOrderPayment.description,
                          ),
                          // Update total amount
                          amount: (
                            Number(data.amount) -
                            Number(salesOrderPayment.amount)
                          ).toFixed(2),
                        });
                      },
                    },
                  ]
                : []
            }
            getData={() => getOrderDetail(order?.id)}
            transformData={(salesOrder) => ({
              "Order Date": moment(salesOrder?.orderDate).format("YYYY-MM-DD"),
              "Order Status": salesOrder?.orderStatus,
              "Channel Name": salesOrder?.applicationClient?.label,
              "Order Currency Name": salesOrder?.orderCurrency?.name,
              "Subtotal (inc tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.subtotalIncTax}`,
              "Subtotal (ex tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.subtotalExTax}`,
              "Shipping Cost (inc tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.shippingCostIncTax}`,
              "Shipping Cost (ex tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.shippingCostExTax}`,
              "Handling Cost (inc tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.handlingCostIncTax}`,
              "Handling Cost (ex tax)": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.handlingCostExTax}`,
              "Store Credit": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.storeCreditRedeemed}`,
              "Gift Certificate Amount": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.giftCertificateAmountRedeemed}`,
              "Tax Total": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.taxTotal}`,
              "Refunded Amount": `${salesOrder?.orderCurrency?.symbol}${salesOrder?.refundedAmount}`,
              "Payment Method": `${salesOrder?.paymentMethod}`,
            })}
          />
        );
      })}

      {/* Add new order to payment request */}
      {!data?.status || data?.status === "Draft" ? (
        <MDBox component="form" mt={3} p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
              <MDTypography variant="h5">
                Add a new order to the payment request
              </MDTypography>
            </Grid>
            {/* Orders */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={orders}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                disableClearable
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Orders"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Add an order"
                    required
                  />
                )}
                value={
                  orders?.find(
                    (order) =>
                      order?.id === salesOrderPaymentData?.salesOrderId,
                  ) || null
                }
                onChange={(e, newValue) => onChangeOrder(newValue)}
              />
            </Grid>
            {/* Amount */}
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                label="Amount"
                InputLabelProps={{ shrink: true }}
                placeholder="Enter amount"
                onChange={(e) => onChangeAmount(e.target.value)}
                value={salesOrderPaymentData?.amount || "0.00"}
                required
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="Description"
                freeSolo
                options={presetPaymentDescriptionOptions}
                sx={{
                  // border: "1px solid blue",
                  "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  },
                }}
                onChange={(e, value) => onChangeDescription(value)}
                value={salesOrderPaymentData.description || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Description"
                    placeholder="Add a description, e.g. Deposit, Shipping, etc."
                    value={salesOrderPaymentData.description || ""}
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={(e) => onChangeDescription(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDButton
                type="button"
                variant="contained"
                color="dark"
                onClick={(e) => onAddOrder(e)}
                style={{
                  width: "100%",
                }}>
                Add order
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      ) : null}
    </>
  );
}

export default OrderInfo;
