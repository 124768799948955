/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { displayDate } from 'utils/date';
function BasicDeviceModelInfo({ deviceModelInfo }) {
  const [currentDeviceModelInfo, setCurrentDeviceModelInfo] = React.useState(
    {}
  );

  React.useEffect(() => {
    const info = deviceModelInfo ?? {};
    setCurrentDeviceModelInfo(info);
  }, [deviceModelInfo]);

  return (
    <Card id="basic-device-model-info" sx={{ overflow: 'visible' }}>
      {/* <MDBox p={3} pb={0}>
        <MDTypography variant="h6">Customer</MDTypography>
      </MDBox> */}
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={1} pt={1}>
          {/* basic information */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Device Model information
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox key="name" display="flex" mt={0.5} mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Model: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentDeviceModelInfo.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox key="sku" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      SKU: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentDeviceModelInfo?.sku}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="upc" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      UPC: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentDeviceModelInfo?.upc}
                    </MDTypography>
                  </MDBox>

                  <MDBox key="ean" display="flex" mb={0.5} pr={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      EAN: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text">
                      {currentDeviceModelInfo?.ean}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {/* activities */}
          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />
            <Card sx={{ boxShadow: 'none' }}>
              <MDBox pb={2} lineHeight={1.25}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize">
                  Activities
                </MDTypography>
                <MDBox alignItems="center" mb={0.5}>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Created Since:{' '}
                      <b>
                        {currentDeviceModelInfo?.createdAt
                          ? displayDate(currentDeviceModelInfo?.createdAt)
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <MDTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize">
                      Last Updated:{' '}
                      <b>
                        {currentDeviceModelInfo?.updatedAt
                          ? displayDate(currentDeviceModelInfo?.updatedAt)
                          : '-'}
                      </b>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicDeviceModelInfo;
